import { useMemo } from 'react';
import moment from 'moment';
import { CvdRiskLog } from '@hdcorner/ui-library';

const usePrepareGraphData = (graphData?: { logs: CvdRiskLog[] }) => {
  return useMemo(() => {
    if (!graphData || graphData.logs.length === 0) return [];

    return graphData.logs.map(item => ({
      y: item.score,
      x: moment(item.createdAt).toISOString(),
    }));
  }, [graphData]);
};

export default usePrepareGraphData;
