import { useAppDispatch, useAppSelector } from './redux/hooks';
import Routes from './routes';
import React, { useEffect } from 'react';
import { getAccessToken, getRefreshToken } from './utils/TokenStorage';
import LoadingPage from './features/authentication/components/LoadingPage';
import { useLazyGetAuthUserQuery } from './features/authentication/queries/signInQueries';
import { useLazyGetUserDataQuery } from './features/profile/queries/personalInfoQueries';
import { setAccessToken, setRefreshToken } from './features/authentication/authSlice';
import { disconnectSocket, initializeSocket } from './features/chat/socket';
import { useTranslation } from 'react-i18next';
import { useLazyGetUserSettingsQuery } from './features/profile/queries/userSettingsQueries';

const AppStack = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const {
    data: { token },
    meta: { authResolved },
  } = useAppSelector(state => state.auth);

  const [
    getAuthUser,
    { isLoading: authUserLoading, isUninitialized: authUserIsUninitialized },
  ] = useLazyGetAuthUserQuery();

  const [
    getUserData,
    { isLoading: userDataLoading, isUninitialized: userDataIsUninitialized },
  ] = useLazyGetUserDataQuery();

  const [getUserSettings] = useLazyGetUserSettingsQuery();

  const setLangFromDevice = () => {
    const languageFull = window.navigator.language;
    const langSplit = languageFull.split('-');
    const lang = langSplit[0] ? langSplit[0] : 'en';
    if (lang === 'en') {
      i18n.changeLanguage('en');
    } else if (lang === 'el') {
      i18n.changeLanguage('el');
    } else {
      i18n.changeLanguage('en');
    }
  };

  useEffect(() => {
    if (token) {
      getAuthUser();
      getUserData();
      getUserSettings()
        .unwrap()
        .then(res => {
          const settings = res;
          if (settings && settings.length > 0) {
            const data = settings[0];
            if (data) {
              if (data.selectedLang === 'en') {
                i18n.changeLanguage('en');
              } else if (data.selectedLang === 'el') {
                i18n.changeLanguage('el');
              } else {
                i18n.changeLanguage('en');
              }
            } else {
              setLangFromDevice();
            }
          } else {
            setLangFromDevice();
          }
        })
        .catch(() => {
          console.log('getUserSettings error');
          setLangFromDevice();
        });
      initializeSocket(token);
    }
    if (!token) {
      getAccessToken().then(token => {
        dispatch(setAccessToken(token));
      });
      getRefreshToken().then(accessToken => {
        dispatch(setRefreshToken(accessToken));
      });
      setLangFromDevice();
    }

    return () => {
      disconnectSocket();
    };
  }, [token]);

  const render = () => {
    let isLoading;

    if (!authResolved) {
      isLoading = true;
    } else {
      if (!token) {
        isLoading = false;
      } else {
        if (authUserIsUninitialized || userDataIsUninitialized) {
          isLoading = true;
        } else isLoading = !!(userDataLoading || authUserLoading);
      }
    }

    if (isLoading) {
      return <LoadingPage web={false} />;
    } else {
      return <Routes />;
    }
  };

  return render();
};

export default AppStack;
