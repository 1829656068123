import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import {
  FloatingActionButton,
  HeaderBar,
  SnackbarAlert,
  theme,
} from '@hdcorner/ui-library';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from '../features/notifications/queries/notificationQueries';
import { RootState } from '../redux/store';
import { getScreenName } from '../utils/getScreenName';
import { hideNavigation } from '../utils/hideNavigation';
import { logout } from '../features/authentication/authSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { removeAlert, shrinkBarToggle } from '../redux/slices/appSlice';
import { useSignOutMutation } from '../features/authentication/queries/signInQueries';
import { Box, styled, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import useAlert from '../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography, {
  shouldForwardProp: prop => prop !== 'headerColor',
})<TypographyProps & { headerColor: CSSProperties['color'] }>(
  ({ theme, headerColor }) => ({
    maxWidth: '325px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(1),
    color: headerColor ? headerColor : theme.palette.primary.main,
  }),
);

type ButtonsType = {
  disabled?: boolean;
  onClick?: () => void;
  icon: React.ReactNode;
};

type PageLayoutType = {
  children: any;
  defaultHref?: string;
  fabClick?: () => void;
  unreadMessages?: number;
  fabIcon?: React.ReactNode;
  backgroundWhite?: boolean;
  endButtons?: ButtonsType[];
  headerTitle?: string | null;
  headerIcon?: React.ReactNode;
  headerColor?: CSSProperties['color'];
};
const PageLayout: FC<PageLayoutType> = ({
  fabIcon,
  fabClick,
  children,
  headerIcon,
  headerTitle,
  headerColor,
  defaultHref,
  unreadMessages,
  endButtons = [],
  backgroundWhite,
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { presentError } = useAlert();

  const pathname = router?.routeInfo?.pathname;
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const shrunkSideBar = useAppSelector((state: RootState) => state.layout.shrunkSideBar);

  const {
    data: { user },
    meta: { isAuthenticated },
  } = useAppSelector(state => state.auth);

  const {
    open: alertOpen,
    message: alertMessage,
    severity: alertSeverity,
  } = useAppSelector(state => state.layout.alert);

  const { data: notificationRes } = useGetNotificationsQuery(
    {
      skip: 0,
      limit: 50,
    },
    { skip: !isAuthenticated },
  );

  const [logoutTrigger] = useSignOutMutation();
  const [readNotification] = useReadNotificationMutation();

  const notifications = useMemo(() => {
    if (!notificationRes || notificationRes.notifications.length === 0) return [];
    return notificationRes.notifications;
  }, [notificationRes]);

  const unReadNotis = useMemo(() => {
    if (!notificationRes) return 0;
    return notificationRes.unreadCount;
  }, [notificationRes]);

  const currentScreen = useMemo(
    () => getScreenName(router?.routeInfo?.pathname),
    [router?.routeInfo?.pathname],
  );

  const getUser = useMemo(() => {
    if (user) {
      const authUser = user.authUser;
      if (authUser && typeof authUser === 'object') {
        return {
          verified: user.verified,
          avatarSrc: authUser.profilePicture?.url || '',
          username: authUser.firstName + ' ' + authUser.lastName,
        };
      }
    }

    return {
      avatarSrc: '',
      username: t('general.labels.nA'),
    };
  }, [user]);

  const handleShrinkBar = useCallback(() => {
    dispatch(shrinkBarToggle());
  }, [dispatch]);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      dispatch(removeAlert());
    },
    [dispatch],
  );

  const handleClickAccountSettings = () => {
    router.push('/dashboard/profile-settings', 'forward', 'replace');
  };

  const handleClickLogout = () => {
    logoutTrigger()
      .unwrap()
      .finally(() => {
        dispatch(logout());
        router.push('/sign-in', 'root', 'replace');
      });
  };

  const handleClickMessages = () => {
    router.push('/dashboard/chat', 'forward', 'replace');
  };

  const handleAboutUs = () => {
    router.push('/dashboard/about-us', 'forward', 'replace');
  };

  const handleMarkAsRead = (id: string) => {
    readNotification({ id: id })
      .unwrap()
      .catch(() => {
        presentError(t('errors.notifications.errorRead'));
      });
  };

  const renderToolbar = () => {
    const minimizeToolbar = !headerIcon && !headerTitle && endButtons.length === 0;

    const isHome = pathname.includes('/home');

    return (
      <IonToolbar style={{ height: minimizeToolbar ? '0px' : 'inherit' }}>
        {!isHome && (
          <IonButtons slot="start">
            <IonBackButton text={''} color={'dark'} defaultHref={defaultHref} />
          </IonButtons>
        )}
        <IonTitle style={{ paddingInline: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {headerIcon}
            <Title variant={'h1'} headerColor={headerColor}>
              {headerTitle}
            </Title>
          </Box>
        </IonTitle>
        <IonButtons slot="end">
          {endButtons.map((item, index) => (
            <IonButton
              onClick={item.onClick}
              disabled={item.disabled}
              key={`endButton${index}`}
            >
              {item.icon}
            </IonButton>
          ))}
        </IonButtons>
      </IonToolbar>
    );
  };

  const renderHeader = () => {
    if (!smallScreen) {
      if (hideNavigation(pathname)) {
        return null;
      }

      return (
        <HeaderBar
          hcpApp
          user={getUser}
          notifications={notifications}
          shrunkSideBar={shrunkSideBar}
          currentScreen={currentScreen}
          handleShrinkBar={handleShrinkBar}
          unreadNotifications={unReadNotis}
          handleClickAboutUs={handleAboutUs}
          unreadMessages={unreadMessages ?? 0}
          handleClickLogout={handleClickLogout}
          onNotificationClick={handleMarkAsRead}
          handleClickMessages={handleClickMessages}
          handleClickAccountSettings={handleClickAccountSettings}
        />
      );
    }

    return (
      <IonHeader mode={'ios'} className="ion-no-border">
        {renderToolbar()}
      </IonHeader>
    );
  };

  const renderFooter = () => {
    if (!smallScreen) {
      return null;
    }

    return (
      <IonFooter className="ion-no-border">
        <IonToolbar style={{ height: 0 }}></IonToolbar>
      </IonFooter>
    );
  };

  return (
    <IonPage>
      {renderHeader()}
      <IonContent class={backgroundWhite ? 'pageWhite' : undefined}>
        <SnackbarAlert
          open={alertOpen}
          message={alertMessage}
          severity={alertSeverity}
          handleClose={handleClose}
        />
        {children}
      </IonContent>
      {renderFooter()}
      {fabClick ? <FloatingActionButton onClick={fabClick} icon={fabIcon} /> : null}
    </IonPage>
  );
};

export default PageLayout;
