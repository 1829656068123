import api from '../../../../redux/api';
import { User24HourHypertension } from '@hdcorner/ui-library';

const twentyFourHoursQueries = api
  .enhanceEndpoints({ addTagTypes: ['HypertensionLog'] })
  .injectEndpoints({
    endpoints: build => ({
      getTwentyFourHours: build.query<
        { documents: User24HourHypertension[]; count: number },
        { userId: string; skip: number; limit: number; start?: string; end?: string }
      >({
        query: ({ skip, limit, start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getUserTwentyHourHypertension',
          params: { skip, limit, start, end, scope: `BasicHealth:${userId}` },
        }),
        providesTags: ['HypertensionLog'],
      }),
      downloadPdfReport: build.query<string, { id: string; userId: string }>({
        query: ({ userId, id }) => ({
          method: 'POST',
          params: { hypertension24HourId: id, userId },
          url: '/hdCornerService/user/hypertension/24-hour-pdf',
        }),
      }),
    }),
  });

export const { useGetTwentyFourHoursQuery, useLazyDownloadPdfReportQuery } =
  twentyFourHoursQueries;

export default twentyFourHoursQueries;
