import SectionHeading from '../../components/SectionHeading';
import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import WellnessCards from './WellnessCards';
import SleepIcon from '../../../../assets/icons/SleepIcon';
import WeightIcon from '../../../../assets/icons/WeightIcon';
import { useIonRouter } from '@ionic/react';
import { useGetTodaysLastWeightLogQuery } from '../queries/weightQueries';
import { useGetTodaysLastSleepLogQuery } from '../queries/sleepQueries';
import useAlert from '../../../../hooks/useAlert';
import { useGetUserDetailsQuery } from '../../../Users/queries/usersQueries';
import { useTranslation } from 'react-i18next';
import { BMIIndicator, calculateBMI, categoryBMI } from '@hdcorner/ui-library';

interface Props {
  userId: string;
}
const SectionWellness: React.FC<Props> = ({ userId }) => {
  const router = useIonRouter();
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { data: userData, error: errorUser } = useGetUserDetailsQuery({ userId });
  const { data: todaySleepLog, error: errorSleep } = useGetTodaysLastSleepLogQuery({
    userId,
  });
  const { data: todayWeightLog, error: errorWeight } = useGetTodaysLastWeightLogQuery({
    userId,
  });

  useEffect(() => {
    if (errorUser) presentError(t('errors.generic.errorFetchUser'));
    if (errorSleep) presentError(t('errors.fitness.wellness.errorSleepFetch'));
    if (errorWeight) presentError(t('errors.fitness.wellness.errorWeightFetch'));
  }, [errorWeight, errorSleep, errorUser]);

  const weight = useMemo(() => {
    if (!todayWeightLog || todayWeightLog.documents.length === 0) return '-';
    return todayWeightLog.documents[0].weight.toString();
  }, [todayWeightLog]);

  const sleep = useMemo(() => {
    if (!todaySleepLog || todaySleepLog.documents.length === 0) return '-';
    return todaySleepLog.documents[0].quantity.toFixed(2).toString();
  }, [todaySleepLog]);

  const userHeight = useMemo(() => {
    if (!userData) return 0;
    return userData.data.height || 0;
  }, [userData]);

  const BMI = useMemo(() => {
    if (!todayWeightLog || todayWeightLog.documents.length === 0) return '-';
    return calculateBMI(userHeight, parseFloat(weight));
  }, [todayWeightLog, userHeight, weight]);

  const handleViewMore = () => {
    router.push(`/dashboard/users/${userId}/fitness/wellness`);
  };

  return (
    <Box gap={1} flexGrow={1} width={'100%'} display={'flex'} flexDirection={'column'}>
      <SectionHeading
        hasGoal={false}
        viewMoreClick={handleViewMore}
        heading={t('headingsTitles.wellness')}
      />
      <Box display={'flex'} flexGrow={1} gap={1} flexDirection={'column'}>
        <WellnessCards
          amount={weight}
          color={'#E93C16'}
          icon={<WeightIcon />}
          onClick={handleViewMore}
          unit={`${t('measurements.kg')}`}
          title={t('fitness.wellness.labels.bodyWeight')}
        >
          {BMI && (
            <BMIIndicator
              bmi={BMI}
              weight={BMI === '-' ? categoryBMI(0) : categoryBMI(parseFloat(BMI))}
            />
          )}
        </WellnessCards>
        <WellnessCards
          amount={sleep}
          color={'#7456B6'}
          icon={<SleepIcon />}
          unit={`${t('measurements.hours')}`}
          title={t('fitness.wellness.labels.sleepTim')}
        />
      </Box>
    </Box>
  );
};

export default SectionWellness;
