import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  CalendarModal,
  CustomButton,
  indepthDataTime,
  ModalMobile,
  PDFIcon,
  theme,
  ToggleButtons,
  ToggleCard,
  XLSIcon,
} from '@hdcorner/ui-library';
import {
  Box,
  CircularProgress,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import moment from 'moment/moment';
import { useDownloadInDepthDataMutation } from '../redux/queries/queries';
import useAlert from '../hooks/useAlert';
import { MeasurementOptions } from '../features/hypertension/constants';
import { FilterOptions } from '../features/diabetes/constants';
import { downloadFile } from '../utils/Filesystem';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../utils/useConvertJSON';

const TypographyButton = styled(Typography, {
  shouldForwardProp: prop => prop !== 'pdf',
})<TypographyProps & { pdf: boolean }>(({ theme, pdf }) => ({
  color: pdf ? '#D75148' : '#2FAD72',
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
}));

export type ModalInDepthDataDLProps = {
  open: boolean;
  module:
    | 'cvd'
    | 'esh'
    | 'hbac1'
    | 'lipid'
    | 'sleep'
    | 'steps'
    | 'weight'
    | 'calories'
    | 'activities'
    | 'hypertension'
    | 'blood_glucose';
  dismiss: () => void;
};

const ModalInDepthDataDL: FC<ModalInDepthDataDLProps> = ({ open, module, dismiss }) => {
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();
  const defaultHeadings = useConvertJSON(indepthDataTime);
  const eshHeadings = useConvertJSON([
    { label: 'constants.toggleValues.dates.week', value: 'week' },
    { label: 'constants.toggleValues.dates.custom', value: 'custom' },
  ]);

  const [value, setValue] = useState<string>('week');
  const [selectedDate, setSelectedDate] = useState<string[]>([]);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [headings, setHeadings] = useState<{ label: string; value: string }[]>([]);
  const [measurement, setMeasurement] = useState<string>(
    module === 'hypertension' ? 'all' : 'default',
  );

  const [trigger, { isLoading }] = useDownloadInDepthDataMutation();

  useEffect(() => {
    if (module === 'esh') {
      setHeadings(eshHeadings);
    } else {
      setHeadings(defaultHeadings);
    }
  }, [module]);

  const handleChange = (newValue: string) => {
    if (newValue === 'custom') {
      setCalendarOpen(true);
    }
    setValue(newValue);
  };

  const handleChangeDate = useCallback((key: string[]) => {
    setSelectedDate(key);
  }, []);

  const handleDownloadButton = (format: 'pdf' | 'excel') => {
    let diabetesCategory: string | undefined;
    let hypertensionCategory: string | undefined;
    let start: string | undefined, end: string | undefined;

    if (value === 'custom') {
      start = moment(selectedDate[0]).utc(true).startOf('day').toISOString();
      end = moment(selectedDate[1]).utc(true).endOf('day').toISOString();
    }
    if (value === 'month') {
      start = moment().utc(true).startOf('month').toISOString();
      end = moment().utc(true).endOf('month').toISOString();
    }
    if (value === 'year') {
      start = moment().utc(true).startOf('year').toISOString();
      end = moment().utc(true).endOf('year').toISOString();
    }
    if (module === 'hypertension') {
      hypertensionCategory = measurement === 'all' ? undefined : measurement;
    }
    if (module === 'blood_glucose') {
      diabetesCategory = measurement === 'default' ? undefined : measurement;
    }
    trigger({ module, format, start, end, diabetesCategory, hypertensionCategory })
      .unwrap()
      .then((res: any) => {
        if (res && res.uploadUrl) {
          presentSuccess(t('errors.generic.successDLStarting'));
          return res.uploadUrl;
        } else {
          throw Error(`${t('errors.generic.error01')}`);
        }
      })
      .then(url => {
        const filename = `${module}-${start && end ? `${start}-${end}` : 'all'}-data.${
          format === 'pdf' ? 'pdf' : 'xlsx'
        }`;
        return downloadFile(url, filename);
      })
      .catch(err => {
        console.log(err);
        const { data, status } = err;
        if (data && status === 404) {
          const { message } = data;
          presentError(message);
        } else {
          presentError(t('errors.generic.error01'));
        }
      })
      .finally(() => {
        dismiss();
      });
  };

  return (
    <>
      <ModalMobile open={open} width={'344px'} height={'auto'} setOpen={dismiss}>
        <Box
          gap={3}
          padding={2}
          display={'flex'}
          flexDirection={'column'}
          boxSizing={'border-box'}
        >
          <Box
            gap={3}
            display={'flex'}
            flexDirection={'column'}
            sx={{
              filter: isLoading ? 'blur(2px)' : 'none',
              pointerEvents: isLoading ? 'none' : 'auto',
              WebkitFilter: isLoading ? 'blur(2px)' : 'none',
            }}
          >
            <Box>
              <Typography
                align={'left'}
                variant={'subtitle2'}
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              >
                {t('inDepth.dlInDepth')}
              </Typography>
            </Box>
            <Box>
              <ToggleButtons
                value={value}
                size={'medium'}
                headings={headings}
                handleChange={handleChange}
              />
            </Box>
            {module === 'hypertension' && (
              <Box>
                {MeasurementOptions.map(category => (
                  <ToggleCard
                    key={category.value}
                    value={category.value}
                    textTransform={'none'}
                    label={t(category.label)}
                    selected={category.value === measurement}
                    backgroundColor={theme.palette.secondary.main}
                    setSelected={() => setMeasurement(category.value)}
                  />
                ))}
              </Box>
            )}

            {module === 'blood_glucose' && (
              <Box>
                {FilterOptions.map(category => (
                  <ToggleCard
                    key={category.value}
                    value={category.value}
                    textTransform={'none'}
                    label={t(category.label)}
                    selected={category.value === measurement}
                    backgroundColor={theme.palette.secondary.main}
                    setSelected={() => setMeasurement(category.value)}
                  />
                ))}
              </Box>
            )}

            <Box display={'flex'} justifyContent={'space-between'}>
              <CustomButton
                variant={'outlined'}
                onClick={() => handleDownloadButton('pdf')}
                sx={{ display: module === 'esh' ? 'none' : 'flex' }}
              >
                <Box display={'flex'} gap={1}>
                  <PDFIcon />
                  <TypographyButton pdf>{t('buttons.pdfDL')}</TypographyButton>
                </Box>
              </CustomButton>

              <CustomButton
                variant={'outlined'}
                onClick={() => handleDownloadButton('excel')}
              >
                <Box display={'flex'} gap={1}>
                  <XLSIcon />
                  <TypographyButton pdf={false}>{t('buttons.xlsDL')}</TypographyButton>
                </Box>
              </CustomButton>
            </Box>
          </Box>
          {isLoading && (
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                align={'left'}
                variant={'subtitle2'}
                sx={{
                  color: theme.palette.text.primary,
                }}
              >
                {t('general.text.preparingData')}
              </Typography>
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
      </ModalMobile>
      <CalendarModal
        open={calendarOpen}
        selectedDate={selectedDate}
        saveDate={date => handleChangeDate(date)}
        setOpen={() => setCalendarOpen(false)}
      />
    </>
  );
};

export default ModalInDepthDataDL;
