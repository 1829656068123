import React, { FC } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ArrowRight,
  CustomButton,
  CustomInput,
  FrequencyItemType,
  GeneralList,
} from '@hdcorner/ui-library';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useTranslation } from 'react-i18next';

const TimeCard: FC<{
  date?: string;
  amountAndType?: string;
  onClick: () => void;
}> = ({ date, amountAndType, onClick }) => {
  const theme = useTheme();

  return (
    <Box
      flex={1}
      height={35}
      display={'flex'}
      onClick={onClick}
      borderRadius={'8px'}
      sx={{ cursor: 'pointer' }}
      bgcolor={theme.palette.highlight.main}
    >
      <Box
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingX={theme.spacing(2)}
      >
        <Box display={'flex'} flex={1} alignItems={'center'}>
          <Typography
            variant={'subtitle2'}
            sx={{ color: theme.palette.primary.main, mr: theme.spacing(1) }}
          >
            {date}
          </Typography>
          <Typography
            variant={'body1'}
            sx={{ color: theme.palette.kmColorsCoolGrey.main }}
          >
            {amountAndType ?? ''}
          </Typography>
        </Box>
        <ArrowRight />
      </Box>
    </Box>
  );
};

const TimeList: FC<{
  frequenciesArray: FrequencyItemType[];
  handleClickAddFrequency: () => void;
  handleClickCard: (ind: number) => void;
}> = ({ frequenciesArray, handleClickAddFrequency, handleClickCard }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant={'body1'}
        sx={{
          mb: 1,
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightRegular,
        }}
      >
        {t('general.labels.time')}
      </Typography>
      <GeneralList listProps={{ sx: { background: theme.palette.secondary.main } }}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {frequenciesArray.map((item, index) => (
            <Box display={'flex'} alignItems={'center'} key={index} gap={2}>
              <TimeCard
                date={item.time}
                onClick={() => handleClickCard(index)}
                amountAndType={`${item.amount ?? 1} (unit here)`}
              />
              {index === frequenciesArray?.length - 1 && (
                <CustomButton
                  color={'secondary'}
                  variant={'contained'}
                  onClick={handleClickAddFrequency}
                >
                  <AddOutlinedIcon htmlColor={theme.palette.secondary.light} />
                </CustomButton>
              )}
            </Box>
          ))}
        </Box>
      </GeneralList>
    </Box>
  );
};

const FreqToggleCard: FC<{
  title: number;
  web?: boolean;
  isSelected: boolean;
  onClick: () => void;
}> = ({ title, isSelected, onClick, web }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleBackground = () => {
    if (web) {
      return isSelected ? theme.palette.primary.main : theme.palette.secondary.main;
    }

    return isSelected ? theme.palette.secondary.dark : theme.palette.secondary.light;
  };

  return (
    <Box
      px={2}
      py={1}
      borderRadius={1}
      onClick={onClick}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'start',
        background: handleBackground(),
      }}
    >
      <Typography
        variant={'subtitle2'}
        color={
          isSelected
            ? web
              ? theme.palette.secondary.main
              : theme.palette.primary.main
            : theme.palette.primary.main
        }
      >
        {web ? t(ReturnNameFromNumber(title)) : t(ReturnNameFromNumberMobile(title))}
      </Typography>
    </Box>
  );
};

const ReturnNameFromNumber = (number: number): string => {
  return number >= 1 && number <= 4
    ? ['numbers.zero', 'numbers.one', 'numbers.two', 'numbers.three', 'numbers.four'][
        number
      ]
    : number.toString();
};

const ReturnNameFromNumberMobile = (number: number): string => {
  return number >= 1 && number <= 4
    ? [
        'numbers.zero',
        'medication.labels.oncePerDay',
        'numbers.2xday',
        'numbers.3xday',
        'numbers.4xday',
      ][number]
    : number.toString();
};

type Props = {
  frequencyAmount: number;
  handleClickAddFrequency: () => void;
  frequenciesArray: FrequencyItemType[];
  handleClickCard: (index: number) => void;
  setFrequencyAmount: (num: number) => void;
};

const MedicationFrequency: FC<Props> = ({
  handleClickCard,
  setFrequencyAmount,
  handleClickAddFrequency,
  frequenciesArray,
  frequencyAmount,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  //FIXME maximum frequency amount
  const handleSetFrequency = (option: string) => {
    setFrequencyAmount(parseInt(option));
  };

  const handleSetFrequencyAmount = (amount: string) => {
    setFrequencyAmount(amount === '0' ? 1 : parseInt(amount));
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      {web && (
        <Typography
          variant={'subtitle2'}
          sx={{
            mb: 1,
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {t('medication.text.frequency')}
        </Typography>
      )}
      <Box display={'flex'} flexDirection={web ? 'row' : 'column'} gap={1}>
        {[1, 2, 3, 4]?.map((item, index) => (
          <FreqToggleCard
            web={web}
            key={index}
            title={item}
            isSelected={frequencyAmount === item}
            onClick={() => handleSetFrequency(item.toString())}
          />
        ))}
      </Box>
      <CustomInput
        fullWidth
        type={'number'}
        alternativeBackground={!web}
        value={frequencyAmount?.toString()}
        handleChange={handleSetFrequencyAmount}
        label={`${t('medication.labels.customAmount')}`}
        placeholder={`${t('medication.labels.howMany')}`}
        inputProps={{
          min: 1,
          max: 20,
          pattern: '[0-9]',
          inputMode: 'numeric',
        }}
        sx={{
          margin: theme.spacing(6, 0, 3, 0),
          background: theme.palette.highlight.main,
          borderColor: theme.palette.highlight.main,
        }}
      />
      <TimeList
        handleClickCard={handleClickCard}
        frequenciesArray={frequenciesArray}
        handleClickAddFrequency={handleClickAddFrequency}
      />
    </Box>
  );
};

export default MedicationFrequency;
