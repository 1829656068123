import React, { FC, useMemo } from 'react';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import FETableInner from './components/FETableInner';
import { FEData, FEDataEL } from '@hdcorner/ui-library';
import { RouteComponentProps } from 'react-router';

interface Props
  extends RouteComponentProps<{
    userId: string;
    id: string;
  }> {}
const FoodEquivalentsTable: FC<Props> = ({
  match: {
    params: { userId, id },
  },
}) => {
  const router = useIonRouter();

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const feEN = FEData();
  const feEL = FEDataEL();

  const selection = router?.routeInfo?.pathname?.split('/').slice(-1);
  const category = useMemo(() => {
    if (lang === 'el') return feEL.find((it: any) => it.type === selection[0]);
    return feEN.find((it: any) => it.type === selection[0]);
  }, [lang]);
  const title = category?.title || '';

  return (
    <PageLayout headerTitle={title} defaultHref={`/dashboard/users/${userId}/fitness`}>
      <FETableInner header={title} lang={lang} />
    </PageLayout>
  );
};

export default FoodEquivalentsTable;
