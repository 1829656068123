import {
  ChatDateInfo,
  ChatMessageCard,
  ChatMessageMobile,
  DropdownTrigger,
  NewMessageDivider,
  SupportChatMessageCard,
  theme,
} from '@hdcorner/ui-library';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { Box } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import {
  useCreateSupportChatRoomMutation,
  useGetChatMessagesQuery,
  useGetChatRoomQuery,
  useGetSupportChatRoomQuery,
  useLeaveChatRoomMutation,
  useResolveSupportChatRoomMutation,
} from './queries/chatQueries';
import { usePrepareChatMessageListData } from './hooks/usePrepareChatMessageData';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { usePrepareChatRoomData } from './hooks/usePrepareChatRoomData';
import { useIonRouter } from '@ionic/react';
import { emitReadMsg, emitSendMsg } from './socket';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    chatId: string;
  }> {}

const MESSAGES_PER_PAGE = 25;

const ChatMessage: FC<Props> = ({ match }) => {
  const router = useIonRouter();
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  const userId = useAppSelector(
    (state: RootState) => state.auth.data.user?.authUser?._id,
  );

  /* SUPPORT CHAT ROOM */
  const { data: supportChatRoomData, error: errorSupport } = useGetSupportChatRoomQuery();
  const [createSupportChat] = useCreateSupportChatRoomMutation();
  const [resolveSupportChat] = useResolveSupportChatRoomMutation();

  /* SELECTED CHAT ROOM */
  const { data: selectedChatRoomData, error: errorSelectedChat } = useGetChatRoomQuery(
    { roomId: match.params.chatId ?? '' },
    { skip: !match.params.chatId || match.params.chatId === 'support' },
  );

  const selectedChatRoomType = useMemo(
    () =>
      match.params.chatId === 'support'
        ? supportChatRoomData?.chatRoom
        : match.params.chatId
        ? selectedChatRoomData
        : undefined,
    [match.params.chatId, selectedChatRoomData, supportChatRoomData?.chatRoom],
  );

  const selectedChatRoom = usePrepareChatRoomData(userId, selectedChatRoomType);

  /* CHAT MESSAGE LIST */
  const [messageListSkip, setMessageListSkip] = useState<number>(0);
  const {
    data: chatMessageListData,
    isFetching: chatMessageListFetching,
    error: errorChatMessage,
  } = useGetChatMessagesQuery(
    {
      roomId: selectedChatRoomType?._id ?? '',
      skip: messageListSkip,
      limit: MESSAGES_PER_PAGE,
    },
    { skip: !selectedChatRoomType?._id },
  );
  const chatMessageList = usePrepareChatMessageListData(
    userId,
    chatMessageListData?.messages,
    selectedChatRoomType?.participants,
  );

  /* LEAVE CHAT */
  const [leaveChat] = useLeaveChatRoomMutation();

  const filteredMessages = useMemo(() => {
    const firstRead = chatMessageList?.findIndex(message => message.read);
    return {
      unread: chatMessageList?.slice(0, firstRead),
      read: chatMessageList?.slice(firstRead),
    };
  }, [chatMessageList]);

  const handleLoadMoreMessages = useCallback(() => {
    if (chatMessageListFetching) return;
    if (
      !chatMessageListData?.count ||
      chatMessageListData?.count <= messageListSkip + MESSAGES_PER_PAGE
    )
      return;
    setMessageListSkip(messageListSkip + MESSAGES_PER_PAGE);
  }, [chatMessageListData?.count, chatMessageListFetching, messageListSkip]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;
    const isNear =
      container.scrollTop <= (container.clientHeight - container.scrollHeight) * 0.8;
    if (isNear) {
      handleLoadMoreMessages();
    }
  }, [handleLoadMoreMessages, containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, containerRef]);

  const handleSendMessage = useCallback(
    (message: string) => {
      if (!selectedChatRoomType) {
        if (match.params.chatId === 'support') {
          createSupportChat({ message });
          console.log('Creating support chat', message);
        }
        return;
      }
      console.log('Sending message', selectedChatRoomType._id, message);
      emitSendMsg(selectedChatRoomType._id, message);
    },
    [createSupportChat, match.params.chatId, selectedChatRoomType],
  );

  const handleReadMessage = useCallback(() => {
    if (
      !selectedChatRoomType ||
      !chatMessageListData?.messages ||
      selectedChatRoomType._id !== chatMessageListData.messages?.[0]?.room
    )
      return;
    console.log('Reading message', selectedChatRoomType._id);
    emitReadMsg(selectedChatRoomType._id);
  }, [chatMessageListData?.messages, selectedChatRoomType]);

  useEffect(() => {
    handleReadMessage();
  }, [handleReadMessage]);

  const handleLeaveChat = useCallback(() => {
    if (!match.params.chatId) return;
    leaveChat({ roomId: match.params.chatId });
    router.push('/dashboard/chat', 'back', 'replace');
  }, [leaveChat, match.params.chatId, router]);

  const handleResolveSupportChat = useCallback(() => {
    if (match.params.chatId !== 'support' || !supportChatRoomData?._id) return;
    resolveSupportChat({ supportRoomId: supportChatRoomData?._id });
    router.push('/dashboard/chat', 'back', 'replace');
  }, [match.params.chatId, resolveSupportChat, router, supportChatRoomData?._id]);

  useEffect(() => {
    if (errorChatMessage || errorSelectedChat || errorSupport)
      presentError(t('errors.chat.fetchChatData'));
  }, [errorChatMessage, errorSelectedChat, errorSupport]);

  const options = useMemo(() => {
    return match.params.chatId === 'support'
      ? [
          {
            isDelete: false,
            icon: <DoneAllIcon />,
            text: t('chat.buttons.resolve'),
            onClick: handleResolveSupportChat,
          },
        ]
      : [
          {
            isDelete: false,
            icon: <ExitToAppIcon />,
            onClick: handleLeaveChat,
            text: t('chat.buttons.leave'),
          },
        ];
  }, [handleLeaveChat, handleResolveSupportChat, match.params.chatId]);

  const handleAddContent = () => {
    console.log('Add content');
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/chat'}
      headerTitle={
        match.params.chatId === 'support'
          ? t('chat.titles.support')
          : selectedChatRoom?.title
      }
      endButtons={[
        {
          icon: <DropdownTrigger options={options} />,
        },
      ]}
    >
      <Box display={'flex'} height={'100%'} flexDirection={'column'}>
        <Box flexGrow={1} display={'flex'} flexDirection={'column'} overflow={'hidden'}>
          <Box
            flexGrow={1}
            display={'flex'}
            ref={containerRef}
            boxSizing={'border-box'}
            flexDirection={'column-reverse'}
            sx={{ overflowY: 'auto', overflowX: 'hidden' }}
            padding={theme.spacing(1, 2, 0, 2)}
          >
            {filteredMessages.unread?.map(message => (
              <ChatMessageCard
                key={message.id}
                avatar={message.avatar}
                message={message.message}
                currentUser={message.currentUser}
                name={`${message.firstName} ${message.lastName}`}
                date={moment(message.date).format('HH:mm')}
              />
            ))}
            {filteredMessages.unread && filteredMessages.unread.length ? (
              <>
                <ChatDateInfo
                  date={moment(
                    filteredMessages.unread[filteredMessages.unread.length - 1].date,
                  ).format('DD/MM/YYYY')}
                />
                <NewMessageDivider />
              </>
            ) : null}
            {filteredMessages.read?.map(message => (
              <ChatMessageCard
                key={message.id}
                avatar={message.avatar}
                message={message.message}
                currentUser={message.currentUser}
                name={`${message.firstName} ${message.lastName}`}
                date={moment(message.date).format('HH:mm')}
              />
            ))}
            {match.params.chatId === 'support' && supportChatRoomData?.message ? (
              <SupportChatMessageCard message={supportChatRoomData?.message} />
            ) : null}
            {match.params.chatId === 'support' &&
            (!chatMessageList || chatMessageList.length === 0) ? (
              <>
                <ChatMessageCard
                  support
                  key={'support-1'}
                  message={t('chat.automated')}
                  name={t('chat.titles.support')}
                  date={moment().format('HH:mm')}
                />
                {!supportChatRoomData?.message ? <NewMessageDivider /> : null}
              </>
            ) : null}
          </Box>
        </Box>
        <Box padding={theme.spacing(1, 2, 3, 2)}>
          <ChatMessageMobile
            placeholder={t('chat.message')}
            handleSendMessage={handleSendMessage}
            handleAddContentClick={handleAddContent}
            disabled={
              match.params.chatId === 'support' &&
              Boolean(supportChatRoomData?.message) &&
              (!chatMessageList || chatMessageList.length === 0)
            }
          />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default ChatMessage;
