import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import UserInfo from './UserInfo';
import { useIonRouter } from '@ionic/react';
import useAlert from '../../hooks/useAlert';
import { Box, useTheme } from '@mui/material';
import GraphView from './components/GraphView';
import UserDataCvd from '../cvdRisk/CvdRiskWeb';
import DiabetesWeb from '../diabetes/DiabetesWeb';
import RecordsInner from '../records/RecordsInner';
import UserDataLipid from '../lipid/UserDataLipid';
import { RouteComponentProps } from 'react-router';
import UserOverview from './components/UserOverview';
import PageLayout from '../../components/PageLayout';
import UserDataToolbar from './components/UserDataToolbar';
import WebNutrition from '../fitness/nutrition/WebNutrition';
import WebFitnessOverview from '../fitness/WebFitnessOverview';
import { useGetUserDetailsQuery } from './queries/usersQueries';
import UserDataMedication from '../Medication/UserDataMedication';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import WebCalories from '../fitness/calories/components/WebCalories';
import WebExercise from '../fitness/exercise/components/WebExercise';
import WebWellness from '../fitness/wellness/components/WebWellness';
import UserDataHypertension from '../hypertension/UserDataHypertension';
import AddMedicationModalWeb from '../Medication/components/AddMedicationModalWeb';
import {
  setCustomDateFilter,
  setDateFilter,
  setUserFilter,
} from '../../redux/slices/appSlice';
import {
  CalendarModal,
  ComponentsLayout,
  CVDRiskSQ,
  dataGraphs,
  dayWeekMonthYearCustom,
  DiabetesSQ,
  fitnessHeadings,
  FitnessHeartIcon,
  HypertensionSQ,
  LipidSQ,
  MedicationSQ,
  ModalMobile,
  OptionType,
  OverviewIcon,
  UserDownload,
  userHCPHeadings,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const options: OptionType[] = [
  { value: 'Overview', icon: OverviewIcon, name: 'general.labels.overview' },
  { value: 'Lipid', icon: LipidSQ, name: 'headingsTitles.lipid' },
  { value: 'CVD', icon: CVDRiskSQ, name: 'dashboard.dashcards.cvd.title' },
  { value: 'Diabetes', icon: DiabetesSQ, name: 'userDetails.diabetes' },
  {
    value: 'Medication',
    icon: MedicationSQ,
    name: 'dashboard.dashcards.medication.title',
  },
  { value: 'Fitness', icon: FitnessHeartIcon, name: 'dashboard.dashcards.fitness.title' },
  { value: 'Hypertension', icon: HypertensionSQ, name: 'userDetails.hypertension' },
];

const screensWithToggle: string[] = ['Diabetes', 'Hypertension', 'Lipid', 'Fitness'];

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}

const UsersData: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const theme = useTheme();
  const { presentError } = useAlert();
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();

  const { dateFilter, userFilter } = useAppSelector(state => state.layout);

  const [customEndDate, setCustomEndDate] = useState<string>('');
  const [calendarModal, setCalendarModal] = useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = useState<string>('');
  const [usersDataModalOpen, setUsersDataModalOpen] = useState<boolean>(false);

  const { error, data: userDetails } = useGetUserDetailsQuery({ userId });

  const dataGraphsToggles = useConvertJSON(dataGraphs);
  const weekMonthYearCustomToggles = useConvertJSON(weekMonthYearCustom);
  const dayWeekMonthYearCustomToggles = useConvertJSON(dayWeekMonthYearCustom);
  const fitnessToggles = useConvertJSON(
    fitnessHeadings.filter(it => it.value !== 'workouts'),
  );

  useEffect(() => {
    if (error) presentError(t('errors.generic.errorFetchUser'));
  }, [error]);

  const selectedType = useMemo(
    () =>
      options.find(
        item => item.value?.toLowerCase() === router.routeInfo.pathname.split('/')?.[4],
      ) ?? options[0],
    [router.routeInfo.pathname],
  );

  const [toggleDataGraphs, setToggleDataGraphs] = useState(
    selectedType.value?.toLowerCase() === 'fitness' ? 'overview' : 'data',
  );

  const handleGraphView = useMemo(() => {
    return (
      <GraphView
        userId={userId}
        selectedType={selectedType.value ? selectedType.value?.toLowerCase() : ''}
      />
    );
  }, [selectedType.value, userId]);

  const SwitchPage = useMemo(() => {
    switch (selectedType.value) {
      case 'Overview':
        return <UserOverview userId={userId} />;
      case 'Medication':
        return <UserDataMedication userId={userId} />;
      case 'Diabetes':
        return <DiabetesWeb userId={userId} />;
      case 'Hypertension':
        return <UserDataHypertension userId={userId} />;
      case 'Fitness':
        return <WebFitnessOverview userId={userId} />;
      case 'CVD':
        return <UserDataCvd userId={userId} />;
      case 'Lipid':
        return <UserDataLipid userId={userId} />;
      default:
        return;
    }
  }, [selectedType.value, userId]);

  const handleShowContent = useCallback(() => {
    switch (toggleDataGraphs) {
      case 'data':
        return SwitchPage;
      case 'graphs':
        return handleGraphView;
      case 'overview':
        return <WebFitnessOverview userId={userId} />;
      case 'calories':
        return <WebCalories userId={userId} />;
      case 'nutrition':
        return <WebNutrition userId={userId} />;
      case 'exercise':
        return <WebExercise userId={userId} />;
      case 'wellness':
        return <WebWellness userId={userId} />;
    }
  }, [SwitchPage, toggleDataGraphs, userId]);

  const prepareSegmentOptions = useMemo<
    { label: string; value: string }[] | string[]
  >(() => {
    switch (selectedType.value?.toLowerCase()) {
      case 'lipid':
      case 'diabetes':
      case 'hypertension':
        return dataGraphsToggles;
      case 'fitness':
        return fitnessToggles;
      default:
        return [];
    }
  }, [selectedType.value]);

  const prepareFilterOptions = useMemo<{ label: string; value: string }[] | []>(() => {
    switch (selectedType.value?.toLowerCase()) {
      case 'fitness':
      case 'medication':
        return [];
      case 'overview':
        return dayWeekMonthYearCustomToggles.slice(1);
      default:
        return weekMonthYearCustomToggles;
    }
  }, [dayWeekMonthYearCustomToggles, selectedType.value, weekMonthYearCustomToggles]);

  const onDateFilterChange = (newFilter: string) => {
    if (newFilter === 'custom date' || newFilter === 'custom') {
      setCalendarModal(true);
    }
    dispatch(setDateFilter(newFilter));
  };

  const handleSetDropdown = (val: OptionType) => {
    const option = val.value?.toLowerCase();
    router.push(`/dashboard/users/${userId}/${option}`, 'forward', 'push');
  };

  const handleToggleDataGraphs = useMemo(() => {
    return screensWithToggle.includes(selectedType.value ? selectedType.value : '');
  }, [selectedType.value]);

  const onCustomDatesChange = (dates: string[]) => {
    let start, end;
    end = dates[1];
    start = dates[0];
    setCustomEndDate(end);
    setCustomStartDate(start);
    dispatch(setCustomDateFilter({ start: start, end: end }));
  };

  const handleMainContent = useMemo(() => {
    switch (userFilter) {
      case 'data':
        return (
          <>
            <UserDataToolbar
              options={options}
              activeFilter={dateFilter}
              activeOption={selectedType}
              changeOption={handleSetDropdown}
              changeFilter={onDateFilterChange}
              filterOptions={prepareFilterOptions}
              segmentOptions={prepareSegmentOptions}
              activeSegment={handleToggleDataGraphs ? toggleDataGraphs : undefined}
              changeSegment={handleToggleDataGraphs ? setToggleDataGraphs : undefined}
            />
            {handleShowContent()}
          </>
        );
      case 'user info':
        return <UserInfo userId={userId} />;
      case 'records':
        return <RecordsInner userId={userId} />;
      default:
        return null;
    }
  }, [userFilter, dateFilter, selectedType, toggleDataGraphs]);

  return (
    <PageLayout
      fabClick={
        selectedType.value?.toLowerCase() === 'medication'
          ? () => setUsersDataModalOpen(true)
          : undefined
      }
    >
      <ComponentsLayout>
        <UserDownload
          userFilter={userFilter}
          isUserPremium={userDetails?.data.premiumUser ?? false}
          avatar={userDetails?.data?.authUser?.profilePicture?.url || ''}
          headings={useConvertJSON(userHCPHeadings.filter(it => it.value !== 'devices'))}
          name={
            userDetails?.data.authUser.firstName +
              ' ' +
              userDetails?.data.authUser.lastName ?? ''
          }
          setUserFilter={(filter: string) => dispatch(setUserFilter(filter))}
          backArrow={() => router.push('/dashboard/users', 'back', 'pop')}
        />
        <Box
          flex={1}
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={theme.spacing(3)}
        >
          {handleMainContent}
        </Box>
      </ComponentsLayout>
      <ModalMobile open={usersDataModalOpen} setOpen={setUsersDataModalOpen}>
        <AddMedicationModalWeb
          userId={userId}
          onSaveSuccess={() => setUsersDataModalOpen(false)}
        />
      </ModalMobile>
      <CalendarModal
        open={calendarModal}
        setOpen={setCalendarModal}
        saveDate={onCustomDatesChange}
        selectedDate={[customStartDate, customEndDate]}
      />
    </PageLayout>
  );
};

export default UsersData;
