import React, { FC } from 'react';
import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import SearchActivityPanel from './components/SearchActivityPanel';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const SearchActivity: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('fitness.exercise.labels.activity')}
      defaultHref={`/dashboard/users/${userId}/fitness/exercise`}
    >
      <ComponentsLayout>
        <SearchActivityPanel userId={userId} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default SearchActivity;
