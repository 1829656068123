import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { theme, ToggleCard, useDebounce } from '@hdcorner/ui-library';
import React, { useEffect, useRef, useState } from 'react';
import {
  useGetUserSettingsQuery,
  useUpdateLanguageSettingsMutation,
} from '../queries/userSettingsQueries';
import { useTranslation } from 'react-i18next';
import useAlert from '../../../hooks/useAlert';

const languages = [
  { label: 'constants.languages.en', value: 'en' },
  { label: 'constants.languages.gr', value: 'el' },
];

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const TypographyHeading = styled(Typography)<TypographyProps>(() => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const WebLanguage = () => {
  const { presentError } = useAlert();
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState<string>('en');

  const { error, data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateLanguageSettingsMutation();

  const debounceLanguage = useDebounce(language, 100);

  const isFirstRef = useRef<boolean>(true);

  useEffect(() => {
    if (error) presentError(t('errors.profile.errorUserData'));
  }, [error]);

  const handleLanguageChange = (newValue: string) => {
    if (newValue === 'en') {
      i18n.changeLanguage('en');
    }
    if (newValue === 'el') {
      i18n.changeLanguage('el');
    }
    setLanguage(newValue);
  };

  useEffect(() => {
    if (userSettings && userSettings.length > 0) {
      const data = userSettings[0];
      if (data) {
        setLanguage(data.selectedLang || 'en');
      }
      isFirstRef.current = false;
    }
  }, [userSettings]);

  useEffect(() => {
    if (isFirstRef.current) {
      return;
    }
    updateUserSettings({
      selectedLang: language,
    });
  }, [debounceLanguage]);

  return (
    <>
      <TypographyHeading variant={'subtitle2'}>
        {t('profile.titles.language')}
      </TypographyHeading>
      <Box
        padding={2}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        bgcolor={theme.palette.highlight.main}
      >
        <Box flexGrow={'1'}>
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            {t('profile.titles.language')}
          </Typography>
          <Box rowGap={1} columnGap={3} flexGrow={'1'} display={'flex'}>
            {languages.map((lang, index) => (
              <ToggleCard
                value={lang.value}
                label={t(lang.label)}
                textTransform={'none'}
                key={`${index}-${lang.value}`}
                selected={language === lang.value}
                setSelected={handleLanguageChange}
                backgroundColor={theme.palette.secondary.main}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WebLanguage;
