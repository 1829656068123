import { Redirect, Route } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { AuthUser, theme } from '@hdcorner/ui-library';
import OnBoarding from '../features/authentication/onBoarding/OnBoarding';
import Dashboard from '../features/dashboard/Dashboard';
import Chat from '../features/chat/Chat';
import ChatMessage from '../features/chat/ChatMessage';
import Profile from '../features/profile/Profile';
import AccountSettings from '../features/profile/AccountSettings';
import AccountSettingsEmail from '../features/profile/AccountSettingsEmail';
import AccountSettingsPassword from '../features/profile/AccountSettingsPassword';
import AccountSettingsPhone from '../features/profile/AccountSettingsPhone';
import AccountNotifications from '../features/profile/AccountNotifications';
import AccountSettingsDeactivate from '../features/profile/AccountSettingsDeactivate';
import PersonalInfo from '../features/profile/PersonalInfo';
import Records from '../features/records/Records';
import RecordsSend from '../features/records/RecordsSend';
import Language from '../features/profile/Language';
import TermsAndConditionsPage from '../features/authentication/signUp/TermsAndConditionsPage';
import { isNil } from 'lodash';
import { useAppSelector } from '../redux/hooks';
import Users from '../features/Users/Users';
import UsersData from '../features/Users/UsersData';
import User from '../features/Users/User';
import Hypertension from '../features/hypertension/Hypertension';
import HypertensionInDepthData from '../features/hypertension/InDepthData';
import HypertensionGoal from '../features/hypertension/HypertensionGoal';
import Esh from '../features/hypertension/esh/Esh';
import Diabetes from '../features/diabetes/Diabetes';
import DiabetesHbac1Goal from '../features/diabetes/DiabetesHbac1Goal';
import DiabetesBloodGlucoseGoal from '../features/diabetes/DiabetesBloodGlucoseGoal';
import BloodGlucoseInDepthData from '../features/diabetes/BloodGlucoseInDepthData';
import Hbac1InDepthData from '../features/diabetes/Hbac1InDepthData';
import CvdRisk from '../features/cvdRisk/CvdRisk';
import CvdRiskInDepthData from '../features/cvdRisk/CvdRiskInDepthData';
import CvdRiskGoalScreen from '../features/cvdRisk/CvdRiskGoalScreen';
import Lipid from '../features/lipid/Lipid';
import LipidInDepthData from '../features/lipid/LipidInDepthData';
import LipidGoalScreen from '../features/lipid/LipidGoalScreen';
import Fitness from '../features/fitness/Fitness';
import NutritionFitness from '../features/fitness/nutrition/NutritionFitness';
import ExerciseFitness from '../features/fitness/exercise/ExerciseFitness';
import SearchActivity from '../features/fitness/exercise/SearchActivity';
import FitnessGoalSteps from '../features/fitness/exercise/FitnessGoalSteps';
import FitnessGoalActivity from '../features/fitness/exercise/FitnessGoalActivity';
import WellnessInDepthData from '../features/fitness/wellness/WellnessInDepthData';
import Wellness from '../features/fitness/wellness/Wellness';
import FitnessGoalWeight from '../features/fitness/wellness/FitnessGoalWeight';
import DevicesFitness from '../features/fitness/DevicesFitness';
import DailyCalories from '../features/fitness/calories/DailyCalories';
import CalorieData from '../features/fitness/calories/CalorieData';
import FoodEquivalents from '../features/fitness/FoodEquivalents';
import FoodEquivalentsTable from '../features/fitness/FoodEquivalentsTable';
import TipsTricks from '../features/fitness/nutrition/TipsTricks';
import DietCalculatorInitialPage from '../features/fitness/diet-plan/DietCalculatorInitialPage';
import DietCalculatorMobile from '../features/fitness/diet-plan/DietCalculatorMobile';
import Medications from '../features/Medication/Medications';
import MedicationAllMedicines from '../features/Medication/MedicationAllMedicines';
import AddMedication from '../features/Medication/AddMedication';
import MedicationRepeatPage from '../features/Medication/MedicationRepeatPage';
import MedicationDrug from '../features/Medication/MedicationDrug';
import MedicationInstructions from '../features/Medication/MedicationInstructions';
import MedicationFrequencyPage from '../features/Medication/MedicationFrequencyPage';
import MedicationDrugDescription from '../features/Medication/MedicationDrugDescription';
import { IonRouterOutlet } from '@ionic/react';
import CodesActivity from '../features/profile/CodesActivity';
import Notifications from '../features/notifications/Notifications';
import AboutUs from '../features/aboutUs/AboutUs';
import DiabetesGraphDataPage from '../features/diabetes/DiabetesGraphDataPage';
import HypertensionGraphDataPage from '../features/hypertension/HypertensionGraphDataPage';
import LipidGraphDataPage from '../features/lipid/LipidGraphDataPage';
import TwentyFourHours from '../features/hypertension/24hours/TwentyFourHours';

const ProtectedRoutes = () => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const shouldRedirect = useMemo(() => {
    if (isNil(user) || isNil(user.authUser)) {
      return true;
    }

    if (!isNil(user) && !isNil(user.authUser)) {
      const authUser: AuthUser = user.authUser as AuthUser;
      if (!authUser.privacyPolicyAccepted || !authUser.termsAndConditionsAccepted) {
        return true;
      }

      if (!authUser.firstName || !authUser.lastName || !authUser.gender) {
        return true;
      }
    }

    return false;
  }, [user]);

  const decideRedirect = useMemo(() => {
    if (user && user.authUser) {
      const authUser: AuthUser = user.authUser as AuthUser;

      if (!authUser.privacyPolicyAccepted || !authUser.termsAndConditionsAccepted) {
        return <Redirect to={'/dashboard/terms-and-conditions'} />;
      }

      if (!authUser.firstName || !authUser.lastName || !authUser.gender) {
        return <Redirect to={'/dashboard/onboarding'} />;
      }
    } else {
      return <Redirect to={'/sign-in'} />;
    }
  }, [user]);

  const renderRoutes = () => {
    return (
      <IonRouterOutlet animated={smallScreen}>
        <Route
          exact
          render={() => <TermsAndConditionsPage />}
          path="/dashboard/terms-and-conditions"
        />
        <Route exact path="/dashboard/onboarding" render={() => <OnBoarding />} />
        <Route
          exact
          path="/dashboard"
          render={() => <Redirect to={'/dashboard/home'} />}
        />
        <Route
          exact
          path="/dashboard/home"
          render={() => (!shouldRedirect ? <Dashboard /> : decideRedirect)}
        />
        <Route exact path="/dashboard/about-us" render={() => <AboutUs />} />
        <Route exact path="/dashboard/users" component={Users} />
        <Route exact path="/dashboard/notifications">
          {!smallScreen ? <Redirect to="/dashboard/home" /> : <Notifications />}
        </Route>
        <Route
          exact
          path="/dashboard/users/:userId/overview"
          render={props =>
            !smallScreen ? <UsersData {...props} /> : <User {...props} />
          }
        />
        {/* Hypertension paths */}
        <Route
          exact
          path="/dashboard/users/:userId/hypertension"
          render={props =>
            !smallScreen ? <UsersData {...props} /> : <Hypertension {...props} />
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/hypertension-in-depth-data"
          render={props =>
            !smallScreen ? (
              <Redirect
                to={`/dashboard/users/${props.match.params.userId}/hypertension`}
              />
            ) : (
              <HypertensionInDepthData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/hypertension-goal"
          render={props =>
            !smallScreen ? (
              <Redirect
                to={`/dashboard/users/${props.match.params.userId}/hypertension`}
              />
            ) : (
              <HypertensionGoal {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/hypertension/esh"
          render={props =>
            !smallScreen ? (
              <Redirect
                to={`/dashboard/users/${props.match.params.userId}/hypertension`}
              />
            ) : (
              <Esh {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/hypertension/24hours"
          render={props =>
            !smallScreen ? (
              <Redirect
                to={`/dashboard/users/${props.match.params.userId}/hypertension`}
              />
            ) : (
              <TwentyFourHours {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/hypertension-graph-data"
          render={props =>
            !smallScreen ? (
              <Redirect
                to={`/dashboard/users/${props.match.params.userId}/hypertension`}
              />
            ) : (
              <HypertensionGraphDataPage {...props} />
            )
          }
        />
        {/*  --------------------- */}
        {/*  Medication paths*/}
        <Route
          exact
          path="/dashboard/users/:userId/medication"
          render={props =>
            !smallScreen ? <UsersData {...props} /> : <Medications {...props} />
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/medication-add"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/medication`} />
            ) : (
              <AddMedication {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/medication-repeat"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/medication`} />
            ) : (
              <MedicationRepeatPage {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/medication/:medicationId"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/medication`} />
            ) : (
              <MedicationDrug {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/medication-instructions/:medicationId"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/medication`} />
            ) : (
              <MedicationInstructions {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/medication-frequency"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/medication`} />
            ) : (
              <MedicationFrequencyPage {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/medicines"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/medication`} />
            ) : (
              <MedicationAllMedicines {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/medicine/:medicineId"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/medication`} />
            ) : (
              <MedicationDrugDescription {...props} />
            )
          }
        />
        {/*  ---------------------*/}
        {/*  Diabetes paths*/}
        <Route
          exact
          path="/dashboard/users/:userId/diabetes"
          render={props =>
            !smallScreen ? <UsersData {...props} /> : <Diabetes {...props} />
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/diabetes-hbac1-goal"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/diabetes`} />
            ) : (
              <DiabetesHbac1Goal {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/diabetes-blood-glucose-goal"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/diabetes`} />
            ) : (
              <DiabetesBloodGlucoseGoal {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/diabetes-graph-data/:type"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/diabetes`} />
            ) : (
              <DiabetesGraphDataPage {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/diabetes/blood-glucose-in-depth-data"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/diabetes`} />
            ) : (
              <BloodGlucoseInDepthData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/diabetes/hbac1-in-depth-data"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/diabetes`} />
            ) : (
              <Hbac1InDepthData {...props} />
            )
          }
        />
        {/*  ---------------------*/}
        {/*  Chat paths */}
        <Route exact path="/dashboard/chat" component={Chat} />
        <Route
          exact
          path="/dashboard/chat/:chatId"
          render={props =>
            !smallScreen ? <Chat {...props} /> : <ChatMessage {...props} />
          }
        />
        {/*  ---------------------*/}
        {/*  Profile paths*/}
        <Route exact path="/dashboard/profile" component={Profile} />
        <Route
          exact
          path="/dashboard/profile-settings"
          render={() =>
            !smallScreen ? <Redirect to={`/dashboard/profile`} /> : <AccountSettings />
          }
        />
        <Route
          exact
          path="/dashboard/account-settings/change-email"
          render={() =>
            !smallScreen ? (
              <Redirect to={`/dashboard/profile`} />
            ) : (
              <AccountSettingsEmail />
            )
          }
        />
        <Route
          exact
          path="/dashboard/account-settings/change-password"
          render={() =>
            !smallScreen ? (
              <Redirect to={`/dashboard/profile`} />
            ) : (
              <AccountSettingsPassword />
            )
          }
        />
        <Route
          exact
          path="/dashboard/account-settings/change-phone"
          render={() =>
            !smallScreen ? (
              <Redirect to={`/dashboard/profile`} />
            ) : (
              <AccountSettingsPhone />
            )
          }
        />
        <Route
          exact
          path="/dashboard/account-settings/notifications"
          render={() =>
            !smallScreen ? (
              <Redirect to={`/dashboard/profile`} />
            ) : (
              <AccountNotifications />
            )
          }
        />
        <Route
          exact
          path="/dashboard/account-settings/deactivate-account"
          render={() =>
            !smallScreen ? (
              <Redirect to={`/dashboard/profile`} />
            ) : (
              <AccountSettingsDeactivate />
            )
          }
        />
        <Route
          exact
          path="/dashboard/codes-activity"
          render={() =>
            !smallScreen ? <Redirect to={`/dashboard/profile`} /> : <CodesActivity />
          }
        />
        <Route
          exact
          path="/dashboard/personal-info"
          render={() =>
            !smallScreen ? <Redirect to={`/dashboard/profile`} /> : <PersonalInfo />
          }
        />
        <Route
          path="/dashboard/language"
          render={() =>
            !smallScreen ? <Redirect to={`/dashboard/profile`} /> : <Language />
          }
        />
        {/*  ---------------------*/}
        {/*  Records paths*/}
        <Route exact path="/dashboard/users/:userId/healthdata" component={Records} />
        <Route exact path="/dashboard/users-records/send" component={RecordsSend} />
        {/*  ---------------------*/}
        {/*  CVD paths*/}
        <Route
          exact
          path="/dashboard/users/:userId/cvd"
          render={props =>
            !smallScreen ? <UsersData {...props} /> : <CvdRisk {...props} />
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/cvd-in-depth-data"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/cvd`} />
            ) : (
              <CvdRiskInDepthData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/cvd-risk-goal"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/cvd`} />
            ) : (
              <CvdRiskGoalScreen {...props} />
            )
          }
        />
        {/*  ---------------------*/}
        {/*  Lipid paths*/}
        <Route
          exact
          path="/dashboard/users/:userId/lipid"
          render={props =>
            !smallScreen ? <UsersData {...props} /> : <Lipid {...props} />
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/lipid-in-depth-data/:type"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/lipid`} />
            ) : (
              <LipidInDepthData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/lipid-graph-data/:type"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/lipid`} />
            ) : (
              <LipidGraphDataPage {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/lipid-goal/:type"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/lipid`} />
            ) : (
              <LipidGoalScreen {...props} />
            )
          }
        />
        {/*  ---------------------*/}
        {/*  Fitness paths*/}
        <Route
          exact
          path="/dashboard/users/:userId/fitness"
          render={props =>
            !smallScreen ? <UsersData {...props} /> : <Fitness {...props} />
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/nutrition"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <NutritionFitness {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/exercise"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <ExerciseFitness {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/activities"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <SearchActivity {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/exercise/steps/goal"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <FitnessGoalSteps {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/exercise/activity/goal"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <FitnessGoalActivity {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/exercise/in-depth/steps"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <WellnessInDepthData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/exercise/in-depth/activities"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <WellnessInDepthData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/wellness"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <Wellness {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/wellness/weight/goal"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <FitnessGoalWeight {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/wellness/in-depth/:id"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <WellnessInDepthData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/devices"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <DevicesFitness {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/calories"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <DailyCalories {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/calories/in-depth-data"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <CalorieData {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/nutrition/food-equivalents"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <FoodEquivalents {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/nutrition/food-equivalents/:id"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <FoodEquivalentsTable {...props} />
            )
          }
        />
        <Route
          exact
          path={'/dashboard/users/:userId/fitness/nutrition/tips-tricks'}
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <TipsTricks {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/nutrition/diet-plans"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <DietCalculatorInitialPage {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard/users/:userId/fitness/nutrition/diet-plans/calculator"
          render={props =>
            !smallScreen ? (
              <Redirect to={`/dashboard/users/${props.match.params.userId}/fitness`} />
            ) : (
              <DietCalculatorMobile {...props} />
            )
          }
        />
      </IonRouterOutlet>
    );
  };

  return renderRoutes();
};

export default ProtectedRoutes;
