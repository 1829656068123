import {
  CalendarModal,
  ClickNavCardNutrition,
  ComponentsLayout,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Box, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import CvdRiskIcon from '../../assets/icons/CvdRiskIcon';
import PageLayout from '../../components/PageLayout';
import CvdRiskChartCard from './components/CvdRiskChartCard';
import {
  useGetCvdCurrentMeasurementQuery,
  useGetCvdGraphDataQuery,
  useGetCvdRiskGoalQuery,
} from './queries/cvdRiskQueries';
import useTimeframe from '../../hooks/useTimeframe';
import useIdealData from './hooks/useIdealData';
import usePrepareGraphData from './hooks/usePrepareGraphData';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const CvdRisk: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentError } = useAlert();

  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  // Used for updating the params for the query
  const [startParam, setStartParam] = useState<string>('');
  const [endParam, setEndParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const { data: graphData, error: errorGraph } = useGetCvdGraphDataQuery({
    userId,
    ...timeframe,
  });
  const { data: currentMeasurement, error: errorCurrentMeasurement } =
    useGetCvdCurrentMeasurementQuery({ userId });
  const { data: cvdRiskGoal, error: cvdRiskGoalError } = useGetCvdRiskGoalQuery({
    userId,
  });

  const idealData = useIdealData(currentMeasurement);
  const prepareGraphData = usePrepareGraphData(graphData);

  useEffect(() => {
    if (cvdRiskGoalError || errorGraph || errorCurrentMeasurement) {
      presentError('An unexpected error occurred retrieving data');
    }
  }, [cvdRiskGoalError, errorGraph, errorCurrentMeasurement]);

  const handleClickGoal = useCallback(() => {
    router.push(`/dashboard/users/${userId}/cvd-risk-goal`);
  }, [userId]);

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <PageLayout
      defaultHref={'/dashboard'}
      headerTitle={'CVD Risk Calculator'}
      headerColor={theme.palette.cvdRisk.main}
      headerIcon={<CvdRiskIcon fill={theme.palette.cvdRisk.main} />}
    >
      <ComponentsLayout>
        <CvdRiskChartCard
          data={idealData}
          chartData={prepareGraphData}
          selectedToggle={selectedTimeframe}
          setSelectedToggle={setSelectedTimeframe}
          openCalendar={() => setCalendarModalOpen(true)}
        />
        <Box mt={3}>
          <ClickNavCardNutrition
            headings={t('buttons.navigation.inDepth')}
            onClick={() => router.push(`/dashboard/users/${userId}/cvd-in-depth-data`)}
          />
          {cvdRiskGoal && cvdRiskGoal.length > 0 && (
            <ClickNavCardNutrition
              headings={t('headingsTitles.goal')}
              body={
                cvdRiskGoal?.[0] && cvdRiskGoal?.[0]?.goal
                  ? `${cvdRiskGoal[0].goal} %`
                  : '-'
              }
              onClick={handleClickGoal}
            />
          )}
        </Box>
      </ComponentsLayout>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </PageLayout>
  );
};

export default CvdRisk;
