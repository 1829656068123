import * as React from 'react';
import { FC, useEffect, useMemo } from 'react';
import { ComponentsLayout, Goal, theme } from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import moment from 'moment/moment';
import {
  useGetFirstLogOfGoalQuery,
  useGetLatestLogQuery,
  useGetPressureGoalQuery,
} from './queries/hypertensionQueries';
import { RouteComponentProps } from 'react-router';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const HypertensionGoal: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { data: pressureGoal, error: errorGoal } = useGetPressureGoalQuery({ userId });
  const { data: latestHypertensionLogs, error: errorHypLastLog } = useGetLatestLogQuery({
    userId,
  });
  const { data: startingHypertensionLogs, error: errorHypFirstLog } =
    useGetFirstLogOfGoalQuery(
      {
        userId,
        start:
          pressureGoal && pressureGoal.length > 0 ? pressureGoal[0].startDate : undefined,
      },
      {
        skip: !pressureGoal || pressureGoal.length === 0,
      },
    );

  useEffect(() => {
    if (errorGoal) presentError(t('errors.generic.errorFetchGoal'));
    if (errorHypLastLog || errorHypFirstLog)
      presentError(t('errors.generic.errorFetchLog'));
  }, [errorGoal, errorHypLastLog, errorHypFirstLog]);

  const latestHypertensionData = useMemo(() => {
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      return {
        systolic: data ? data.systolic : '-',
        diastolic: data ? data.diastolic : '-',
      };
    }

    return { systolic: '-', diastolic: '-' };
  }, [latestHypertensionLogs]);

  const startingHypertensionData = useMemo(() => {
    if (startingHypertensionLogs && startingHypertensionLogs.documents.length > 0) {
      const data = startingHypertensionLogs.documents[0];
      return {
        systolic: data ? data.systolic : '-',
        diastolic: data ? data.diastolic : '-',
      };
    }

    return { systolic: '-', diastolic: '-' };
  }, [startingHypertensionLogs]);

  const prepareData = useMemo(() => {
    if (!pressureGoal || pressureGoal.length === 0) return [];

    return [
      {
        label: t('hypertension.labels.goalSystolic'),
        value: `${pressureGoal![0].goal.systolic} ${t('measurements.mmGH')}`,
      },
      {
        label: t('hypertension.labels.goalDiastolic'),
        value: `${pressureGoal![0].goal.diastolic} ${t('measurements.mmGH')}`,
        marginBottom: true,
      },
      {
        label: t('goals.startingDate'),
        value: moment(pressureGoal![0].startDate).format('MMM Do, YYYY'),
      },
      {
        label: t('goals.startingMeasurement'),
        value: `${startingHypertensionData.systolic} / ${
          startingHypertensionData.diastolic
        } ${t('measurements.mmGH')}`,
      },
      {
        label: t('goals.latestMeasurement'),
        value: `${latestHypertensionData.systolic} / ${
          latestHypertensionData.diastolic
        } ${t('measurements.mmGH')}`,
      },
    ];
  }, [
    pressureGoal,
    latestHypertensionData.diastolic,
    latestHypertensionData.systolic,
    startingHypertensionData.diastolic,
    startingHypertensionData.systolic,
  ]);

  return (
    <PageLayout
      headerTitle={t('headingsTitles.goal')}
      headerColor={theme.palette.primary.main}
      defaultHref={`/dashboard/users/${userId}/hypertension`}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <Goal data={prepareData} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default HypertensionGoal;
