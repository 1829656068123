import React, { useEffect, useMemo } from 'react';
import SectionHeading from '../../components/SectionHeading';
import { Box, BoxProps, styled, useMediaQuery } from '@mui/material';
import { GoalDetailsCard, theme } from '@hdcorner/ui-library';
import moment from 'moment/moment';
import {
  useGetFirstWeightLogQuery,
  useGetLatestWeightLogsQuery,
  useGetUserGoalWeightQuery,
} from '../queries/weightQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const BoxInner = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: web ? 0 : theme.spacing(1),
  padding: theme.spacing(web ? 1 : 0),
  backgroundColor: web ? theme.palette.secondary.light : 'transparent',
}));

interface Props {
  userId: string;
}
const SectionGoalWeight: React.FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { data: lastLogData, error: errorLastLog } = useGetLatestWeightLogsQuery({
    userId,
  });
  const { data: getGoalRes, error: errorGoal } = useGetUserGoalWeightQuery({
    userId,
  });
  const { data: firstLog, error: errorFirstLog } = useGetFirstWeightLogQuery({
    userId,
    start: getGoalRes && getGoalRes.length > 0 ? getGoalRes[0].startDate : undefined,
  });

  useEffect(() => {
    if (errorLastLog || errorFirstLog) presentError(t('errors.generic.errorFetchLog'));
    if (errorGoal) presentError(t('errors.generic.errorFetchGraph'));
  }, [errorLastLog, errorFirstLog, errorGoal]);

  const startWeight = useMemo(() => firstLog?.documents?.[0]?.weight ?? 0, [firstLog]);
  const lastLog = useMemo(() => lastLogData?.documents?.[0]?.weight ?? 0, [lastLogData]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0)
      return [
        {
          label: t('headingsTitles.goal'),
          value: `-`,
        },
        {
          label: t('goals.start'),
          value: '-',
        },
        {
          label: t('goals.startingMeasurement'),
          value: `${startWeight} kg`,
        },
        {
          label: t('goals.latestMeasurement'),
          value: `${lastLog} kg`,
        },
        {
          label: t('goals.progress'),
          value: `-`,
        },
      ];

    const goalWeight = getGoalRes[0].goal.measurement;
    const progress = Math.round(
      ((startWeight - lastLog) / (startWeight - goalWeight)) * 100,
    );

    return [
      {
        label: t('headingsTitles.goal'),
        value: `${getGoalRes[0].goal.measurement} ${t('measurements.kg')}`,
      },
      {
        label: t('goals.start'),
        value: moment(getGoalRes[0].startDate).format('MMMM Do, YYYY'),
      },
      {
        label: t('goals.startingMeasurement'),
        value: `${startWeight} ${t('measurements.kg')}`,
      },
      {
        label: t('goals.latestMeasurement'),
        value: `${lastLog} ${t('measurements.kg')}`,
      },
      {
        label: t('goals.progress'),
        value: `${progress}%`,
      },
    ];
  }, [getGoalRes, lastLog, startWeight]);

  return (
    <Box gap={1} display={'flex'} width={'100%'} flexDirection={'column'}>
      <Box display={web ? 'block' : 'none'}>
        <SectionHeading
          heading={t('headingsTitles.goal')}
          hasGoal={!!(getGoalRes && getGoalRes.length > 0)}
        />
      </Box>
      <BoxInner web={web}>
        {goalData.map((it, index) => (
          <Box key={`${it}${index}`}>
            <GoalDetailsCard goal={it} />
          </Box>
        ))}
      </BoxInner>
    </Box>
  );
};

export default SectionGoalWeight;
