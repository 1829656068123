import UserDetailsOne from './UserDetailsOne';
import OnBoardingHeading from './OnBoardingHeading';
import React, { useEffect, useState } from 'react';
import UserDetailsTwo from './UserDetailsTwo';
import {
  AuthUser,
  BarStepper,
  ComponentsLayout,
  HealthProfessionalsData,
} from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import {
  useCalculateLocationDataMutation,
  useSetOnboardingOneMutation,
  useSetOnboardingTwoMutation,
} from '../../queries/onBoardingQueries';
import useQuery from '../../../../hooks/useQuery';
import { v4 as uuidv4 } from 'uuid';
import { useIonRouter } from '@ionic/react';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const OnBoardingPanelMobile = () => {
  const router = useIonRouter();
  const searchParams = useQuery();
  const { t } = useTranslation();

  const { presentError } = useAlert();

  const [count, setCount] = useState<number>(1);

  const [onboardingPtOne] = useSetOnboardingOneMutation();
  const [onboardingPtTwo] = useSetOnboardingTwoMutation();
  const [calculateLocationData] = useCalculateLocationDataMutation();

  useEffect(() => {
    if (searchParams.get('incomplete')) {
      setCount(2);
    }
  }, [searchParams]);

  const handleNextPage = (data: any) => {
    if (count === 2) {
      sendUserDataTwo(data);
    } else {
      sendUserDataOne(data);
    }
  };

  const sendUserDataOne = async (
    data: Omit<AuthUser, '_id' | 'email' | 'createdAt' | 'birthDate'>,
  ) => {
    try {
      const body = {
        gender: data.gender,
        lastName: data.lastName,
        firstName: data.firstName,
      };
      await onboardingPtOne(body).unwrap();
      setCount(count + 1);
    } catch (e) {
      console.log(e);
      presentError(t('errors.authentication.errorCreateAcc'));
    }
  };

  const sendUserDataTwo = async (data: any) => {
    try {
      const code = uuidv4();
      const hcpData: Omit<HealthProfessionalsData, 'authUser' | '_id' | 'verified'> = {
        code,
        vat: data.vat,
        area: data.area,
        phone: data.phone,
        address: data.address,
        speciality: data.speciality,
        postalCode: data.postalCode,
        addressNum: data.addressNum,
        clinicalCoach: data.clinicalCoach,
      };
      await onboardingPtTwo(hcpData).unwrap();

      if (data.clinicalCoach) {
        calculateLocationData()
          .unwrap()
          .catch(e => {
            console.log(e);
            presentError(t('errors.authentication.errorCreateLocation'));
          });
      }

      router.push('/dashboard');
    } catch (e) {
      console.log(e);
      presentError(t('errors.authentication.errorCreateAcc'));
    }
  };

  return (
    <>
      <BarStepper value={25 * count} />
      <ComponentsLayout>
        <Box maxWidth={'350px'} margin={'0 auto'}>
          <OnBoardingHeading web={false} />
          {count === 1 && <UserDetailsOne handleNextPage={handleNextPage} />}
          {count === 2 && <UserDetailsTwo handleNextPage={handleNextPage} />}
        </Box>
      </ComponentsLayout>
    </>
  );
};

export default OnBoardingPanelMobile;
