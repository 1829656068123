import api from '../../../redux/api';
import { UserRecord } from '@hdcorner/ui-library';
import { DashTrends } from '../types/DashTrends';

const userOverviewQueries = api
  .enhanceEndpoints({
    addTagTypes: ['Dashboard'],
  })
  .injectEndpoints({
    endpoints: build => ({
      getRecordsValues: build.query<UserRecord[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: `/database/function/getUserRecordHcp`,
          params: {
            userId,
            sort: '-updatedAt',
          },
        }),
        providesTags: ['Dashboard'],
      }),
      getDashboardTrends: build.query<
        DashTrends,
        { userId: string; timeframe?: string; start?: string; end?: string }
      >({
        query: ({ userId, timeframe, start, end }) => ({
          method: 'GET',
          params: { userId, timeframe, start, end },
          url: `/hdCornerService/hcp/user/${userId}/dashboard`,
        }),
      }),
    }),
  });

export const { useGetRecordsValuesQuery, useGetDashboardTrendsQuery } =
  userOverviewQueries;

export default userOverviewQueries;
