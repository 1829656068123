import { createSlice } from '@reduxjs/toolkit';
import { FrequencyItemType, Medicine } from '@hdcorner/ui-library';

type InitialStateType = {
  endDate: string;
  repeat: string[];
  startDate: string;
  medicine: Medicine | undefined;
  frequency: FrequencyItemType[];
};

const initialState: InitialStateType = {
  repeat: [],
  endDate: '',
  startDate: '',
  frequency: [],
  medicine: undefined,
};

export const medicationSlice = createSlice({
  name: 'medication',
  initialState,
  reducers: {
    setMedicine: (state, action) => {
      return { ...state, medicine: action.payload };
    },
    setRepeat: (state, action) => {
      return { ...state, repeat: action.payload };
    },
    setFrequency: (state, action) => {
      return { ...state, frequency: action.payload };
    },
    setStartDate: (state, action) => {
      return { ...state, startDate: action.payload };
    },
    setEndDate: (state, action) => {
      return { ...state, endDate: action.payload };
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { setMedicine, setRepeat, setFrequency, clear } = medicationSlice.actions;

export default medicationSlice.reducer;
