import api from '../../../../redux/api';
import { MealsData } from '../types/Food';

const foodsQueries = api
  .enhanceEndpoints({ addTagTypes: ['CustomMeals', 'FoodData', 'MealsData'] })
  .injectEndpoints({
    endpoints: build => ({
      getMealsOfDay: build.query<
        MealsData[],
        { userId: string; start?: string; end?: string }
      >({
        query: ({ userId, start, end }) => ({
          method: 'GET',
          url: '/database/function/getMealsOfDay',
          params: { populate: 'foods.food', start, end, scope: `Nutrition:${userId}` },
        }),
        providesTags: ['MealsData'],
      }),
    }),
  });

export const { useGetMealsOfDayQuery } = foodsQueries;

export default foodsQueries;
