import { Medication, MedicationStateEnum } from '@hdcorner/ui-library';

const extractMedicationState = (medication: Medication): MedicationStateEnum => {
  if (medication.taken) return MedicationStateEnum.taken;

  if (medication.rescheduled && medication.rescheduledTo)
    return MedicationStateEnum.rescheduledTo;

  if (medication.rescheduled && medication.rescheduledFrom)
    return MedicationStateEnum.rescheduledFrom;

  return MedicationStateEnum.notTaken;
};

export default extractMedicationState;
