import LipidGroupDetails from './LipidGroupDetails';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetLipidLogsQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import moment from 'moment/moment';
import { useAppSelector } from '../../redux/hooks';
import useTimeframe from '../../hooks/useTimeframe';
import { LipidGoal, minMaxAvg, minMaxObj } from '@hdcorner/ui-library';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
};
const HdlCholesterolSection: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [params, setParams] = useState({
    skip: 0,
    limit: 8,
    sort: ['-logDate'],
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: lastLog, error: errorLastLog } = useGetLipidLastLogQuery({ userId });
  const { data: hdlGoalRes, error: errorHdlGoal } = useGetLipidGoalQuery({
    userId,
    goalType: 'hdl',
  });
  const { data: lipidOverallData, error: errorLpdGoal } = useGetOverallLipidQuery({
    ...timeframe,
    userId,
  });

  const {
    isFetching,
    data: lipidLogs,
    error: errorLpdLogs,
  } = useGetLipidLogsQuery({
    userId,
    params,
    ...timeframe,
  });
  const { data: chartDataRes, error: errorLpdChart } = useGetLipidGraphDataQuery({
    userId,
    params: { metric: 'hdl', ...timeframe },
  });

  const { data: hdlFirstLog, error: errorHdlFirstLog } = useGetLipidFirstLogQuery(
    {
      startingDate:
        hdlGoalRes && hdlGoalRes.length > 0
          ? (hdlGoalRes as LipidGoal[])[0].startDate
          : '',
      userId,
    },
    { skip: hdlGoalRes ? hdlGoalRes.length === 0 : true },
  );

  useEffect(() => {
    if (errorLastLog || errorLpdLogs || errorHdlFirstLog)
      presentError(t('errors.generic.errorFetchLog'));
    if (errorHdlGoal || errorLpdGoal) presentError(t('errors.generic.errorFetchGoal'));
    if (errorLpdChart) presentError(t('errors.generic.errorFetchGraph'));
  }, [
    errorLastLog,
    errorHdlGoal,
    errorLpdGoal,
    errorLpdLogs,
    errorLpdChart,
    errorHdlFirstLog,
  ]);

  const getOverallData = useCallback(() => {
    return minMaxAvg(lipidOverallData, 'hdl');
  }, [lipidOverallData]);

  const goalData = useMemo(() => {
    const hdlGoal = hdlGoalRes ? hdlGoalRes[0] : null;
    const lastLogItem = lastLog ? lastLog.documents[0] : null;
    const hdlFirst = hdlFirstLog ? hdlFirstLog.documents[0] : null;

    let progress;

    if (hdlGoal && hdlFirst && lastLogItem) {
      const currentMeasurement = lastLogItem.totalChol || 0;
      const startingMeasurement = hdlFirst.totalChol || 0;
      const goalMeasurement = parseFloat(hdlGoal.goal.measurement) || 0;
      progress = Math.round(
        ((currentMeasurement - startingMeasurement) /
          (goalMeasurement - startingMeasurement)) *
          100,
      );
      if (progress > 100) progress = 100;
    }

    return {
      progress: progress ? progress + '%' : t('general.labels.nA'),
      goal: hdlGoal ? parseFloat(hdlGoal.goal.measurement) : undefined,
      startingMeasurement: hdlFirst ? hdlFirst.hdl : undefined,
      latestMeasurement: lastLogItem ? lastLogItem.hdl : undefined,
      startingDate: hdlGoal
        ? moment(hdlGoal.startDate).format('MMMM Do, YYYY')
        : undefined,
    };
  }, [hdlFirstLog, hdlGoalRes, lastLog]);

  const chartData = useMemo(() => {
    if (chartDataRes) {
      const logs = chartDataRes.logs;
      if (!logs) return [];

      return logs.map(log => {
        return {
          value: log.hdl,
          date: log.logDate,
        };
      });
    }
    return [];
  }, [chartDataRes]);

  const overallData = useMemo(() => {
    return minMaxObj('HDL Cholesterol', getOverallData);
  }, [getOverallData]);

  const inDepthData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      return lipidLogs.documents
        .filter(log => log.hdl)
        .map(log => {
          return {
            _id: log._id,
            title: log.hdl,
            titleSub: t('measurements.mgdl'),
            data: {
              [t('dashboard.dashcards.lipid.hdl')]: {
                value: `${log.hdl} ${t('measurements.mgdl')}`,
              },
              [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
              [t('inDepth.dateOfCalc')]: {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs]);

  return (
    <LipidGroupDetails
      userId={userId}
      lipidType={'hdl'}
      goalData={goalData}
      listParams={params}
      chartData={chartData}
      isFetching={isFetching}
      inDepthData={inDepthData}
      overallData={overallData}
      totalCount={lipidLogs?.count}
      count={lipidLogs?.documents.length}
      sectionName={t('lipid.labels.hdlChol')}
      setListParams={arg0 => setParams({ ...params, ...arg0 })}
    />
  );
};

export default HdlCholesterolSection;
