import React, { FC } from 'react';
import TakeIcon from '../../../assets/icons/TakeIcon';
import { Button, useTheme } from '@mui/material';
import RescheduleIcon from '../../../assets/icons/RescheduleIcon';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

type ActionButtonSmallTakeProps = {
  taken: boolean;
};
const ActionButtonSmallTake: FC<ActionButtonSmallTakeProps> = ({ taken }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Button
      fullWidth
      disableRipple
      disableElevation
      disabled={true}
      variant={'contained'}
      startIcon={
        <TakeIcon
          color={taken ? theme.palette.secondary.light : theme.palette.success.main}
        />
      }
      sx={{
        height: '40px',
        backgroundColor: taken
          ? theme.palette.success.main
          : theme.palette.secondary.light,
        color: taken ? theme.palette.secondary.main : theme.palette.success.main,
        boxShadow: 'none',
        minWidth: 'max-content',
        paddingInline: theme.spacing(2),
        '&:disabled': {
          opacity: 0.7,
          color: taken ? theme.palette.secondary.main : theme.palette.success.main,
          backgroundColor: taken
            ? theme.palette.success.main
            : theme.palette.secondary.light,
        },
      }}
    >
      {taken ? t('buttons.taken') : t('buttons.take')}
    </Button>
  );
};

type ActionButtonSmallRescheduleProps = {
  rescheduled: boolean;
};

const ActionButtonSmallReschedule: FC<ActionButtonSmallRescheduleProps> = ({
  rescheduled,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      disableRipple
      disableElevation
      disabled={true}
      variant={'contained'}
      startIcon={
        <RescheduleIcon
          color={rescheduled ? theme.palette.secondary.light : theme.palette.error.main}
        />
      }
      sx={{
        height: '40px',
        boxShadow: 'none',
        minWidth: 'max-content',
        color: rescheduled ? theme.palette.secondary.light : theme.palette.error.main,
        paddingInline: theme.spacing(2),
        backgroundColor: rescheduled
          ? theme.palette.warning.main
          : theme.palette.secondary.light,
        '&:disabled': {
          opacity: 0.7,
          color: rescheduled ? '' : theme.palette.error.main,
          backgroundColor: rescheduled
            ? theme.palette.warning.main
            : theme.palette.secondary.light,
        },
      }}
    >
      {t('buttons.reschedule')}
    </Button>
  );
};

type ActionButtonsSmallProps = {
  taken: boolean;
  rescheduledTo: boolean;
  rescheduledFrom: boolean;
};

const ActionButtonsSmall: FC<ActionButtonsSmallProps> = ({
  taken,
  rescheduledTo,
  rescheduledFrom,
}) => {
  const theme = useTheme();

  const isRescheduled = rescheduledFrom || rescheduledTo;

  return (
    <Box display={'flex'} gap={theme.spacing(1)}>
      <ActionButtonSmallTake taken={taken} />
      <ActionButtonSmallReschedule rescheduled={isRescheduled} />
    </Box>
  );
};

export default ActionButtonsSmall;
