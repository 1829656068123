import { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import AppleHealthIcon from '../../../assets/icons/AppleHealthIcon';
import { PlusIconCircular } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const BoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: '1',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const BoxDevices = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

const ButtonAppleDevice = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  width: '106px',
  height: '106px',
  textAlign: 'right',
  borderRadius: '16px',
  boxSizing: 'border-box',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));

const ButtonDevices = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  width: '106px',
  height: '106px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '16px',
  justifyContent: 'center',
  boxSizing: 'border-box',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));

const TypographyTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  width: '82px',
  textAlign: 'center',
  marginTop: theme.spacing(1),
  color: theme.palette.primary.main,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
}));

type DeviceAppsProps = {
  handleAddDevice: () => void;
  handleAppleHealth: () => void;
  handleRemoveDevice: (id: string) => void;
  devices: { id: string; title: string; icon: ReactNode }[];
};

const DeviceApps: FC<DeviceAppsProps> = ({
  devices,
  handleAddDevice,
  handleAppleHealth,
  handleRemoveDevice,
}) => {
  const { t } = useTranslation();

  return (
    <BoxContainer>
      {devices &&
        devices.map(it => (
          <BoxDevices key={it.id}>
            <ButtonDevices onClick={() => handleRemoveDevice(it.id)}>
              {it.icon}
            </ButtonDevices>
            <TypographyTitle>
              {t('devices.text.brandConnected', { device: t(it.title) })}
            </TypographyTitle>
          </BoxDevices>
        ))}

      <BoxDevices>
        <ButtonAppleDevice onClick={handleAppleHealth}>
          <AppleHealthIcon />
        </ButtonAppleDevice>
        <TypographyTitle>{t('appleHealth.connectApple')}</TypographyTitle>
      </BoxDevices>

      <BoxDevices>
        <ButtonDevices onClick={handleAddDevice}>
          <PlusIconCircular width={'14px'} height={'14px'} />
        </ButtonDevices>
        <TypographyTitle>{t('devices.text.connectOther')}</TypographyTitle>
      </BoxDevices>
    </BoxContainer>
  );
};

export default DeviceApps;
