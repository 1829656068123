import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import SignIn from '../features/authentication/signIn/SignIn';
import SignUp from '../features/authentication/signUp/SignUp';
import ForgotPassword from '../features/authentication/forgotPassword/ForgotPassword';
import ResetPassword from '../features/authentication/forgotPassword/ResetPassword';
import { useAppSelector } from '../redux/hooks';

const NonProtectedRoutes = () => {
  const {
    meta: { isAuthenticated },
  } = useAppSelector(state => state.auth);

  const renderRoutes = () => {
    return (
      <>
        <Route
          exact
          path="/sign-in"
          render={() =>
            isAuthenticated ? <Redirect to={'/dashboard/home'} /> : <SignIn />
          }
        />

        <Route
          exact
          path="/sign-up"
          render={() =>
            isAuthenticated ? <Redirect to={'/dashboard/home'} /> : <SignUp />
          }
        />

        <Route
          exact
          path="/forgot-password"
          render={() =>
            isAuthenticated ? <Redirect to={'/dashboard/home'} /> : <ForgotPassword />
          }
        />

        <Route
          exact
          path="/reset-password"
          render={props =>
            isAuthenticated ? (
              <Redirect to={'/dashboard/home'} />
            ) : (
              <ResetPassword {...props} />
            )
          }
        />
        <Route
          exact
          path="/reset-password/:resetToken"
          render={props =>
            isAuthenticated ? (
              <Redirect to={'/dashboard/home'} />
            ) : (
              <ResetPassword {...props} />
            )
          }
        />
      </>
    );
  };

  return renderRoutes();
};

export default NonProtectedRoutes;
