import React, { FC, useMemo, useState } from 'react';
import { Box, BoxProps, styled, Typography, useMediaQuery } from '@mui/material';
import {
  Activity,
  ClickNavCardNutrition,
  CustomButton,
  CustomInput,
  theme,
  useDebounce,
} from '@hdcorner/ui-library';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { useIonRouter } from '@ionic/react';
import { useGetActivityListQuery } from '../queries/activitiesQueries';
import { useAppDispatch } from '../../../../redux/hooks';
import { addActivityDetails } from '../slices/activitySlice';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
}));

type Props = {
  userId: string;
  onClick?: () => void;
  onActivityClick?: (data: Activity) => void;
};
const SearchActivityPanel: FC<Props> = ({ onClick, onActivityClick, userId }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [search, setSearch] = useState<string>('');
  const searchText = useDebounce(search, 500);
  const [activityParams] = useState({
    skip: 0,
    limit: 1000,
  });

  const { data: activityRes } = useGetActivityListQuery({
    ...activityParams,
    searchText: searchText,
  });

  const activityData = useMemo(() => {
    if (!activityRes) return [];

    return activityRes.documents;
  }, [activityRes]);

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    router.push(`/dashboard/users/${userId}/fitness/activities/custom-activity`);
  };

  const handleAddActivity = (data: Activity) => {
    const name = typeof data.name === 'string' ? data.name : data.name[lang];
    const path = name.toLowerCase().split(' ').join('-');

    router.push(`/dashboard/users/${userId}/fitness/activities/${path}`);
    dispatch(addActivityDetails(data));

    if (onActivityClick) {
      onActivityClick(data);
    }
  };

  return (
    <>
      <Box
        gap={3}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        margin={theme.spacing(3, 0)}
      >
        <CustomInput
          fullWidth
          value={search}
          handleChange={setSearch}
          label={`${t('fitness.exercise.labels.activityName')}`}
          placeholder={`${t('fitness.exercise.placeholders.egActivity')}`}
        />

        <Box gap={1} display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            {searchText
              ? t('general.labels.searchRes')
              : t('fitness.exercise.labels.allAct')}
          </Typography>
          <BoxMain web={web}>
            {activityData.map(it => (
              <ClickNavCardNutrition
                key={it._id}
                onClick={() => handleAddActivity(it)}
                headings={capitalizeFirstLetter(
                  typeof it.name === 'string' ? it.name : it.name[lang],
                )}
              />
            ))}
          </BoxMain>
        </Box>
      </Box>

      <CustomButton
        fullWidth
        color={'primary'}
        variant={'contained'}
        onClick={handleClick}
        children={t('buttons.addCustom')}
      />
    </>
  );
};

export default SearchActivityPanel;
