import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../features/authentication/authSlice';
import diabetesSlice from '../features/diabetes/diabetesSlice';
import activitySlice from '../features/fitness/exercise/slices/activitySlice';
import mealFoodsSlice from '../features/fitness/nutrition/slices/mealFoodsSlice';
import hypertensionSlice from '../features/hypertension/slices/hypertensionSlice';
import medicationSlice from '../features/Medication/medicationSlice';
import api from './api';
import appSlice from './slices/appSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    layout: appSlice,
    diabetes: diabetesSlice,
    activity: activitySlice,
    medication: medicationSlice,
    mealFoodsList: mealFoodsSlice,
    hypertension: hypertensionSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
