import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

type Props = {
  title: string;
  color: string;
  icon: React.ReactNode;
  averages: { title: string; details: string; value: string }[];
};
const AveragesCard: FC<Props> = ({ title, icon, color, averages }) => {
  const theme = useTheme();

  return (
    <Box
      p={1}
      gap={1}
      display={'flex'}
      borderRadius={1}
      flexDirection={'column'}
      sx={{ background: theme.palette.secondary.light }}
    >
      <Box>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Box
            sx={{
              width: '24px',
              height: '24px',
              padding: '4px',
              display: 'flex',
              borderRadius: '4px',
              backgroundColor: color,
            }}
          >
            {icon}
          </Box>
          <Typography variant={'body1'} fontWeight={'fontWeightBold'}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} gap={1} flexDirection={'column'}>
        {averages.map(average => (
          <Box
            p={1}
            display={'flex'}
            borderRadius={1}
            key={average.title}
            alignItems={'center'}
            sx={{ background: '#F2F1F3' }}
          >
            <Box flex={'1 0 auto'}>
              <Typography variant={'subtitle2'}>{average.title}</Typography>
            </Box>
            <Box flex={'2 0 auto'} textAlign={'center'}>
              <Typography variant={'subtitle2'} fontWeight={'fontWeightBold'}>
                {average.details}
              </Typography>
            </Box>
            <Box flex={'1 0 auto'} textAlign={'end'}>
              <Typography variant={'h1'}>{average.value}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AveragesCard;
