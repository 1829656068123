import {
  Box,
  BoxProps,
  IconButton,
  styled,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import SectionHeading from '../../components/SectionHeading';
import {
  CalendarGoalsModal,
  CalendarIcon,
  ClickNavCardNutrition,
  HorizontalCalendar,
  theme,
} from '@hdcorner/ui-library';
import React, { useMemo, useState } from 'react';
import moment from 'moment/moment';
import {
  useGetCalendarActivityLogsQuery,
  useGetUserGoalActivityQuery,
} from '../queries/activitiesQueries';
import { useIonRouter } from '@ionic/react';
import { useGetCaloriesInDepthQuery } from '../../calories/queries/caloriesQueries';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  maxHeight: web ? 'none' : '80%',
  marginBottom: web ? 0 : theme.spacing(3),
  backgroundColor: theme.palette.secondary.light,
  borderBottom: `${theme.spacing(1)} solid ${theme.palette.secondary.light}`,
}));

const BoxSecondary = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flex: 1,
  display: 'flex',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  boxSizing: 'border-box',
  flexDirection: 'column',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  maxHeight: web ? '151px' : 'none',
  borderTop: `1px solid ${theme.palette.secondary.dark}`,
  padding: web ? theme.spacing(2) : theme.spacing(2.5, 2),
}));

const TableData = styled(TableCell)<TableCellProps & { web: boolean }>(
  ({ web, theme }) => ({
    flex: 1,
    border: 'none',
    textAlign: 'center',
    fontsize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    padding: web ? theme.spacing(0, 0, 2, 0) : theme.spacing(0, 0, 5, 0),
    '&:first-of-type': {
      textAlign: 'left',
      textTransform: 'capitalize',
      fontsize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:last-of-type': {
      textAlign: 'right',
    },
  }),
);

interface Props {
  userId: string;
}
const SectionActivitiesCal: React.FC<Props> = ({ userId }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [monthCalendarGoals, setMonthCalendarGoals] = useState<string>();
  const [openCalendarGoals, setOpenCalendarGoals] = useState<boolean>(false);
  const [monthlyCalendarOpen, setMonthlyCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().utc(true).toDate());
  const [params, setParams] = useState({
    end: moment().endOf('day').toISOString(),
    start: moment().startOf('day').toISOString(),
  });

  const { data: activityLogRes } = useGetCalendarActivityLogsQuery({
    userId,
    skip: 0,
    limit: 1000,
    end: params.end,
    start: params.start,
    sort: ['-logDate'],
  });

  const { data: activityGoalRes } = useGetUserGoalActivityQuery({ userId });

  const { data: getCaloriesInDepth } = useGetCaloriesInDepthQuery(
    {
      params: { skip: 0, limit: 10000 },
      end: moment(monthCalendarGoals).endOf('month').toISOString(),
      start: moment(monthCalendarGoals).startOf('month').toISOString(),
    },
    { skip: !monthCalendarGoals },
  );

  const goalActivity = useMemo(() => {
    if (!activityGoalRes || activityGoalRes.length === 0)
      return `- ${t('measurements.minutes')}`;

    return `${activityGoalRes[0].goal.duration} ${t('measurements.minutes')}`;
  }, [activityGoalRes]);

  const activityList = useMemo(() => {
    if (!activityLogRes || activityLogRes.documents.length === 0) return [];
    return activityLogRes.documents.map(it => {
      return {
        _id: it._id,
        duration: it.duration,
        calories: (it.activity?.met || 0) * it.duration,
        name: it.activity?.name
          ? typeof it.activity.name === 'string'
            ? it.activity.name
            : it.activity.name[lang]
          : it.name,
      };
    });
  }, [activityLogRes]);

  const handleClickGoal = () => {
    router.push(`/dashboard/users/${userId}/fitness/exercise/activity/goal`);
  };

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);
    const newDate = moment(date).utc(true);
    const endDate = newDate.endOf('day').toISOString();
    const startDate = newDate.startOf('day').toISOString();
    const data = { endDate, startDate };

    handleCalClick(data);
  };

  const openCalendar = () => {
    setOpenCalendarGoals(true);
  };

  const handleCalClick = (data: any) => {
    const endDate = data.endDate;
    const startDate = data.startDate;

    setParams((prevState: any) => ({
      ...prevState,
      end: endDate,
      start: startDate,
    }));
  };

  const prepareCompletedDays = useMemo(() => {
    if (!getCaloriesInDepth) return [];
    const data = getCaloriesInDepth.documents.map(cal => ({
      success: cal.goalAchieved,
      date: new Date(cal.createdAt),
    }));
    return data.map(cal => ({
      date: cal.date,
      success: cal.success,
    }));
  }, [getCaloriesInDepth]);

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <Box display={web ? 'flex' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('headingsTitles.activities')} />
        <IconButton onClick={openCalendar} sx={{ padding: 0 }}>
          <CalendarIcon width={'20px'} height={'20px'} />
        </IconButton>
      </Box>
      <BoxMain web={web}>
        <HorizontalCalendar
          sx={{ flex: 1 }}
          selectedDate={selectedDate}
          openMonthly={monthlyCalendarOpen}
          setOpenMonthly={setMonthlyCalendarOpen}
          handleChange={(date: any) => handleChangeDate(date)}
        />
        <BoxSecondary web={web}>
          <TableBody>
            {activityList.map(it => (
              <TableRow
                key={it._id}
                sx={{
                  display: 'flex',
                  '&:last-child th, &:last-child td': {
                    padding: 0,
                  },
                }}
              >
                <TableData web={web}>{it.name}</TableData>
                <TableData web={web}>
                  {it.duration} {t('measurements.minutes')}
                </TableData>
                <TableData web={web}>
                  {it.calories} {t('measurements.kcal')}
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </BoxSecondary>
      </BoxMain>

      <Box display={web ? 'none' : 'block'}>
        <ClickNavCardNutrition
          headings={t('buttons.navigation.inDepth')}
          onClick={() =>
            router.push(`/dashboard/users/${userId}/fitness/exercise/in-depth/activities`)
          }
        />
        <ClickNavCardNutrition
          body={goalActivity}
          onClick={handleClickGoal}
          headings={t('headingsTitles.goal')}
        />
      </Box>
      <CalendarGoalsModal
        open={openCalendarGoals}
        setOpen={setOpenCalendarGoals}
        goalDates={prepareCompletedDays}
        onMonthChange={setMonthCalendarGoals}
      />
    </Box>
  );
};

export default SectionActivitiesCal;
