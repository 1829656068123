import * as React from 'react';
import { FC, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ClickNavCardNutrition, Medication } from '@hdcorner/ui-library';
import BannerReschedule from './BannerReschedule';
import moment from 'moment/moment';
import ActionButtonsSmall from './ActionButtonsSmall';
import MedicationHeaderIcon from '../../../assets/icons/MedicationHeaderIcon';
import { useTranslation } from 'react-i18next';

type MedicationDrugModalProps = {
  userId: string;
  medication: Medication | null;
  handleDeleteClick: () => void;
  handleInstructionsClick: () => void;
};
const MedicationDrugModal: FC<MedicationDrugModalProps> = ({
  medication,
  handleInstructionsClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const rescheduleBanner = useMemo(() => {
    if (!medication) return null;

    if (medication.rescheduledTo) {
      return (
        <BannerReschedule
          time={t('general.labels.to')}
          date={moment(medication.rescheduledTo).format('DD/MM/YYYY')}
        />
      );
    }

    if (medication.rescheduledFrom) {
      return (
        <BannerReschedule
          time={t('general.labels.from')}
          date={moment(medication.rescheduledFrom).format('DD/MM/YYYY')}
        />
      );
    }
  }, [medication]);

  const renderActionButtons = useMemo(() => {
    if (!medication) return null;

    return (
      <ActionButtonsSmall
        taken={medication.taken}
        rescheduledTo={!!medication.rescheduledTo}
        rescheduledFrom={!!medication.rescheduledFrom}
      />
    );
  }, [medication]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} padding={3}>
      {medication?.rescheduledFrom && (
        <BannerReschedule
          time={t('general.labels.from')}
          date={moment(medication.rescheduledFrom).format('DD/MM/YYYY')}
        />
      )}
      {rescheduleBanner}
      {renderActionButtons}
      {medication?.medicine.substanceUrl && (
        <Box
          px={2}
          py={1}
          borderRadius={1}
          sx={{
            backgroundColor: 'white',
            paddingInline: theme.spacing(2),
          }}
        >
          <MedicationHeaderIcon
            width={'16px'}
            height={'16px'}
            color={theme.palette.medication.main}
          />
          <Typography
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            variant={'subtitle2'}
            color={theme.palette.medication.main}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {medication.medicine.substanceUrl}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography
          variant={'subtitle2'}
          marginBottom={theme.spacing(1)}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {t('general.labels.info')}
        </Typography>
        <ClickNavCardNutrition
          headings={t('general.labels.content')}
          body={medication ? medication.medicine.content : `${t('general.labels.nA')}`}
        />
        <ClickNavCardNutrition
          headings={t('general.labels.type')}
          body={
            medication ? medication.medicine.medicineType : `${t('general.labels.nA')}`
          }
        />
        <ClickNavCardNutrition
          headings={t('buttons.navigation.package')}
          body={medication ? medication.medicine.package : `${t('general.labels.nA')}`}
        />
        <ClickNavCardNutrition
          onClick={handleInstructionsClick}
          headings={t('buttons.navigation.medInstructions')}
        />
      </Box>
    </Box>
  );
};

export default MedicationDrugModal;
