import api from '../../../../redux/api';
import { ApiPagination, TipsTricks } from '@hdcorner/ui-library';

const tipsTricksQueries = api
  .enhanceEndpoints({ addTagTypes: ['TipsTricks'] })
  .injectEndpoints({
    endpoints: build => ({
      getTipsAndTricks: build.query<
        { trafficLights: TipsTricks[] },
        ApiPagination & { level?: string; userId: string; lang: string }
      >({
        query: ({ skip, limit, sort, level, userId, lang }) => ({
          method: 'GET',
          params: { skip, limit, level, userId, sort, lang },
          url: '/hdCornerService/user/traffic-lights',
        }),
        providesTags: ['TipsTricks'],
      }),
    }),
  });

export const { useGetTipsAndTricksQuery } = tipsTricksQueries;

export default tipsTricksQueries;
