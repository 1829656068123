import * as React from 'react';
import { SVGProps } from 'react';

const ArrowForwardDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.04353 11.7614C7.68498 12.0757 7.63654 12.6362 7.93533 13.0133C8.23411 13.3904 8.76698 13.4414 9.12552 13.1271L14.1959 8.6827C14.3886 8.51381 14.5 8.26364 14.5 7.99984C14.5 7.73603 14.3886 7.48586 14.1959 7.31698L9.12553 2.87255C8.76698 2.55827 8.23411 2.60923 7.93533 2.98636C7.63654 3.36349 7.68498 3.92399 8.04353 4.23827L12.3349 7.99984L8.04353 11.7614Z"
      fill={props?.color ?? '#B2B8BD'}
    />
    <path
      d="M2.80409 11.7614C2.44555 12.0757 2.3971 12.6362 2.69589 13.0133C2.99468 13.3904 3.52755 13.4414 3.88609 13.1271L8.95649 8.6827C9.14916 8.51381 9.26056 8.26364 9.26056 7.99984C9.26056 7.73603 9.14916 7.48586 8.95649 7.31698L3.88609 2.87255C3.52755 2.55827 2.99468 2.60923 2.69589 2.98636C2.39711 3.36349 2.44555 3.92399 2.80409 4.23827L7.09546 7.99984L2.80409 11.7614Z"
      fill={props?.color ?? '#B2B8BD'}
    />
  </svg>
);

export default ArrowForwardDouble;
