import api from '../../../redux/api';
import { AuthUser, HealthProfessionalsData } from '@hdcorner/ui-library';

const onBoardingQueries = api.injectEndpoints({
  endpoints: build => ({
    setTermsAndConditions: build.mutation<
      AuthUser,
      { termsAndConditionsAccepted: boolean; privacyPolicyAccepted: boolean }
    >({
      query: ({ termsAndConditionsAccepted, privacyPolicyAccepted }) => ({
        method: 'PUT',
        url: 'database/function/setAuthUser',
        body: { termsAndConditionsAccepted, privacyPolicyAccepted },
      }),
      invalidatesTags: ['AuthUser'],
    }),

    setAuthUserRole: build.mutation<AuthUser, void>({
      query: () => ({
        method: 'PUT',
        body: { role: 'hcp' },
        url: '/database/function/setAuthUser',
      }),
      invalidatesTags: ['AuthUser'],
    }),

    setOnboardingOne: build.mutation<
      AuthUser,
      Omit<AuthUser, '_id' | 'email' | 'createdAt' | 'birthDate'>
    >({
      query: body => ({
        method: 'PUT',
        body: { ...body, role: 'hcp' },
        url: 'database/function/setAuthUser',
      }),
      invalidatesTags: ['AuthUser'],
    }),

    setOnboardingTwo: build.mutation<
      HealthProfessionalsData,
      Omit<HealthProfessionalsData, 'authUser' | '_id' | 'verified'>
    >({
      query: body => ({
        method: 'POST',
        body: { ...body },
        url: 'database/function/createHcpData',
      }),
      invalidatesTags: ['HealthProfessionalData'],
    }),

    calculateLocationData: build.mutation<string, void>({
      query: () => ({
        method: 'POST',
        url: `hdCornerService/hcp/location`,
      }),
    }),

    removeCalculatedLocationData: build.mutation<string, void>({
      query: () => ({
        method: 'DELETE',
        url: `hdCornerService/hcp`,
      }),
    }),

    authorizeHcpUser: build.mutation<AuthUser, void>({
      query: () => ({
        method: 'POST',
        url: 'hdCornerService/hcp/authorization',
      }),
      invalidatesTags: ['AuthUser', 'HealthProfessionalData'],
    }),
  }),
});

export const {
  useSetAuthUserRoleMutation,
  useAuthorizeHcpUserMutation,
  useSetOnboardingOneMutation,
  useSetOnboardingTwoMutation,
  useSetTermsAndConditionsMutation,
  useCalculateLocationDataMutation,
  useRemoveCalculatedLocationDataMutation,
} = onBoardingQueries;

export default onBoardingQueries;
