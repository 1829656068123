import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import {
  ComponentsLayout,
  CustomButton,
  HorizontalCalendar,
  MedicationRequest,
  ModalMobile,
  theme,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import moment from 'moment/moment';
import PageLayout from '../../components/PageLayout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useCreateNewMedicationMutation } from './queries/medicationQueries';
import { clear } from './medicationSlice';
import { extractRepeat } from './utils/extractRepeat';
import AddMedicationOverview from './components/AddMedicationOverview';
import { RouteComponentProps } from 'react-router';
import useAlert from '../../hooks/useAlert';
import { useNotifyUserMutation } from '../../redux/queries/queries';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const AddMedication: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();

  const { medicine, frequency, repeat } = useAppSelector(state => state.medication);

  const [notify] = useNotifyUserMutation();
  const [createMedication, { isLoading }] = useCreateNewMedicationMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date>(moment().add(1, 'days').toDate());

  const medication = useMemo(() => {
    return {
      medicine: '',
      repeat: extractRepeat(repeat),
      end: moment(endDate).endOf('day').toISOString(),
      start: moment(startDate).startOf('day').toISOString(),
      frequency: frequency.map(f => {
        const hours = f.time.split(':')[0];
        const minutes = f.time.split(':')[1];
        const time = moment()
          .hours(Number(hours))
          .minutes(Number(minutes))
          .seconds(0)
          .milliseconds(0);
        return {
          amount: f.amount,
          time: time.toISOString(),
        };
      }),
    };
  }, [repeat, endDate, startDate, frequency]);

  const handleShowFrequency = () => {
    router.push(`/dashboard/users/${userId}/medication-frequency`);
  };

  const handleShowDuration = () => {
    setOpen(true);
  };

  const handleMedicationNext = () => {
    if (!medicine) {
      presentError(t('errors.medication.errorPleaseSelect'));
      return;
    }
    const medication: MedicationRequest = {
      medicine: medicine._id,
      repeat: extractRepeat(repeat),
      end: moment(endDate).endOf('day').toISOString(),
      start: moment(startDate).startOf('day').toISOString(),
      frequency: frequency.map(f => {
        const hours = f.time.split(':')[0];
        const minutes = f.time.split(':')[1];
        const time = moment()
          .hours(Number(hours))
          .minutes(Number(minutes))
          .seconds(0)
          .milliseconds(0);
        return {
          amount: f.amount,
          time: time.toISOString(),
        };
      }),
    };

    createMedication({ ...medication, userId })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('medication.notifications.addMed'),
            body: t('medication.notifications.addMedBody'),
          },
        });
        dispatch(clear());
        router.push(`/dashboard/users/${userId}/medication`, 'back', 'pop');
      })
      .catch(err => {
        console.log(err);
        presentError(t('errors.generic.error01'));
      });
  };

  const handleShowRepeat = () => {
    router.push(`/dashboard/users/${userId}/medication-repeat`);
  };

  const handleChange = (dateRange: Date[]) => {
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  return (
    <PageLayout
      headerColor={theme.palette.primary.main}
      headerTitle={t('medication.titles.addMeds')}
      defaultHref={`/dashboard/users/${userId}/medication`}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <AddMedicationOverview
          medicine={medicine}
          medication={medication}
          handleShowRepeat={handleShowRepeat}
          handleShowDuration={handleShowDuration}
          handleShowFrequency={handleShowFrequency}
        />
        <CustomButton
          fullWidth
          disableElevation
          color={'primary'}
          disabled={
            !endDate ||
            isLoading ||
            !medicine ||
            !startDate ||
            !repeat?.length ||
            !frequency?.length
          }
          variant={'contained'}
          onClick={handleMedicationNext}
        >
          {t('buttons.createMedication')}
        </CustomButton>
        {isLoading && (
          <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Typography variant={'subtitle2'}>
              {t('medication.text.creatingMeds')}
            </Typography>
            <CircularProgress />
          </Box>
        )}
      </ComponentsLayout>
      <ModalMobile open={open} setOpen={setOpen}>
        <HorizontalCalendar
          openMonthly
          selectFutureDates
          selectedDate={[startDate, endDate]}
          handleChange={date => handleChange(date as Date[])}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default AddMedication;
