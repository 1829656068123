import * as React from 'react';
import { SVGProps } from 'react';

const ArrowBackward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M11.5335 4.23827C11.8871 3.92399 11.9348 3.36349 11.6402 2.98636C11.3455 2.60923 10.8201 2.55827 10.4665 2.87255L5.46653 7.31698C5.27654 7.48586 5.16669 7.73603 5.16669 7.99984C5.16669 8.26364 5.27654 8.51381 5.46653 8.6827L10.4665 13.1271C10.8201 13.4414 11.3455 13.3904 11.6402 13.0133C11.9348 12.6362 11.8871 12.0757 11.5335 11.7614L7.30172 7.99984L11.5335 4.23827Z"
        fill={props?.color ?? '#B2B8BD'}
      />
    </g>
  </svg>
);

export default ArrowBackward;
