import React, { FC, useEffect, useState } from 'react';
import {
  CustomButton,
  CustomInput,
  DropdownMenu,
  HealthProfessionalsData,
} from '@hdcorner/ui-library';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../redux/hooks';
import { hcpSpecialties } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../../utils/useConvertJSON';

export type UserDetailsThreeProps = {
  handleNextPage: (
    data: Omit<HealthProfessionalsData, 'authUser' | '_id' | 'verified' | 'code'>,
  ) => void;
};

const UserDetailsTwo: FC<UserDetailsThreeProps> = ({ handleNextPage }) => {
  const {
    data: { user },
  } = useAppSelector(state => state.auth);
  const { t } = useTranslation();

  const [vat, setVat] = useState('');
  const [area, setArea] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [clinical, setClinical] = useState('');
  const [addressNum, setAddressNum] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [speciality, setSpeciality] = useState('');

  useEffect(() => {
    if (user) {
      setVat(user.vat);
      setArea(user.area);
      setPhone(user.phone);
      setAddress(user.address);
      setAddressNum(user.addressNum);
      setPostalCode(user.postalCode);
      setSpeciality(user.speciality);
      setClinical(
        user.clinicalCoach === true ? 'yes' : user.clinicalCoach === false ? 'no' : '',
      );
    }
  }, [user]);

  const handleSaveData = () => {
    const clinicalCoach = clinical === 'yes';
    const data = {
      vat,
      area,
      phone,
      address,
      addressNum,
      postalCode,
      speciality,
      clinicalCoach,
    };
    handleNextPage(data);
  };

  const isDisabled = (): boolean => {
    return (
      vat === '' ||
      area === '' ||
      phone === '' ||
      address === '' ||
      addressNum === '' ||
      postalCode === '' ||
      speciality === '' ||
      clinical === ''
    );
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <DropdownMenu
          fullWidth
          placeholder={'-'}
          value={speciality}
          menuItems={useConvertJSON(hcpSpecialties)}
          label={`${t('healthProf.hcp.details.speciality')}`}
          setValue={(speciality: string) => setSpeciality(speciality)}
        />
      </Box>
      <Box>
        <CustomInput
          fullWidth
          value={vat}
          type={'number'}
          handleChange={setVat}
          label={`${t('healthProf.hcp.details.vat')}`}
        />
      </Box>
      <Box display={'flex'}>
        <CustomInput
          value={address}
          handleChange={setAddress}
          label={`${t('location.street')}`}
        />
        <CustomInput
          type={'number'}
          value={addressNum}
          handleChange={setAddressNum}
          label={`${t('location.streetNum')}`}
        />
      </Box>
      <Box display={'flex'}>
        <CustomInput
          value={area}
          handleChange={setArea}
          label={`${t('location.area')}`}
        />
        <CustomInput
          type={'number'}
          value={postalCode}
          handleChange={setPostalCode}
          label={`${t('location.postCode')}`}
        />
      </Box>
      <Box display={'flex'}>
        <CustomInput
          fullWidth
          value={phone}
          type={'number'}
          handleChange={setPhone}
          label={`${t('profile.titles.phone')}`}
        />
      </Box>
      <Box display={'flex'}>
        <DropdownMenu
          fullWidth
          value={clinical}
          placeholder={'-'}
          label={`${t('healthProf.hcp.details.cc')}`}
          menuItems={[
            { label: t('userDetails.no'), value: 'no' },
            { label: t('userDetails.yes'), value: 'yes' },
          ]}
          setValue={(clinical: string) => setClinical(clinical)}
        />
      </Box>
      <CustomButton
        fullWidth
        variant={'contained'}
        disabled={isDisabled()}
        onClick={handleSaveData}
      >
        {t('buttons.continue')}
      </CustomButton>
    </Box>
  );
};

export default UserDetailsTwo;
