import {
  calculateValues,
  Collapsible,
  ComponentsLayout,
  DownloadIcon,
  FeelsEmpty,
  filterValues,
} from '@hdcorner/ui-library';
import { Box, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import PageLayout from '../../components/PageLayout';
import InDepthDataOverallCard from './components/InDepthDataOverallCard';
import useTitle from './hooks/useTitle';
import useType from './hooks/useType';
import {
  useDeleteLipidLogMutation,
  useGetLipidLogsQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import useAlert from '../../hooks/useAlert';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useNotifyUserMutation } from '../../redux/queries/queries';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
    type: string;
  }> {}
const LipidInDepthData: FC<Props> = ({
  match: {
    params: { userId, type },
  },
}) => {
  const theme = useTheme();
  const title = useTitle(type);
  const lipidType = useType(type);
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [lipidParams, setLipidParams] = useState({
    skip: 0,
    limit: 20,
  });

  const [notify] = useNotifyUserMutation();
  const [deleteLogMutation] = useDeleteLipidLogMutation();

  const {
    data: lipidLogs,
    error: lipidError,
    isFetching: lipidLogsFetching,
  } = useGetLipidLogsQuery({
    userId,
    params: { ...lipidParams, sort: ['-logDate'] },
  });

  const { data: lipidOverallData, error: lipidOverallError } = useGetOverallLipidQuery({
    userId,
  });

  useEffect(() => {
    if (lipidError || lipidOverallError) presentError(t('errors.generic.error01'));
  }, [lipidError, lipidOverallError]);

  const overallData = useMemo(() => {
    if (!lipidOverallData)
      return {
        max: 0,
        min: 0,
        avg: 0,
        title: lipidType,
        measure: t('measurements.mgdl'),
      };

    const lipidOverall = lipidOverallData as any;

    return {
      max:
        lipidOverall && lipidOverall[lipidType]
          ? Math.round(lipidOverall[lipidType].max)
          : 0,
      min:
        lipidOverall && lipidOverall[lipidType]
          ? Math.round(lipidOverall[lipidType].min)
          : 0,
      avg:
        lipidOverall && lipidOverall[lipidType]
          ? Math.round(lipidOverall[lipidType].avg)
          : 0,
      title: lipidType === 'totalChol' ? 'total chol' : lipidType,
      measure: t('measurements.mgdl'),
    };
  }, [lipidOverallData, lipidType]);

  const prepareData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      const lipidTypeUpperCased = lipidType.toUpperCase();
      return lipidLogs.documents
        .filter(log => filterValues(lipidType, log))
        .map(log => {
          return {
            _id: log._id,
            title: calculateValues(lipidType, log) + '',
            titleSub: t('measurements.mgdl'),
            data: {
              [lipidTypeUpperCased]: {
                value: `${calculateValues(lipidType, log)} ${t('measurements.mgdl')}`,
              },
              [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
              [t('inDepth.dateOfCalc')]: {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs, lipidType]);

  const removeLog = (id: string) => {
    let valueKey: string = lipidType;
    if (lipidType === 'chol') {
      valueKey = 'totalChol';
    } else if (lipidType === 'trg') {
      valueKey = 'triglycerides';
    }
    deleteLogMutation({ userId, _id: id, lipidType: valueKey })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('lipid.notifications.logDel'),
            body: t('lipid.notifications.logDelBody'),
          },
        });
      })
      .catch(() => {
        presentError(t('errors.generic.error01'));
      });
  };

  return (
    <PageLayout
      defaultHref={`/dashboard/users/${userId}/lipid`}
      headerTitle={t('inDepth.indepthTitle', { type: title })}
      endButtons={[
        {
          icon: <DownloadIcon />,
          onClick: () => setDownloadDataModalOpen(true),
        },
      ]}
    >
      <ComponentsLayout>
        <Box marginBottom={theme.spacing(3)}>
          <InDepthDataOverallCard
            min={overallData.min}
            max={overallData.max}
            avg={overallData.avg}
            type={overallData.title}
            measure={overallData.measure}
          />
        </Box>
        <Typography variant={'h1'} marginBottom={theme.spacing(3)}>
          {t('headingsTitles.latestUpdates')}
        </Typography>
        {prepareData.length > 0 ? (
          <InfiniteScrollList
            skip={lipidParams.skip}
            limit={lipidParams.limit}
            count={prepareData.length}
            loading={lipidLogsFetching}
            totalCount={lipidLogs?.count}
            onLoadMore={(skip, limit) => setLipidParams({ skip, limit })}
          >
            {prepareData.map((item, index) => (
              <Box key={index}>
                <Collapsible
                  data={item.data}
                  title={item.title}
                  titleSub={item.titleSub}
                  handleRemove={() => {
                    removeLog(item._id);
                  }}
                />
              </Box>
            ))}
          </InfiniteScrollList>
        ) : (
          <FeelsEmpty />
        )}
      </ComponentsLayout>
      <ModalInDepthDataDL
        module={'lipid'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </PageLayout>
  );
};

export default LipidInDepthData;
