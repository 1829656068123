import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { IDBadge, InfoCards } from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useGetUserDetailsQuery } from './queries/usersQueries';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const SectionBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const Heading = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type Props = {
  userId: string;
};
const UserInfo: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { data: userDetails } = useGetUserDetailsQuery({ userId });

  const details = useMemo(() => {
    return {
      personal: [
        {
          category: t('personalDetails.name'),
          text:
            userDetails?.data.authUser.firstName +
            ' ' +
            userDetails?.data.authUser.lastName,
        },
        {
          category: t('userDetails.dob'),
          text: userDetails?.data.authUser.birthDate
            ? moment(userDetails?.data.authUser.birthDate).format('DD/MM/YYYY')
            : '-',
        },
        {
          category: t('userDetails.sex'),
          text: userDetails?.data.authUser.gender || '-',
        },
        {
          category: t('personalDetails.email'),
          text: userDetails?.data.authUser.email || '-',
        },
      ],
      health: [
        {
          text: userDetails?.mood.mood || '-',
          category: t('healthProf.hcp.text.howAre'),
          date: moment(userDetails?.mood.createdAt).format('DD/MM/YYYY'),
        },
        {
          category: t('general.labels.type'),
          text: userDetails?.data.questionnaireOutcome || '-',
          date: moment(userDetails?.data.createdAt).format('DD/MM/YYYY'),
        },
        {
          text: userDetails?.data.hypertension ? 'Yes' : 'No',
          category: t('dashboard.dashcards.hypertension.title'),
          date: moment(userDetails?.data.updatedAt).format('DD/MM/YYYY'),
        },
        {
          category: t('userDetails.dyslipidemia'),
          text: userDetails?.data.dyslipidemia ? 'Yes' : 'No',
          date: moment(userDetails?.data.updatedAt).format('DD/MM/YYYY'),
        },
        {
          category: t('userDetails.medicated'),
          text: userDetails?.data.underMedication ? 'Yes' : 'No',
          date: moment(userDetails?.data.updatedAt).format('DD/MM/YYYY'),
        },
        {
          category: t('userDetails.smoker'),
          text: userDetails?.data.smoker ? 'Yes' : 'No',
          date: moment(userDetails?.data.updatedAt).format('DD/MM/YYYY'),
        },
        {
          text: userDetails?.data.diabetes || '-',
          category: t('dashboard.dashcards.diabetes.title'),
          date: moment(userDetails?.data.updatedAt).format('DD/MM/YYYY'),
        },
      ],
      account: [
        {
          category: t('subscription.labels.role'),
          text: userDetails?.data.premiumUser
            ? t('subscription.labels.premium')
            : t('subscription.labels.basic'),
        },
        {
          category: t('subscription.text.expDate'),
          text: userDetails?.subscriptionActiveUntil
            ? moment(userDetails?.subscriptionActiveUntil).format('DD/MM/YYYY')
            : '-',
        },
        {
          category: t('personalDetails.accCreated'),
          text: moment(userDetails?.data.createdAt).format('DD/MM/YYYY'),
        },
        {
          category: t('personalDetails.lastLogin'),
          text: userDetails?.lastLogin
            ? moment(userDetails?.lastLogin).format('DD/MM/YYYY')
            : '-',
        },
      ],
    };
  }, [userDetails]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
      {userDetails?.data.code && (
        <Box>
          <Heading>{t('profile.titles.codeReferral')}</Heading>
          <Box maxWidth={'fit-content'}>
            <IDBadge id={userDetails?.data.code} />
          </Box>
        </Box>
      )}
      <Box>
        <Heading>{t('profile.titles.personalInfo')}</Heading>
        <SectionBox flexDirection={mobileView ? 'column' : 'row'}>
          {details &&
            details.personal.map((value, index) => (
              <InfoCards key={index} text={value.text} category={value.category} />
            ))}
        </SectionBox>
      </Box>
      <Box>
        <Heading>{t('profile.titles.healthInfo')}</Heading>
        <SectionBox flexDirection={mobileView ? 'column' : 'row'}>
          {details &&
            details.health.map((value, index) => (
              <InfoCards
                key={index}
                text={value.text}
                date={value.date}
                category={value.category}
              />
            ))}
        </SectionBox>
      </Box>
      <Box>
        <Heading>{t('profile.text.accountInfo')}</Heading>
        <SectionBox flexDirection={mobileView ? 'column' : 'row'}>
          {details &&
            details.account.map((value, index) => (
              <InfoCards key={index} text={value.text} category={value.category} />
            ))}
        </SectionBox>
      </Box>
    </Box>
  );
};

export default UserInfo;
