import Box from '@mui/material/Box';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  AllMeasurements,
  CalendarModal,
  ClickNavCardNutrition,
  FeelsEmpty,
  LineChart,
  ModalFilters,
  ToggleButtonsComp,
  usePreparePressureGraph,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Typography, useTheme } from '@mui/material';
import GraphDots from './components/GraphDots';
import {
  useGetHypertensionOverallQuery,
  useGetLatestLogQuery,
  useGetPressureGoalQuery,
  useGetPressureGraphDataQuery,
} from './queries/hypertensionQueries';
import useTimeframe from '../../hooks/useTimeframe';
import usePrepareOverallData from './hooks/usePrepareOverallData';
import useCategoryLabel from './hooks/useCategoryLabel';
import { MeasurementOptions } from './constants';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

interface Props {
  userId: string;
}
const HypertensionPressure: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { presentError } = useAlert();

  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [categorySelectModal, setCategorySelectModal] = useState<boolean>(false);

  // Used for updating the params for the query
  const [startParam, setStartParam] = useState<string>('');
  const [endParam, setEndParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const { data: overallData, error: errorOverall } = useGetHypertensionOverallQuery({
    userId,
    ...timeframe,
    category: selectedCategory === 'all' ? undefined : selectedCategory,
  });

  const { data: pressureGoal, error: pressureGoalError } = useGetPressureGoalQuery({
    userId,
  });

  const { data: graphData, error: errorGraph } = useGetPressureGraphDataQuery({
    userId,
    ...timeframe,
  });

  const { data: hypertensionLatestLog, error: hypertensionError } = useGetLatestLogQuery({
    userId,
  });

  useEffect(() => {
    if (hypertensionError) presentError(t('errors.generic.errorFetchLog'));
    if (pressureGoalError) presentError(t('errors.generic.errorFetchGoal'));
    if (errorGraph) presentError(t('errors.generic.errorFetchGraph'));
    if (errorOverall) presentError(t('errors.generic.error01'));
  }, [hypertensionError, pressureGoalError, errorOverall, errorGraph]);

  const prepareGoalValue = useMemo(() => {
    if (pressureGoal && pressureGoal.length > 0) {
      const value = {
        systolic: pressureGoal[0].goal.systolic,
        diastolic: pressureGoal[0].goal.diastolic,
      };

      return value.systolic + '/' + value.diastolic + ` ${t('measurements.mmGH')}`;
    }

    return t('buttons.addGoal');
  }, [pressureGoal]);

  const hypertensionData = useMemo(() => {
    if (hypertensionLatestLog && hypertensionLatestLog.documents.length > 0) {
      const data = hypertensionLatestLog.documents[0];
      return {
        systolic: data ? data.systolic : '-',
        diastolic: data ? data.diastolic : '-',
      };
    }

    return { systolic: '-', diastolic: '-' };
  }, [hypertensionLatestLog]);

  const categoryLabel = t(useCategoryLabel(selectedCategory));
  const prepareGraphData = usePreparePressureGraph(graphData);
  const prepareOverallData = usePrepareOverallData(overallData);

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  const handleShowInDepthData = () => {
    router.push(`/dashboard/users/${userId}/hypertension-in-depth-data`);
  };

  const handleShowGraphData = () => {
    router.push(`/dashboard/users/${userId}/hypertension-graph-data`);
  };

  const handleShowGoal = () => {
    router.push(`/dashboard/users/${userId}/hypertension-goal`);
  };

  const renderDots = useMemo(() => {
    let dots: any[] = [];
    if (prepareGraphData.length > 0) {
      dots = [
        {
          color: theme.palette.nutrition.main,
          text: t('dashboard.dashcards.hypertension.systolic'),
        },
        {
          color: theme.palette.medication.main,
          text: t('dashboard.dashcards.hypertension.diastolic'),
        },
      ];
    }
    return dots;
  }, [prepareGraphData]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={theme.spacing(1)}
      marginTop={theme.spacing(3)}
    >
      <AllMeasurements
        text={categoryLabel}
        openModal={() => setCategorySelectModal(true)}
      />
      <Box>
        <ToggleButtonsComp
          data={prepareOverallData}
          setOpen={setCalendarModalOpen}
          toggleButtonsValue={selectedTimeframe}
          setToggleButtonsValue={setSelectedTimeframe}
          customColor={theme.palette.hypertension.main}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          borderRadius: '8px',
          flexDirection: 'column',
          backgroundColor: 'white',
          gap: theme.spacing(2),
          padding: theme.spacing(2),
        }}
      >
        <Box display={'flex'} alignItems={'center'} gap={theme.spacing(1)}>
          <Typography
            variant={'h1'}
            color={theme.palette.primary.main}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          >
            {t('hypertension.labels.latestSys')}
          </Typography>
          <Typography
            variant={'subtitle1'}
            color={theme.palette.primary.light}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {hypertensionData.systolic} {t('measurements.mmGH')}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={theme.spacing(1)}>
          <Typography
            variant={'h1'}
            color={theme.palette.primary.main}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          >
            {t('hypertension.labels.latestDia')}
          </Typography>
          <Typography
            variant={'subtitle1'}
            color={theme.palette.primary.light}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {hypertensionData.diastolic} {t('measurements.mmGH')}
          </Typography>
        </Box>
        <GraphDots justify={'flex-start'} dots={renderDots} />
        {prepareGraphData.length > 0 && (
          <LineChart
            chartData={prepareGraphData}
            colors={[theme.palette.medication.main, theme.palette.nutrition.main]}
          />
        )}
        {prepareGraphData.length === 0 && <FeelsEmpty />}
      </Box>
      <Box marginTop={2}>
        <ClickNavCardNutrition
          onClick={handleShowInDepthData}
          headings={t('buttons.navigation.inDepth')}
        />

        <ClickNavCardNutrition
          headings={t('buttons.navigation.graphData')}
          onClick={handleShowGraphData}
        />

        <ClickNavCardNutrition
          headings={t('hypertension.titles.esh')}
          onClick={() => router.push(`/dashboard/users/${userId}/hypertension/esh`)}
        />

        <ClickNavCardNutrition
          headings={t('hypertension.titles.24Hours')}
          onClick={() => router.push(`/dashboard/users/${userId}/hypertension/24hours`)}
        />

        {pressureGoal && pressureGoal.length > 0 && (
          <ClickNavCardNutrition
            body={prepareGoalValue}
            onClick={handleShowGoal}
            headings={t('headingsTitles.goal')}
          />
        )}
      </Box>

      <ModalFilters
        open={categorySelectModal}
        selected={selectedCategory}
        options={useConvertJSON(MeasurementOptions)}
        dismiss={newValue => {
          setSelectedCategory(newValue);
          setCategorySelectModal(false);
        }}
      />
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default HypertensionPressure;
