import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  ClickNavCardNutrition,
  Medicine,
  Searchbar,
  theme,
  useDebounce,
} from '@hdcorner/ui-library';
import { Box, List, ListItem } from '@mui/material';
import { useGetSearchMedicinesQuery } from '../queries/medicationQueries';
import { concatMedicine } from '../utils/medicineUtils';
import { useAppDispatch } from '../../../redux/hooks';
import { setMedicine } from '../medicationSlice';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  handleMedicineSelect: () => void;
};
const MedicinesList: FC<Props> = ({ handleMedicineSelect }) => {
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const [medicineSearchParams] = useState({
    skip: 0,
    limit: 1000,
  });

  const searchText = useDebounce(search, 500);

  const { error, data: medicinesRes } = useGetSearchMedicinesQuery({
    ...medicineSearchParams,
    searchText: searchText,
  });

  useEffect(() => {
    if (error) presentError(t('errors.medication.errorFetchMeds'));
  }, [error]);

  const prepareMedicines = useMemo(() => {
    if (!medicinesRes) return [];

    return medicinesRes.documents;
  }, [medicinesRes]);

  const handleClickMedicine = (medicine: Medicine) => {
    dispatch(setMedicine(medicine));
    handleMedicineSelect();
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
      <Searchbar placeholder={'Search'} value={search} setValue={setSearch} />
      <List
        sx={{
          borderRadius: '8px',
          backgroundColor: theme.palette.secondary.light,
        }}
      >
        {prepareMedicines.map(medicine => {
          return (
            <ListItem disablePadding key={medicine._id}>
              <ClickNavCardNutrition
                headings={concatMedicine(medicine)}
                onClick={() => handleClickMedicine(medicine)}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default MedicinesList;
