import { Box, Typography } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import RescheduleIcon from '../../../assets/icons/RescheduleIcon';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type BannerRescheduleProps = {
  time: 'from' | 'to';
  date: string;
};

const BannerReschedule: FC<BannerRescheduleProps> = ({ time, date }) => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      gap={theme.spacing(1)}
      padding={theme.spacing(1, 2)}
      marginBottom={theme.spacing(-2)}
      sx={{ backgroundColor: theme.palette.secondary.light }}
    >
      <RescheduleIcon color={theme.palette.error.main} />
      <Typography
        variant={'subtitle2'}
        color={theme.palette.primary.main}
        fontWeight={theme.typography.fontWeightRegular}
      >
        {t('medication.text.reschedule', { time: time, date: date })}
      </Typography>
    </Box>
  );
};

export default BannerReschedule;
