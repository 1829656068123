import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { MealsData } from '../types/Food';

export const findMeal = (value: string, array?: MealsData[]) => {
  return array?.find((it: any) => it.category === value);
};

export const totalValue = (object: any, value: any) => {
  if (object && Array.isArray(object.foods)) {
    const calories = object.foods.map((it: any) => it.food[value]);
    const quantity = object.foods.map((it: any) => it.quantity);
    const calculation = calories.map((it: number, index: number) => {
      return Math.round((it / 100) * quantity[index]);
    });
    return calculation.reduce((sum: number, current: number) => sum + current, 0);
  }
  return 0;
};

export const foodTitlesString = (object: any) => {
  if (object && Array.isArray(object.foods)) {
    const names = object.foods.map((it: any) => it.food.name);
    const namesCapitalised = names.map((it: string) => {
      return capitalizeFirstLetter(it);
    });
    return namesCapitalised.join(', ');
  }
};
