import { useMemo } from 'react';

const useCategoryLabel = (selectedCategory: string): string => {
  return useMemo<string>(() => {
    if (selectedCategory === 'morning') {
      return 'constants.hypertension.measurements.morning';
    } else if (selectedCategory === 'afternoon') {
      return 'constants.hypertension.measurements.afternoon';
    }
    return 'constants.hypertension.measurements.all';
  }, [selectedCategory]);
};

export default useCategoryLabel;
