import * as React from 'react';
import { CSSProperties, FC, useEffect, useMemo } from 'react';
import {
  BarChart,
  FeelsEmpty,
  LineChart,
  LipidLog,
  ScatterChart,
  theme,
} from '@hdcorner/ui-library';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { useGetLipidGraphDataQuery } from '../queries/lipidQueries';
import useTimeframe from '../../../hooks/useTimeframe';
import useTitle from '../hooks/useTitle';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const GraphTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'dotColor',
})<TypographyProps & { dotColor?: CSSProperties['color'] }>(({ dotColor, theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    backgroundColor: dotColor ? dotColor : '#222222',
  },
}));

type Props = {
  userId: string;
  metric: string;
  customEndDate?: string;
  customStartDate?: string;
  selectedGraphType: string;
  selectedTimeframe: string;
};
const LipidGraphData: FC<Props> = ({
  userId,
  metric,
  customEndDate,
  customStartDate,
  selectedTimeframe,
  selectedGraphType,
}) => {
  const title = useTitle(metric);
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const timeframe = useTimeframe(selectedTimeframe, customStartDate, customEndDate);

  const { error, data: lipidGraphData } = useGetLipidGraphDataQuery({
    userId,
    params: { metric: metric === 'trg' ? 'triglycerides' : metric, ...timeframe },
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.errorFetchGraph'));
  }, [error]);

  const chartData = useMemo(() => {
    if (!lipidGraphData) return [];

    return lipidGraphData.logs.map((item: LipidLog) => ({
      x: item.logDate,
      y: (item as any)[metric === 'trg' ? 'triglycerides' : metric],
    }));
  }, [lipidGraphData, metric]);

  return (
    <Box>
      <GraphContainer>
        {chartData.length > 0 && selectedGraphType === 'lineChart' && (
          <LineChart
            chartData={[{ name: title, data: chartData }]}
            colors={[theme.palette.error.main, theme.palette.warning.main]}
          />
        )}
        {chartData.length > 0 && selectedGraphType === 'barChart' && (
          <BarChart
            unitOfTime={'day'}
            chartData={chartData}
            colorList={[theme.palette.error.main, theme.palette.warning.main]}
          />
        )}
        {chartData.length > 0 && selectedGraphType === 'scatterChart' && (
          <ScatterChart
            chartData={[{ name: title, data: chartData }]}
            colorList={[theme.palette.error.main, theme.palette.warning.main]}
          />
        )}
        {chartData.length === 0 && <FeelsEmpty />}
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={theme.spacing(3)}
          justifyContent={'center'}
          paddingLeft={theme.spacing(2)}
        >
          <GraphTypography dotColor={theme.palette.warning.main}>{title}</GraphTypography>
        </Box>
      </GraphContainer>
    </Box>
  );
};

export default LipidGraphData;
