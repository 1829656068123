import {
  ClickNavCardNutrition,
  HorizontalCalendar,
  Medication,
  ModalMobile,
  Searchbar,
  useDebounce,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Typography, useTheme } from '@mui/material';
import {
  useDeleteAllUnTakenMedicationMutation,
  useDeleteSingleMedicationMutation,
  useGetMedicationsQuery,
  useGetSearchMedicinesQuery,
} from './queries/medicationQueries';
import NavCardMedication from './components/NavCardMedication';
import extractMedicationState from './utils/extractMedicationState';
import { concatMedicine } from './utils/medicineUtils';
import MedicationDrugModal from './components/MedicationDrugModal';
import MedicationDeleteModal from './components/MedicationDeleteModal';
import MedicationInstructionsModal from './components/MedicationInstructionsModal';
import useAlert from '../../hooks/useAlert';
import { useNotifyUserMutation } from '../../redux/queries/queries';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

type Props = { userId: string };
const UserDataMedication: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [search, setSearch] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [medicationInstructions, setMedicationInstruction] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState<Medication | null>(null);
  const [medicineSearchParams, setMedicineSearchParams] = useState({
    skip: 0,
    limit: 10,
  });

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const searchText = useDebounce(search, 500);

  const {
    isFetching,
    data: medicinesRes,
    error: errorMedicines,
  } = useGetSearchMedicinesQuery({
    ...medicineSearchParams,
    searchText: searchText,
  });
  const { data: medicationRes, error: errorMedication } = useGetMedicationsQuery({
    userId,
    skip: 0,
    limit: 1000,
    endDate: moment(selectedDate).utc(true).endOf('day').toISOString(),
    startDate: moment(selectedDate).utc(true).startOf('day').toISOString(),
  });

  useEffect(() => {
    if (errorMedicines || errorMedication)
      presentError(t('errors.medication.errorFetchMeds'));
  }, [errorMedicines, errorMedication]);

  const [notify] = useNotifyUserMutation();
  const [deleteAllMedications] = useDeleteAllUnTakenMedicationMutation();
  const [deleteSingleMedication] = useDeleteSingleMedicationMutation();

  const prepareMedicines = useMemo(() => {
    if (!medicinesRes) return [];

    return medicinesRes.documents;
  }, [medicinesRes]);

  const medications = useMemo(() => {
    if (!medicationRes) return [];
    return medicationRes.documents;
  }, [medicationRes]);

  const handleChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const handleDeleteAll = () => {
    if (!selectedMedication) return;
    const medicine = selectedMedication.medicine._id;
    deleteAllMedications({ id: medicine, userId })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('notifications.notifications.delMeds'),
            body: t('notifications.notifications.delMedsBody'),
          },
        });
      })
      .catch(() => {
        presentError(t('errors.generic.error01'));
      });
  };

  const handleDeleteEntry = () => {
    if (!selectedMedication) return;
    const medicationId = selectedMedication._id;
    deleteSingleMedication({ medicationId, userId })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('notifications.notifications.delMed'),
            body: t('notifications.notifications.delMedBody'),
          },
        });
      })
      .catch(() => {
        presentError(t('errors.generic.error01'));
      });
  };

  return (
    <Box display={'flex'} gap={3}>
      <Box display={'flex'} flex={1} flexDirection={'column'} gap={2}>
        {/*Medications Calendar*/}
        <Box>
          <Typography
            variant={'subtitle1'}
            sx={{
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(1),
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {t('dashboard.dashcards.medication.medications')}
          </Typography>
          <Box
            overflow={'hidden'}
            borderRadius={'8px'}
            bgcolor={theme.palette.secondary.light}
            padding={theme.spacing(2, 1)}
          >
            <HorizontalCalendar
              selectedDate={selectedDate}
              handleChange={date => handleChange(date as Date)}
            />
            {medications.map((medication, index) => (
              <NavCardMedication
                medicineName={medication.medicine.medicineName}
                key={index}
                time={medication.time}
                checkLabel={medication.medicine.label as string}
                medicationState={extractMedicationState(medication)}
                onClickHandler={() => setSelectedMedication(medication)}
                amount={`${medication.amount} (${medication.medicine.medicineType})`}
              />
            ))}
          </Box>
        </Box>
        {/*All Medicines list*/}
        <Box>
          <Typography
            variant={'subtitle1'}
            sx={{
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(1),
              fontWeight: theme.typography.fontWeightMedium,
              textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
            }}
          >
            {t('medication.titles.allMeds')}
          </Typography>
          <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
            <Searchbar placeholder={'Search'} value={search} setValue={setSearch} />
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.secondary.light,
              }}
            >
              <InfiniteScrollList
                loading={isFetching}
                count={prepareMedicines.length}
                totalCount={medicinesRes?.count}
                skip={medicineSearchParams.skip}
                limit={medicineSearchParams.limit}
                onLoadMore={(skip, limit) => {
                  setMedicineSearchParams({ skip, limit });
                }}
              >
                {prepareMedicines.map(medicine => {
                  return (
                    <Box key={medicine._id}>
                      <ClickNavCardNutrition headings={concatMedicine(medicine)} />
                    </Box>
                  );
                })}
              </InfiniteScrollList>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalMobile
        open={!!selectedMedication}
        setOpen={() => setSelectedMedication(null)}
      >
        <MedicationDrugModal
          userId={userId}
          medication={selectedMedication}
          handleDeleteClick={() => setDeleteModalOpen(true)}
          handleInstructionsClick={() => setMedicationInstruction(true)}
        />
      </ModalMobile>

      {/*Not wanted for now*/}
      {/*<ModalMobile open={!!selectedMedicine} setOpen={() => setSelectedMedicine(null)}>*/}
      {/*  <MedicineModal medicine={selectedMedicine} />*/}
      {/*</ModalMobile>*/}

      {/*Delete Modal*/}
      <ModalMobile open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <MedicationDeleteModal
          deleteAll={handleDeleteAll}
          deleteEntry={handleDeleteEntry}
          setModalOpen={setDeleteModalOpen}
        />
      </ModalMobile>
      <ModalMobile open={medicationInstructions} setOpen={setMedicationInstruction}>
        <MedicationInstructionsModal userId={userId} medication={selectedMedication} />
      </ModalMobile>
    </Box>
  );
};

export default UserDataMedication;
