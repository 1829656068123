import moment from 'moment';

const formatHourTime = (dateTime: string) => {
  const time = dateTime.split('T')[1];
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  const hoursNum = parseInt(hours);

  if (hoursNum === 12) {
    return `${hours}:${minutes} PM`;
  } else if (hoursNum > 12) {
    const newHours = hoursNum - 12;

    if (newHours < 9) {
      return `0${newHours.toString()}:${minutes} PM`;
    } else {
      return `${newHours.toString()}:${minutes} PM`;
    }
  } else {
    return `${hours}:${minutes} AM`;
  }
};

const findUpdatedAt = (updatedAt: string | undefined) => {
  if (!updatedAt) return { translation: '', time: '-' };

  const inputDate = moment(updatedAt);
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');

  if (inputDate.isSame(today, 'd')) {
    // If it's today, return the time
    return {
      translation: 'general.labels.todayAt',
      time: ` ${inputDate.utc(false).format('HH:mm')}`,
    };
  } else if (inputDate.isSame(yesterday, 'd')) {
    // If it's yesterday, return 'Yesterday' and the time
    return {
      translation: 'general.labels.yesterdayAt',
      time: ` ${inputDate.utc(false).format('HH:mm')}`,
    };
  } else {
    // Otherwise, return the date and time
    return {
      translation: '',
      time: `${inputDate.utc(false).format('MMM DD, YYYY [at] HH:mm')}`,
    };
  }
};

const convertDateFormat = (date: string) => {
  const splitDateTime = date.split('T');
  const splitDate = splitDateTime[0].split('-');
  return splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];
};

const convertTimeFormat = (date: string) => {
  const splitDateTime = date.split('T');
  const splitTime = splitDateTime[1].split(':');
  return splitTime[0] + ':' + splitTime[1];
};

export { formatHourTime, findUpdatedAt, convertDateFormat, convertTimeFormat };
