import React, { useEffect, useMemo, useState } from 'react';
import { CustomInput, DropdownMenu, SaveIcon, theme } from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  useGetUserDataQuery,
  useUpdateAuthUserMutation,
  useUpdateUserDataMutation,
} from '../queries/personalInfoQueries';
import { useGetAuthUserQuery } from '../../authentication/queries/signInQueries';
import useAlert from '../../../hooks/useAlert';
import _ from 'lodash';
import { hcpSpecialties } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../utils/useConvertJSON';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const TypographyHeading = styled(Typography)<TypographyProps>(() => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const FieldBox = styled(Box)<BoxProps>(() => ({
  flex: '1 0 30%',
}));

const WebGeneralInfo = () => {
  const { presentError, presentSuccess } = useAlert();
  const { t } = useTranslation();

  // TODO: Is there a reason these fields are not reflected in the UI when they are all asked in onBoarding?
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    gender: 'male',
    // vat: '',
    // code: '',
    speciality: '',
    // area: '',
    // phone: '',
    // address: '',
    // addressNum: '',
    // postalCode: '',
    clinicalCoach: false,
  });

  const [updateAuthUser, { error: updateAuthUserError }] = useUpdateAuthUserMutation();
  const [updateUserData, { error: updateUserDataError }] = useUpdateUserDataMutation();

  const { data: userData, error: errorUserData } = useGetUserDataQuery();
  const { data: authUserData, error: errorAuthUser } = useGetAuthUserQuery();

  useEffect(() => {
    if (errorUserData || errorAuthUser) presentError(t('errors.profile.errorUserData'));
  }, [errorUserData, errorAuthUser]);

  useEffect(() => {
    let newAuthUser, newUser;

    if (authUserData) {
      const authUser = authUserData[0];

      if (authUser)
        newAuthUser = {
          lastName: authUser.lastName,
          firstName: authUser.firstName,
          gender: authUser.gender ? authUser.gender.toLowerCase() : '',
        };
    }

    if (userData) {
      const user = userData[0];

      if (user)
        newUser = {
          // vat: user.vat,
          // code: user.code,
          // area: user.area,
          // phone: user.phone,
          // address: user.address,
          speciality: user.speciality,
          // addressNum: user.addressNum,
          // postalCode: user.postalCode,
          clinicalCoach: user.clinicalCoach,
        };
    }

    setUserDetails({ ...userDetails, ...newUser, ...newAuthUser });
  }, [authUserData, userData]);

  useEffect(() => {
    if (updateAuthUserError || updateUserDataError) {
      presentError(t('errors.generic.error01'));
    }
  }, [updateAuthUserError, updateUserDataError]);

  const stateHasChanged = useMemo(() => {
    if (userData && authUserData) {
      const authUser = authUserData[0];
      const userDataDetails = userData[0];

      let allUserDataInitial = {};

      if (authUser) {
        allUserDataInitial = {
          lastName: authUser.lastName,
          firstName: authUser.firstName,
          gender: authUser.gender ? authUser.gender.toLowerCase() : '',
        };
      }

      if (userDataDetails) {
        allUserDataInitial = {
          ...allUserDataInitial,
          // vat: userDataDetails.vat,
          // code: userDataDetails.code,
          // area: userDataDetails.area,
          // phone: userDataDetails.phone,
          // address: userDataDetails.address,
          speciality: userDataDetails.speciality,
          // addressNum: userDataDetails.addressNum,
          // postalCode: userDataDetails.postalCode,
          clinicalCoach: userDataDetails.clinicalCoach,
        };
      }

      return !_.isEqual(allUserDataInitial, userDetails);
    }

    return false;
  }, [userDetails, userData, authUserData]);

  const handleChange = (fieldName: string, value: any) => {
    setUserDetails(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    updateAuthUser({
      gender: userDetails.gender,
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.profile.successUserDateUpdate')))
      .catch(() => presentError(t('errors.generic.error01')));

    updateUserData({
      // vat: userDetails.vat,
      // code: userDetails.code,
      // area: userDetails.area,
      // phone: userDetails.phone,
      // address: userDetails.address,
      // addressNum: userDetails.addressNum,
      // postalCode: userDetails.postalCode,
      speciality: userDetails.speciality,
      clinicalCoach: userDetails.clinicalCoach,
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.profile.successUserDateUpdate')))
      .catch(() => presentError(t('errors.generic.error01')));
  };

  const labelText = (value: any) => {
    const found = hcpSpecialties.find(category => category.value === value);
    if (found) return t(found.label);
  };

  return (
    <Box flexGrow={1} display={'flex'} flexDirection={'column'}>
      <TypographyHeading variant={'subtitle2'}>
        {t('profile.titles.genInfo')}
      </TypographyHeading>
      <Box
        borderRadius={'8px'}
        bgcolor={theme.palette.highlight.main}
        padding={theme.spacing(1, 3, 3)}
      >
        <Box display={'flex'} justifyContent={'end'}>
          <IconButton
            onClick={handleSave}
            disabled={!stateHasChanged}
            sx={[
              {
                '&:disabled': {
                  opacity: 0.5,
                },
              },
            ]}
          >
            <SaveIcon color={theme.palette.kmColorsRed.main} />
          </IconButton>
        </Box>
        <Box display={'flex'} flexWrap={'wrap'} gap={2}>
          <FieldBox>
            <CustomInput
              fullWidth
              value={userDetails.firstName}
              label={`${t('userDetails.fName')}`}
              handleChange={value => handleChange('firstName', value)}
            />
          </FieldBox>
          <FieldBox>
            <CustomInput
              fullWidth
              value={userDetails.lastName}
              label={`${t('userDetails.lName')}`}
              handleChange={value => handleChange('lastName', value)}
            />
          </FieldBox>
          <FieldBox>
            <CustomInput
              readOnly
              fullWidth
              label={`${t('userDetails.profession')}`}
              value={labelText(userDetails.speciality)}
              handleChange={value => handleChange('speciality', value)}
            />
          </FieldBox>

          <FieldBox>
            <DropdownMenu
              value={userDetails.gender}
              label={`${t('userDetails.sex')}`}
              setValue={value => handleChange('gender', value)}
              menuItems={[
                { label: t('userDetails.male'), value: 'male' },
                { label: t('userDetails.female'), value: 'female' },
              ]}
            />
          </FieldBox>
          <FieldBox>
            <DropdownMenu
              placeholder={'-'}
              label={`${t('healthProf.hcp.details.cc')}`}
              value={userDetails.clinicalCoach ? 'true' : 'false'}
              setValue={value => handleChange('clinicalCoach', value === 'true')}
              menuItems={[
                { label: t('userDetails.no'), value: 'false' },
                { label: t('userDetails.yes'), value: 'true' },
              ]}
            />
          </FieldBox>
        </Box>
      </Box>
    </Box>
  );
};

export default WebGeneralInfo;
