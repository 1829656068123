import { createSlice } from '@reduxjs/toolkit';

interface AlertState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
}

interface LayoutState {
  alert: AlertState;
  shrunkSideBar: boolean;
  customDateFilter?: { start: string; end: string };
  userFilter: 'data' | 'user info' | 'devices' | 'records';
  dateFilter: 'all' | 'day' | 'week' | 'month' | 'year' | 'custom' | 'custom date';
}

const initialState: LayoutState = {
  dateFilter: 'week',
  userFilter: 'data',
  shrunkSideBar: false,
  customDateFilter: undefined,
  alert: { open: false, message: '', severity: 'info' },
};

export const appSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.alert = { ...action.payload, open: true };
    },
    removeAlert: state => {
      state.alert.open = false;
    },
    shrinkBarToggle: state => {
      state.shrunkSideBar = !state.shrunkSideBar;
    },
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    setCustomDateFilter: (state, action) => {
      state.customDateFilter = action.payload;
    },
    setUserFilter: (state, action) => {
      state.userFilter = action.payload;
    },
  },
});

export const {
  showAlert,
  removeAlert,
  setDateFilter,
  setUserFilter,
  shrinkBarToggle,
  setCustomDateFilter,
} = appSlice.actions;

export default appSlice.reducer;
