import api from '../../../redux/api';
import { ApiResult, AuthResponse, AuthUser } from '@hdcorner/ui-library';

const signInQueries = api.injectEndpoints({
  endpoints: build => ({
    getAuthUser: build.query<AuthUser[], void>({
      query: () => ({
        method: 'GET',
        url: 'database/function/getAuthUser',
        params: { populate: 'profilePicture' },
      }),
      providesTags: ['AuthUser'],
    }),

    signIn: build.mutation<AuthResponse, { email: string; password: string }>({
      query: ({ email, password }) => ({
        method: 'POST',
        body: { email, password },
        url: 'authentication/local',
      }),
    }),

    signOut: build.mutation<ApiResult<string>, void>({
      query: () => ({
        method: 'POST',
        url: 'authentication/logout',
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignOutMutation,
  useGetAuthUserQuery,
  useLazyGetAuthUserQuery,
} = signInQueries;

export default signInQueries;
