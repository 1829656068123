import React, { FC, useState } from 'react';
import { Box, FormControl, MenuItem, Select, styled } from '@mui/material';
import MeasurementsIcon from '../assets/icons/MeasurementsIcon';
import {
  DropdownDownArrow,
  DropdownDownArrowDisabled,
  theme,
} from '@hdcorner/ui-library';
import { MeasurementOptions } from '../features/hypertension/constants';
import { useAppDispatch } from '../redux/hooks';
import { updateMeasurementCategory } from '../features/hypertension/slices/hypertensionSlice';
import { FilterOptions } from '../features/diabetes/constants';
import { updateDiabetesFilter } from '../features/diabetes/diabetesSlice';
import { useTranslation } from 'react-i18next';

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '.MuiInputBase-root > div': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '.MuiInputBase-root > svg': {
    transform: 'translateY(-6px)',
  },
  '.MuiSelect-select.MuiSelect-standard.MuiInputBase-input': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle1.lineHeight,
  },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
  '&:first-of-type': {
    marginTop: theme.spacing(2),
  },
  '&:last-child': {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  toolToggle: boolean;
  currentPage: string;
  disabledDropdown?: boolean;
};

const DropdownAllMeasurements: FC<Props> = ({
  toolToggle,
  currentPage,
  disabledDropdown,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [hypertensionMeasurement, setHypertensionMeasurement] = useState(
    MeasurementOptions[0].value,
  );
  const [diabetesMeasurement, setDiabetesMeasurement] = useState(FilterOptions[0].value);

  const handleMeasurementChange = (event: any) => {
    if (currentPage.toLowerCase() === 'hypertension') {
      const newState = MeasurementOptions.find(
        option => option.value === event.target.value,
      );
      handleSetValue(newState?.value);
    } else {
      const newState = FilterOptions.find(option => option.value === event.target.value);
      handleSetValue(newState?.value);
    }
  };

  const handleSetValue = (value: string | undefined) => {
    if (!value) {
      setHypertensionMeasurement('');
    } else if (currentPage.toLowerCase() === 'hypertension') {
      setHypertensionMeasurement(value);
      dispatch(updateMeasurementCategory(value));
    } else {
      setDiabetesMeasurement(value);
      dispatch(updateDiabetesFilter(value));
    }
  };

  return (
    <>
      <Box
        alignItems={'center'}
        gap={theme.spacing(1)}
        display={
          (currentPage.toLowerCase() === 'hypertension' && !toolToggle) ||
          currentPage.toLowerCase() === 'diabetes'
            ? 'flex'
            : 'none'
        }
      >
        <CustomFormControl disabled={disabledDropdown}>
          <Box gap={1} display={'flex'} alignItems={'center'}>
            <MeasurementsIcon width={16} height={16} />
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                display: 'flex',
                paddingRight: '8px',
                position: 'relative',
                alignItems: 'center',
              }}
              disableUnderline
              variant={'standard'}
              value={
                currentPage.toLowerCase() === 'hypertension'
                  ? hypertensionMeasurement
                  : diabetesMeasurement
              }
              onChange={handleMeasurementChange}
              inputProps={{
                IconComponent: disabledDropdown
                  ? DropdownDownArrowDisabled
                  : DropdownDownArrow,
              }}
            >
              {currentPage.toLowerCase() === 'hypertension' &&
                MeasurementOptions.map((option, index) => (
                  <CustomMenuItem key={index} value={option.value}>
                    {t(option.label)}
                  </CustomMenuItem>
                ))}

              {currentPage.toLowerCase() === 'diabetes' &&
                FilterOptions.map((option, index) => (
                  <CustomMenuItem key={index} value={option.value}>
                    {t(option.label)}
                  </CustomMenuItem>
                ))}
            </Select>
          </Box>
        </CustomFormControl>
      </Box>
    </>
  );
};

export default DropdownAllMeasurements;
