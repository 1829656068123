import {
  Box,
  BoxProps,
  Button,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { InDepthDataCard, theme, XLSIcon } from '@hdcorner/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useGetESHQuery } from '../queries/eshQueries';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { ESHType } from '../types/ESHType';
import { useAppSelector } from '../../../../redux/hooks';
import { convertDateFormat } from '../../../../utils/dateTimeUtils';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import ModalInDepthDataDL from '../../../../components/ModalInDepthDataDL';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: theme.palette.secondary.light,
}));

const BoxTitle = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: web ? 0 : theme.spacing(1),
  backgroundColor: web ? 'none' : theme.palette.secondary.light,
}));

const TypographyButton = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
}));

const AveragesTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'web',
})<TypographyProps & { web: boolean }>(({ web, theme }) => ({
  color: web ? theme.palette.primary.light : theme.palette.primary.main,
  fontWeight: web
    ? theme.typography.fontWeightRegular
    : theme.typography.fontWeightMedium,
}));

const TableAverages = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { customDateFilter } = useAppSelector(state => state.layout);

  const [open, setOpen] = useState<boolean>(false);

  const endDate = moment().endOf('day');
  const startDate = moment().subtract(1, 'week').startOf('day');
  const [params, setParams] = useState({
    start: startDate.toISOString(),
    end: endDate.toISOString(),
  });
  const [displayDate, setDisplayDate] = useState({
    start: startDate.format('DD/MM/YYYY'),
    end: endDate.format('DD/MM/YYYY'),
  });

  const { error, data: eshRes } = useGetESHQuery({
    end: params.end,
    start: params.start,
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.error01'));
  }, [error]);

  useEffect(() => {
    if (!customDateFilter) return;
    const endDate = moment(customDateFilter.end).endOf('day');
    const startDate = moment(customDateFilter.start).startOf('day');
    setParams({ start: startDate.toISOString(), end: endDate.toISOString() });
    setDisplayDate({
      start: convertDateFormat(customDateFilter.start),
      end: convertDateFormat(customDateFilter.end),
    });
  }, [customDateFilter]);

  const translateTitle = (value: any) => {
    switch (value) {
      case 'systolic':
        return t('hypertension.labels.systolic');
        break;
      case 'diastolic':
        return t('hypertension.labels.diastolic');
        break;
      case 'pulses':
        return t('dashboard.dashcards.hypertension.pulses');
        break;
      default:
        return '';
    }
  };

  const findResults = useCallback(
    (value: keyof ESHType) => {
      const unit = value === 'pulses' ? t('measurements.bpm') : t('measurements.mmGH');

      if (!eshRes || Object.keys(eshRes.diastolic).length === 0 || value === 'logs')
        return {
          name: capitalizeFirstLetter(translateTitle(value)),
          min: `- ${unit}`,
          max: `- ${unit}`,
          avg: `- ${unit}`,
        };

      const measurement = eshRes[value];

      const min = measurement?.min ? measurement.min.toFixed(0) : '';
      const max = measurement?.max ? measurement.max.toFixed(0) : '';
      const avg = measurement?.avg ? measurement.avg.toFixed(0) : '';

      return {
        name: capitalizeFirstLetter(translateTitle(value)),
        min: `${min} ${unit}`,
        max: `${max} ${unit}`,
        avg: `${avg} ${unit}`,
      };
    },
    [eshRes],
  );

  const rows = useMemo(() => {
    return [findResults('systolic'), findResults('diastolic'), findResults('pulses')];
  }, [findResults]);

  const downloadButton = (
    <Button
      onClick={() => setOpen(true)}
      sx={{
        border: 'none',
        padding: '15px',
        background: '#2FAD72',
        ':hover': {
          background: '#2FAD72',
        },
      }}
    >
      <Box display={'flex'} gap={1}>
        <XLSIcon color={'#FFFFFF'} />
        <TypographyButton>{t('buttons.xlsDL')}</TypographyButton>
      </Box>
    </Button>
  );

  return (
    <Box>
      <Box
        mb={1}
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <BoxTitle web={web}>
          <AveragesTypography web={web} variant={web ? 'subtitle1' : 'subtitle2'}>
            {t('general.labels.avgs')}
          </AveragesTypography>
        </BoxTitle>
        <Box display={web ? 'flex' : 'none'}>{downloadButton}</Box>
      </Box>
      <BoxMain>
        <InDepthDataCard
          esh
          profApp
          rows={rows}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
          altHeading={`${displayDate.start} - ${displayDate.end}`}
        />
        <Box padding={2} display={web ? 'none' : 'flex'} boxSizing={'border-box'}>
          {downloadButton}
        </Box>
      </BoxMain>
      <ModalInDepthDataDL open={open} module={'esh'} dismiss={() => setOpen(false)} />
    </Box>
  );
};

export default TableAverages;
