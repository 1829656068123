import React, { FC } from 'react';
import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout, DownloadIcon } from '@hdcorner/ui-library';
import CalorieDataInner from '../components/CalorieDataInner';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const CalorieData: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('headingsTitles.calInDepth')}
      defaultHref={`/dashboard/users/${userId}/fitness`}
      endButtons={[
        {
          onClick: () => console.log('share'),
          icon: <DownloadIcon width={'18px'} height={'20px'} />,
        },
      ]}
    >
      <ComponentsLayout>
        <CalorieDataInner />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default CalorieData;
