import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const CustomBox = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(props => ({
  maxWidth: props.web ? '350px' : '264px',
  margin: props.web
    ? theme.spacing(4, 'auto', 7.5, 'auto')
    : theme.spacing(3, 'auto', 6.5, 'auto'),
}));

const TypographySubHeading = styled(Typography, {
  shouldForwardProp: propName => propName !== 'web',
})<TypographyProps & { web: boolean }>(props => ({
  fontWeight: props.web
    ? theme.typography.fontWeightMedium
    : theme.typography.fontWeightRegular,
  fontSize: props.web
    ? theme.typography.subtitle2.fontSize
    : theme.typography.body1.fontSize,
  color: props.web ? theme.palette.primary.light : theme.palette.primary.main,
}));

type OnBoardingHeadingProps = {
  web: boolean;
};
const OnBoardingHeading: FC<OnBoardingHeadingProps> = ({ web }) => {
  const { t } = useTranslation();

  return (
    <Box textAlign={'center'}>
      <Typography variant={'h1'}>{t('authentication.onboarding.greeting')}</Typography>
      <CustomBox web={web}>
        <TypographySubHeading web={web}>
          {t('authentication.onboarding.request')}
        </TypographySubHeading>
      </CustomBox>
    </Box>
  );
};

export default OnBoardingHeading;
