import api from '../../../redux/api';
import { ApiResult } from '@hdcorner/ui-library';

const forgotPasswordQueries = api.injectEndpoints({
  endpoints: build => ({
    forgotPassword: build.mutation<ApiResult<string>, { email: string }>({
      query: ({ email }) => ({
        method: 'POST',
        url: 'authentication/forgot-password',
        body: {
          email,
          redirectUri: process.env.REACT_APP_FORGOT_REDIRECT_URI,
        },
      }),
    }),
    resetHcpPassword: build.mutation<
      ApiResult<string>,
      { password: string; token: string }
    >({
      query: ({ password, token }) => ({
        method: 'PATCH',
        body: { password },
        url: `/hdCornerService/hcp/reset-password/${token}`,
      }),
    }),
    resetPassword: build.mutation<
      ApiResult<string>,
      { passwordResetToken: string; password: string }
    >({
      query: ({ password, passwordResetToken }) => ({
        method: 'POST',
        url: '/authentication/reset-password',
        body: { password, passwordResetToken },
      }),
    }),
  }),
});

export const {
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useResetHcpPasswordMutation,
} = forgotPasswordQueries;
