import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useTitle = (paramType: string) => {
  const { t } = useTranslation();
  return useMemo<string>(() => {
    switch (paramType) {
      case 'trg':
        return t('lipid.labels.tryg');
      case 'chol':
        return t('lipid.labels.totalChol');
      case 'ldl':
        return t('dashboard.dashcards.lipid.ldl');
      case 'hdl':
        return t('dashboard.dashcards.lipid.hdl');
      case 'lpa':
        return t('dashboard.dashcards.lipid.lpa');
      default:
        return '';
    }
  }, [paramType]);
};

export default useTitle;
