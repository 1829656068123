import api from '../../../../redux/api';
import { DietPlan } from '@hdcorner/ui-library';

const dietPlanQueries = api
  .enhanceEndpoints({ addTagTypes: ['DietPlan'] })
  .injectEndpoints({
    endpoints: build => ({
      getDietPlan: build.query<DietPlan[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getDietPlan',
          params: {
            scope: `Nutrition:${userId}`,
            populate: ['dietPlan', 'dietPlan.attachedFile'],
          },
        }),
        providesTags: ['DietPlan'],
      }),
      createDietPlan: build.mutation<
        void,
        { calculatedCalories: number; dietType: string; userId: string }
      >({
        query: ({ calculatedCalories, dietType, userId }) => ({
          method: 'POST',
          params: { scope: `Nutrition:${userId}` },
          url: '/database/function/setCalculatedCalories',
          body: { calories: calculatedCalories, dietType },
        }),
        invalidatesTags: ['DietPlan'],
      }),
      editDietPlan: build.mutation<
        void,
        { calculatedCalories: number; dietType: string; userId: string }
      >({
        query: ({ calculatedCalories, dietType, userId }) => ({
          method: 'PATCH',
          params: { scope: `Nutrition:${userId}` },
          body: { calories: calculatedCalories, dietType },
          url: `/database/function/changeCalculatedCalories`,
        }),
        invalidatesTags: ['DietPlan'],
      }),
    }),
  });

export const { useGetDietPlanQuery, useCreateDietPlanMutation, useEditDietPlanMutation } =
  dietPlanQueries;

export default dietPlanQueries;
