const instructionCategories = [
  { label: 'constants.diabetes.timing.withMeal', value: 'with_meal' },
  { label: 'constants.diabetes.timing.afterMeal', value: 'after_meal' },
  { label: 'constants.diabetes.timing.beforeMeal', value: 'before_meal' },
  { label: 'constants.medication.noInstruct', value: 'no_instructions' },
];

const dates: { label: string; value: string }[] = [
  { label: 'days.every', value: 'Everyday' },
  { label: 'constants.toggleValues.dates.custom', value: 'Custom' },
];
const datesWeek: { label: string; value: string }[] = [
  { label: 'days.mon', value: 'Monday' },
  { label: 'days.tue', value: 'Tuesday' },
  { label: 'days.wed', value: 'Wednesday' },
  { label: 'days.thurs', value: 'Thursday' },
  { label: 'days.fri', value: 'Friday' },
  { label: 'days.sat', value: 'Saturday' },
  { label: 'days.sun', value: 'Sunday' },
];

export { instructionCategories, dates, datesWeek };
