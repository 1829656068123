import { useMemo } from 'react';
import { ChatRoom, ChatRoomType } from '@hdcorner/ui-library';

const prepareChatRoomData = (
  userId?: string,
  chatRoom?: ChatRoomType,
): ChatRoom | undefined =>
  chatRoom
    ? {
        id: chatRoom._id,
        title:
          chatRoom.participants
            ?.filter(participant => participant._id !== userId)
            ?.map(participant => participant.firstName)
            ?.join(', ') ?? '-',
        avatar: chatRoom.participants?.filter(
          participant => participant._id !== userId,
        )?.[0]?.profilePicture?.url,
        lastMessage: chatRoom?.lastMessage?.message ?? '',
        lastMessageDate: chatRoom?.lastMessage?.createdAt ?? chatRoom.createdAt,
        unreadMessages: chatRoom?.unreadMessageCount ?? 0,
        group: chatRoom.participants && chatRoom.participants.length > 2,
      }
    : undefined;

export const usePrepareChatRoomData = (
  userId?: string,
  chatRoom?: ChatRoomType,
): ChatRoom | undefined => {
  return useMemo(() => prepareChatRoomData(userId, chatRoom), [chatRoom, userId]);
};

export const usePrepareChatRoomListData = (
  userId?: string,
  chatRooms?: ChatRoomType[],
): ChatRoom[] => {
  return useMemo(
    () =>
      chatRooms
        ?.map(chatRoom => prepareChatRoomData(userId, chatRoom))
        ?.filter((chatRoom): chatRoom is ChatRoom => !!chatRoom) ?? [],
    [chatRooms, userId],
  );
};
