import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import InDepthHeading from '../../components/InDepthHeading';
import { useGetStepsLogsQuery } from '../queries/stepsQueries';
import { CalendarModal, Collapsible, FeelsEmpty, theme } from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import InfiniteScrollList from '../../../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
};
const SectionStepsData: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [open, setOpen] = useState<boolean>(false);
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const [params, setParams] = useState({
    skip: 0,
    limit: 20,
    sort: ['-logDate'],
  });

  const {
    error,
    isFetching,
    data: stepLogs,
  } = useGetStepsLogsQuery({
    userId,
    params,
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.error01'));
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!stepLogs || stepLogs.documents.length === 0) return [];

    return stepLogs.documents.map(it => {
      return {
        _id: it._id,
        measure: t('headingsTitles.steps'),
        count: it.steps.toString(),
        data: {
          [t('headingsTitles.steps')]: { value: it.steps.toString() },
          [t('inDepth.timeOfCalc')]: { value: moment(it.createdAt).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(it.createdAt).format('MMMM Do, YYYY'),
          },
        },
      };
    });
  }, [stepLogs]);

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <Box>
      <InDepthHeading
        module={'steps'}
        color={theme.palette.exercise.main}
        handleDate={() => setOpen(true)}
      />
      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        <InfiniteScrollList
          skip={params.skip}
          limit={params.limit}
          loading={isFetching}
          count={inDepthData.length}
          totalCount={stepLogs?.count}
          onLoadMore={(skip, limit) => setParams({ ...params, skip, limit })}
        >
          {inDepthData.map(data => (
            <Box key={data._id}>
              <Collapsible data={data.data} titleSub={data.measure} title={data.count} />
            </Box>
          ))}
        </InfiniteScrollList>
        {inDepthData.length === 0 && (
          <Box>
            <FeelsEmpty />
          </Box>
        )}
      </Box>
      <CalendarModal
        open={open}
        setOpen={setOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default SectionStepsData;
