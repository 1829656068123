import React, { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { CalendarIcon, theme } from '@hdcorner/ui-library';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  borderRadius: '8px',
  position: 'relative',
  boxSizing: 'border-box',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
}));

const BoxBadge = styled(Box)<BoxProps>(({ theme }) => ({
  right: '8px',
  bottom: '8px',
  position: 'absolute',
}));

const TypographyTitle = styled(Typography)<TypographyProps & { color?: string }>(
  ({ theme, color }) => ({
    textTransform: 'capitalize',
    color: color ? color : theme.palette.primary.main,
  }),
);

const TypographyAmount = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '54px',
  lineHeight: '54px',
  fontWeight: theme.typography.fontWeightMedium,
}));

const TypographyUnit = styled(Typography)<TypographyProps & { unit: boolean }>(
  ({ theme, unit }) => ({
    display: unit ? 'block' : 'none',
    color: theme.palette.kmColorsCoolGrey.main,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  }),
);

type WellnessCardsProps = {
  unit?: string;
  title: string;
  amount: string;
  color?: string;
  icon: ReactNode;
  onClick?: () => void;
  children?: ReactNode;
};

const WellnessCards: FC<WellnessCardsProps> = ({
  unit,
  icon,
  title,
  color,
  amount,
  onClick,
  children,
}) => {
  return (
    <BoxMain>
      <Box
        flexGrow={1}
        marginBottom={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box gap={1} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
          {icon}
          <TypographyTitle
            variant={'h1'}
            title={title.toLowerCase()}
            color={color ? color : 'primary'}
          >
            {title}
          </TypographyTitle>
        </Box>
        <IconButton
          onClick={onClick}
          sx={{ padding: 0, display: onClick ? 'block' : 'none' }}
        >
          <CalendarIcon
            width={'14px'}
            height={'14px'}
            color={color ? color : theme.palette.primary.main}
          />
        </IconButton>
      </Box>

      <Box gap={1} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <TypographyAmount>{amount ? amount : '-'}</TypographyAmount>
        <TypographyUnit unit={!!unit}>{unit}</TypographyUnit>
      </Box>
      <BoxBadge display={children ? 'block' : 'none'}>{children}</BoxBadge>
    </BoxMain>
  );
};

export default WellnessCards;
