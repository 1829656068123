import SectionHeading from '../components/SectionHeading';
import { Box, BoxProps } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { CustomButton, FileCard, theme, usePrepareDietPlan } from '@hdcorner/ui-library';
import { styled } from '@mui/material/styles';
import CardiovascularIcon from '../../../assets/icons/CardiovascularIcon';
import { useGetDietPlanQuery } from './queries/dietPlanQueries';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.error.main,
}));

type SectionDietPlanProps = {
  userId: string;
  title?: boolean;
  handleCalculateClick: () => void;
};

const SectionDietPlan: FC<SectionDietPlanProps> = ({
  title,
  userId,
  handleCalculateClick,
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { error, data: dietPlanRes } = useGetDietPlanQuery({ userId });

  useEffect(() => {
    if (error) presentError(t('errors.fitness.dietPlan.error01'));
  }, [error]);

  const prepareDietPlan = usePrepareDietPlan(dietPlanRes);

  const handleOpenFile = () => {
    const file = prepareDietPlan?.file;
    if (file) {
      window.open(file, '_blank');
    }
  };

  return (
    <Box gap={1} width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box display={title ? 'block' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('headingsTitles.personalDietCal')} />
      </Box>

      {prepareDietPlan && (
        <FileCard
          onClick={handleOpenFile}
          date={prepareDietPlan.date}
          title={prepareDietPlan.title}
          subtitle={prepareDietPlan.subtitle}
          hasFile={prepareDietPlan.file !== ''}
          sx={{ backgroundColor: theme.palette.secondary.light }}
          icon={
            <IconBox>
              <CardiovascularIcon />
            </IconBox>
          }
        />
      )}

      <CustomButton fullWidth variant={'contained'} onClick={handleCalculateClick}>
        {prepareDietPlan ? t('buttons.recalculate') : t('buttons.calculate')}
      </CustomButton>
    </Box>
  );
};

export default SectionDietPlan;
