import { FC, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { AuthUser, SignInPanelGlobal } from '@hdcorner/ui-library';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import { useLazyGetAuthUserQuery, useSignInMutation } from '../../queries/signInQueries';
import LoadingPage from '../../components/LoadingPage';
import useAlert from '../../../../hooks/useAlert';

export type SignInPanelProps = {
  web: boolean;
  healthProf?: boolean;
};

const SignInPanel: FC<SignInPanelProps> = ({ web, healthProf }) => {
  const router = useIonRouter();
  const { presentError } = useAlert();

  const [login] = useSignInMutation();
  const [getUser] = useLazyGetAuthUserQuery();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const handleSignIn = async () => {
    try {
      setHasLoaded(true);
      await login({ email, password }).unwrap();
      const res = await getUser().unwrap();
      if (!res || res.length === 0) {
        setHasLoaded(false);
        return;
      }
      routeUser(res[0]);
    } catch (e) {
      console.log(e);
      setHasLoaded(false);
      presentError('Invalid email or password');
    }
  };

  const routeUser = (authUser: AuthUser) => {
    setTimeout(() => {
      setHasLoaded(false);
      if (authUser && authUser.role && authUser.role !== 'hcp') {
        presentError('The user is not authorized to access health care professional app');
        return;
      }
      if (!authUser.privacyPolicyAccepted || !authUser.termsAndConditionsAccepted) {
        router.push('/dashboard/terms-and-conditions');
      } else if (!authUser.firstName) {
        router.push('/dashboard/onboarding');
      } else {
        router.push('/dashboard/home');
      }
    }, 600);
  };

  const handleForgotPassword = () => {
    router.push('/forgot-password');
  };

  const handleSignUp = () => {
    router.push('/sign-up');
  };

  return (
    <>
      {hasLoaded ? (
        <LoadingPage web={web} />
      ) : (
        <SignInPanelGlobal
          web={web}
          email={email}
          password={password}
          setEmail={setEmail}
          healthProf={healthProf}
          setPassword={setPassword}
          handleSignIn={handleSignIn}
          handleSignUp={handleSignUp}
          logoComponent={
            <img
              src={LogoHeartPNG}
              alt={'company logo'}
              width={web ? '136px' : '56px'}
              height={web ? '134px' : '54px'}
            />
          }
          handleForgotPassword={handleForgotPassword}
        />
      )}
    </>
  );
};

export default SignInPanel;
