import { Box, Typography, useTheme } from '@mui/material';
import {
  Collapsible,
  DiabetesBloodGlucoseChart,
  DownloadIcon,
  FeelsEmpty,
  GoalDetailsCard,
  weekMonthYearCustom,
  InDepthDataCard,
  returnFiltersLabel,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import moment from 'moment/moment';
import useTimeframe from '../../hooks/useTimeframe';
import {
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseGraphDataQuery,
  useGetBloodGlucoseLogsQuery,
  useGetBloodGlucoseOverallQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
} from './queries/diabetesQueries';
import useOverallData from './hooks/useOverallData';
import useGoalValue from './hooks/useGoalValue';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useBloodGlucoseGoalData from './hooks/useBloodGlucoseGoalData';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import DownloadButton from '../../components/DownloadButton';
import usePrepareGraph from './hooks/usePrepareGraph';
import useAlert from '../../hooks/useAlert';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

type Props = {
  userId: string;
};
const BloodGlucoseWebSection: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { filter } = useAppSelector(state => state.diabetes);
  const [startingDate, setStartingDate] = useState<string>('');
  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);
  const [bloodGlucoseParams, setBloodGlucoseParams] = useState({
    skip: 0,
    limit: 8,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const diabetesFilter = useMemo(() => {
    return filter;
  }, [filter]);

  const { data: graphDataRes, error: errorGraph } = useGetBloodGlucoseGraphDataQuery({
    userId,
    ...timeframe,
  });
  const { data: overallData, error: errorOverall } = useGetBloodGlucoseOverallQuery({
    userId,
    ...timeframe,
    category: diabetesFilter === 'all' ? undefined : diabetesFilter,
  });
  const { data: bloodGlucoseGoal, error: bloodGlucoseGoalError } =
    useGetBloodGlucoseGoalQuery({ userId });
  const { data: currentMeasurements, error: errorCurrentMeasurement } =
    useGetBloodGlucoseCurrentMeasurementQuery({
      userId,
    });
  const {
    data: bloodGlucoseLogs,
    error: errorGlucoseLog,
    isFetching: bloodGlucoseFetching,
  } = useGetBloodGlucoseLogsQuery({
    params: {
      userId,
      ...timeframe,
      ...bloodGlucoseParams,
    },
  });
  const { data: startingMeasurements, error: errorStartingMeasurement } =
    useGetBloodGlucoseStartingMeasurementQuery(
      { startDate: startingDate, userId },
      { skip: !startingDate },
    );

  const graphData = usePrepareGraph(graphDataRes);
  const prepareOverallData = useOverallData(overallData);
  const prepareGoalValue = useGoalValue(bloodGlucoseGoal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);
  const prepareGoalValues = useBloodGlucoseGoalData(
    prepareGoalValue,
    bloodGlucoseGoal,
    prepareStartingMeasurement,
    prepareCurrentMeasurement,
  );

  useEffect(() => {
    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      const goal = bloodGlucoseGoal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [bloodGlucoseGoal]);

  useEffect(() => {
    if (
      errorGraph ||
      errorOverall ||
      errorGlucoseLog ||
      bloodGlucoseGoalError ||
      errorCurrentMeasurement ||
      errorStartingMeasurement
    ) {
      presentError(t('errors.generic.error01'));
    }
  }, [
    errorGraph,
    errorOverall,
    errorGlucoseLog,
    bloodGlucoseGoalError,
    errorCurrentMeasurement,
    errorStartingMeasurement,
  ]);

  const prepareOverallDataValues = useMemo(() => {
    if (!overallData)
      return [
        {
          name: t('general.labels.all'),
          min: t('general.labels.nA'),
          max: t('general.labels.nA'),
          avg: t('general.labels.nA'),
        },
      ];
    const data = overallData;
    return [
      {
        name: t('general.labels.all'),
        min: data.min ? data.min.toFixed() : t('general.labels.nA'),
        max: data.max ? data.max.toFixed() : t('general.labels.nA'),
        avg: data.avg ? data.avg.toFixed() : t('general.labels.nA'),
      },
    ];
  }, [overallData]);

  const prepareInDepthData = useMemo(() => {
    if (!bloodGlucoseLogs) return [];

    return bloodGlucoseLogs.documents.map(item => {
      return {
        title: item.measurement.toString(),
        titleSub: t('measurements.mgdl'),
        values: {
          [t('dashboard.dashcards.diabetes.glucose')]: {
            value: `${item.measurement} ${t('measurements.mgdl')}`,
          },
          Filters: { value: returnFiltersLabel(item.category) },
          [t('inDepth.timeOfCalc')]: { value: moment(item.createdAt).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(item.createdAt).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [bloodGlucoseLogs]);

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} gap={3}>
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
          }}
          variant={'subtitle1'}
        >
          {t('headingsTitles.bloodGluc')}
        </Typography>
        <DiabetesBloodGlucoseChart
          chartData={graphData}
          overallData={prepareOverallData}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
      </Box>
      <Box display={'flex'} flex={1} flexDirection={'column'} gap={1}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography
            variant={'subtitle1'}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            sx={{
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {t('headingsTitles.inDepth')}
          </Typography>
          <DownloadButton
            web={true}
            onClick={() => setDownloadDataModalOpen(true)}
            startIcon={
              <DownloadIcon
                width={'14px'}
                height={'15px'}
                color={theme.palette.secondary.main}
              />
            }
          >
            {t('buttons.dlData')}
          </DownloadButton>
        </Box>
        <InDepthDataCard
          rows={prepareOverallDataValues}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
          measurement={`${t('measurements.mgdl')}`}
        />
        <InfiniteScrollList
          loading={bloodGlucoseFetching}
          skip={bloodGlucoseParams.skip}
          limit={bloodGlucoseParams.limit}
          totalCount={bloodGlucoseLogs?.count}
          count={bloodGlucoseLogs?.documents.length}
          onLoadMore={(skip, limit) => {
            setBloodGlucoseParams({ skip, limit });
          }}
        >
          {prepareInDepthData.map((data, index) => (
            <Box>
              <Collapsible
                key={index}
                title={data.title}
                data={data.values}
                titleSub={data.titleSub}
                handleRemove={() => {
                  console.log('TODO: handle remove outliers');
                }}
              />
            </Box>
          ))}
        </InfiniteScrollList>
        {prepareInDepthData.length === 0 && <FeelsEmpty />}
        <Box
          flex={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginTop={theme.spacing(3)}
          marginBottom={theme.spacing(1)}
        >
          <Typography
            variant={'subtitle1'}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            sx={{
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {t('headingsTitles.goal')}
          </Typography>
        </Box>
        <Box
          borderRadius={'8px'}
          padding={theme.spacing(1)}
          bgcolor={theme.palette.secondary.light}
        >
          {prepareGoalValues.map((item, index) => (
            <Box key={`${item}${index}`} mt={index === 1 ? 2 : 0}>
              <GoalDetailsCard goal={item} />
            </Box>
          ))}
        </Box>
      </Box>
      <ModalInDepthDataDL
        module={'blood_glucose'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </Box>
  );
};

export default BloodGlucoseWebSection;
