import api from '../../../redux/api';
import { AuthUser, ChangeEmail, ChangePassword } from '@hdcorner/ui-library';

const accountSettingsQueries = api
  .enhanceEndpoints({ addTagTypes: ['ChangeEmail', 'ChangePassword', 'AuthUser'] })
  .injectEndpoints({
    endpoints: build => ({
      changeEmail: build.mutation<ChangeEmail, { newEmail: string }>({
        query: ({ newEmail }) => ({
          url: '/authentication/local/change-email',
          method: 'POST',
          body: { newEmail },
        }),
        invalidatesTags: ['ChangeEmail'],
      }),

      updateChangePassword: build.mutation<
        ChangePassword,
        { oldPassword: string; newPassword: string }
      >({
        query: ({ oldPassword, newPassword }) => ({
          url: '/authentication/local/change-password',
          method: 'POST',
          body: { oldPassword, newPassword },
        }),
        invalidatesTags: ['ChangePassword'],
      }),

      updatePhoneNumber: build.mutation<AuthUser, { phoneNumber: string }>({
        query: ({ phoneNumber }) => ({
          url: 'database/function/editUserData',
          method: 'PUT',
          body: { phone: phoneNumber },
        }),
        invalidatesTags: ['AuthUser'],
      }),

      deleteAuthUser: build.mutation<AuthUser, void>({
        query: () => ({
          url: '/authentication/user',
          method: 'DELETE',
        }),
      }),
    }),
  });

export const {
  useChangeEmailMutation,
  useDeleteAuthUserMutation,
  useUpdatePhoneNumberMutation,
  useUpdateChangePasswordMutation,
} = accountSettingsQueries;

export default accountSettingsQueries;
