import { Box, BoxProps, IconButton, styled, useMediaQuery } from '@mui/material';
import MealCollapseCard from './MealCollapseCard';
import SectionHeading from '../../components/SectionHeading';
import {
  CalendarGoalsModal,
  CalendarIcon,
  HorizontalCalendar,
  KnifeForkIcon,
  theme,
} from '@hdcorner/ui-library';
import { useGetMealsOfDayQuery } from '../queries/foodsQueries';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import usePrepareMealList from '../hooks/usePrepareMealList';
import { useGetCaloriesInDepthQuery } from '../../calories/queries/caloriesQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  flexDirection: 'column',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  maxHeight: web ? '535px' : '80%',
  marginBottom: web ? 0 : theme.spacing(3),
  backgroundColor: theme.palette.secondary.light,
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.secondary.dark}`,
  padding: theme.spacing(1, 1, 2, 1),
}));

interface Props {
  userId: string;
}
const SectionNutritionCal: React.FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [monthCalendarGoals, setMonthCalendarGoals] = useState<string>();
  const [openCalendarGoals, setOpenCalendarGoals] = useState<boolean>(false);
  const [monthlyCalendarOpen, setMonthlyCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().utc(true).toDate());
  const [params, setParams] = useState({
    end: moment().utc(true).endOf('day').toDate().toISOString(),
    start: moment().utc(true).startOf('day').toDate().toISOString(),
  });

  const { data: mealLogRes, error: errorMeals } = useGetMealsOfDayQuery({
    userId,
    end: params.end,
    start: params.start,
  });

  const { data: getCaloriesInDepth, error: errorCalories } = useGetCaloriesInDepthQuery(
    {
      params: { skip: 0, limit: 10000 },
      end: moment(monthCalendarGoals).endOf('month').toISOString(),
      start: moment(monthCalendarGoals).startOf('month').toISOString(),
    },
    { skip: !monthCalendarGoals },
  );

  useEffect(() => {
    if (errorMeals) presentError(t('errors.fitness.nutrition.errorMealFetch'));
    if (errorCalories) presentError(t('errors.fitness.nutrition.errorMealFetch'));
  }, [errorMeals, errorCalories]);

  const lunchList = usePrepareMealList('lunch', params, mealLogRes);
  const dinnerList = usePrepareMealList('dinner', params, mealLogRes);
  const snacksList = usePrepareMealList('snacks', params, mealLogRes);
  const breakfastList = usePrepareMealList('breakfast', params, mealLogRes);

  const data = useMemo(() => {
    return [breakfastList, lunchList, dinnerList, snacksList];
  }, [breakfastList, dinnerList, lunchList, snacksList]);

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);

    const newDate = moment(date).utc(true);

    const endDate = newDate.endOf('day').toISOString();
    const startDate = newDate.startOf('day').toISOString();

    const data = { endDate, startDate };

    handleCalClick(data);
  };

  const handleCalClick = (data: any) => {
    const endDate = data.endDate;
    const startDate = data.startDate;

    setParams((prevState: any) => ({
      ...prevState,
      end: endDate,
      start: startDate,
    }));
  };

  const prepareCompletedDays = useMemo(() => {
    if (!getCaloriesInDepth) return [];
    return getCaloriesInDepth.documents?.map(cal => ({
      date: new Date(cal.createdAt),
      success: Math.abs((cal.calculated ?? 0) - (cal.eaten ?? 0)) <= 50,
    }));
  }, [getCaloriesInDepth]);

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <Box display={web ? 'flex' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('fitness.nutrition.labels.nutCal')} />
        <IconButton onClick={() => setOpenCalendarGoals(true)} sx={{ padding: 0 }}>
          <CalendarIcon width={'20px'} height={'20px'} />
        </IconButton>
      </Box>
      <BoxMain web={web}>
        <HorizontalCalendar
          sx={{ flex: 1 }}
          selectedDate={selectedDate}
          openMonthly={monthlyCalendarOpen}
          setOpenMonthly={setMonthlyCalendarOpen}
          handleChange={(date: any) => handleChangeDate(date)}
        />
        <BoxSecondary>
          {data.map(it => (
            <MealCollapseCard
              key={it?.id}
              data={it?.data}
              metrics={it.metrics}
              totalCal={it?.totalCal}
              title={it ? it.title : ''}
              description={it?.description}
            />
          ))}
        </BoxSecondary>
      </BoxMain>
      <CalendarGoalsModal
        open={openCalendarGoals}
        altIcon={<KnifeForkIcon />}
        setOpen={setOpenCalendarGoals}
        goalDates={prepareCompletedDays}
        onMonthChange={setMonthCalendarGoals}
      />
    </Box>
  );
};

export default SectionNutritionCal;
