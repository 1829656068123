import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { ComponentsLayout, CustomButton, theme } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import MedicationRepeat from './components/MedicationRepeat';
import { useAppDispatch } from '../../redux/hooks';
import { setRepeat } from './medicationSlice';
import { RouteComponentProps } from 'react-router';
import { dates, datesWeek } from './constants';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const MedicationRepeatPage: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedRepeat, setSelectedRepeat] = useState<string>('Everyday');

  useEffect(() => {
    setSelectedDays(datesWeek.map(it => it.value));
  }, [datesWeek]);

  const handleShowRepeatSelect = () => {
    dispatch(setRepeat(selectedDays));
    router.push(`/dashboard/users/${userId}/medication-add`, 'back', 'pop');
  };

  return (
    <PageLayout
      headerColor={theme.palette.primary.main}
      headerTitle={t('medication.titles.repeat')}
      defaultHref={`/dashboard/users/${userId}/medication`}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <MedicationRepeat
          dates={dates}
          datesWeek={datesWeek}
          selected={selectedRepeat}
          selectedDays={selectedDays}
          setSelected={setSelectedRepeat}
          setSelectedDays={setSelectedDays}
        />
        <CustomButton
          fullWidth
          disableElevation
          color={'primary'}
          variant={'contained'}
          onClick={handleShowRepeatSelect}
        >
          {t('buttons.save')}
        </CustomButton>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default MedicationRepeatPage;
