import api from '../../../redux/api';
import {
  ApiPagination,
  CvdRiskGoal,
  CvdRiskLog,
  PaginationResult,
} from '@hdcorner/ui-library';

const cvdRiskQueries = api
  .enhanceEndpoints({ addTagTypes: ['CvdRiskLog', 'CvdRiskGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      getCvdRiskLogs: build.query<
        PaginationResult<CvdRiskLog>,
        ApiPagination & { userId: string; start?: string; end?: string }
      >({
        query: ({ skip, limit, start, end, userId }) => ({
          method: 'GET',
          url: `hdCornerService/user/cvd-risk/in-depth`,
          params: { skip, limit, sort: ['-logDate'], start, end, userId },
        }),
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.logs = newItems?.logs;
          } else {
            currentCache?.logs.push(...newItems?.logs);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
      }),
      getCvdStartingMeasurement: build.query<
        PaginationResult<CvdRiskLog>,
        { startingDate?: string; userId: string }
      >({
        query: ({ startingDate, userId }) => ({
          method: 'GET',
          url: `hdCornerService/user/cvd-risk/in-depth`,
          params: { skip: 0, limit: 1, startingDate, sort: 'logDate', userId },
        }),
        providesTags: ['CvdRiskLog'],
      }),
      getCvdCurrentMeasurement: build.query<
        PaginationResult<CvdRiskLog>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          method: 'GET',
          url: `hdCornerService/user/cvd-risk/in-depth`,
          params: { skip: 0, limit: 1, sort: '-logDate', userId },
        }),
      }),
      getCvdRiskGoal: build.query<CvdRiskGoal[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: `database/function/getUserGoal`,
          params: { goalType: 'cvd_risk', scope: `Goal:${userId}` },
        }),
        providesTags: ['CvdRiskGoal'],
      }),
      getCvdGraphData: build.query<
        { logs: CvdRiskLog[] },
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          params: { start, end, userId },
          url: `hdCornerService/user/cvd-risk/graph`,
        }),
      }),
      deleteCvdRiskLog: build.mutation<string, { id: string; userId: string }>({
        query: ({ id, userId }) => ({
          method: 'DELETE',
          url: `database/UserCvdRiskLog/${id}`,
          params: { scope: `PremiumHealth:${userId}` },
        }),
        invalidatesTags: ['CvdRiskLog'],
      }),
    }),
  });

export const {
  useGetCvdRiskLogsQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdGraphDataQuery,
  useDeleteCvdRiskLogMutation,
  useGetCvdStartingMeasurementQuery,
  useGetCvdCurrentMeasurementQuery,
} = cvdRiskQueries;

export default cvdRiskQueries;
