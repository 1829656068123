import React, { FC, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { ResetPasswordPanelGlobal } from '@hdcorner/ui-library';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import {
  useResetHcpPasswordMutation,
  useResetPasswordMutation,
} from '../../queries/forgotPasswordQueries';
import useQuery from '../../../../hooks/useQuery';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type ResetPasswordPanelProps = {
  web: boolean;
  token?: string;
};
const ResetPasswordPanel: FC<ResetPasswordPanelProps> = ({ token, web }) => {
  const query = useQuery();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [resetPassword] = useResetPasswordMutation();
  const [resetHcpPassword] = useResetHcpPasswordMutation();

  const [count, setCount] = useState(0);
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  const handleContinue = () => {
    setCount(count + 1);
  };

  const handleResetPassword = async () => {
    const paramsResetToken = token;
    const queryResetToken = query.get('reset_token');

    if (!paramsResetToken && !queryResetToken) {
      presentError(t('authentication.forgotReset.error.errorReset'));
      return;
    }

    if (paramsResetToken) {
      try {
        await resetHcpPassword({
          token,
          password: password1,
        }).unwrap();
        setCount(count + 1);
      } catch (e) {
        console.log(e);
        presentError(t('authentication.forgotReset.error.errorReset'));
      }
      return;
    }

    if (queryResetToken) {
      try {
        await resetPassword({
          password: password1,
          passwordResetToken: queryResetToken,
        }).unwrap();
        setCount(count + 1);
      } catch (e) {
        console.log(e);
        presentError(t('authentication.forgotReset.error.errorReset'));
      }
    }
  };

  const handleGoToSignIn = () => {
    router.push('/sign-in', 'back', 'pop');
  };

  const handleCancelButton = () => {
    setCount(0);
    router.push('/sign-in', 'back', 'pop');
  };

  return (
    <ResetPasswordPanelGlobal
      web={web}
      count={count}
      password1={password1}
      password2={password2}
      setPassword1={setPassword1}
      setPassword2={setPassword2}
      handleContinue={handleContinue}
      handleGoToSignIn={handleGoToSignIn}
      handleCancelButton={handleCancelButton}
      handleResetPassword={handleResetPassword}
      logoContainer={
        <img
          src={LogoHeartPNG}
          alt={'company logo'}
          width={web ? '134px' : '54px'}
          height={web ? '136px' : '56px'}
        />
      }
    />
  );
};

export default ResetPasswordPanel;
