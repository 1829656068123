import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { ComponentsLayout, Goal, theme } from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import {
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetHbacGoalQuery,
} from './queries/diabetesQueries';
import useGoalValue from './hooks/useGoalValue';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useHbac1GoalData from './hooks/useHbac1GoalData';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const DiabetesHbac1Goal: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [startingDate, setStartingDate] = useState<string>('');

  const { data: hbac1Goal, error: hbac1GoalError } = useGetHbacGoalQuery({ userId });

  const { data: startingMeasurements, error: errorStarting } =
    useGetHbac1StartingMeasurementQuery(
      { startDate: startingDate, userId },
      { skip: !startingDate },
    );
  const { data: currentMeasurements, error: errorCurrent } =
    useGetHbac1CurrentMeasurementQuery({ userId });

  const prepareGoalValue = useGoalValue(hbac1Goal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);
  const prepareData = useHbac1GoalData(
    prepareGoalValue,
    hbac1Goal,
    prepareCurrentMeasurement,
    prepareStartingMeasurement,
  );

  useEffect(() => {
    if (hbac1Goal && hbac1Goal.length > 0) {
      const goal = hbac1Goal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [hbac1Goal]);

  useEffect(() => {
    if (hbac1GoalError || errorStarting || errorCurrent) {
      presentError(t('errors.generic.error01'));
    }
  }, [hbac1GoalError, errorStarting, errorCurrent]);

  return (
    <PageLayout headerTitle={'HbA1c Goal'} headerColor={theme.palette.primary.main}>
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Goal data={prepareData} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DiabetesHbac1Goal;
