import {
  ChartDataType,
  CvdRiskChartWithRisk,
  FeelsEmpty,
  ToggleButtons,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { Box, Card, CardProps, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useConvertJSON } from '../../../utils/useConvertJSON';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  flex: 1,
  minHeight: 310,
  display: 'flex',
  boxShadow: 'none',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  flexDirection: 'column',
  padding: theme.spacing(2, 4),
}));

type CvdRiskChartCardType = {
  selectedToggle: string;
  chartData: ChartDataType[];
  data: { idealRisk: string; overallRisk: string };
  openCalendar: () => void;
  setSelectedToggle: (value: string) => void;
};

const CvdRiskChartCard: FC<CvdRiskChartCardType> = ({
  data,
  chartData,
  selectedToggle,
  openCalendar,
  setSelectedToggle,
}) => {
  const theme = useTheme();

  const handleChange = (newValue: string) => {
    setSelectedToggle(newValue);

    if (newValue === 'custom') {
      openCalendar();
    }
  };

  return (
    <CardMain>
      <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
        <ToggleButtons
          value={selectedToggle}
          handleChange={handleChange}
          color={theme.palette.cvdRisk.main}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
        {chartData.length > 0 && (
          <CvdRiskChartWithRisk data={data} chartData={chartData} />
        )}
        {chartData.length === 0 && <FeelsEmpty />}
      </Box>
    </CardMain>
  );
};

export default CvdRiskChartCard;
