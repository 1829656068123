import { CustomInput, theme } from '@hdcorner/ui-library';
import React, { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface UserType {
  sex: string;
  age: number;
  height: number;
  weight: number;
}

type Props = {
  user: UserType;
  setUser: (user: UserType) => void;
};
const DietCalculatorFirstStep: FC<Props> = ({ user, setUser }) => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (fieldName: keyof Props['user'], value: any) => {
    const updatedUser: UserType = {
      ...user,
      [fieldName]: value,
    };
    setUser(updatedUser);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} py={2}>
      <Box display={'flex'} gap={3}>
        <Box flex={1} flexGrow={1}>
          <CustomInput
            readOnly
            fullWidth
            value={user.sex}
            alternativeBackground={!web}
            label={`${t('userDetails.sex')}`}
            placeholder={`${t('fitness.dietPlan.placeholders.exFe')}`}
            handleChange={value => handleChange('sex', value)}
            sx={{
              flex: 1,
              minWidth: 120,
              flexBasis: 'calc(50% - 40px)',
            }}
          />
        </Box>
        <Box flex={1} flexGrow={1}>
          <CustomInput
            readOnly
            fullWidth
            type={'number'}
            value={user.age}
            alternativeBackground={!web}
            label={`${t('userDetails.age')}`}
            placeholder={`${t('measurements.ageEg')}`}
            handleChange={value => handleChange('age', value)}
            inputProps={{
              min: 0,
              max: 500,
              pattern: '[0-9]',
              inputMode: 'numeric',
            }}
            sx={{
              flex: 1,
              minWidth: 120,
              flexBasis: 'calc(50% - 40px)',
            }}
          />
        </Box>
      </Box>
      <Box display={'flex'} gap={3}>
        <Box flex={1} flexGrow={1}>
          <CustomInput
            readOnly
            fullWidth
            type={'number'}
            value={user.weight}
            alternativeBackground={!web}
            label={`${t('userDetails.weight')}`}
            placeholder={`${t('measurements.weightEg')}`}
            handleChange={value => handleChange('weight', value)}
            sx={{ flex: 1, minWidth: 120, mt: 3, flexBasis: 'calc(50% - 40px)' }}
            inputProps={{
              min: 0,
              max: 500,
              pattern: '[0-9]',
              inputMode: 'numeric',
            }}
          />
        </Box>
        <Box flex={1} flexGrow={1}>
          <CustomInput
            readOnly
            fullWidth
            type={'number'}
            value={user.height}
            alternativeBackground={!web}
            label={`${t('userDetails.height')}`}
            placeholder={`${t('measurements.heightEg')}`}
            handleChange={value => handleChange('height', value)}
            sx={{ flex: 1, minWidth: 120, mt: 3, flexBasis: 'calc(50% - 40px)' }}
            inputProps={{
              min: 0,
              max: 500,
              pattern: '[0-9]',
              inputMode: 'numeric',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DietCalculatorFirstStep;
