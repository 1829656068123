import React, { useEffect, useMemo, useState } from 'react';
import {
  ComponentsLayout,
  CustomInput,
  DropdownMenu,
  SaveIcon,
  theme,
  UserParticipantImage,
} from '@hdcorner/ui-library';
import {
  useGetUserDataQuery,
  useSetUserProfilePictureMutation,
  useUpdateAuthUserMutation,
  useUpdateUserDataMutation,
} from './queries/personalInfoQueries';
import _ from 'lodash';
import { Box } from '@mui/material';
import useAlert from '../../hooks/useAlert';
import { hcpSpecialties } from '../../constants';
import PageLayout from '../../components/PageLayout';
import { useGetAuthUserQuery } from '../authentication/queries/signInQueries';
import { v4 as uuidv4 } from 'uuid';
import { useReUploadFileMutation, useUploadFileMutation } from './queries/fileQueries';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();

  const [userDetails, setUserDetails] = useState({
    gender: '',
    lastName: '',
    firstName: '',
    speciality: '',
    profilePhoto: '',
    clinicalCoach: false,
  });

  const [updateAuthUser, { error: updateAuthUserError }] = useUpdateAuthUserMutation();
  const [updateUserData, { error: updateUserDataError }] = useUpdateUserDataMutation();

  const { data: userData, error: errorUser } = useGetUserDataQuery();
  const { data: authUserData, error: errorAuth } = useGetAuthUserQuery();

  useEffect(() => {
    if (errorUser || errorAuth) presentError(t('errors.profile.errorUserData'));
  }, [errorUser, errorAuth]);

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [setProfilePicture] = useSetUserProfilePictureMutation();

  useEffect(() => {
    let newAuthUser, newUser;

    if (authUserData) {
      const authUser = authUserData[0];

      if (authUser)
        newAuthUser = {
          lastName: authUser.lastName,
          firstName: authUser.firstName,
          gender: authUser.gender?.toLowerCase() || '',
          profilePhoto: authUser.profilePicture?.url || '',
        };
    }

    if (userData) {
      const user = userData[0];

      if (user)
        newUser = {
          speciality: user.speciality,
          clinicalCoach: user.clinicalCoach,
        };
    }

    setUserDetails({ ...userDetails, ...newUser, ...newAuthUser });
  }, [authUserData, userData]);

  useEffect(() => {
    if (updateAuthUserError || updateUserDataError) {
      presentError(t('errors.generic.error01'));
    }
  }, [updateAuthUserError, updateUserDataError]);

  const stateHasChanged = useMemo(() => {
    if (userData && authUserData) {
      const authUser = authUserData[0];
      const userDataDetails = userData[0];

      let allUserDataInitial = {};

      if (authUser) {
        allUserDataInitial = {
          lastName: authUser.lastName,
          firstName: authUser.firstName,
          gender: authUser.gender?.toLowerCase() || '',
        };
      }

      if (userDataDetails) {
        allUserDataInitial = {
          ...allUserDataInitial,
          speciality: userDataDetails.speciality,
          clinicalCoach: userDataDetails.clinicalCoach,
        };
      }

      return !_.isEqual(allUserDataInitial, userDetails);
    }

    return false;
  }, [userDetails, userData, authUserData]);

  const handleChange = (fieldName: string, value: any) => {
    setUserDetails(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    updateAuthUser({
      gender: userDetails.gender,
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.profile.successUserDateUpdate')));

    updateUserData({
      speciality: userDetails.speciality,
      clinicalCoach: userDetails.clinicalCoach,
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.profile.successUserDateUpdate')));
  };

  const handleChangeImage = async (files: File[]) => {
    const file = files[0];
    const fileName = `${uuidv4()}-${file.name}`;
    let existingProfileImage;
    if (authUserData) {
      existingProfileImage = authUserData[0].profilePicture;
    }

    try {
      if (existingProfileImage) {
        const res = await reUpload({
          name: fileName,
          size: file.size,
          _id: existingProfileImage._id,
        }).unwrap();
        const url = res.url;
        const conduitFile = res.file;
        await uploadBlob(url, file);
        await setProfilePicture({
          profilePicture: conduitFile._id,
        }).unwrap();
      } else {
        const res = await upload({
          name: fileName,
          size: file.size,
        }).unwrap();
        const conduitFile = res.file;
        const url = res.url;
        await uploadBlob(url, file);
        await setProfilePicture({
          profilePicture: conduitFile._id,
        }).unwrap();
      }
    } catch (e) {
      console.log(e);
      presentError(t('profile.errorUploadingProfile'));
    }
  };

  const uploadBlob = async (url: string, file: File) => {
    return await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': file.type,
      },
    });
  };

  return (
    <PageLayout
      backgroundWhite
      headerTitle={t('profile.titles.personalInfo')}
      endButtons={[
        {
          icon: stateHasChanged ? (
            <SaveIcon color={theme.palette.kmColorsRed.main} />
          ) : (
            <SaveIcon />
          ),
          onClick: stateHasChanged ? handleSave : undefined,
        },
      ]}
    >
      <ComponentsLayout>
        <Box margin={theme.spacing(0, 'auto', 6.5, 'auto')} width={'70px'}>
          <UserParticipantImage
            maxFiles={1}
            photo={userDetails.profilePhoto}
            onFilesSelected={handleChangeImage}
            acceptedFiles={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
          />
        </Box>
        <Box gap={2} display={'flex'} flexDirection={'column'}>
          <CustomInput
            fullWidth
            value={userDetails.firstName}
            label={`${t('userDetails.fName')}`}
            handleChange={value => handleChange('firstName', value)}
          />

          <CustomInput
            fullWidth
            value={userDetails.lastName}
            label={`${t('userDetails.lName')}`}
            handleChange={value => handleChange('lastName', value)}
          />

          <DropdownMenu
            fullWidth
            value={userDetails.speciality}
            label={`${t('userDetails.profession')}`}
            menuItems={useConvertJSON(hcpSpecialties)}
            setValue={value => handleChange('speciality', value)}
          />

          <DropdownMenu
            placeholder={'-'}
            value={userDetails.gender}
            label={`${t('userDetails.sex')}`}
            setValue={value => handleChange('gender', value)}
            menuItems={[
              { label: t('userDetails.male'), value: 'male' },
              { label: t('userDetails.female'), value: 'female' },
            ]}
          />

          <DropdownMenu
            placeholder={'-'}
            label={`${t('healthProf.hcp.details.cc')}`}
            value={userDetails.clinicalCoach ? 'true' : 'false'}
            setValue={value => handleChange('clinicalCoach', value === 'true')}
            menuItems={[
              { label: t('userDetails.no'), value: 'false' },
              { label: t('userDetails.yes'), value: 'true' },
            ]}
          />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default PersonalInfo;
