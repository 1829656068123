import { FC } from 'react';
import ChartSteps from './ChartSteps';
import SectionGoalSteps from './SectionGoalSteps';
import { Box, BoxProps, styled } from '@mui/material';
import SectionActivitiesCal from './SectionActivitiesCal';
import SectionGoalActivities from './SectionGoalActivities';
import SectionStepsData from './SectionStepsData';
import SectionActivitiesData from './SectionActivitiesData';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  gap: theme.spacing(3),
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  flex: '1 0 250px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

type Props = {
  userId: string;
};
const WebExercise: FC<Props> = ({ userId }) => {
  return (
    <BoxMain>
      <BoxColumn>
        <ChartSteps userId={userId} />
        <SectionStepsData userId={userId} />
        <SectionGoalSteps userId={userId} />
      </BoxColumn>
      <BoxColumn>
        <SectionActivitiesCal userId={userId} />
        <SectionActivitiesData userId={userId} />
        <SectionGoalActivities userId={userId} />
      </BoxColumn>
    </BoxMain>
  );
};

export default WebExercise;
