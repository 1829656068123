import React, { FC } from 'react';
import { ComponentsLayout } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import SectionGoalWeight from './components/SectionGoalWeight';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const FitnessGoalWeight: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('headingsTitles.weightGoal')}
      defaultHref={`/dashboard/users/${userId}/fitness/wellness`}
    >
      <ComponentsLayout>
        <SectionGoalWeight userId={userId} />
      </ComponentsLayout>
    </PageLayout>
  );
};
export default FitnessGoalWeight;
