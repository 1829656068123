import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { CustomButton, FrequencyItemType } from '@hdcorner/ui-library';
import moment from 'moment/moment';
import { v4 as uuidv4 } from 'uuid';
import { Box, Modal } from '@mui/material';
import MedicationFrequency from './MedicationFrequency';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setFrequency } from '../medicationSlice';
import TimePickerFields from '../../../components/TimePickerFields';
import { useTranslation } from 'react-i18next';

type Props = {
  handleFrequencySelect: () => void;
};
const MedicationFrequencyModal: FC<Props> = ({ handleFrequencySelect }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [frequencyAmount, setFrequencyAmount] = useState<number>(1);
  const [openTimePillPicker, setOpenTimePillPicker] = useState(false);
  const [frequenciesArray, setFrequenciesArray] = useState<FrequencyItemType[]>([
    {
      amount: 1,
      uuid: uuidv4(),
      time: moment().format('HH:mm'),
    },
  ]);
  const [selected, setSelected] = useState<FrequencyItemType | undefined>(undefined);

  const { frequency } = useAppSelector(state => state.medication);

  useEffect(() => {
    if (frequency && frequency.length > 0) {
      setFrequenciesArray(frequency);
      setFrequencyAmount(frequency.length);
    }
  }, [frequency]);

  useEffect(() => {
    const arr = [...frequenciesArray];
    if (frequencyAmount > frequenciesArray.length) {
      for (let i = 1; i <= frequencyAmount - frequenciesArray.length; i++) {
        arr.push({
          amount: 1,
          uuid: uuidv4(),
          time: moment().format('HH:mm'),
        });
      }
      setFrequenciesArray(arr);
    } else if (frequencyAmount < frequenciesArray.length) {
      const newArr = arr.slice(0, frequencyAmount);
      setFrequenciesArray(newArr);
    }
  }, [frequenciesArray, frequencyAmount]);

  const handleClickAddFrequency = useCallback(() => {
    setFrequencyAmount(frequencyAmount + 1);
    setFrequenciesArray([
      ...frequenciesArray,
      {
        amount: 1,
        uuid: uuidv4(),
        time: moment().format('HH:mm'),
      },
    ]);
  }, [frequencyAmount, frequenciesArray]);

  const handleClickCard = useCallback(
    (index: number) => {
      setSelected(frequenciesArray[index]);
      setOpenTimePillPicker(true);
    },
    [frequenciesArray],
  );

  const handleSaveData = useCallback(
    (newData: FrequencyItemType) => {
      setOpenTimePillPicker(false);
      if (!selected) return;

      const index = frequenciesArray.findIndex(item => item.uuid === selected.uuid);
      if (index === -1) return;

      const current = frequenciesArray[index];
      const newItem = { ...current, ...newData };
      frequenciesArray.splice(index, 1, newItem as FrequencyItemType);
    },
    [frequenciesArray, selected],
  );

  const handleClickNext = () => {
    dispatch(setFrequency(frequenciesArray));
    handleFrequencySelect();
  };

  return (
    <Box
      gap={3}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box>
        <MedicationFrequency
          frequencyAmount={frequencyAmount}
          handleClickCard={handleClickCard}
          frequenciesArray={frequenciesArray}
          setFrequencyAmount={setFrequencyAmount}
          handleClickAddFrequency={handleClickAddFrequency}
        />
      </Box>
      <Box>
        <CustomButton
          fullWidth
          disableElevation
          color={'primary'}
          variant={'contained'}
          onClick={handleClickNext}
          sx={{
            height: '48px',
          }}
        >
          {t('buttons.next')}
        </CustomButton>
      </Box>
      <Modal open={openTimePillPicker} onClose={() => setOpenTimePillPicker(false)}>
        <TimePickerFields
          handleSaveData={handleSaveData}
          time={selected ? selected.time : ''}
          amount={selected ? selected.amount : 1}
          label={t('medication.labels.medsAmount')}
        />
      </Modal>
    </Box>
  );
};

export default MedicationFrequencyModal;
