import * as React from 'react';
import { CSSProperties, FC, useEffect } from 'react';
import useTimeframe from '../../../hooks/useTimeframe';
import {
  useGetPressureGraphDataQuery,
  useGetPulsesGraphDataQuery,
} from '../queries/hypertensionQueries';
import {
  FeelsEmpty,
  LineChart,
  ScatterChart,
  theme,
  usePreparePressureGraph,
  usePreparePressureScatterXy,
  usePreparePulsesGraph,
} from '@hdcorner/ui-library';
import Box from '@mui/material/Box';
import { styled, Typography, TypographyProps } from '@mui/material';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const GraphTypography = styled(Typography)<
  TypographyProps & { dotColor?: CSSProperties['color'] }
>(({ dotColor, theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    backgroundColor: dotColor ? dotColor : '#222222',
  },
}));

type Props = {
  userId: string;
  customEndDate?: string;
  customStartDate?: string;
  selectedCategory: string;
  selectedGraphType: string;
  selectedTimeframe: string;
};
const HypertensionGraphData: FC<Props> = ({
  userId,
  customEndDate,
  customStartDate,
  selectedCategory,
  selectedTimeframe,
  selectedGraphType,
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const timeframePressure = useTimeframe(
    selectedTimeframe,
    customStartDate,
    customEndDate,
  );
  const timeframePulses = useTimeframe(selectedTimeframe, customStartDate, customEndDate);

  const { data: graphDataPressure, error: errorPressure } = useGetPressureGraphDataQuery({
    userId,
    ...timeframePressure,
    category: selectedCategory === 'all' ? undefined : selectedCategory,
  });

  const { data: graphDataPulses, error: errorPulses } = useGetPulsesGraphDataQuery({
    userId,
    ...timeframePulses,
    category: selectedCategory === 'all' ? undefined : selectedCategory,
  });

  useEffect(() => {
    if (errorPressure || errorPulses)
      presentError(t('errors.hypertension.errorGraphFetch'));
  }, [errorPressure, errorPulses]);

  const scatterXy = usePreparePressureScatterXy(graphDataPressure);
  const prepareGraphDataPulses = usePreparePulsesGraph(graphDataPulses);
  const prepareGraphDataPressure = usePreparePressureGraph(graphDataPressure);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <GraphContainer>
          {prepareGraphDataPressure.length > 0 && selectedGraphType === 'lineChart' && (
            <LineChart
              height={'250px'}
              chartData={prepareGraphDataPressure}
              colors={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {prepareGraphDataPressure.length > 0 &&
            selectedGraphType === 'scatterChart' && (
              <ScatterChart
                height={'250px'}
                chartData={scatterXy}
                axisTitles={{
                  xAxis: t('dashboard.dashcards.hypertension.systolic'),
                  yAxis: t('dashboard.dashcards.hypertension.diastolic'),
                }}
                colorList={[theme.palette.error.main, theme.palette.warning.main]}
              />
            )}
          {prepareGraphDataPressure.length === 0 && <FeelsEmpty />}
          {selectedGraphType === 'lineChart' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={theme.spacing(3)}
              justifyContent={'center'}
              paddingLeft={theme.spacing(2)}
            >
              <GraphTypography dotColor={theme.palette.warning.main}>
                {t('hypertension.labels.systolic')}
              </GraphTypography>
              <GraphTypography dotColor={theme.palette.error.main}>
                {t('hypertension.labels.diastolic')}
              </GraphTypography>
            </Box>
          )}
        </GraphContainer>
      </Box>
      <Box>
        {prepareGraphDataPulses.length > 0 && selectedGraphType === 'lineChart' && (
          <GraphContainer>
            <LineChart
              height={'250px'}
              chartData={prepareGraphDataPulses}
              colors={[theme.palette.warning.main]}
            />
            {prepareGraphDataPulses.length === 0 && <FeelsEmpty />}
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={theme.spacing(3)}
              justifyContent={'center'}
              paddingLeft={theme.spacing(2)}
            >
              <GraphTypography dotColor={theme.palette.warning.main}>
                {t('dashboard.dashcards.hypertension.pulses')}
              </GraphTypography>
            </Box>
          </GraphContainer>
        )}
      </Box>
    </Box>
  );
};

export default HypertensionGraphData;
