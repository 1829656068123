import { ComponentsLayout } from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import WebCodesActivity from './components/WebCodesActivity';
import { useTranslation } from 'react-i18next';

const CodesActivity = () => {
  const { t } = useTranslation();

  return (
    <PageLayout headerTitle={t('profile.titles.')} defaultHref={'/dashboard/profile'}>
      <ComponentsLayout>
        <WebCodesActivity />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default CodesActivity;
