import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Button, Table, TableBody } from '@mui/material';
import {
  ESHSortTableHeading,
  ESHSortTableRows,
  HeadCell,
  User24HourHypertension,
} from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import useAlert from '../../../../hooks/useAlert';
import { useGetTwentyFourHoursQuery } from '../queries';
import { DescriptionOutlined } from '@mui/icons-material';
import TwentyFourHoursReport from './TwentyFourHoursReport';

type TwentyFourHoursReadingsProps = {
  userId: string;
  showReport: boolean;
  selectReport: (id: string) => void;
};

const TwentyFourHoursReadings = ({
  userId,
  showReport,
  selectReport,
}: TwentyFourHoursReadingsProps) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { customDateFilter } = useAppSelector(state => state.layout);

  const [reportId, setReportId] = useState<string | null>(null);

  const { error, data } = useGetTwentyFourHoursQuery({
    userId,
    skip: 0,
    limit: 1000,
    end: customDateFilter?.end,
    start: customDateFilter?.start,
  });

  useEffect(() => {
    if (error) presentError(t('errors.hypertension.error24hoursFetch'));
  }, [error]);

  const rows = useMemo<User24HourHypertension[]>(() => {
    if (!data) return [];
    return data.documents;
  }, [data]);

  const selectedReport = useMemo(() => {
    if (!reportId) return;
    return rows?.find(it => it._id === reportId);
  }, [reportId, rows]);

  const createData = (row: User24HourHypertension) => {
    const logs = [...row.logs];
    const sortedLogs = logs.sort((a, b) => {
      return moment(a.logDate).diff(moment(b.logDate));
    });
    return [
      moment(row.logDate).format('DD-MM-YYYY'),
      row.logs.length,
      moment(sortedLogs[0].logDate).format('DD-MM-YYYY HH:mm'),
      moment(sortedLogs[sortedLogs.length - 1].logDate).format('DD-MM-YYYY HH:mm'),
      <Button
        onClick={() => {
          setReportId(row._id);
          selectReport(row._id);
        }}
      >
        <DescriptionOutlined />
      </Button>,
    ];
  };

  const headCells: HeadCell[] = [
    { _id: 'date', label: 'Date', align: 'left' },
    {
      align: 'center',
      _id: 'measurement',
      label: 'Number of measurements',
    },
    {
      align: 'center',
      _id: 'firstMeasurement',
      label: '1st measurement',
    },
    {
      align: 'center',
      _id: 'lastMeasurement',
      label: 'Last measurement',
    },
    { _id: 'report', label: 'View report', align: 'center' },
  ];

  if (!showReport) {
    return (
      <Table>
        <ESHSortTableHeading headCells={headCells} />
        <TableBody>
          {rows.map(it => (
            <ESHSortTableRows key={it._id} rowCells={createData(it)} alignData />
          ))}
        </TableBody>
      </Table>
    );
  } else {
    return <TwentyFourHoursReport report={selectedReport} />;
  }
};

export default TwentyFourHoursReadings;
