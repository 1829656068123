import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DiabetesType = {
  data: {};
  meta: {
    loading: boolean;
    error: string | null;
    status: 'idle' | 'loading' | 'failed' | 'success';
  };
  filter: string;
};

const initialState: DiabetesType = {
  data: {},
  meta: {
    error: null,
    status: 'idle',
    loading: false,
  },
  filter: 'default',
};

const diabetesSlice = createSlice({
  name: 'diabetes',
  initialState: initialState,
  reducers: {
    updateDiabetesFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
  },
});

export const { updateDiabetesFilter } = diabetesSlice.actions;

export default diabetesSlice.reducer;
