import { capitalizeFirstLetter, MyDataComponent, theme } from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo } from 'react';
import { findUpdatedAt } from '../../../utils/dateTimeUtils';
import {
  useGetDashboardTrendsQuery,
  useGetRecordsValuesQuery,
} from '../queries/userOverviewQueries';
import { findCategoryLabel } from '../../records/utils';
import useAlert from '../../../hooks/useAlert';
import { Box, styled, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightMedium,
}));

type DataType = {
  date: string;
  title: string;
  data: { label: string; info: string; trend?: 'ascending' | 'descending' | 'stable' }[];
};

type DashDataCardsProps = {
  userId: string;
  handleClickCard: (value: string) => void;
};
const DashDataCards: FC<DashDataCardsProps> = ({ userId, handleClickCard }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  /** TIMEFRAME TRENDS */
  const { data: dashTrends, error: errorDash } = useGetDashboardTrendsQuery({
    userId,
    end:
      customDateFilter && dateFilter === 'custom date' ? customDateFilter.end : undefined,
    start:
      customDateFilter && dateFilter === 'custom date'
        ? customDateFilter.start
        : undefined,
    timeframe: dateFilter !== 'custom date' ? dateFilter : undefined,
  });

  const { data: recordsRes, error: errorRecords } = useGetRecordsValuesQuery({ userId });

  useEffect(() => {
    if (errorDash) presentError(t('errors.generic.errorTimeframe'));
    if (errorRecords) presentError(t('errors.records.errorFetch'));
  }, [errorDash, errorRecords]);

  /**
   * Trends sensitive to timeframe
   * Fitness | Hyp | Workouts | Meds | Diabetes
   * "any" type used to resolve error - data structure unchanged
   */
  const affectedTrends = useMemo<any>(() => {
    const medication = dashTrends?.averages?.medication;
    const meds = medication?.map(
      it =>
        `${capitalizeFirstLetter(it.name.toLowerCase())} - ${Math.round(
          it.takenPercentage,
        )}%`,
    );
    const steps = dashTrends?.averages?.fitness?.steps?.measurement;
    const target = dashTrends?.averages?.fitness?.target?.measurement;
    const intake = dashTrends?.averages?.fitness?.intake?.measurement;
    const burned = dashTrends?.averages?.fitness?.burned?.measurement;
    const exercise = dashTrends?.averages?.fitness?.exercise?.measurement;
    const systolic = dashTrends?.averages?.hypertension?.systolic;
    const diastolic = dashTrends?.averages?.hypertension?.diastolic;
    const pulses = dashTrends?.averages?.hypertension?.pulses;
    const workoutsNumber = dashTrends?.averages?.workouts?.workoutsNumber?.measurement;
    const topPrograms = dashTrends?.averages?.workouts?.topPrograms?.measurement;
    const bloodGlucose = dashTrends?.averages?.diabetes?.bloodGlucose;

    return [
      {
        fitness: {
          twoColumn: true,
          title: t('dashboard.dashcards.fitness.title'),
          data: [
            {
              label: t('general.labels.target'),
              info: `${target ? Math.round(target) : '-'} ${t('measurements.kcal')}`,
            },
            {
              label: t('general.labels.intake'),
              info: `${intake ? `${Math.round(intake)}` : '-'} ${t('measurements.kcal')}`,
            },
            {
              label: t('dashboard.dashcards.fitness.burned'),
              info: `${burned ? `${Math.round(burned)}` : '-'} ${t('measurements.kcal')}`,
            },
            {
              label: t('dashboard.dashcards.fitness.exercise'),
              info:
                `${exercise ? `${Math.round(exercise)}` : '-'} ` +
                t('measurements.minutes'),
            },

            {
              label: t('headingsTitles.steps'),
              info: steps ? `${Math.round(steps)}` : '-',
            },
          ],
        },
      },
      {
        hypertension: {
          twoColumn: true,
          title: t('dashboard.dashcards.hypertension.title'),
          data: [
            {
              trend: systolic?.trend ? systolic.trend : 'stable',
              label: t('dashboard.dashcards.hypertension.systolic'),
              info: `${
                systolic?.measurement ? Math.round(systolic.measurement) : '-'
              } ${t('measurements.mmGH')}`,
            },
            {
              trend: diastolic?.trend ? diastolic.trend : 'stable',
              label: t('dashboard.dashcards.hypertension.diastolic'),
              info: `${
                diastolic?.measurement ? Math.round(diastolic.measurement) : '-'
              } ${t('measurements.mmGH')}`,
            },
            {
              label: t('dashboard.dashcards.hypertension.pulses'),
              trend: pulses?.trend ? pulses.trend : 'stable',
              info: `${pulses?.measurement ? Math.round(pulses.measurement) : '-'} ${t(
                'measurements.bpm',
              )}`,
            },
          ],
        },
      },
      {
        programs: {
          title: t('dashboard.dashcards.fitness.workouts'),
          data: [
            {
              info: workoutsNumber ? workoutsNumber : '-',
              label: t('dashboard.dashcards.fitness.numWorkouts'),
            },
            {
              label: t('dashboard.dashcards.fitness.top'),
              info: topPrograms ? topPrograms.join(', ') : '-',
            },
          ],
        },
      },
      {
        medication: {
          title: t('dashboard.dashcards.medication.title'),
          data: [
            {
              info: meds ? meds.join(', ') : '-',
              label: t('dashboard.dashcards.medication.medsTaken'),
            },
          ],
        },
      },
      {
        diabetes: {
          title: t('dashboard.dashcards.diabetes.title'),
          data: [
            {
              trend: bloodGlucose?.trend || 'stable',
              label: t('dashboard.dashcards.diabetes.glucose'),
              info: `${
                bloodGlucose?.measurement ? Math.round(bloodGlucose.measurement) : '-'
              } ${t('measurements.mgdl')}`,
            },
          ],
        },
      },
    ];
  }, [dashTrends]);

  /**
   * FITNESS : WEIGHT ONLY
   */
  const fitnessWeightData = useMemo<DataType>(() => {
    const updatedAt = dashTrends?.latestMeasurements?.fitness?.updatedAt;
    const weight = dashTrends?.latestMeasurements?.fitness?.weight?.measurement;
    const timeStamp = findUpdatedAt(updatedAt);

    return {
      twoColumn: true,
      title: t('dashboard.dashcards.fitness.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          label: t('userDetails.weight'),
          info: `${weight ? weight : '-'} kg`,
        },
      ],
    };
  }, [dashTrends]);

  /** LIPID */
  const lipidLogs = useMemo<DataType>(() => {
    const lipid = dashTrends?.latestMeasurements?.lipidProfile;
    const trg = lipid?.trg?.measurement;
    const lpa = lipid?.lpa?.measurement;
    const hdl = lipid?.hdl?.measurement;
    const ldl = lipid?.ldl?.measurement;
    const totalCholesterol = lipid?.totalChol?.measurement;
    const timeStamp = findUpdatedAt(lipid?.updatedAt);

    return {
      twoColumn: true,
      title: t('dashboard.dashcards.lipid.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          label: t('dashboard.dashcards.lipid.chol'),
          info: `${totalCholesterol ? totalCholesterol : '-'} ${t('measurements.mgdl')}`,
        },
        {
          label: t('dashboard.dashcards.lipid.ldl'),
          info: `${ldl ? ldl : '-'} ${t('measurements.mgdl')}` || '',
        },
        {
          label: t('dashboard.dashcards.lipid.trg'),
          info: `${trg ? trg : '-'} ${t('measurements.mgdl')}`,
        },
        {
          label: t('dashboard.dashcards.lipid.hdl'),
          info: `${hdl ? hdl : '-'} ${t('measurements.mgdl')}`,
        },
        {
          label: t('dashboard.dashcards.lipid.lpa'),
          info: `${lpa ? lpa : '-'} ${t('measurements.mgdl')}`,
        },
      ],
    };
  }, [dashTrends]);

  /**
   * DIABETES : HBAC1 ONLY
   */
  const hba1cLogs = useMemo<DataType>(() => {
    const updatedAt = dashTrends?.latestMeasurements?.diabetes?.updatedAt;
    const hba1c = dashTrends?.latestMeasurements?.diabetes?.hbac?.measurement;
    const timeStamp = findUpdatedAt(updatedAt);

    return {
      title: t('dashboard.dashcards.diabetes.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          info: `${hba1c ? hba1c : '-'} %`,
          label: t('dashboard.dashcards.diabetes.hba1c'),
        },
      ],
    };
  }, [dashTrends]);

  /** LIPID */
  const cvdData = useMemo<DataType>(() => {
    const cvd = dashTrends?.latestMeasurements?.cvdRisk?.overallRisk?.measurement;
    const updatedAt = dashTrends?.latestMeasurements?.cvdRisk?.updatedAt;
    const timeStamp = findUpdatedAt(updatedAt);

    return {
      title: t('dashboard.dashcards.cvd.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          info: `${cvd ? cvd + '%' : '-'}`,
          label: t('dashboard.dashcards.cvd.overall'),
        },
      ],
    };
  }, [dashTrends]);

  /**
   * RECORDS : Mobile ONLY
   */
  const healthData = useMemo<DataType>(() => {
    const timeStamp = findUpdatedAt(recordsRes?.[0]?.updatedAt);

    return {
      title: t('dashboard.dashcards.health.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          info: `${recordsRes?.length}` || '',
          label: t('dashboard.dashcards.health.files'),
        },
        {
          label: t('dashboard.dashcards.health.latest'),
          info: recordsRes?.[0]?.category
            ? t(findCategoryLabel(recordsRes[0].category))
            : '-',
        },
      ],
    };
  }, [recordsRes]);

  /** STATIC TRENDS */
  const data = useMemo<any>(() => {
    return [
      { lipid: lipidLogs },
      { fitness: fitnessWeightData },
      { cvd: cvdData },
      { diabetes: hba1cLogs },
    ];
  }, [cvdData, lipidLogs, fitnessWeightData, hba1cLogs]);

  const mobData = useMemo<any>(() => {
    return [{ healthdata: healthData }];
  }, [healthData]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Box>
          <Title
            variant={'subtitle1'}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          >
            {t('profile.titles.userData')}
          </Title>
        </Box>
        <Box>
          <MyDataComponent handleClickCard={handleClickCard} data={affectedTrends} />
        </Box>
      </Box>
      <Box>
        <Box>
          <Title
            variant={'subtitle1'}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          >
            {t('goals.latestMeasurement')}
          </Title>
        </Box>
        <Box>
          <MyDataComponent
            handleClickCard={handleClickCard}
            data={web ? data : [...data, ...mobData]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DashDataCards;
