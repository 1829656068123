import { CustomSlider, RadioGroup, RadioSingle } from '@hdcorner/ui-library';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { useTranslation } from 'react-i18next';

const RadioTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const RadioBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '40px',
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.secondary.main,
}));

type Props = {
  changePerWeek: number;
  activityLevel: 'light' | 'moderate' | 'heavy';
  goal: 'lose_weight' | 'muscle_gain' | 'maintain_weight';
  dietType: 'standard' | 'low_carb' | 'low_fat' | 'high_protein';
  setChangePerWeek: (str: number) => void;
  setActivityLevel: (str: 'light' | 'moderate' | 'heavy') => void;
  setGoal: (str: 'lose_weight' | 'muscle_gain' | 'maintain_weight') => void;
  setDietType: (str: 'standard' | 'low_carb' | 'low_fat' | 'high_protein') => void;
};
const DietCalculatorSecondStep: FC<Props> = ({
  goal,
  setGoal,
  activityLevel,
  setActivityLevel,
  changePerWeek,
  setChangePerWeek,
  dietType,
  setDietType,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleSetGrams = (value: number | number[]) => {
    setChangePerWeek(value as number);
  };

  const gramsLabel = useMemo(() => {
    return goal === 'muscle_gain'
      ? t('fitness.calories.text.grGain')
      : t('fitness.calories.text.grLose');
  }, [goal]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} sx={{ background: '#FBFBFB' }}>
      <Box>
        <RadioTitle variant={'body1'}>{t('fitness.dietPlan.text.iWant')}</RadioTitle>
        <RadioGroup
          row={true}
          value={goal}
          onChange={event => setGoal(event.target.value as any)}
        >
          <Box display={'flex'} gap={1} flex={1}>
            <RadioBox>
              <RadioSingle
                reverse
                value={'muscle_gain'}
                label={`${t('fitness.dietPlan.text.gainMuscle')}`}
              />
            </RadioBox>
            <RadioBox>
              <RadioSingle
                reverse
                value={'lose_weight'}
                label={`${t('fitness.dietPlan.text.loseFat')}`}
              />
            </RadioBox>
            <RadioBox>
              <RadioSingle
                reverse
                value={'maintain_weight'}
                label={`${t('fitness.dietPlan.text.keepWeight')}`}
              />
            </RadioBox>
          </Box>
        </RadioGroup>
      </Box>

      {goal !== 'maintain_weight' && (
        <Box display={'flex'} flexDirection={'column'} gap={3}>
          <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {gramsLabel}
          </Typography>
          <Box marginX={3}>
            <CustomSlider
              min={500}
              max={1000}
              selected={changePerWeek}
              valueLabelDisplay={'on'}
              setSliderValue={handleSetGrams}
              valueLabelText={`${t('measurements.gr')}`}
              getAriaValueText={value => `${value}gr`}
              marks={[
                { value: 500, label: `500${t('measurements.gr')}` },
                { value: 1000, label: `1000${t('measurements.gr')}}` },
              ]}
            />
          </Box>
        </Box>
      )}
      <Box>
        <RadioTitle variant={'body1'}>Body activity while working</RadioTitle>
        <RadioGroup
          row={true}
          value={activityLevel}
          onChange={event => setActivityLevel(event.target.value as any)}
        >
          <Box display={'flex'} gap={1} flex={1}>
            <RadioBox>
              <RadioSingle
                reverse
                value={'light'}
                label={`${t('fitness.dietPlan.text.light')}`}
              />
            </RadioBox>
            <RadioBox>
              <RadioSingle
                reverse
                value={'moderate'}
                label={`${t('fitness.dietPlan.text.moderate')}`}
              />
            </RadioBox>
            <RadioBox>
              <RadioSingle
                reverse
                value={'heavy'}
                label={`${t('fitness.dietPlan.text.intense')}`}
              />
            </RadioBox>
          </Box>
        </RadioGroup>
      </Box>
      <Box>
        <RadioTitle variant={'body1'}>Diet type</RadioTitle>
        <RadioGroup
          row={true}
          value={dietType}
          onChange={event => setDietType(event.target.value as any)}
        >
          <Box display={'flex'} gap={1} flex={1}>
            <RadioBox>
              <RadioSingle
                reverse
                value={'standard'}
                label={`${t('fitness.dietPlan.text.standard')}`}
              />
            </RadioBox>
            <RadioBox>
              <RadioSingle
                reverse
                value={'lowCarb'}
                label={`${t('fitness.dietPlan.text.lowCarb')}`}
              />
            </RadioBox>
            <RadioBox>
              <RadioSingle
                reverse
                value={'lowFat'}
                label={`${t('fitness.dietPlan.text.lowFat')}`}
              />
            </RadioBox>
            <RadioBox>
              <RadioSingle
                reverse
                value={'highProtein'}
                label={`${t('fitness.dietPlan.text.highProtein')}`}
              />
            </RadioBox>
          </Box>
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default DietCalculatorSecondStep;
