import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import {
  CalendarGoalsModal,
  CalendarIcon,
  ComponentsLayout,
  CustomTabs,
} from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import ChartSteps from './components/ChartSteps';
import SectionActivitiesCal from './components/SectionActivitiesCal';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const ExerciseFitness: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openCalendarGoals, setOpenCalendarGoals] = useState<boolean>(false);

  const handleOpenStepsCalendar = () => {
    setOpenCalendarGoals(true);
  };

  return (
    <PageLayout
      defaultHref={`/dashboard/users/${userId}/fitness`}
      headerTitle={t('dashboard.dashcards.fitness.exercise')}
      endButtons={
        selectedTab === 0
          ? []
          : [
              {
                icon: <CalendarIcon width={'20px'} height={'20px'} />,
                onClick: handleOpenStepsCalendar,
              },
            ]
      }
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <CustomTabs
            value={selectedTab}
            setValue={setSelectedTab}
            tabs={[
              {
                label: t('headingsTitles.steps'),
                children: <ChartSteps userId={userId} />,
              },
              {
                label: t('headingsTitles.activities'),
                children: <SectionActivitiesCal userId={userId} />,
              },
            ]}
            sx={{ display: 'flex', flex: 1, width: '100%' }}
          />
        </Box>
      </ComponentsLayout>
      <CalendarGoalsModal
        open={openCalendarGoals}
        setOpen={setOpenCalendarGoals}
        goalDates={[
          { date: moment().toDate(), success: true },
          { date: moment().add(1, 'days').toDate(), success: false },
        ]}
        onMonthChange={(date: string) =>
          console.log('Request data with start of month: ' + date)
        }
      />
    </PageLayout>
  );
};
export default ExerciseFitness;
