import { Storage } from '@ionic/storage';

let storage: Storage;

const getStore = async () => {
  if (!storage) {
    storage = new Storage();
    await storage.create();
  }
  return storage;
};

export const clearTokenStorage = async () => await storage.clear();

// ACCESS TOKEN
export const storeAccessToken = async (accessToken: string) => {
  const store = await getStore();
  await store.set('hcp_user_accessToken', accessToken);
};

export const getAccessToken = async () => {
  const store = await getStore();
  return await store.get('hcp_user_accessToken');
};

export const removeAccessToken = async () => {
  const store = await getStore();
  await store.remove('hcp_user_accessToken');
};

// REFRESH TOKEN
export const storeRefreshToken = async (refreshToken: string) => {
  const store = await getStore();
  await store.set('hcp_user_refreshToken', refreshToken);
};

export const getRefreshToken = async () => {
  const store = await getStore();
  return await store.get('hcp_user_refreshToken');
};

export const removeRefreshToken = async () => {
  const store = await getStore();
  await store.remove('hcp_user_refreshToken');
};
