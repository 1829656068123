import React, { FC } from 'react';
import { Box } from '@mui/material';
import BloodGlucoseWebSection from './BloodGlucoseWebSection';
import Hbac1WebSection from './Hbac1WebSection';

type Props = {
  userId: string;
};
const DiabetesWeb: FC<Props> = ({ userId }) => {
  return (
    <>
      <Box display={'flex'} flex={1} flexWrap={'wrap'} gap={3} alignItems={'baseline'}>
        <BloodGlucoseWebSection userId={userId} />
        <Hbac1WebSection userId={userId} />
      </Box>
    </>
  );
};

export default DiabetesWeb;
