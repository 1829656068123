import * as React from 'react';
import { SVGProps } from 'react';

const GraphDownRed = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 18V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8947 17.2652 22 17 22C16.7348 22 16.4804 21.8947 16.2929 21.7071C16.1054 21.5196 16 21.2652 16 21V18C16 17.7348 16.1054 17.4805 16.2929 17.2929C16.4804 17.1054 16.7348 17 17 17C17.2652 17 17.5196 17.1054 17.7071 17.2929C17.8946 17.4805 18 17.7348 18 18ZM8 14V21C8 21.2652 8.10536 21.5196 8.29289 21.7071C8.48043 21.8947 8.73478 22 9 22C9.26522 22 9.51957 21.8947 9.70711 21.7071C9.89464 21.5196 10 21.2652 10 21V14C10 13.7348 9.89464 13.4805 9.70711 13.2929C9.51957 13.1054 9.26522 13 9 13C8.73478 13 8.48043 13.1054 8.29289 13.2929C8.10536 13.4805 8 13.7348 8 14ZM0 21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8947 0.734784 22 1 22C1.26522 22 1.51957 21.8947 1.70711 21.7071C1.89464 21.5196 2 21.2652 2 21V11C2 10.7348 1.89464 10.4805 1.70711 10.2929C1.51957 10.1054 1.26522 10 1 10C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4805 0 10.7348 0 11V21ZM18 5.00003C18 4.73481 17.8946 4.48046 17.7071 4.29292C17.5196 4.10539 17.2652 4.00003 17 4.00003C16.7348 4.00003 16.4804 4.10539 16.2929 4.29292C16.1054 4.48046 16 4.73481 16 5.00003V6.58603L9.707 0.293031C9.51947 0.10556 9.26516 0.000244141 9 0.000244141C8.73484 0.000244141 8.48053 0.10556 8.293 0.293031L5 3.58603L1.707 0.293031C1.5184 0.110873 1.2658 0.0100782 1.0036 0.0123566C0.741402 0.0146351 0.49059 0.119804 0.305182 0.305212C0.119773 0.49062 0.0146044 0.741433 0.012326 1.00363C0.0100476 1.26583 0.110842 1.51843 0.293 1.70703L4.293 5.70703C4.48053 5.8945 4.73484 5.99982 5 5.99982C5.26516 5.99982 5.51947 5.8945 5.707 5.70703L9 2.41403L14.586 8.00003H13C12.7348 8.00003 12.4804 8.10539 12.2929 8.29292C12.1054 8.48046 12 8.73481 12 9.00003C12 9.26525 12.1054 9.5196 12.2929 9.70714C12.4804 9.89467 12.7348 10 13 10H17C17.2652 10 17.5196 9.89467 17.7071 9.70714C17.8946 9.5196 18 9.26525 18 9.00003V5.00003Z"
        fill="#E84044"
      />
    </svg>
  );
};

export default GraphDownRed;
