import React, { useEffect, useMemo } from 'react';
import { theme } from '@hdcorner/ui-library';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { useGetConnectedUsersQuery } from '../../Users/queries/usersQueries';
import moment from 'moment';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const TypographyHeading = styled(Typography)<TypographyProps>(() => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const WebCodesActivity = () => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { error, data: connectionsRes } = useGetConnectedUsersQuery({
    skip: 0,
    limit: 1000,
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.errorFetchUser'));
  }, [error]);

  const prepareCodeActivity = useMemo(() => {
    if (!connectionsRes) return [];

    return connectionsRes.documents.map(conn => ({
      id: conn._id,
      code: conn.healthProfessionalData.code,
      user: conn.user.firstName + ' ' + conn.user.lastName,
      dateUsed: moment(conn.createdAt).format('DD/MM/YYYY'),
    }));
  }, [connectionsRes]);

  return (
    <>
      <TypographyHeading variant={'subtitle2'}>
        {t('profile.titles.codes')}
      </TypographyHeading>

      <Box
        height={'150px'}
        overflow={'scroll'}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        bgcolor={theme.palette.highlight.main}
        sx={{
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {prepareCodeActivity.map(it => (
          <Box
            key={it.id}
            padding={2}
            flexGrow={1}
            display={'flex'}
            boxSizing={'border-box'}
            justifyContent={'space-between'}
            sx={{ '&:last-of-type': { borderBottom: 'none' } }}
            borderBottom={`1px solid ${theme.palette.secondary.main}`}
          >
            <Box display={'flex'} justifyContent={'flex-start'}>
              <Typography
                variant={'body1'}
                sx={{
                  marginRight: theme.spacing(1),
                  color: theme.palette.kmColorsDarkGrey.main,
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                {it.user}
              </Typography>
              <Typography
                variant={'body1'}
                sx={{
                  color: theme.palette.kmColorsDarkGrey.main,
                }}
              >
                {' '}
                {t('profile.text.useCode', { code: it.code })}
              </Typography>
            </Box>

            <Typography
              variant={'body2'}
              sx={{
                color: theme.palette.kmColorsDarkGrey.main,
                fontWeight: theme.typography.fontWeightLight,
              }}
            >
              {it.dateUsed}
            </Typography>
          </Box>
        ))}
        {prepareCodeActivity.length === 0 && (
          <Box
            padding={2}
            flexGrow={1}
            display={'flex'}
            alignItems={'center'}
            boxSizing={'border-box'}
            justifyContent={'center'}
          >
            <Typography variant={'body1'} fontWeight={'bold'}>
              {t('profile.text.noActivity')}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default WebCodesActivity;
