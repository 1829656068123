import { Box } from '@mui/material';
import TableAverages from './esh/components/TableAverages';
import TableReadings from './esh/components/TableReadings';
import { useAppSelector } from '../../redux/hooks';
import ToolbarHypertension from './esh/components/ToolbarHypertension';
import WebHyperInDepth from './components/WebHyperInDepth';
import React, { FC, useRef, useState } from 'react';
import { CustomButton, PDFIcon } from '@hdcorner/ui-library';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useLazyDownloadPdfReportQuery } from './24hours/queries';
import TwentyFourHoursReadings from './24hours/components/TwentyFourHoursReadings';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../utils/Filesystem';
import useAlert from '../../hooks/useAlert';

type Props = {
  userId: string;
};
const UserDataHypertension: FC<Props> = ({ userId }) => {
  const { presentInfo } = useAlert();
  const { t } = useTranslation();

  const { toggle } = useAppSelector(state => state.hypertension);

  const reportIdRef = useRef<string | null>(null);
  const [twentyFourHoursReport, setTwentyFourHoursReport] = useState(false);

  const [trigger] = useLazyDownloadPdfReportQuery();

  const downloadPdf = () => {
    if (!reportIdRef.current) return;
    presentInfo(t('hypertension.notifications.pdf24HoursReportGenerating'));
    trigger({ id: reportIdRef.current, userId })
      .unwrap()
      .then((res: any) => {
        if (res && res.uploadUrl) {
          return res.uploadUrl;
        } else {
          throw Error(t('errors.generic.error01') as string);
        }
      })
      .then(url => {
        return downloadFile(url, '24hours_report.pdf');
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'}>
      <ToolbarHypertension />
      {toggle === 'indepth' && <WebHyperInDepth userId={userId} />}

      {toggle === 'esh' && (
        <>
          <TableAverages />
          <TableReadings />
        </>
      )}

      {toggle === '24hrs' && (
        <Box>
          <Box
            display={'flex'}
            hidden={!twentyFourHoursReport}
            justifyContent={'space-between'}
          >
            <CustomButton
              onClick={() => {
                reportIdRef.current = null;
                setTwentyFourHoursReport(false);
              }}
            >
              <ChevronLeftRounded />
            </CustomButton>
            <CustomButton variant={'outlined'} onClick={() => downloadPdf()}>
              <Box display={'flex'} gap={1}>
                <PDFIcon />
                {t('buttons.pdfDL')}
              </Box>
            </CustomButton>
          </Box>
          <TwentyFourHoursReadings
            userId={userId}
            showReport={twentyFourHoursReport}
            selectReport={reportId => {
              reportIdRef.current = reportId;
              setTwentyFourHoursReport(true);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserDataHypertension;
