import * as React from 'react';
import { FC, useEffect } from 'react';
import { ComponentsLayout, CustomButton, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import { IonImg } from '@ionic/react';
import { useGetMedicineByIdQuery } from './queries/medicationQueries';
import { RouteComponentProps } from 'react-router';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
    medicineId: string;
  }> {}
const MedicationDrugDescription: FC<Props> = ({
  match: {
    params: { userId, medicineId },
  },
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { error, data: medicine } = useGetMedicineByIdQuery(medicineId, {
    skip: !medicineId,
  });

  useEffect(() => {
    if (error) presentError(t('errors.medication.errorFetchMeds'));
  }, [error]);

  return (
    <PageLayout
      headerTitle={medicine?.medicineName}
      headerColor={theme.palette.primary.main}
      defaultHref={`/dashboard/users/${userId}/medicines`}
    >
      <ComponentsLayout>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          padding={theme.spacing(4, 2)}
        >
          <IonImg src={medicine?.imagePreview as string} />
        </Box>
        <Box marginTop={theme.spacing(2)}>
          <Typography
            variant={'subtitle2'}
            color={theme.palette.primary.main}
            marginBottom={theme.spacing(1)}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {t('general.labels.description')}
          </Typography>
          <CustomButton
            onClick={() => window.open(medicine?.substanceUrl, '_blank')}
            sx={{
              paddingInline: '0px',
              backgroundColor: 'transparent',
              marginTop: theme.spacing(-0.8),
              marginBottom: theme.spacing(0.2),
              '&:hover': { backgroundColor: 'secondary.light' },
              '&:focus': { backgroundColor: 'secondary.light' },
            }}
          >
            <Typography
              variant={'subtitle2'}
              color={theme.palette.medication.main}
              fontWeight={theme.typography.fontWeightRegular}
            >
              {medicine?.substance}
            </Typography>
          </CustomButton>
          <Typography variant={'body1'} color={theme.palette.primary.light}>
            {medicine?.description}
          </Typography>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default MedicationDrugDescription;
