import { UserRecord } from '@hdcorner/ui-library';
import api from '../../../redux/api';

const recordsQueries = api
  .enhanceEndpoints({ addTagTypes: ['Records'] })
  .injectEndpoints({
    endpoints: build => ({
      getUserRecords: build.query<
        UserRecord[],
        { fileType?: string; fileCategory?: string; search?: string; userId: string }
      >({
        query: ({ userId, fileType, search, fileCategory }) => ({
          method: 'GET',
          url: '/database/function/getUserRecordHcp',
          params: {
            userId,
            populate: 'file',
            searchText: search ? search : undefined,
            fileType: fileType ? fileType : undefined,
            category: fileCategory ? fileCategory : undefined,
          },
        }),
        providesTags: ['Records'],
      }),
      getFileUrl: build.query<{ result: string }, { fileId: string }>({
        query: ({ fileId }) => ({
          method: 'GET',
          url: '/storage/getFileUrl/' + fileId,
        }),
      }),
    }),
  });

export const { useGetFileUrlQuery, useGetUserRecordsQuery } = recordsQueries;

export default recordsQueries;
