import ChartSleep from './ChartSleep';
import ChartWeight from './ChartWeight';
import SectionSleepData from './SectionSleepData';
import SectionWeightData from './SectionWeightData';
import { Box, BoxProps, styled } from '@mui/material';
import SectionGoalWeight from './SectionGoalWeight';
import React from 'react';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  gap: theme.spacing(3),
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  flex: '1 0 250px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

type Props = {
  userId: string;
};

const WebWellness: React.FC<Props> = ({ userId }) => {
  return (
    <BoxMain>
      <BoxColumn>
        <ChartWeight userId={userId} />
        <SectionWeightData userId={userId} />
        <SectionGoalWeight userId={userId} />
      </BoxColumn>
      <BoxColumn>
        <ChartSleep userId={userId} />
        <SectionSleepData userId={userId} />
      </BoxColumn>
    </BoxMain>
  );
};

export default WebWellness;
