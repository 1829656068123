import {
  AppBarExpandedIcon,
  AppBarExpandMoreIcon,
  ChatMessagePanelWeb,
  ChatPanel,
  ChatSendersHeaderWeb,
  ChatSendersSupport,
  ChatTabs,
  EditPencil,
  ModalMobile,
  RequestInfoModal,
  theme,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { RouteComponentProps } from 'react-router';
import ModalCreateChat from '../../components/ModalCreateChat';
import {
  useCreateChatRoomMutation,
  useCreateSupportChatRoomMutation,
  useGetChatMessagesQuery,
  useGetChatRoomQuery,
  useGetChatRoomsQuery,
  useGetSupportChatRoomQuery,
  useLeaveChatRoomMutation,
  useResolveSupportChatRoomMutation,
} from './queries/chatQueries';
import {
  usePrepareChatRoomData,
  usePrepareChatRoomListData,
} from './hooks/usePrepareChatRoomData';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  useGetConnectedHCPsByUserQuery,
  useGetConnectedUsersQuery,
} from '../Users/queries/usersQueries';
import {
  usePrepareGroupParticipantsDataHCPs,
  usePrepareGroupParticipantsDataUsers,
} from './hooks/usePrepareGroupParticipantsData';
import { usePrepareChatMessageListData } from './hooks/usePrepareChatMessageData';
import { emitReadMsg, emitSendMsg } from './socket';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    chatId?: string;
  }> {}

const CHAT_ROOMS_PER_PAGE = 15;
const MESSAGES_PER_PAGE = 25;

const Chat: FC<Props> = ({ match }) => {
  const router = useIonRouter();

  const { t } = useTranslation();

  const web = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreens = useMediaQuery(theme.breakpoints.down('lg'));

  const [roomType, setRoomType] = useState<string>('mixed');
  const [collapseChat, setCollapseChat] = useState<boolean>(mediumScreens);
  const [requestInfoModalOpen, setRequestInfoModalOpen] = useState<boolean>(false);
  const [createChatModalOpen, setCreateChatModalOpen] = useState<boolean>(false);

  const userId = useAppSelector(
    (state: RootState) => state.auth.data.user?.authUser?._id,
  );

  /* SUPPORT CHAT ROOM */
  const [createSupportChat] = useCreateSupportChatRoomMutation();
  const [resolveSupportChat] = useResolveSupportChatRoomMutation();
  const { data: supportChatRoomData } = useGetSupportChatRoomQuery();

  /* SELECTED CHAT ROOM */
  const { data: selectedChatRoomData } = useGetChatRoomQuery(
    { roomId: match.params.chatId ?? '' },
    { skip: !match.params.chatId || match.params.chatId === 'support' },
  );

  const selectedChatRoomType = useMemo(
    () =>
      match.params.chatId === 'support'
        ? supportChatRoomData?.chatRoom
        : match.params.chatId
        ? selectedChatRoomData
        : undefined,
    [match.params.chatId, selectedChatRoomData, supportChatRoomData?.chatRoom],
  );

  const selectedChatRoom = usePrepareChatRoomData(userId, selectedChatRoomType);

  /* CHAT ROOM LIST */
  const [chatRoomListSkip, setChatRoomListSkip] = useState<number>(0);
  const { data: chatRoomListData, isFetching: chatRoomListFetching } =
    useGetChatRoomsQuery({
      roomType: roomType as 'mixed' | 'hcp-only',
      skip: chatRoomListSkip,
      limit: CHAT_ROOMS_PER_PAGE,
    });
  const chatRoomList = usePrepareChatRoomListData(userId, chatRoomListData?.rooms);

  /* CHAT MESSAGE LIST */
  const [messageListSkip, setMessageListSkip] = useState<number>(0);
  const { data: chatMessageListData, isFetching: chatMessageListFetching } =
    useGetChatMessagesQuery(
      {
        roomId: selectedChatRoomType?._id ?? '',
        skip: messageListSkip,
        limit: MESSAGES_PER_PAGE,
      },
      { skip: !selectedChatRoomType?._id },
    );
  const chatMessageList = usePrepareChatMessageListData(
    userId,
    chatMessageListData?.messages,
    selectedChatRoomType?.participants,
  );

  /* CREATE CHAT */
  const [createChat] = useCreateChatRoomMutation();
  const { data: usersData } = useGetConnectedUsersQuery({ skip: 0, limit: 1000 });
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>('');
  const { data: healthProfsData } = useGetConnectedHCPsByUserQuery(
    {
      userId: selectedUser,
      skip: 0,
      limit: 1000,
    },
    { skip: !selectedUser },
  );
  const users = usePrepareGroupParticipantsDataUsers(usersData?.documents);
  const healthProfs = usePrepareGroupParticipantsDataHCPs(
    userId,
    healthProfsData?.documents,
    selectedSpeciality,
  );

  /* LEAVE CHAT */
  const [leaveChat] = useLeaveChatRoomMutation();

  const handleSendMessage = useCallback(
    (message: string) => {
      if (!selectedChatRoomType) {
        if (match.params.chatId === 'support') {
          createSupportChat({ message });
          console.log('Creating support chat', message);
        }
        return;
      }
      console.log('Sending message', selectedChatRoomType._id, message);
      emitSendMsg(selectedChatRoomType._id, message);
    },
    [createSupportChat, match.params.chatId, selectedChatRoomType],
  );

  const handleReadMessage = useCallback(() => {
    if (
      !selectedChatRoomType ||
      !chatMessageListData?.messages ||
      selectedChatRoomType._id !== chatMessageListData.messages?.[0]?.room
    )
      return;
    console.log('Reading message', selectedChatRoomType._id);
    emitReadMsg(selectedChatRoomType._id);
  }, [chatMessageListData?.messages, selectedChatRoomType]);

  useEffect(() => {
    handleReadMessage();
  }, [handleReadMessage]);

  const handleLoadMoreMessages = useCallback(() => {
    if (chatMessageListFetching) return;
    if (
      !chatMessageListData?.count ||
      chatMessageListData?.count <= messageListSkip + MESSAGES_PER_PAGE
    )
      return;
    setMessageListSkip(messageListSkip + MESSAGES_PER_PAGE);
  }, [chatMessageListData?.count, chatMessageListFetching, messageListSkip]);

  const handleCreateChat = useCallback(
    (users: string[]) => {
      const roomName = `room-${[...users, userId].sort().join('-')}`;
      createChat({ roomName, users });
      setCreateChatModalOpen(false);
    },
    [createChat, userId],
  );

  const handleLoadMoreChatRooms = useCallback(() => {
    if (chatRoomListFetching) return;
    if (
      !chatRoomListData?.count ||
      chatRoomListData?.count <= chatRoomListSkip + CHAT_ROOMS_PER_PAGE
    )
      return;
    setChatRoomListSkip(chatRoomListSkip + CHAT_ROOMS_PER_PAGE);
  }, [chatRoomListData?.count, chatRoomListFetching, chatRoomListSkip]);

  const handleLeaveChat = useCallback(() => {
    if (!match.params.chatId) return;
    leaveChat({ roomId: match.params.chatId });
    router.push('/dashboard/chat', 'back', 'replace');
  }, [leaveChat, match.params.chatId, router]);

  const handleResolveSupportChat = useCallback(() => {
    if (match.params.chatId !== 'support' || !supportChatRoomData?._id) return;
    resolveSupportChat({ supportRoomId: supportChatRoomData?._id });
    router.push('/dashboard/chat', 'back', 'replace');
  }, [match.params.chatId, resolveSupportChat, router, supportChatRoomData?._id]);

  const handleOpenMessage = (chatId: string) => {
    setMessageListSkip(0);
    router.push(`/dashboard/chat/${chatId}`);
  };

  const handleOpenSupport = () => {
    router.push(`/dashboard/chat/support`);
  };

  const handleAccept = () => console.log('Accept');
  const handleDecline = () => console.log('Decline');

  return (
    <PageLayout
      backgroundWhite
      headerTitle={t('chat.titles.chat')}
      endButtons={[
        {
          onClick: () => setCreateChatModalOpen(true),
          icon: <EditPencil width={'22px'} height={'22px'} />,
        },
      ]}
    >
      <Box
        hidden={mobile}
        height={'100%'}
        display={'flex'}
        bgcolor={theme.palette.secondary.light}
      >
        <Box
          display={'flex'}
          overflow={'hidden'}
          flexDirection={'column'}
          width={collapseChat ? 72 : 360}
          minWidth={collapseChat ? 72 : 360}
          borderTop={`1px solid ${theme.palette.secondary.main}`}
          borderLeft={`1px solid ${theme.palette.secondary.main}`}
          borderRight={`1px solid ${theme.palette.secondary.main}`}
        >
          <ChatSendersHeaderWeb
            collapsed={collapseChat}
            title={t('chat.labels.recent')}
            handleNewMessage={() => setCreateChatModalOpen(true)}
          />
          <Box padding={theme.spacing(1, 0.5, 2, 0.5)}>
            <ChatTabs
              value={roomType}
              shrink={collapseChat}
              setValue={setRoomType}
              tabs={[
                { label: t('general.labels.users'), value: 'mixed' },
                { label: t('healthProf.hcp.labels.hcp'), value: 'hcp-only' },
              ]}
            />
          </Box>
          <ChatPanel
            web
            collapse={collapseChat}
            chatRooms={chatRoomList}
            handleOpenMessage={handleOpenMessage}
            selectedChatRoom={match.params.chatId}
            handleLoadMoreChatRooms={handleLoadMoreChatRooms}
          />
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-end'}
            borderTop={`1px solid ${theme.palette.secondary.main}`}
            borderBottom={`1px solid ${theme.palette.secondary.main}`}
          >
            <IconButton
              onClick={() => setCollapseChat(!collapseChat)}
              sx={{
                margin: theme.spacing(1, 2),
                alignSelf: collapseChat ? 'center' : 'flex-end',
              }}
            >
              {collapseChat ? <AppBarExpandedIcon /> : <AppBarExpandMoreIcon />}
            </IconButton>
          </Box>
          <Box onClick={handleOpenSupport}>
            <ChatSendersSupport
              web={web}
              collapsed={collapseChat}
              unreadMessages={supportChatRoomData?.chatRoom?.unreadMessageCount ?? 0}
              selected={match.params.chatId === 'support'}
            />
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexGrow={1}
          borderTop={`1px solid ${theme.palette.secondary.main}`}
        >
          {selectedChatRoom ? (
            <ChatMessagePanelWeb
              title={
                match.params.chatId === 'support'
                  ? t('chat.titles.support')
                  : selectedChatRoom.title
              }
              messages={chatMessageList}
              group={selectedChatRoom.group}
              avatar={selectedChatRoom.avatar}
              handleSendMessage={handleSendMessage}
              support={match.params.chatId === 'support'}
              supportMessage={supportChatRoomData?.message}
              handleLoadMoreMessages={handleLoadMoreMessages}
              handleLeaveChat={
                match.params.chatId === 'support'
                  ? handleResolveSupportChat
                  : handleLeaveChat
              }
            >
              <Box width={'84px'} height={'84px'} bgcolor={'red'} />
              <Box width={'84px'} height={'84px'} bgcolor={'green'} />
              <Box width={'84px'} height={'84px'} bgcolor={'blue'} />
            </ChatMessagePanelWeb>
          ) : match.params.chatId === 'support' ? (
            <ChatMessagePanelWeb
              support={true}
              title={t('chat.titles.support')}
              handleSendMessage={handleSendMessage}
              handleLeaveChat={handleResolveSupportChat}
              supportMessage={supportChatRoomData?.message}
            >
              <Box width={'84px'} height={'84px'} bgcolor={'red'} />
              <Box width={'84px'} height={'84px'} bgcolor={'green'} />
              <Box width={'84px'} height={'84px'} bgcolor={'blue'} />
            </ChatMessagePanelWeb>
          ) : null}
        </Box>
      </Box>

      <Box hidden={web} height={'100%'} display={'flex'} flexDirection={'column'}>
        <Box padding={theme.spacing(1, 1.5)}>
          <ChatTabs
            tabs={[
              { label: t('general.labels.users'), value: 'mixed' },
              { label: t('healthProf.hcp.labels.hcp'), value: 'hcp-only' },
            ]}
            shrink={false}
            value={roomType}
            setValue={setRoomType}
          />
        </Box>
        <ChatPanel
          web={false}
          handleOpenMessage={handleOpenMessage}
          chatRooms={chatRoomList}
          handleLoadMoreChatRooms={handleLoadMoreChatRooms}
        />
        <Box onClick={handleOpenSupport}>
          <ChatSendersSupport web={false} unreadMessages={2} />
        </Box>
      </Box>
      <ModalMobile open={requestInfoModalOpen} setOpen={setRequestInfoModalOpen}>
        <RequestInfoModal
          username={'Thanos K.'}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
          target={t('dashboard.dashcards.medication.title')}
        />
      </ModalMobile>
      <ModalCreateChat
        users={users}
        healthProfs={healthProfs}
        open={createChatModalOpen}
        handleCreate={handleCreateChat}
        setOpen={setCreateChatModalOpen}
        onSelectedUser={setSelectedUser}
        onSelectedSpeciality={setSelectedSpeciality}
      />
    </PageLayout>
  );
};

export default Chat;
