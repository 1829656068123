import React, { FC, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import {
  InDepthDataCard,
  LipidChartCard,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import {
  useGetLipidGraphDataQuery,
  useGetOverallLipidQuery,
} from '../queries/lipidQueries';
import useAlert from '../../../hooks/useAlert';
import useTimeframe from '../../../hooks/useTimeframe';
import SectionHeading from '../../fitness/components/SectionHeading';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../utils/useConvertJSON';

type Props = {
  userId: string;
};
const AllMeasurementsSection: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: overallData, error: errorOverall } = useGetOverallLipidQuery({
    ...timeframe,
    userId,
  });
  const { data: lipidGraphData, error: lipidGraphError } = useGetLipidGraphDataQuery({
    userId,
    params: { metric: undefined, ...timeframe },
  });

  useEffect(() => {
    if (lipidGraphError) presentError(t('errors.generic.errorFetchGraph'));
    if (errorOverall) presentError(t('errors.generic.error01'));
  }, [lipidGraphError, errorOverall]);

  const chartData = useMemo(() => {
    if (!lipidGraphData) return;

    let totalChol: any[] = [],
      triglycerides: any[] = [],
      hdl: any[] = [],
      ldl: any[] = [],
      lpa: any[] = [];
    lipidGraphData.logs.forEach(item => {
      if (item.totalChol) {
        totalChol.push({
          x: item.logDate,
          y: item.totalChol,
        });
      }
      if (item.triglycerides) {
        triglycerides.push({
          x: item.logDate,
          y: item.triglycerides,
        });
      }
      if (item.hdl) {
        hdl.push({
          y: item.hdl,
          x: item.logDate,
        });
      }
      if (item.ldl) {
        ldl.push({
          y: item.ldl,
          x: item.logDate,
        });
      }
      if (item.lpa) {
        lpa.push({
          y: item.lpa,
          x: item.logDate,
        });
      }
    });

    return [
      {
        name: t('lipid.labels.totalChol'),
        data: totalChol,
      },
      {
        name: t('lipid.labels.tryg'),
        data: triglycerides,
      },
      {
        name: t('dashboard.dashcards.lipid.hdl'),
        data: hdl,
      },
      {
        name: t('dashboard.dashcards.lipid.ldl'),
        data: ldl,
      },
      {
        name: t('dashboard.dashcards.lipid.lpa'),
        data: lpa,
      },
    ];
  }, [lipidGraphData]);

  const chartOverallValues = useMemo(() => {
    if (!overallData) return;

    return [
      {
        label: t('lipid.labels.avgChol'),
        value: Math.round(overallData.totalChol?.avg) || '-',
      },
      { label: t('lipid.labels.avgHdl'), value: Math.round(overallData.hdl?.avg) || '-' },
      { label: t('lipid.labels.avgLdl'), value: Math.round(overallData.ldl?.avg) || '-' },
      {
        label: t('lipid.labels.avgTrg'),
        value: Math.round(overallData.triglycerides?.avg) || '-',
      },
      { label: t('lipid.labels.avgLpa'), value: Math.round(overallData.lpa?.avg) || '-' },
    ];
  }, [overallData]);

  const tableData = useMemo(() => {
    if (!overallData) return;

    return [
      {
        name: t('dashboard.dashcards.lipid.chol'),
        min: Math.round(overallData.totalChol?.min) || '-',
        max: Math.round(overallData.totalChol?.max) || '-',
        avg: Math.round(overallData.totalChol?.avg) || '-',
      },
      {
        name: t('dashboard.dashcards.lipid.trg'),
        min: Math.round(overallData.triglycerides?.min) || '-',
        max: Math.round(overallData.triglycerides?.max) || '-',
        avg: Math.round(overallData.triglycerides?.avg) || '-',
      },
      {
        name: t('dashboard.dashcards.lipid.hdl'),
        min: Math.round(overallData.hdl?.min) || '-',
        max: Math.round(overallData.hdl?.max) || '-',
        avg: Math.round(overallData.hdl?.avg) || '-',
      },
      {
        name: t('dashboard.dashcards.lipid.ldl'),
        min: Math.round(overallData.ldl?.min) || '-',
        max: Math.round(overallData.ldl?.max) || '-',
        avg: Math.round(overallData.ldl?.avg) || '-',
      },
      {
        name: t('dashboard.dashcards.lipid.lpa'),
        min: Math.round(overallData.lpa?.min) || '-',
        max: Math.round(overallData.lpa?.max) || '-',
        avg: Math.round(overallData.lpa?.avg) || '-',
      },
    ];
  }, [overallData]);

  return (
    <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <SectionHeading
          hasGoal={false}
          heading={t('constants.hypertension.measurements.all')}
        />
        <LipidChartCard
          chartData={chartData}
          toggleButtonsValue={''}
          values={chartOverallValues}
          handleChangeOption={() => console.log('')}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <SectionHeading heading={t('headingsTitles.overall')} hasGoal={false} />
        <InDepthDataCard
          profApp
          rows={tableData}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
          measurement={`${t('measurements.mgdl')}`}
        />
      </Box>
    </Box>
  );
};

export default AllMeasurementsSection;
