import Box from '@mui/material/Box';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  AllMeasurements,
  CalendarModal,
  ComponentsLayout,
  DownloadIcon,
  InDepthDataCard,
  MeasurementsContainer,
  ModalFilters,
  theme,
} from '@hdcorner/ui-library';
import InDepthDatePicker from '../../components/InDepthDatePicker';
import { Typography } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import { useGetHypertensionOverallQuery } from './queries/hypertensionQueries';
import LogsList from './LogsList';
import { MeasurementOptions } from './constants';
import useCategoryLabel from './hooks/useCategoryLabel';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import { RouteComponentProps } from 'react-router';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const HypertensionInDepthData: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);
  const [categorySelectModal, setCategorySelectModal] = useState<boolean>(false);

  // Used for updating the params for the query
  const [startParam, setStartParam] = useState<string>('');
  const [endParam, setEndParam] = useState<string>('');

  const { error, data: overallData } = useGetHypertensionOverallQuery({
    userId,
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
    category: selectedCategory === 'all' ? undefined : selectedCategory,
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.error01'));
  }, [error]);

  const prepareOverallData = useMemo(() => {
    return [
      {
        name: t('hypertension.labels.systolic'),
        min:
          overallData && overallData.systolic
            ? overallData.systolic.min.toFixed().toString()
            : '-',
        max:
          overallData && overallData.systolic
            ? overallData.systolic.max.toFixed().toString()
            : '-',
        avg:
          overallData && overallData.systolic
            ? overallData.systolic.avg.toFixed().toString()
            : '-',
      },
      {
        name: t('hypertension.labels.diastolic'),
        min:
          overallData && overallData.diastolic
            ? overallData.diastolic.min.toFixed().toString()
            : '-',
        max:
          overallData && overallData.diastolic
            ? overallData.diastolic.max.toFixed().toString()
            : '-',
        avg:
          overallData && overallData.diastolic
            ? overallData.diastolic.avg.toFixed().toString()
            : '-',
      },
      {
        name: t('dashboard.dashcards.hypertension.pulses'),
        min:
          overallData && overallData.pulses
            ? overallData.pulses.min.toFixed().toString()
            : '-',
        max:
          overallData && overallData.pulses
            ? overallData.pulses.max.toFixed().toString()
            : '-',
        avg:
          overallData && overallData.pulses
            ? overallData.pulses.avg.toFixed().toString()
            : '-',
      },
    ];
  }, [overallData]);

  const categoryLabel = t(useCategoryLabel(selectedCategory));

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.inDepth')}
      headerColor={theme.palette.primary.main}
      defaultHref={`/dashboard/users/${userId}/hypertension`}
      endButtons={[
        {
          icon: <DownloadIcon />,
          onClick: () => setDownloadDataModalOpen(true),
        },
      ]}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <MeasurementsContainer>
          <AllMeasurements
            flex={2}
            text={categoryLabel}
            openModal={() => setCategorySelectModal(true)}
          />
          <InDepthDatePicker flex={1} onClick={() => setCalendarModalOpen(true)} />
        </MeasurementsContainer>
        <InDepthDataCard
          rows={prepareOverallData}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
          measurement={`${t('measurements.mmGH')}`}
        />
        <Box>
          <Typography variant={'h1'} color={'primary'} marginBottom={1} marginTop={2}>
            {t('headingsTitles.latestUpdates')}
          </Typography>
          <LogsList
            userId={userId}
            endParam={endParam}
            startParam={startParam}
            selectedCategory={selectedCategory}
          />
        </Box>
      </ComponentsLayout>
      <ModalFilters
        open={categorySelectModal}
        selected={selectedCategory}
        options={useConvertJSON(MeasurementOptions)}
        dismiss={newValue => {
          setSelectedCategory(newValue);
          setCategorySelectModal(false);
        }}
      />
      <CalendarModal
        open={calendarModalOpen}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
        saveDate={date => handleDateChange(date)}
      />
      <ModalInDepthDataDL
        module={'hypertension'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </PageLayout>
  );
};

export default HypertensionInDepthData;
