import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  CalendarModal,
  CustomButton,
  CustomInput,
  ModalHeading,
  ModalMobile,
} from '@hdcorner/ui-library';
import { useGetUserGoalWeightQuery } from '../queries/weightQueries';
import moment from 'moment/moment';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type ModalSliderWeightProps = {
  log: boolean;
  open: boolean;
  userId: string;
  setOpen: (open: boolean) => void;
};
const ModalSliderWeight: FC<ModalSliderWeightProps> = ({
  log,
  open,
  userId,
  setOpen,
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { error, data: getGoalRes } = useGetUserGoalWeightQuery({
    userId,
  });

  useEffect(() => {
    if (error) presentError(t('errors.fitness.wellness.errorWeightGoalFetch'));
  }, [error]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return 0;
    return getGoalRes[0].goal.measurement;
  }, [getGoalRes]);

  const [date, setDate] = useState<string>(moment().toISOString());
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [selectedWeight, setSelectedWeight] = useState<string>(goalData.toString());

  return (
    <ModalMobile open={open} setOpen={setOpen} width={'400px'}>
      <Box width={'100%'} padding={2}>
        <ModalHeading
          align={'left'}
          showCalendar={log ? 'flex' : 'none'}
          title={
            log
              ? t('fitness.wellness.labels.addWeightLog')
              : !getGoalRes || getGoalRes.length === 0
              ? t('fitness.wellness.labels.addWeightGoal')
              : t('fitness.wellness.labels.editWeightGoal')
          }
          headingButton={log ? `${t('buttons.customDate')}` : ''}
          handleCalendarClick={log ? () => setOpenPicker(true) : undefined}
        />
        <Box mb={3}>
          <CustomInput
            fullWidth
            type={'number'}
            value={selectedWeight}
            handleChange={setSelectedWeight}
            label={`${t('measurements.weightIn')}`}
            placeholder={`${t('medication.labels.enterAmount')}`}
          />
        </Box>
        <CustomButton fullWidth variant={'contained'} children={t('buttons.saveData')} />
      </Box>
      <CalendarModal
        open={openPicker}
        selectedDate={date}
        setOpen={setOpenPicker}
        saveDate={date => setDate(date)}
      />
    </ModalMobile>
  );
};

export default ModalSliderWeight;
