import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InfoBox = styled(Box, { shouldForwardProp: propName => propName !== 'show' })<
  BoxProps & { show: boolean }
>(({ show, theme }) => ({
  zIndex: 99,
  width: '208px',
  borderRadius: '8px',
  position: 'absolute',
  boxSizing: 'border-box',
  boxShadow: theme.shadows[1],
  display: show ? 'flex' : 'none',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
}));

type Props = {
  top: number;
  right: number;
  display: boolean;
};

const EshInfo: FC<Props> = ({ top, right, display }) => {
  const { t } = useTranslation();
  return (
    <InfoBox show={display} top={top} right={right}>
      <Typography variant={'body2'}>
        {t('hypertension.esh.instructions.01')}
        <br />
        <br />
        {t('hypertension.esh.instructions.02')}
        <br />
        <br />
        {t('hypertension.esh.instructions.03')}
        <br />
        <br />
        {t('hypertension.esh.instructions.04')}
      </Typography>
    </InfoBox>
  );
};

export default EshInfo;
