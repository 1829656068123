import { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { DeleteCircleIcon } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps>(() => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const TextMain = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'left',
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

const TextSecondary = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'left',
  marginRight: theme.spacing(1),
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
}));

type FoodItemsProps = {
  cal?: number;
  title: string;
  icon?: ReactNode;
  quantity?: number;
  onClick?: () => void;
};

const FoodItems: FC<FoodItemsProps> = ({ cal, icon, title, onClick, quantity }) => {
  const { t } = useTranslation();

  return (
    <BoxMain>
      <Box>
        <TextMain>{title}</TextMain>
        <TextSecondary>
          {quantity ? `${quantity}${t('measurements.gr')}` : `100${t('measurements.gr')}`}
        </TextSecondary>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <TextSecondary>{`${cal} ${t('measurements.kcal')}`}</TextSecondary>
        <IconButton onClick={onClick} sx={{ padding: 0 }}>
          {icon ? icon : <DeleteCircleIcon />}
        </IconButton>
      </Box>
    </BoxMain>
  );
};

export default FoodItems;
