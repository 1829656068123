import React, { FC, useEffect, useMemo } from 'react';
import PageLayout from '../../components/PageLayout';
import { ComponentsLayout, GoalDetailsCard } from '@hdcorner/ui-library';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
} from './queries/lipidQueries';
import { RouteComponentProps } from 'react-router';
import { Box } from '@mui/material';
import moment from 'moment';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
    type: string;
  }> {}
const LipidGoalScreen: FC<Props> = ({
  match: {
    params: { userId, type },
  },
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { data: lastLog, error: lastLogError } = useGetLipidLastLogQuery({
    userId,
  });
  const { data: lipidGoal, error: lipidGoalError } = useGetLipidGoalQuery(
    { userId, goalType: type },
    {
      skip: !type,
    },
  );
  const { data: firstLog, error: firstLogError } = useGetLipidFirstLogQuery(
    {
      userId,
      startingDate: lipidGoal && lipidGoal.length > 0 ? lipidGoal[0].startDate : '',
    },
    { skip: !lipidGoal || lipidGoal.length === 0 },
  );

  useEffect(() => {
    if (lipidGoalError) {
      presentError(t('errors.generic.errorFetchGoal'));
    }
    if (firstLogError || lastLogError) {
      presentError(t('errors.generic.errorFetchLog'));
    }
  }, [firstLogError, lipidGoalError, lastLogError]);

  const getGoal = useMemo(() => {
    if (!lipidGoal || lipidGoal.length === 0) return [];

    const goalData = lipidGoal[0];
    const latestLogData: any = lastLog?.documents[0];
    const startingLogData: any = firstLog?.documents[0];

    return [
      {
        label: t('headingsTitles.goal'),
        value: goalData.goal.measurement + ' ' + t('measurements.mgdl'),
      },
      {
        label: t('goals.start'),
        value: moment(goalData.startDate).format('MMM DD, YYYY'),
      },
      {
        label: t('goals.startingMeasurement'),
        value: startingLogData
          ? startingLogData[type] + ' ' + t('measurements.mgdl')
          : t('general.labels.nA'),
      },
      {
        label: t('goals.latestMeasurement'),
        value: latestLogData
          ? latestLogData[type] + ' ' + t('measurements.mgdl')
          : t('general.labels.nA'),
      },
    ];
  }, [firstLog?.documents, lipidGoal, lastLog?.documents, type]);

  return (
    <PageLayout
      headerTitle={t('headingsTitles.goal')}
      defaultHref={`/dashboard/users/${userId}/lipid`}
    >
      <ComponentsLayout>
        {getGoal.map((item, index) => (
          <Box key={`${item}${index}`} mt={index === 2 ? 3 : 0} py={1}>
            <GoalDetailsCard goal={item} />
          </Box>
        ))}
      </ComponentsLayout>
    </PageLayout>
  );
};

export default LipidGoalScreen;
