import { FC } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { CustomButton, theme } from '@hdcorner/ui-library';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const ViewButton = styled(CustomButton, {
  shouldForwardProp: propName => propName !== 'display',
})<ButtonProps & { display: boolean }>(({ theme, display }) => ({
  display: display ? 'block' : 'none',
  color: theme.palette.medication.main,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.secondary.main,
}));

type SectionHeadingProps = {
  heading: string;
  hasGoal: boolean;
  viewMoreClick?: () => void;
};

const SectionHeading: FC<SectionHeadingProps> = ({ heading, viewMoreClick }) => {
  const web = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <Box flexGrow={1} display={'flex'} justifyContent={'space-between'}>
      <Typography
        variant={'subtitle1'}
        sx={{ fontWeight: theme.typography.fontWeightMedium }}
        textTransform={web || lang === 'en' ? 'uppercase' : 'capitalize'}
      >
        {heading}
      </Typography>
      <ViewButton
        variant={'text'}
        onClick={viewMoreClick}
        display={web ? false : !!viewMoreClick}
      >
        {t('buttons.navigation.viewMore')}
      </ViewButton>
    </Box>
  );
};

export default SectionHeading;
