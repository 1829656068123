import PageLayout from '../../../components/PageLayout';
import { ClickNavCardNutrition, ComponentsLayout } from '@hdcorner/ui-library';
import SectionGraph from './components/SectionGraph';
import { useIonRouter } from '@ionic/react';
import { Box } from '@mui/material';
import { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const DailyCalories: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const handleNextPage = () => {
    router.push(`/dashboard/users/${userId}/fitness/calories/in-depth-data`);
  };
  return (
    <PageLayout
      headerTitle={t('headingsTitles.dailyCal')}
      defaultHref={`/dashboard/users/${userId}/fitness`}
    >
      <ComponentsLayout>
        <SectionGraph toggle heading={false} userId={userId} />
        <Box mb={3}></Box>
        <ClickNavCardNutrition
          onClick={handleNextPage}
          headings={t('buttons.navigation.inDepth')}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DailyCalories;
