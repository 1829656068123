import api from '../../../redux/api';
import { UserSettings } from '../types/UserSettings';

const userSettingsQueries = api
  .enhanceEndpoints({ addTagTypes: ['UserSettings'] })
  .injectEndpoints({
    endpoints: build => ({
      createUserSettings: build.mutation<
        UserSettings,
        Omit<UserSettings, '_id' | 'createdAt'>
      >({
        query: ({ selectedLang, generalNotifications }) => ({
          url: '/database/function/createUserSettings',
          method: 'POST',
          body: {
            selectedLang,
            generalNotifications,
          },
        }),
        invalidatesTags: ['UserSettings'],
      }),

      getUserSettings: build.query<UserSettings[], void>({
        query: () => ({
          method: 'GET',
          url: '/database/function/getUserSettings',
        }),
        providesTags: ['UserSettings'],
      }),

      updateLanguageSettings: build.mutation<UserSettings[], { selectedLang: string }>({
        query: ({ selectedLang }) => ({
          method: 'PUT',
          body: { selectedLang },
          url: '/database/function/editUserSettings',
        }),
        invalidatesTags: ['UserSettings'],
      }),

      updateNotificationsSettings: build.mutation<
        UserSettings,
        {
          generalNotifications: boolean;
          knowledgeNotifications: boolean;
          otherMessagesNotifications: boolean;
          clinicalCoachNotifications: boolean;
        }
      >({
        query: ({
          generalNotifications,
          knowledgeNotifications,
          otherMessagesNotifications,
          clinicalCoachNotifications,
        }) => ({
          url: '/database/function/editUserSettings',
          method: 'PUT',
          body: {
            generalNotifications,
            knowledgeNotifications,
            otherMessagesNotifications,
            clinicalCoachNotifications,
          },
        }),
        invalidatesTags: ['UserSettings'],
      }),
    }),
  });

export const {
  useGetUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  useCreateUserSettingsMutation,
  useUpdateLanguageSettingsMutation,
  useUpdateNotificationsSettingsMutation,
} = userSettingsQueries;

export default userSettingsQueries;
