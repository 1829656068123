import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import SectionHeading from '../../components/SectionHeading';
import LunchIcon from '../../../../assets/icons/LunchIcon';
import SnackIcon from '../../../../assets/icons/SnackIcon';
import DinnerIcon from '../../../../assets/icons/DinnerIcon';
import BreakfastIcon from '../../../../assets/icons/BreakfastIcon';
import { useIonRouter } from '@ionic/react';
import MealCard from './MealCard';
import { useGetMealsOfDayQuery } from '../queries/foodsQueries';
import moment from 'moment/moment';
import { findMeal, totalValue } from '../utilities/getMealObject';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
};
const SectionNutrition: React.FC<Props> = ({ userId }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { error, data: mealLogRes } = useGetMealsOfDayQuery({
    userId,
    end: moment().utc(true).endOf('day').toDate().toISOString(),
    start: moment().utc(true).startOf('day').toDate().toISOString(),
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.error01'));
  }, [error]);

  const breakfastList = useMemo(() => {
    const breakfast = findMeal('breakfast', mealLogRes);

    return {
      icon: <BreakfastIcon />,
      meals: breakfast ? '1' : '0',
      id: breakfast ? breakfast._id : '1',
      color: theme.palette.hypertension.main,
      category: t('fitness.nutrition.labels.breakfast'),
      cal: !mealLogRes ? '' : totalValue(breakfast, 'calories').toString(),
    };
  }, [mealLogRes]);

  const lunchList = useMemo(() => {
    const lunch = findMeal('lunch', mealLogRes);

    return {
      icon: <LunchIcon />,
      meals: lunch ? '1' : '0',
      id: lunch ? lunch._id : '2',
      color: theme.palette.medication.main,
      category: t('fitness.nutrition.labels.lunch'),
      cal: !mealLogRes ? '' : totalValue(lunch, 'calories').toString(),
    };
  }, [mealLogRes]);

  const dinnerList = useMemo(() => {
    const dinner = findMeal('dinner', mealLogRes);

    return {
      icon: <DinnerIcon />,
      meals: dinner ? '1' : '0',
      id: dinner ? dinner._id : '3',
      color: theme.palette.nutrition.main,
      category: t('fitness.nutrition.labels.dinner'),
      cal: !mealLogRes ? '' : totalValue(dinner, 'calories').toString(),
    };
  }, [mealLogRes]);

  const snacksList = useMemo(() => {
    const snacks = findMeal('snacks', mealLogRes);

    return {
      icon: <SnackIcon />,
      meals: snacks ? '1' : '0',
      id: snacks ? snacks._id : '4',
      color: theme.palette.diabetes.main,
      category: t('fitness.nutrition.labels.snack'),
      cal: !mealLogRes ? '' : totalValue(snacks, 'calories').toString(),
    };
  }, [mealLogRes]);

  const mealCardsList = useMemo(() => {
    return [breakfastList, lunchList, dinnerList, snacksList];
  }, [mealLogRes]);

  const handleViewMore = () => {
    router.push(`/dashboard/users/${userId}/fitness/nutrition`);
  };

  return (
    <Box gap={1} flexGrow={1} display={'flex'} flexDirection={'column'}>
      <SectionHeading
        hasGoal={false}
        heading={t('headingsTitles.nutrition')}
        viewMoreClick={handleViewMore}
      />
      <Box display={'flex'} flexGrow={1} gap={1} flexWrap={'wrap'}>
        {mealCardsList.map(it => (
          <MealCard
            key={it.id}
            cal={it.cal}
            icon={it.icon}
            color={it.color}
            meals={it.meals}
            title={it.category}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SectionNutrition;
