import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flex: 1,
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  minWidth: web ? '212px' : '170px',
  backgroundColor: theme.palette.secondary.light,
}));

const TypographyTitle = styled(Typography)<
  TypographyProps & { title: string; color: string }
>(({ theme, color }) => ({
  textTransform: 'capitalize',
  fontWeight: theme.typography.fontWeightMedium,
  color: color ? color : theme.palette.primary.main,
}));

const TypographyData = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

type MealCardProps = {
  cal: string;
  color: string;
  title: string;
  meals: string;
  icon: React.ReactNode;
};
const MealCard: FC<MealCardProps> = ({ cal, icon, color, meals, title }) => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <BoxMain web={web}>
      <Box
        flex={'1 0 25%'}
        display={'flex'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        marginBottom={theme.spacing(2)}
      >
        <Box gap={1} display={'flex'} alignItems={'center'}>
          {icon}
          <TypographyTitle
            color={color}
            variant={'subtitle2'}
            title={title.toLowerCase()}
          >
            {title}
          </TypographyTitle>
        </Box>
      </Box>
      <Box>
        <TypographyData>
          {cal ? cal : '0'} {t('measurements.kcal')}
        </TypographyData>
        <TypographyData>
          {meals && parseInt(meals) > 1
            ? t('fitness.nutrition.text.meals', { amount: meals })
            : meals && parseInt(meals) === 1
            ? t('fitness.nutrition.text.meal', { amount: meals })
            : t('fitness.nutrition.text.noMeals')}
        </TypographyData>
      </Box>
    </BoxMain>
  );
};

export default MealCard;
