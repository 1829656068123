import { Box, useMediaQuery } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import OnBoardingPanelMobile from './components/OnBoardingPanelMobile';
import OnBoardingPanelWeb from './components/OnBoardingPanelWeb';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';
import PageLayout from '../../../components/PageLayout';

const OnBoarding = () => {
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageLayout backgroundWhite>
      <Box hidden={mobile} display={'flex'} height={'100%'}>
        <Box>
          <OnBoardingPanelWeb />
        </Box>
        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        />
      </Box>
      <Box hidden={web}>
        <OnBoardingPanelMobile />
      </Box>
    </PageLayout>
  );
};

export default OnBoarding;
