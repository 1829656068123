import TandCPanel from './components/TandCPanel';
import { ComponentsLayout, theme } from '@hdcorner/ui-library';
import { Box, useMediaQuery } from '@mui/material';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';
import PageLayout from '../../../components/PageLayout';

const TermsAndConditionsPage = () => {
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageLayout backgroundWhite>
      <Box hidden={mobile} display={'flex'} height={'100%'}>
        <Box
          height={'100%'}
          minWidth={'376px'}
          position={'relative'}
          boxSizing={'border-box'}
          bgcolor={theme.palette.secondary.light}
          padding={theme.spacing(7.25, 13, 3.5, 13)}
        >
          <TandCPanel web />
        </Box>

        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        ></Box>
      </Box>

      <Box hidden={web}>
        <ComponentsLayout height={'100%'}>
          <TandCPanel web={false} />
        </ComponentsLayout>
      </Box>
    </PageLayout>
  );
};

export default TermsAndConditionsPage;
