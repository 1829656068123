import * as React from 'react';
import { FC, useState } from 'react';
import {
  ComponentsLayout,
  CustomTabs,
  HypertensionSQ,
  theme,
} from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import HypertensionPressure from './HypertensionPressure';
import HypertensionPulses from './HypertensionPulses';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const Hypertension: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <PageLayout
      headerColor={theme.palette.hypertension.main}
      defaultHref={`/dashboard/users/${userId}/overview`}
      headerTitle={t('dashboard.dashcards.hypertension.title')}
      headerIcon={<HypertensionSQ color={theme.palette.hypertension.main} />}
    >
      <ComponentsLayout disablePaddingBottom marginBottom={theme.spacing(1)}>
        <CustomTabs
          value={selectedTab}
          setValue={setSelectedTab}
          tabs={[
            {
              label: t('hypertension.labels.pressure'),
              children: <HypertensionPressure userId={userId} />,
            },
            {
              label: t('dashboard.dashcards.hypertension.pulses'),
              children: <HypertensionPulses userId={userId} />,
            },
          ]}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Hypertension;
