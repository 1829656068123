import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';
import SectionNutrition from './components/SectionNutrition';
import FoodEquivalentsInner from '../components/FoodEquivalentsInner';
import SectionNutritionCal from './components/SectionNutritionCal';
import { theme } from '@hdcorner/ui-library';
import TrafficLightTabsList from './components/TrafficLightTabsList';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  overflow: 'scroll',
  flexDirection: 'column',
  gap: theme.spacing(3),
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const SubText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

interface Props {
  userId: string;
}
const WebNutrition: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();

  return (
    <BoxMain>
      <Box flex={1} gap={3} display={'flex'} flexWrap={'wrap'}>
        <Box flex={'1 0 47%'} gap={3} display={'flex'} flexDirection={'column'}>
          <SectionNutrition userId={userId} />
          <FoodEquivalentsInner userId={userId} />
        </Box>
        <Box flex={'1 0 47%'}>
          <SectionNutritionCal userId={userId} />
        </Box>
      </Box>

      <Box gap={1} display={'flex'} flex={1} flexDirection={'column'}>
        <Typography
          variant={'subtitle1'}
          sx={{ fontWeight: theme.typography.fontWeightMedium }}
          textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
        >
          {t('headingsTitles.tipsTricks')}
        </Typography>
        <SubText>{t('headingsTitles.trafficLights')}</SubText>
        <Box display={'flex'} gap={3}>
          <TrafficLightTabsList userId={userId} />
        </Box>
      </Box>
    </BoxMain>
  );
};

export default WebNutrition;
