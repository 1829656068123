import { useTranslation } from 'react-i18next';

const useConvertJSON = (array: { label: string; value: string }[]) => {
  const { t } = useTranslation();

  return array.map(data => {
    return {
      label: t(data.label),
      value: data.value,
    };
  });
};

const useConvertJSONAndBoolean = (
  array: { label: string; value: string; disabled: boolean }[],
) => {
  const { t } = useTranslation();

  return array.map(data => {
    return {
      label: t(data.label),
      value: data.value,
      disabled: data.disabled,
    };
  });
};

const useConvertJsonString = (array: string[]) => {
  const { t } = useTranslation();
  return array.map((data: string) => {
    return t(data);
  });
};

export { useConvertJSON, useConvertJsonString, useConvertJSONAndBoolean };
