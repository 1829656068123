import React, { FC, useState } from 'react';
import { ComponentsLayout, DownloadIcon } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../../components/PageLayout';
import SectionSleepData from './components/SectionSleepData';
import SectionWeightData from './components/SectionWeightData';
import SectionStepsData from '../exercise/components/SectionStepsData';
import ModalInDepthDataDL from '../../../components/ModalInDepthDataDL';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import SectionActivitiesData from '../exercise/components/SectionActivitiesData';

interface Props
  extends RouteComponentProps<{
    userId: string;
    id?: string;
  }> {}
const WellnessInDepthData: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const pathname = router.routeInfo.pathname.split('/').slice(-1).toString();

  const [open, setOpen] = useState<boolean>(false);
  const [module, setModule] = useState<'sleep' | 'weight' | 'steps' | 'activities'>(
    'weight',
  );

  const handleDLData = (value: 'sleep' | 'weight' | 'steps' | 'activities') => {
    setModule(value);
    setOpen(true);
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.inDepth')}
      defaultHref={`/dashboard/users/${userId}/fitness`}
      endButtons={[
        {
          icon: <DownloadIcon width={'18px'} height={'20px'} />,
          onClick:
            pathname === 'steps'
              ? () => handleDLData('steps')
              : pathname === 'weight'
              ? () => handleDLData('weight')
              : pathname === 'sleep'
              ? () => handleDLData('sleep')
              : pathname === 'activities'
              ? () => handleDLData('activities')
              : () => {},
        },
      ]}
    >
      <ComponentsLayout>
        {pathname === 'steps' ? (
          <SectionStepsData userId={userId} />
        ) : pathname === 'weight' ? (
          <SectionWeightData userId={userId} />
        ) : pathname === 'sleep' ? (
          <SectionSleepData userId={userId} />
        ) : pathname === 'activities' ? (
          <SectionActivitiesData userId={userId} />
        ) : null}
      </ComponentsLayout>
      <ModalInDepthDataDL open={open} module={module} dismiss={() => setOpen(false)} />
    </PageLayout>
  );
};

export default WellnessInDepthData;
