import api from '../../../redux/api';
import { ApiResult } from '@hdcorner/ui-library';

const signUpQueries = api.injectEndpoints({
  endpoints: build => ({
    signUp: build.mutation<ApiResult<string>, { email: string; password: string }>({
      query: ({ email, password }) => ({
        method: 'POST',
        url: 'authentication/local/new',
        body: {
          email,
          password,
          redirectUri: process.env.REACT_APP_VERIFICATION_REDIRECT_URI,
        },
      }),
    }),
  }),
});

export const { useSignUpMutation } = signUpQueries;

export default signUpQueries;
