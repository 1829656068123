import api from '../api';

const queries = api.injectEndpoints({
  endpoints: build => ({
    downloadInDepthData: build.mutation<
      string,
      {
        end?: string;
        module: string;
        start?: string;
        format: 'pdf' | 'excel';
        diabetesCategory?: string;
        hypertensionCategory?: string;
      }
    >({
      query: ({
        end,
        start,
        module,
        format,
        diabetesCategory,
        hypertensionCategory,
      }) => ({
        url: `hdCornerService/user/file/pdf-excel`,
        method: 'POST',
        params: { module, format, start, end, diabetesCategory, hypertensionCategory },
      }),
    }),
    notifyUser: build.mutation<
      string,
      { userId: string; payload: { title: string; body: string } }
    >({
      query: ({ userId, payload }) => ({
        method: 'POST',
        body: { ...payload, id: userId },
        url: `hdCornerService/hcp/user/${userId}/notification`,
      }),
    }),
  }),
});

export const { useDownloadInDepthDataMutation, useNotifyUserMutation } = queries;

export default queries;
