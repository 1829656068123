import React, { FC, useEffect, useMemo } from 'react';
import SectionHeading from '../../components/SectionHeading';
import { Box, BoxProps, styled, useMediaQuery } from '@mui/material';
import { GoalDetailsCard, theme } from '@hdcorner/ui-library';
import {
  useGetLatestStepsLogsQuery,
  useGetUserGoalStepsQuery,
} from '../queries/stepsQueries';
import moment from 'moment';
import { calculateLogs } from '../utils/calculateLogs';
import useAlert from '../../../../hooks/useAlert';
import { useGetTodaysActivityLogsQuery } from '../queries/activitiesQueries';
import { useTranslation } from 'react-i18next';

const BoxInner = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: web ? 0 : theme.spacing(1),
  padding: theme.spacing(web ? 1 : 0),
  backgroundColor: web ? theme.palette.secondary.light : 'transparent',
}));

type Props = {
  userId: string;
};
const SectionGoalSteps: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { data: getGoalRes, error: errorGoal } = useGetUserGoalStepsQuery({
    userId,
  });
  const { data: logsData, error: errorLog } = useGetTodaysActivityLogsQuery({
    userId,
  });
  const { data: lastLogData, error: errorLastLog } = useGetLatestStepsLogsQuery({
    userId,
  });

  useEffect(() => {
    if (errorGoal || errorLog || errorLastLog) presentError(t('errors.generic.error01'));
  }, [errorGoal, errorLog, errorLastLog]);

  const lastLog = useMemo(() => {
    if (!lastLogData || lastLogData.documents.length === 0) return '-';
    return lastLogData.documents[0].steps;
  }, [lastLogData]);

  const allLogs = useMemo(() => {
    if (!logsData) return 0;
    return calculateLogs(logsData, 'steps');
  }, [logsData]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return [];

    const progress = Math.round((allLogs / getGoalRes[0].goal.steps) * 100).toString();

    return [
      {
        label: t('headingsTitles.goal'),
        value: `${getGoalRes[0].goal.steps} ${t('headingsTitles.steps')}`,
      },
      {
        label: t('goals.start'),
        value: moment(getGoalRes[0].startDate).format('MMMM Do, YYYY'),
      },
      {
        label: t('goals.latestMeasurement'),
        value: `${lastLog} ${t('headingsTitles.steps')}`,
      },
      {
        label: t('goals.progress'),
        value: `${progress}%`,
      },
    ];
  }, [allLogs, getGoalRes, lastLog]);

  return (
    <Box gap={1} display={'flex'} width={'100%'} flexDirection={'column'}>
      <Box display={web ? 'block' : 'none'}>
        <SectionHeading
          heading={t('headingsTitles.goal')}
          hasGoal={!!(getGoalRes && getGoalRes.length > 0)}
        />
      </Box>
      <BoxInner web={web}>
        {goalData.map((it, index) => (
          <Box key={`${it}${index}`}>
            <GoalDetailsCard goal={it} />
          </Box>
        ))}
      </BoxInner>
    </Box>
  );
};

export default SectionGoalSteps;
