import { CustomButton, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  nextStepHandle: () => void;
};
const DietCalculatorInitial: FC<Props> = ({ nextStepHandle }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant={'subtitle1'}
        sx={{
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        {t('headingsTitles.personalDietCal')}
      </Typography>
      <Typography
        variant={'subtitle2'}
        sx={{
          color: theme.palette.kmColorsCoolGrey.main,
          fontWeight: theme.typography.fontWeightRegular,
          margin: theme.spacing(3, 0, 2, 0),
        }}
      >
        {t('fitness.dietPlan.instructions')}
      </Typography>
      <CustomButton
        fullWidth
        variant={'contained'}
        onClick={() => nextStepHandle()}
        children={t('buttons.letsStart')}
      />
    </Box>
  );
};

export default DietCalculatorInitial;
