import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { RadioGroup, RadioSingle, theme } from '@hdcorner/ui-library';

const BoxMain = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flexGrow: 1,
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: web ? 0 : theme.spacing(1),
  backgroundColor: web ? theme.palette.highlight.main : theme.palette.secondary.light,
}));

const BoxSecondary = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flexGrow: 1,
  borderRadius: '8px',
  marginRight: web ? theme.spacing(3) : 0,
  marginBottom: web ? 0 : theme.spacing(1),
  backgroundColor: web ? theme.palette.secondary.main : theme.palette.secondary.light,
  '&:last-of-type': {
    marginRight: 0,
    marginBottom: 0,
  },
}));

const TitleText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

type RadioSectionsProps = {
  title: string;
  value: string;
  radioGroup: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  data: { value: string; choice: string }[];
};

const RadioSections: FC<RadioSectionsProps> = ({
  data,
  title,
  value,
  onChange,
  radioGroup,
}) => {
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box>
      <TitleText>{title}</TitleText>
      <BoxMain web={web}>
        <RadioGroup row={web} value={value} onChange={onChange} name={radioGroup}>
          {data.map(it => (
            <BoxSecondary web={web}>
              <RadioSingle bold key={it.value} value={it.value} label={it.choice} />
            </BoxSecondary>
          ))}
        </RadioGroup>
      </BoxMain>
    </Box>
  );
};

export default RadioSections;
