import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useOverallData = (overallData?: { min: number; max: number; avg: number }) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!overallData)
      return [
        { name: 'MAX', value: t('general.labels.nA') },
        { name: 'MIN', value: t('general.labels.nA') },
        { name: 'AVG', value: t('general.labels.nA') },
      ];

    const { max, min, avg } = overallData;

    return [
      { name: 'MAX', value: max ? max.toFixed() : t('general.labels.nA') },
      { name: 'MIN', value: min ? min.toFixed() : t('general.labels.nA') },
      { name: 'AVG', value: avg ? avg.toFixed() : t('general.labels.nA') },
    ];
  }, [overallData]);
};

export default useOverallData;
