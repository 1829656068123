import api from '../../../redux/api';
import {
  ApiPagination,
  BloodGlucoseLog,
  DiabetesGoal,
  Hbac1Log,
  PaginationResult,
} from '@hdcorner/ui-library';

const diabetesQueries = api
  .enhanceEndpoints({ addTagTypes: ['DiabetesGoal', 'BloodGlucoseLog', 'Hbac1Log'] })
  .injectEndpoints({
    endpoints: build => ({
      getBloodGlucoseLogs: build.query<
        PaginationResult<BloodGlucoseLog>,
        {
          params: ApiPagination & {
            end?: string;
            start?: string;
            userId: string;
            category?: string;
          };
        }
      >({
        query: ({ params: { userId, start, end, ...rest } }) => ({
          method: 'GET',
          url: `database/function/getBloodGlucoseLogs`,
          params: {
            ...rest,
            endDate: end,
            startingDate: start,
            sort: ['-logDate'],
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['BloodGlucoseLog', 'Hbac1Log'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
      }),
      getHbac1Logs: build.query<
        PaginationResult<Hbac1Log>,
        { params: ApiPagination & { userId: string; start?: string; end?: string } }
      >({
        query: ({ params: { userId, start, end, ...rest } }) => ({
          method: 'GET',
          url: `database/function/getHbacLogs`,
          params: {
            ...rest,
            endDate: end,
            startingDate: start,
            sort: ['-logDate'],
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['BloodGlucoseLog', 'Hbac1Log'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
      }),
      getBloodGlucoseGoal: build.query<DiabetesGoal[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: `database/function/getUserGoal`,
          params: { goalType: 'blood_glucose', scope: `Goal:${userId}` },
        }),
        providesTags: ['DiabetesGoal'],
      }),
      getHbacGoal: build.query<DiabetesGoal[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: `database/function/getUserGoal`,
          params: { goalType: 'hbac1', scope: `Goal:${userId}` },
        }),
        providesTags: ['DiabetesGoal'],
      }),
      getBloodGlucoseOverall: build.query<
        { min: number; max: number; avg: number },
        { category?: string; start?: string; end?: string; userId: string }
      >({
        query: ({ category, start, end, userId }) => ({
          method: 'GET',
          params: { category, start, end, userId },
          url: `hdCornerService/user/blood-glucose/overall`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getBloodGlucoseCurrentMeasurement: build.query<
        PaginationResult<BloodGlucoseLog>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          method: 'GET',
          url: `database/function/getBloodGlucoseLogs`,
          params: {
            skip: 0,
            limit: 1,
            sort: ['-logDate'],
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHbac1CurrentMeasurement: build.query<
        PaginationResult<Hbac1Log>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          method: 'GET',
          url: `database/function/getHbacLogs`,
          params: {
            skip: 0,
            limit: 1,
            sort: ['-logDate'],
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getBloodGlucoseStartingMeasurement: build.query<
        PaginationResult<BloodGlucoseLog>,
        { startDate: string; userId: string }
      >({
        query: ({ startDate, userId }) => ({
          method: 'GET',
          url: `database/function/getBloodGlucoseLogs`,
          params: {
            skip: 0,
            limit: 1,
            sort: ['logDate'],
            startingDate: startDate,
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHbac1StartingMeasurement: build.query<
        PaginationResult<Hbac1Log>,
        { startDate: string; userId: string }
      >({
        query: ({ startDate, userId }) => ({
          method: 'GET',
          url: `database/function/getHbacLogs`,
          params: {
            skip: 0,
            limit: 1,
            sort: ['logDate'],
            startingDate: startDate,
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHbac1OverallData: build.query<
        { min: number; max: number; avg: number },
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          params: { start, end, userId },
          url: `hdCornerService/user/hbac/overall`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getBloodGlucoseGraphData: build.query<
        { logs: BloodGlucoseLog[] },
        { category?: string; start?: string; end?: string; userId: string }
      >({
        query: ({ userId, ...rest }) => ({
          method: 'GET',
          params: { ...rest, userId },
          url: `hdCornerService/user/blood-glucose/graph`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHcac1GraphData: build.query<
        { logs: Hbac1Log[] },
        { start?: string; end?: string; userId: string }
      >({
        query: ({ userId, start, end }) => ({
          method: 'GET',
          params: { start, end, userId },
          url: `hdCornerService/user/hbac/graph`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      deleteBloodGlucoseLog: build.mutation<string, { id: string; userId: string }>({
        query: ({ id, userId }) => ({
          method: 'DELETE',
          url: `database/UserBloodGlucoseLog/${id}`,
          params: { scope: `BasicHealth:${userId}` },
        }),
        invalidatesTags: ['BloodGlucoseLog'],
      }),
      deleteHbac1Log: build.mutation<string, { id: string; userId: string }>({
        query: ({ id, userId }) => ({
          method: 'DELETE',
          url: `database/UserHbacLog/${id}`,
          params: { scope: `BasicHealth:${userId}` },
        }),
        invalidatesTags: ['Hbac1Log'],
      }),
    }),
  });

export const {
  useGetHbacGoalQuery,
  useGetHbac1LogsQuery,
  useGetHcac1GraphDataQuery,
  useDeleteHbac1LogMutation,
  useGetHbac1OverallDataQuery,
  useGetBloodGlucoseLogsQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseOverallQuery,
  useDeleteBloodGlucoseLogMutation,
  useGetBloodGlucoseGraphDataQuery,
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
} = diabetesQueries;

export default diabetesQueries;
