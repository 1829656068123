import { Box, styled, Typography, TypographyProps } from '@mui/material';
import * as React from 'react';
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import useTimeframe from '../../hooks/useTimeframe';
import PageLayout from '../../components/PageLayout';
import {
  BarChart,
  CalendarModal,
  ChartTypeButtons,
  ColumnsIcon,
  ComponentsLayout,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  LineChart,
  MeasurementsContainer,
  ScatterChart,
  theme,
  ToggleButtons,
} from '@hdcorner/ui-library';
import InDepthDatePicker from '../../components/InDepthDatePicker';
import LinearGraphIcon from '../../assets/icons/LinearGraphIcon';
import ToggleHalfRectangleIcon from '../../assets/icons/ToggleHalfRectangleIcon';
import { useGetLipidGraphDataQuery } from './queries/lipidQueries';
import { RouteComponentProps } from 'react-router';
import useTitle from './hooks/useTitle';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const icons = [
  { name: 'lineChart', icon: LinearGraphIcon },
  { name: 'scatterChart', icon: ToggleHalfRectangleIcon },
  { name: 'barChart', icon: ColumnsIcon },
];

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const GraphTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'dotColor',
})<TypographyProps & { dotColor?: CSSProperties['color'] }>(({ dotColor, theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    backgroundColor: dotColor ? dotColor : '#222222',
  },
}));

interface Props
  extends RouteComponentProps<{
    userId: string;
    type: string;
  }> {}
const LipidGraphDataPage: FC<Props> = ({
  match: {
    params: { userId, type },
  },
}) => {
  const { presentError } = useAlert();
  const title = useTitle(type);
  const { t } = useTranslation();

  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  //for pressure
  const [iconTogglePressure, setIconTogglePressure] = useState<string>(icons[0].name);
  const [dayTogglePressure, setDayTogglePressure] = useState<string>('day');

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(dayTogglePressure, startParam, endParam);

  const { error, data: lipidGraphData } = useGetLipidGraphDataQuery({
    userId,
    params: { metric: type === 'trg' ? 'triglycerides' : type, ...timeframe },
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.errorFetchGraph'));
  }, [error]);

  const chartData = useMemo(() => {
    if (!lipidGraphData) return [];

    return lipidGraphData.logs.map(item => ({
      x: item.logDate,
      y: (item as any)[type === 'trg' ? 'triglycerides' : type],
    }));
  }, [lipidGraphData, type]);

  const handleDateChange = (dateRange: string[]) => {
    setDayTogglePressure('custom');
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  const filteredToggle = useConvertJSON(
    dayWeekMonthYearCustom.filter((item: any) => item.value !== 'custom'),
  );

  return (
    <PageLayout
      headerTitle={t('buttons.navigation.graphData')}
      defaultHref={`/dashboard/users/${userId}/lipid`}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <MeasurementsContainer>
          <InDepthDatePicker flex={1} onClick={() => setCalendarModalOpen(true)} />
        </MeasurementsContainer>
        <GraphContainer>
          <Box display={'flex'} justifyContent={'space-between'}>
            <ToggleButtons
              value={dayTogglePressure}
              headings={filteredToggle}
              handleChange={setDayTogglePressure}
            />
            <ChartTypeButtons
              icons={icons}
              iconValue={iconTogglePressure}
              setIconValue={setIconTogglePressure}
            />
          </Box>
          {chartData.length > 0 && iconTogglePressure === 'lineChart' && (
            <LineChart
              chartData={[{ name: title, data: chartData }]}
              colors={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {chartData.length > 0 && iconTogglePressure === 'barChart' && (
            <BarChart
              unitOfTime={'day'}
              chartData={chartData}
              colorList={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {chartData.length > 0 && iconTogglePressure === 'scatterChart' && (
            <ScatterChart
              chartData={[{ name: title, data: chartData }]}
              colorList={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {chartData.length === 0 && <FeelsEmpty />}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={theme.spacing(3)}
            paddingLeft={theme.spacing(2)}
          >
            <GraphTypography dotColor={theme.palette.warning.main}>
              {title}
            </GraphTypography>
          </Box>
        </GraphContainer>
      </ComponentsLayout>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </PageLayout>
  );
};

export default LipidGraphDataPage;
