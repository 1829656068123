import moment from 'moment/moment';
import api from '../../../../redux/api';
import {
  ApiPagination,
  GetStepsLog,
  PaginationResult,
  StepsGoal,
} from '@hdcorner/ui-library';

const stepsQueries = api
  .enhanceEndpoints({ addTagTypes: ['GetStepsLog', 'StepsGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      getStepsLogs: build.query<
        PaginationResult<GetStepsLog>,
        { params: ApiPagination; start?: string; end?: string; userId: string }
      >({
        query: ({ params, start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getStepLogs',
          params: { ...params, start, end, scope: `Exercise:${userId}` },
        }),
        providesTags: ['GetStepsLog'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
          currentCache.count = newItems?.count;
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return (
            previousArg?.end !== currentArg?.end ||
            previousArg?.start !== currentArg?.start ||
            previousArg?.params?.sort !== currentArg?.params?.sort ||
            previousArg?.params?.skip !== currentArg?.params?.skip ||
            previousArg?.params?.limit !== currentArg?.params?.limit ||
            previousArg?.userId !== currentArg?.userId
          );
        },
      }),

      getLatestStepsLogs: build.query<PaginationResult<GetStepsLog>, { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getStepLogs',
          params: {
            skip: 0,
            limit: 100,
            sort: '-logDate',
            scope: `Exercise:${userId}`,
            start: moment().startOf('day').toISOString(),
          },
        }),
        providesTags: ['GetStepsLog'],
      }),

      getTodaysStepsLogs: build.query<PaginationResult<GetStepsLog>, { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getStepLogs',
          params: {
            skip: 0,
            limit: 100,
            scope: `Exercise:${userId}`,
            end: moment().endOf('day').toISOString(),
            start: moment().startOf('day').toISOString(),
          },
        }),
        providesTags: ['GetStepsLog'],
      }),

      getUserGoalSteps: build.query<StepsGoal[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getUserGoal',
          params: { goalType: 'steps', scope: `Exercise:${userId}` },
        }),
        providesTags: ['StepsGoal'],
      }),

      getStepsGraphData: build.query<
        { graphData: any[]; total?: number; avg?: number },
        {
          end?: string;
          userId: string;
          start?: string;
          timezone: string;
          timeframe?: string;
        }
      >({
        query: ({ start, end, timeframe, timezone, userId }) => ({
          method: 'GET',
          url: '/hdCornerService/user/steps/graph',
          params: { start, end, timeframe, timezone, userId },
        }),
        providesTags: ['GetStepsLog'],
      }),
    }),
  });

export const {
  useGetStepsLogsQuery,
  useGetUserGoalStepsQuery,
  useGetStepsGraphDataQuery,
  useGetLatestStepsLogsQuery,
  useGetTodaysStepsLogsQuery,
} = stepsQueries;

export default stepsQueries;
