import api from '../../../redux/api';
import { UserDetails } from '../types/UserDetails';
import { ApiPagination, HCPUser, PaginationResult } from '@hdcorner/ui-library';

const usersQueries = api.enhanceEndpoints({ addTagTypes: ['HCPUser'] }).injectEndpoints({
  endpoints: build => ({
    getConnectedUsers: build.query<PaginationResult<HCPUser>, ApiPagination>({
      query: ({ skip, limit }) => ({
        method: 'GET',
        url: 'database/function/getConnectedUsers',
        params: {
          skip,
          limit,
          populate: [
            'user',
            'healthProfessional',
            'user.profilePicture',
            'healthProfessionalData',
            'healthProfessional.profilePicture',
          ],
        },
      }),
      providesTags: ['HCPUser'],
    }),
    getUserDetails: build.query<UserDetails, { userId: string }>({
      query: ({ userId }) => ({
        method: 'GET',
        url: `hdCornerService/hcp/user/${userId}/details`,
      }),
      providesTags: ['HCPUser'],
    }),
    getConnectedHCPsByUser: build.query<
      PaginationResult<HCPUser>,
      { userId: string } & ApiPagination
    >({
      query: ({ userId, skip, limit }) => ({
        method: 'GET',
        url: 'database/function/getConnectedHCPsByUser',
        params: {
          skip,
          limit,
          userId,
          populate: [
            'user',
            'healthProfessional',
            'user.profilePicture',
            'healthProfessionalData',
            'healthProfessional.profilePicture',
          ],
        },
      }),
      providesTags: ['HCPUser'],
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useGetConnectedUsersQuery,
  useGetConnectedHCPsByUserQuery,
} = usersQueries;
