import React, { useState } from 'react';
import { useIonRouter } from '@ionic/react';
import {
  ModalDeactivateAccount,
  SettingsDeactivatePanelMobile,
} from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import { useTranslation } from 'react-i18next';

const AccountSettingsDeactivate = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const handleContinue = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDeactivate = () => {
    router.push('/signIn');
    setOpen(false);
  };

  return (
    <PageLayout headerTitle={t('profile.titles.deactivate')} backgroundWhite>
      <SettingsDeactivatePanelMobile handleContinue={handleContinue} />
      <ModalDeactivateAccount
        open={open}
        setOpen={setOpen}
        handleCancel={handleCancel}
        handleDeactivate={handleDeactivate}
      />
    </PageLayout>
  );
};

export default AccountSettingsDeactivate;
