import { AuthState } from './types/AuthState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import signInQueries from './queries/signInQueries';
import {
  removeAccessToken,
  removeRefreshToken,
  storeAccessToken,
  storeRefreshToken,
} from '../../utils/TokenStorage';
import personalInfoQueries from '../profile/queries/personalInfoQueries';
import { AuthUser, HealthProfessionalsData } from '@hdcorner/ui-library';

const initialState: AuthState = {
  data: {
    user: null,
    token: null,
    refreshToken: null,
  },
  meta: {
    error: null,
    status: 'idle',
    authResolved: false,
    isAuthenticated: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.meta.authResolved = true;
      state.data.token = action.payload;

      storeAccessToken(action.payload).then(r => console.log('ok'));
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.data.refreshToken = action.payload;
      storeRefreshToken(action.payload).then(r => console.log('ok'));
    },
    logout: state => {
      state.data.user = null;
      state.data.token = null;
      state.data.refreshToken = null;
      state.meta.isAuthenticated = false;

      removeAccessToken().then(() => console.log('removeAccessToken: ok'));
      removeRefreshToken().then(() => console.log('removeRefreshToken: ok'));

      localStorage.clear();
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(signInQueries.endpoints.signIn.matchFulfilled, (state, { payload }) => {
        state.data.token = payload.accessToken;
        state.data.refreshToken = payload.refreshToken;

        storeAccessToken(payload.accessToken).then(r => console.log('ok'));
        storeRefreshToken(payload.refreshToken).then(r => console.log('ok'));
      })
      .addMatcher(
        signInQueries.endpoints.getAuthUser.matchFulfilled,
        (state, action: PayloadAction<AuthUser[]>) => {
          const { role } = action.payload[0];
          if (role) {
            const isUser = role === 'hcp';
            if (!isUser) {
              state.data.user = null;
              state.data.token = null;
              state.data.refreshToken = null;
              state.meta.authResolved = false;
              state.meta.isAuthenticated = false;
              removeAccessToken().then(() => console.log('removeAccessToken: ok'));
              removeRefreshToken().then(() => console.log('removeRefreshToken: ok'));
              return;
            }
          }

          if (action.payload.length === 0) {
            state.meta.isAuthenticated = false;
            return;
          }

          const authUser = {
            ...action.payload[0],
          };

          const user = state.data.user;
          let updatedUser: HealthProfessionalsData;

          if (user) {
            updatedUser = {
              ...user,
              authUser,
            };
          } else {
            updatedUser = {
              authUser,
              vat: '',
              code: '',
              area: '',
              phone: '',
              address: '',
              addressNum: '',
              postalCode: '',
              speciality: '',
              clinicalCoach: false,
            };
          }
          state.data.user = updatedUser;
        },
      )
      .addMatcher(
        personalInfoQueries.endpoints.getUserData.matchFulfilled,
        (state, action: PayloadAction<HealthProfessionalsData[]>) => {
          const authUser: AuthUser = state.data.user?.authUser as AuthUser;
          if (action.payload.length > 0) {
            state.data.user = { ...action.payload[0], authUser: authUser };
          }
          state.meta.isAuthenticated = true;
        },
      );
  },
});

export const { setAccessToken, setRefreshToken, logout } = authSlice.actions;
export default authSlice.reducer;
