import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CheckBoxes,
  CustomButton,
  DropdownMenu,
  GroupParticipantCard,
  ModalHeading,
  ModalMobile,
} from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import CloseIcon from '../assets/icons/CloseIcon';
import { hcpSpecialties } from '../constants';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../utils/useConvertJSON';

export type ModalCreateChatProps = {
  open: boolean;
  users?: GroupParticipantsDataType[];
  healthProfs?: GroupParticipantsDataType[];
  setOpen: (open: boolean) => void;
  handleCreate: (users: string[]) => void;
  onSelectedUser?: (userID: string) => void;
  onSelectedSpeciality?: (speciality: string) => void;
};

export type GroupParticipantsDataType = { id: string; image?: string; name: string };

const ModalCreateChat: FC<ModalCreateChatProps> = ({
  open,
  setOpen,
  handleCreate,
  users,
  healthProfs,
  onSelectedUser,
  onSelectedSpeciality,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [includeUser, setIncludeUser] = useState<boolean>(true);
  const [selectedHPs, setSelectedHPs] = useState<string[]>([]);
  const [speciality, setSpeciality] = useState<string>('');

  useEffect(() => {
    if (!open) {
      setStep(0);
      setIncludeUser(true);
      setSelectedUser('');
      setSelectedHPs([]);
      setSpeciality('');
    }
  }, [open]);

  useEffect(() => {
    onSelectedUser?.(selectedUser);
  }, [onSelectedUser, selectedUser]);

  useEffect(() => {
    onSelectedSpeciality?.(speciality);
  }, [onSelectedSpeciality, speciality]);

  const handleSelected = useCallback(
    (healthProfID: string) => {
      const selectedArray = [...selectedHPs];
      if (!selectedHPs.includes(healthProfID)) {
        selectedArray.push(healthProfID);
      } else {
        selectedArray.splice(selectedArray.indexOf(healthProfID), 1);
      }
      setSelectedHPs(selectedArray);
    },
    [selectedHPs],
  );

  const handleSelectedUser = useCallback(
    (userID: string) => {
      if (selectedUser !== userID) {
        setSelectedUser(userID);
      } else {
        setSelectedUser('');
      }
    },
    [selectedUser],
  );

  const handleButtonClick = useCallback(() => {
    if (step === 0) {
      setStep(1);
    } else {
      if (includeUser) {
        handleCreate([...selectedHPs, selectedUser]);
      } else {
        handleCreate(selectedHPs);
      }
    }
  }, [step, includeUser, handleCreate, selectedHPs, selectedUser]);

  const handleDisable = useMemo(() => {
    if (step === 0) {
      return !selectedUser;
    } else {
      if (includeUser) {
        return false;
      } else {
        return selectedHPs.length === 0;
      }
    }
  }, [step, selectedUser, includeUser, selectedHPs.length]);

  const specialityDropdown = (
    <DropdownMenu
      fullWidth
      value={speciality}
      setValue={setSpeciality}
      menuItems={useConvertJSON(hcpSpecialties)}
      placeholder={`${t('general.labels.all')}`}
    />
  );

  return (
    <ModalMobile open={open} setOpen={setOpen} width={'348px'}>
      <Box padding={2} boxSizing={'border-box'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <ModalHeading
            align={'left'}
            showCalendar={'flex'}
            alternateIcon={<CloseIcon />}
            handleCalendarClick={() => setOpen(false)}
            title={step === 0 ? t('chat.labels.chooseUser') : t('chat.labels.chooseHCP')}
          />
        </Box>
        {step !== 0 ? (
          <>
            {specialityDropdown}

            <Box marginY={1.5} />
          </>
        ) : null}
        {step !== 0
          ? healthProfs?.map((part, index) => (
              <Box key={`keyHealthProf${index}`} marginTop={index === 0 ? 0 : 1}>
                <GroupParticipantCard
                  healthProfKey={part.id}
                  healthProfName={part.name}
                  healthProfImage={part.image}
                  handleSelected={handleSelected}
                  checked={selectedHPs.includes(part.id)}
                />
              </Box>
            ))
          : users?.map((part, index) => (
              <Box key={`keyUser${index}`} marginTop={index === 0 ? 0 : 1}>
                <GroupParticipantCard
                  healthProfKey={part.id}
                  healthProfName={part.name}
                  healthProfImage={part.image}
                  handleSelected={handleSelectedUser}
                  checked={selectedUser === part.id}
                />
              </Box>
            ))}
        {step === 0 ? (
          <>
            <Box marginY={1.5} />
            <CheckBoxes
              checked={includeUser}
              setChecked={setIncludeUser}
              label={`${t('chat.text.includeUser')}`}
            />
          </>
        ) : null}

        <Box marginY={1.5} />
        <CustomButton
          fullWidth
          color={'primary'}
          onClick={handleButtonClick}
          variant={'contained'}
          disabled={handleDisable}
        >
          {step === 0 ? t('buttons.next') : t('buttons.startChat')}
        </CustomButton>
      </Box>
    </ModalMobile>
  );
};

export default ModalCreateChat;
