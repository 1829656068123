import React, { useMemo, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import SectionHeading from './SectionHeading';
import { Box, useMediaQuery } from '@mui/material';
import { FEData, FEDataEL, ModalMobile, theme } from '@hdcorner/ui-library';
import FETableInner from './FETableInner';
import NavFoodEquivalent from '../nutrition/components/NavFoodEquivalent';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
};
const FoodEquivalentsInner: React.FC<Props> = ({ userId }) => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const web = useMediaQuery(theme.breakpoints.up('md'));

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [title, setTitle] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const feEN = FEData();
  const feEL = FEDataEL();

  const data = useMemo(() => {
    if (lang === 'el') return feEL;
    return feEN;
  }, [lang]);

  const handleNextPage = (value: any) => {
    if (web) {
      setTitle(value.title);
      setOpen(true);
    } else {
      router.push(
        `/dashboard/users/${userId}/fitness/nutrition/food-equivalents/${value.type}`,
      );
    }
  };

  return (
    <Box>
      <Box mb={1} display={web ? 'block' : 'none'}>
        <SectionHeading
          hasGoal={false}
          heading={t('fitness.calories.titles.foodEquiv')}
        />
      </Box>
      <Box
        gap={1}
        display={'flex'}
        flexWrap={web ? 'wrap' : 'nowrap'}
        flexDirection={web ? 'row' : 'column'}
      >
        {data.map((it: any) => (
          <NavFoodEquivalent
            icon={it.icon}
            heading={it.title}
            handleNavClick={() => handleNextPage(it)}
          />
        ))}
      </Box>
      <ModalMobile open={open} setOpen={setOpen}>
        <FETableInner header={title} lang={lang} />
      </ModalMobile>
    </Box>
  );
};

export default FoodEquivalentsInner;
