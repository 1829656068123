import api from '../../../redux/api';
import { AuthUser, HealthProfessionalsData } from '@hdcorner/ui-library';

const personalInfoQueries = api
  .enhanceEndpoints({ addTagTypes: ['HealthProfessionalData', 'AuthUser', 'UserData'] })
  .injectEndpoints({
    endpoints: build => ({
      getUserData: build.query<HealthProfessionalsData[], void>({
        query: () => ({
          url: '/database/function/getHcpData',
          method: 'GET',
        }),
        providesTags: ['HealthProfessionalData'],
      }),

      updateAuthUser: build.mutation<
        AuthUser,
        Omit<AuthUser, '_id' | 'email' | 'createdAt' | 'birthDate'>
      >({
        query: body => ({
          method: 'PUT',
          body: { ...body },
          url: 'database/function/setAuthUser',
        }),
        invalidatesTags: ['AuthUser'],
      }),

      updateUserData: build.mutation<
        HealthProfessionalsData,
        { speciality: string; clinicalCoach: boolean }
      >({
        query: ({ speciality, clinicalCoach }) => ({
          method: 'PATCH',
          body: { speciality, clinicalCoach },
          url: 'database/function/editHcpData',
        }),
        invalidatesTags: ['HealthProfessionalData'],
      }),

      setUserProfilePicture: build.mutation<void, { profilePicture: string }>({
        query: ({ profilePicture }) => ({
          method: 'PUT',
          body: { profilePicture },
          url: 'database/function/setAuthUser',
        }),
        invalidatesTags: ['UserData', 'AuthUser'],
      }),
    }),
  });

export const {
  useGetUserDataQuery,
  useLazyGetUserDataQuery,
  useUpdateUserDataMutation,
  useUpdateAuthUserMutation,
  useSetUserProfilePictureMutation,
} = personalInfoQueries;

export default personalInfoQueries;
