import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  CalendarModal,
  ClickNavCardNutrition,
  HBAC1Chart,
  theme,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import {
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1OverallDataQuery,
  useGetHbacGoalQuery,
  useGetHcac1GraphDataQuery,
} from './queries/diabetesQueries';
import useOverallData from './hooks/useOverallData';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useGoalValue from './hooks/useGoalValue';
import useTimeframe from '../../hooks/useTimeframe';
import usePrepareGraph from './hooks/usePrepareGraph';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

interface Props {
  userId: string;
}
const DiabetesTabHBAC1: FC<Props> = ({ userId }) => {
  const router = useIonRouter();

  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const { data: hbac1Goal, error: hbac1GoalError } = useGetHbacGoalQuery({ userId });
  const { data: hbacGraphData, error: errorGraph } = useGetHcac1GraphDataQuery({
    ...timeframe,
    userId,
  });
  const { data: hbac1CurrentMeasurement, error: errorCurrent } =
    useGetHbac1CurrentMeasurementQuery({
      userId,
    });
  const { data: hbac1OverallData, error: errorOverall } = useGetHbac1OverallDataQuery({
    ...timeframe,
    userId,
  });

  const prepareGoalValue = useGoalValue(hbac1Goal);
  const prepareGraphData = usePrepareGraph(hbacGraphData);
  const prepareOverallData = useOverallData(hbac1OverallData);
  const prepareCurrentMeasurement = useCurrentMeasurement(hbac1CurrentMeasurement);

  useEffect(() => {
    if (hbac1GoalError || errorGraph || errorCurrent || errorOverall) {
      presentError(t('errors.generic.error01'));
    }
  }, [hbac1GoalError, errorGraph, errorCurrent, errorOverall]);

  const handleShowInDepthData = () => {
    router.push(`/dashboard/users/${userId}/diabetes/hbac1-in-depth-data`);
  };

  const handleShowGraphData = () => {
    router.push(`/dashboard/users/${userId}/diabetes-graph-data/hbac1`);
  };

  const handleClickGoal = () => {
    router.push(`/dashboard/users/${userId}/diabetes-hbac1-goal`);
  };

  const onOpenCalendar = () => {
    if (selectedTimeframe === 'custom') {
      setCalendarModalOpen(true);
    }
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <>
      <Box
        display={'flex'}
        gap={theme.spacing(2)}
        flexDirection={'column'}
        marginTop={theme.spacing(3)}
      >
        <HBAC1Chart
          chartData={prepareGraphData}
          overallData={prepareOverallData}
          handleOpenCalendar={onOpenCalendar}
          selectedTimeframe={selectedTimeframe}
          selectTimeframe={setSelectedTimeframe}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
        <Box>
          <ClickNavCardNutrition
            onClick={handleShowInDepthData}
            headings={t('buttons.navigation.inDepth')}
          />

          <ClickNavCardNutrition
            headings={t('buttons.navigation.graphData')}
            onClick={handleShowGraphData}
          />

          {hbac1Goal && hbac1Goal.length > 0 && (
            <ClickNavCardNutrition
              headings={t('headingsTitles.goal')}
              onClick={handleClickGoal}
              body={prepareGoalValue ? `${prepareGoalValue} %` : '-'}
            />
          )}
        </Box>
      </Box>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </>
  );
};

export default DiabetesTabHBAC1;
