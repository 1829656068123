import moment from 'moment/moment';
import api from '../../../../redux/api';
import {
  ApiPagination,
  GetWeightLog,
  PaginationResult,
  WeightGoal,
} from '@hdcorner/ui-library';

const weightQueries = api
  .enhanceEndpoints({ addTagTypes: ['GetWeightLog', 'WeightGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      getWeightLogs: build.query<
        PaginationResult<GetWeightLog>,
        { params: ApiPagination; start?: string; end?: string; userId: string }
      >({
        query: ({ params, start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getWeightLogs',
          params: { ...params, start, end, scope: `Weight:${userId}` },
        }),
        providesTags: ['GetWeightLog'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
          currentCache.count = newItems?.count;
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return (
            previousArg?.end !== currentArg?.end ||
            previousArg?.start !== currentArg?.start ||
            previousArg?.params.sort !== currentArg?.params.sort ||
            previousArg?.params.skip !== currentArg?.params.skip ||
            previousArg?.params.limit !== currentArg?.params.limit ||
            previousArg?.userId !== currentArg?.userId
          );
        },
      }),

      getLatestWeightLogs: build.query<
        PaginationResult<GetWeightLog>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getWeightLogs',
          params: { skip: 0, limit: 1, sort: '-logDate', scope: `Weight:${userId}` },
        }),
        providesTags: ['GetWeightLog'],
      }),

      getFirstWeightLog: build.query<
        PaginationResult<GetWeightLog>,
        { start?: string; userId: string }
      >({
        query: ({ start, userId }) => ({
          method: 'GET',
          url: '/database/function/getWeightLogs',
          params: {
            start,
            skip: 0,
            limit: 1,
            sort: 'logDate',
            scope: `Weight:${userId}`,
          },
        }),
        providesTags: ['GetWeightLog'],
      }),

      getTodaysLastWeightLog: build.query<
        PaginationResult<GetWeightLog>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getWeightLogs',
          params: {
            skip: 0,
            limit: 1,
            sort: '-logDate',
            scope: `Weight:${userId}`,
            end: moment().endOf('day').toISOString(),
            start: moment().startOf('day').toISOString(),
          },
        }),
        providesTags: ['GetWeightLog'],
      }),

      getUserGoalWeight: build.query<WeightGoal[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getUserGoal',
          params: { goalType: 'weight', scope: `Weight:${userId}` },
        }),
        providesTags: ['WeightGoal'],
      }),

      getWeightGraphData: build.query<
        GetWeightLog[],
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          url: '/database/function/getWeightGraphData',
          params: { start, end, scope: `Weight:${userId}`, sort: '-logDate' },
        }),
      }),
    }),
  });

export const {
  useGetWeightLogsQuery,
  useGetFirstWeightLogQuery,
  useGetUserGoalWeightQuery,
  useGetWeightGraphDataQuery,
  useGetLatestWeightLogsQuery,
  useGetTodaysLastWeightLogQuery,
} = weightQueries;

export default weightQueries;
