import { useEffect, useState } from 'react';
import {
  ExclamationIcon,
  hypertensionHeadings,
  ToggleButtonGroupCu,
} from '@hdcorner/ui-library';
import { Box, IconButton, styled, useTheme } from '@mui/material';
import EshInfo from './EshInfo';
import { useAppDispatch } from '../../../../redux/hooks';
import { updateToggle } from '../../slices/hypertensionSlice';
import { useTranslation } from 'react-i18next';
import { useConvertJSONAndBoolean } from '../../../../utils/useConvertJSON';

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  padding: theme.spacing(3, 2),
}));

const ToolbarHypertension = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [display, setDisplay] = useState<boolean>(false);
  const [value, setValue] = useState<string>('indepth');

  useEffect(() => {
    dispatch(updateToggle(value));
  }, [value]);

  return (
    <Box position={'relative'}>
      <CustomBox>
        <ToggleButtonGroupCu
          traffic
          value={value}
          setValue={setValue}
          headings={useConvertJSONAndBoolean(hypertensionHeadings)}
        />

        <Box display={value === 'esh' ? 'flex' : 'none'}>
          <IconButton sx={{ padding: 0 }} onClick={() => setDisplay(display => !display)}>
            <ExclamationIcon color={theme.palette.primary.main} />
          </IconButton>
        </Box>
      </CustomBox>
      <EshInfo top={75} right={20} display={display} />
    </Box>
  );
};

export default ToolbarHypertension;
