const dietTypes = {
  standard: {
    name: 'fitness.dietPlan.text.standard',
    description: 'fitness.dietPlan.text.standardDiet',
    carbs: 0.5,
    protein: 0.2,
    fat: 0.3,
  },
  lowCarb: {
    name: 'fitness.dietPlan.text.lowCarb',
    description: 'fitness.dietPlan.text.lowCardDiet',
    carbs: 0.3,
    protein: 0.25,
    fat: 0.45,
  },
  lowFat: {
    name: 'fitness.dietPlan.text.lowFat',
    description: 'fitness.dietPlan.text.lowFatDiet',
    carbs: 0.55,
    protein: 0.2,
    fat: 0.25,
  },
  highProtein: {
    name: 'fitness.dietPlan.text.highProtein',
    description: 'fitness.dietPlan.text.highProteinDiet',
    carbs: 0.35,
    protein: 0.4,
    fat: 0.25,
  },
};

const activityLevels = {
  light: {
    name: 'fitness.dietPlan.text.light',
    description: 'fitness.dietPlan.text.lightLevel',
    value: 1.3,
  },
  moderate: {
    name: 'fitness.dietPlan.text.moderate',
    description: 'fitness.dietPlan.text.modLevel',
    value: 1.55,
  },
  heavy: {
    name: 'fitness.dietPlan.text.intense',
    description: 'fitness.dietPlan.text.intenseLevel',
    value: 1.725,
  },
};

export { dietTypes, activityLevels };
