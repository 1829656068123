import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import {
  CalendarModal,
  ClickNavCardNutrition,
  ComponentsLayout,
  CustomTabs,
  LipidChartCard,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import LipidIcon from '../../assets/icons/LipidIcon';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import { tabs } from './constants';
import useTimeframe from '../../hooks/useTimeframe';
import useMetric from './hooks/useMetric';
import useAlert from '../../hooks/useAlert';
import useGoalMetric from './hooks/useGoalMetric';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const Lipid: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const router = useIonRouter();

  const { presentError } = useAlert();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [calendarModal, setCalendarModal] = useState<boolean>(false);
  const [timeframeValue, setTimeframeValue] = useState<string>('all');

  const metric = useMetric(tabs[selectedTab]);
  const goalMetric = useGoalMetric(tabs[selectedTab]);

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(timeframeValue, startParam, endParam);

  const { data: overallData, error: errorOverall } = useGetOverallLipidQuery({
    ...timeframe,
    userId,
  });
  const { data: lastLog, error: lastLogError } = useGetLipidLastLogQuery({
    userId,
  });
  const { data: lipidGoal, error: lipidGoalError } = useGetLipidGoalQuery(
    { userId, goalType: goalMetric },
    {
      skip: metric === 'all',
    },
  );
  const { data: lipidGraphData, error: lipidGraphError } = useGetLipidGraphDataQuery({
    userId,
    params: { metric: metric === 'all' ? undefined : metric, ...timeframe },
  });
  const { data: firstLog, error: firstLogError } = useGetLipidFirstLogQuery(
    {
      userId,
      startingDate: lipidGoal && lipidGoal.length > 0 ? lipidGoal[0].startDate : '',
    },
    { skip: !lipidGoal || lipidGoal.length === 0 },
  );

  useEffect(() => {
    if (lipidGoalError) {
      presentError(t('errors.generic.errorFetchGoal'));
    }
    if (firstLogError || lastLogError) {
      presentError(t('errors.generic.errorFetchLog'));
    }
    if (errorOverall) {
      presentError(t('errors.generic.error01'));
    }
    if (lipidGraphError) {
      presentError(t('errors.generic.errorFetchGraph'));
    }
  }, [lipidGoalError, firstLogError, lastLogError, lipidGraphError, errorOverall]);

  const getFirstLog = useMemo(() => {
    if (!firstLog || firstLog.documents.length === 0) return;
    const latestLogData: any = firstLog.documents[0];
    return latestLogData[tabs[selectedTab].toLowerCase()];
  }, [firstLog, selectedTab]);

  const getDesiredGoal = useMemo(() => {
    if (!lipidGoal || lipidGoal.length === 0) return;
    const lipidGoalData: any = lipidGoal[0];
    return lipidGoalData.goal.measurement;
  }, [lipidGoal]);

  const getLatestMeasurement = useMemo(() => {
    if (!lastLog || lastLog.documents.length === 0) return;
    const startingLogData: any = lastLog.documents[0];
    return startingLogData[metric];
  }, [lastLog, metric]);

  const prepareGoalData = useMemo(() => {
    if (metric === 'all') return undefined;
    if (!getDesiredGoal) return undefined;

    const goalData: any = getDesiredGoal;
    const lastLogValue: any = getLatestMeasurement;
    const fistLogValue: any = getFirstLog;

    return {
      measure: t('measurements.mgdl'),
      goal: goalData ? goalData : '-',
      latestMeasurement: lastLogValue ? lastLogValue : undefined,
      startingMeasurement: fistLogValue ? fistLogValue : undefined,
    };
  }, [getDesiredGoal, getFirstLog, getLatestMeasurement, metric]);

  const chartData = useMemo(() => {
    if (!lipidGraphData) return;

    if (tabs[selectedTab].toLowerCase() === 'all') {
      let totalChol: any[] = [],
        triglycerides: any[] = [],
        hdl: any[] = [],
        ldl: any[] = [],
        lpa: any[] = [];
      lipidGraphData.logs.forEach(item => {
        if (item.totalChol) {
          totalChol.push({
            x: item.logDate,
            y: item.totalChol,
          });
        }
        if (item.triglycerides) {
          triglycerides.push({
            x: item.logDate,
            y: item.triglycerides,
          });
        }
        if (item.hdl) {
          hdl.push({
            x: item.logDate,
            y: item.hdl,
          });
        }
        if (item.ldl) {
          ldl.push({
            x: item.logDate,
            y: item.ldl,
          });
        }
        if (item.lpa) {
          lpa.push({
            x: item.logDate,
            y: item.lpa,
          });
        }
      });

      return [
        {
          name: t('lipid.labels.totalChol'),
          data: totalChol,
        },
        {
          name: t('lipid.labels.tryg'),
          data: triglycerides,
        },
        {
          name: t('dashboard.dashcards.lipid.hdl'),
          data: hdl,
        },
        {
          name: t('dashboard.dashcards.lipid.ldl'),
          data: ldl,
        },
        {
          name: t('dashboard.dashcards.lipid.lpa'),
          data: lpa,
        },
      ];
    }

    return lipidGraphData.logs.map(item => ({
      x: item.logDate,
      y: (item as any)[metric],
    }));
  }, [lipidGraphData, metric, selectedTab]);

  const pageTabs = () => {
    return tabs.map(item => {
      return {
        label: item,
        children: <></>,
      };
    });
  };

  const handleChange = (newValue: string) => {
    if (newValue === 'custom') {
      setCalendarModal(true);
    }
    setTimeframeValue(newValue);
  };

  const chartOverallValues = useMemo(() => {
    if (!overallData) return;

    const selectedType = tabs[selectedTab].toLowerCase();
    switch (selectedType) {
      case 'all':
        return [
          {
            label: t('lipid.labels.avgChol'),
            value: Math.round(overallData.totalChol?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgHdl'),
            value: Math.round(overallData.hdl?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgLdl'),
            value: Math.round(overallData.ldl?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgTrg'),
            value: Math.round(overallData.triglycerides?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgLpa'),
            value: Math.round(overallData.lpa?.avg) || '-',
          },
        ];
      case 'lpa':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.lpa?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.lpa?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.lpa?.avg) || '-',
          },
        ];
      case 'chol':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.totalChol?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.totalChol?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.totalChol?.avg) || '-',
          },
        ];
      case 'hdl':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.hdl?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.hdl?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.hdl?.avg) || '-',
          },
        ];
      case 'ldl':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.ldl?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.ldl?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.ldl?.avg) || '-',
          },
        ];
      case 'trg':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.triglycerides?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.triglycerides?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.triglycerides?.avg) || '-',
          },
        ];
    }
  }, [overallData, selectedTab]);

  const handleClickGoal = () => {
    if (!lipidGoal || lipidGoal.length === 0) return;
    router.push(
      `/dashboard/users/${userId}/lipid-goal/${tabs[selectedTab].toLowerCase()}`,
    );
  };

  const handleClickInDepthData = () => {
    router.push(
      `/dashboard/users/${userId}/lipid-in-depth-data/${tabs[selectedTab].toLowerCase()}`,
    );
  };

  const handleClickGraphData = () => {
    router.push(
      `/dashboard/users/${userId}/lipid-graph-data/${tabs[selectedTab].toLowerCase()}`,
    );
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.lipid')}
      headerColor={theme.palette.lipid.main}
      defaultHref={`/dashboard/users/${userId}/overview`}
      headerIcon={<LipidIcon fill={theme.palette.lipid.main} />}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <CustomTabs value={selectedTab} setValue={setSelectedTab} tabs={pageTabs()} />
        </Box>
        <LipidChartCard
          chartData={chartData}
          goalData={prepareGoalData}
          values={chartOverallValues}
          title={getLatestMeasurement}
          handleChangeOption={handleChange}
          toggleButtonsValue={timeframeValue}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
        <Box mt={2}>
          {selectedTab !== 0 && (
            <ClickNavCardNutrition
              onClick={handleClickInDepthData}
              headings={t('buttons.navigation.inDepth')}
            />
          )}
          {selectedTab !== 0 && (
            <ClickNavCardNutrition
              onClick={handleClickGraphData}
              headings={t('buttons.navigation.graphData')}
            />
          )}
          {selectedTab !== 0 && lipidGoal && (
            <ClickNavCardNutrition
              onClick={handleClickGoal}
              headings={t('headingsTitles.goal')}
              body={getDesiredGoal ? getDesiredGoal + ' ' + t('measurements.mgdl') : '-'}
            />
          )}
        </Box>
      </ComponentsLayout>
      <CalendarModal
        open={calendarModal}
        setOpen={setCalendarModal}
        selectedDate={[startParam, endParam]}
        saveDate={date => handleDateChange(date)}
      />
    </PageLayout>
  );
};

export default Lipid;
