import api from '../../../redux/api';
import { socketInstance } from '../socket';
import {
  ApiPagination,
  ChatMessageType,
  ChatRoomType,
  SupportChatRoomType,
} from '@hdcorner/ui-library';
import moment from 'moment';

const chatQueries = api
  .enhanceEndpoints({ addTagTypes: ['ChatRoom', 'SupportChatRoom', 'ChatMessage'] })
  .injectEndpoints({
    endpoints: build => ({
      /* CHAT ROOMS */
      getChatRooms: build.query<
        {
          rooms: ChatRoomType[];
          count: number;
        },
        ApiPagination & { roomType: 'mixed' | 'hcp-only' }
      >({
        query: params => ({
          url: 'hdCornerService/user/chat/rooms',
          method: 'GET',
          params: { ...params },
        }),
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
        ) {
          const messageListener = (data: any) => {
            const currentDate = moment().toISOString();
            updateCachedData(draft => {
              const room = draft.rooms?.find(room => room._id === data.room);
              if (!room) return;
              room.lastMessage = {
                room: data.room,
                message: data.message,
                createdAt: currentDate,
                updatedAt: currentDate,
                senderUser: data.sender,
                _id: `temp-${currentDate}`,
              };
              room.unreadMessageCount = room.unreadMessageCount
                ? room.unreadMessageCount + 1
                : 1;
              draft.rooms = draft.rooms.filter(r => r._id !== data.room);
              draft.rooms.unshift(room);
            });
          };

          try {
            await cacheDataLoaded;
            socketInstance.on('message', messageListener);
          } catch (error) {
            console.error(error);
          }
          await cacheEntryRemoved;
          socketInstance.off('message', messageListener);
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { roomType } = queryArgs;
          return { params: { roomType } };
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            return newItems;
          } else {
            currentCache?.rooms.push(...newItems?.rooms);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) =>
          previousArg?.skip !== currentArg?.skip ||
          previousArg?.limit !== currentArg?.limit ||
          previousArg?.roomType !== currentArg?.roomType,
        providesTags: result =>
          result?.rooms
            ? [
                ...result?.rooms.map(({ _id }) => ({
                  type: 'ChatRoom' as const,
                  id: _id,
                })),
                { type: 'ChatRoom', id: 'LIST' },
              ]
            : [{ type: 'ChatRoom', id: 'LIST' }],
      }),
      getChatRoom: build.query<ChatRoomType, { roomId: string }>({
        query: ({ roomId }) => ({
          url: `chat/rooms/${roomId}`,
          method: 'GET',
          params: { populate: ['participants'] },
        }),
        providesTags: result => (result ? [{ type: 'ChatRoom', id: result._id }] : []),
      }),
      createChatRoom: build.mutation<
        { roomId: string },
        { roomName: string; users?: string[] }
      >({
        query: ({ roomName, users }) => ({
          url: 'chat/rooms',
          method: 'POST',
          body: {
            roomName,
            users,
          },
        }),
        invalidatesTags: [{ type: 'ChatRoom', id: 'LIST' }],
      }),
      leaveChatRoom: build.mutation<void, { roomId: string }>({
        query: ({ roomId }) => ({
          url: `chat/leave/${roomId}`,
          method: 'PUT',
        }),
        invalidatesTags: (_result, _error, meta) => [
          { type: 'ChatRoom', id: meta.roomId },
          { type: 'ChatRoom', id: 'LIST' },
        ],
      }),

      /* SUPPORT CHAT ROOM */
      getSupportChatRoom: build.query<SupportChatRoomType, void>({
        query: () => ({
          method: 'GET',
          url: 'hdCornerService/user/chat/rooms/support',
        }),
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
        ) {
          const messageListener = (data: any) => {
            const currentDate = moment().toISOString();
            updateCachedData(draft => {
              if (!draft?.chatRoom || draft.chatRoom._id !== data.room) return;
              draft.chatRoom.lastMessage = {
                room: data.room,
                message: data.message,
                createdAt: currentDate,
                updatedAt: currentDate,
                senderUser: data.sender,
                _id: `temp-${currentDate}`,
              };
              draft.chatRoom.unreadMessageCount = draft.chatRoom.unreadMessageCount
                ? draft.chatRoom.unreadMessageCount + 1
                : 1;
            });
          };
          try {
            await cacheDataLoaded;
            socketInstance.on('message', messageListener);
          } catch (error) {
            console.error(error);
          }
          await cacheEntryRemoved;
          socketInstance.off('message', messageListener);
        },
        transformResponse: (baseQueryReturnValue: { supportRoom: SupportChatRoomType }) =>
          baseQueryReturnValue?.supportRoom,
        providesTags: ['SupportChatRoom'],
      }),
      createSupportChatRoom: build.mutation<SupportChatRoomType, { message: string }>({
        query: params => ({
          url: 'database/function/createSupportChatRoom',
          method: 'POST',
          body: {
            ...params,
            ownerRole: 'hcp',
          },
        }),
        transformResponse: (baseQueryReturnValue: SupportChatRoomType[]) =>
          baseQueryReturnValue?.[0],
        invalidatesTags: ['SupportChatRoom'],
      }),
      resolveSupportChatRoom: build.mutation<void, { supportRoomId: string }>({
        query: ({ supportRoomId }) => ({
          url: `hdCornerService/user/chat/rooms/support/${supportRoomId}/resolve`,
          method: 'PATCH',
        }),
        invalidatesTags: ['SupportChatRoom'],
      }),

      /* CHAT MESSAGES */
      getChatMessages: build.query<
        {
          messages: ChatMessageType[];
          count: number;
        },
        ApiPagination & { roomId: string }
      >({
        query: ({ skip, limit, roomId }) => ({
          url: `chat/messages`,
          method: 'GET',
          params: { roomId, skip, limit },
        }),
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
        ) {
          const messageListener = (data: any) => {
            if (data.room !== arg.roomId) return;
            const currentDate = moment().toISOString();
            updateCachedData(draft => {
              const message = {
                room: data.room,
                message: data.message,
                createdAt: currentDate,
                updatedAt: currentDate,
                senderUser: data.sender,
                _id: `temp-${currentDate}`,
              };
              draft.messages?.unshift(message);
              draft.count += 1;
            });
          };

          try {
            await cacheDataLoaded;
            socketInstance.on('message', messageListener);
          } catch (error) {
            console.error(error);
          }
          await cacheEntryRemoved;
          socketInstance.off('message', messageListener);
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { roomId } = queryArgs;
          return { params: { roomId } };
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            return newItems;
          } else {
            currentCache?.messages.push(...newItems?.messages);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) =>
          previousArg?.skip !== currentArg?.skip ||
          previousArg?.limit !== currentArg?.limit ||
          previousArg?.roomId !== currentArg?.roomId,
        providesTags: result =>
          result?.messages
            ? [
                ...result?.messages.map(({ _id }) => ({
                  type: 'ChatMessage' as const,
                  id: _id,
                })),
                { type: 'ChatMessage', id: 'LIST' },
              ]
            : [{ type: 'ChatMessage', id: 'LIST' }],
      }),
      deleteChatMessage: build.mutation<void, { messageId: string }>({
        query: ({ messageId }) => ({
          url: `chat/messages/${messageId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, _error, meta) => [
          { type: 'ChatMessage', id: meta.messageId },
          { type: 'ChatMessage', id: 'LIST' },
        ],
      }),
    }),
  });

export const {
  useGetChatRoomsQuery,
  useGetChatRoomQuery,
  useCreateChatRoomMutation,
  useLeaveChatRoomMutation,
  useGetSupportChatRoomQuery,
  useCreateSupportChatRoomMutation,
  useResolveSupportChatRoomMutation,
  useGetChatMessagesQuery,
} = chatQueries;
export default chatQueries;
