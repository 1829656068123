import { MealsData } from '../types/Food';
import { useMemo } from 'react';
import { findMeal, foodTitlesString, totalValue } from '../utilities/getMealObject';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { useTranslation } from 'react-i18next';

const usePrepareMealList = (
  meal: string,
  params: { start: string; end: string },
  mealLogRes?: MealsData[],
) => {
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const mealName = () => {
    switch (meal) {
      case 'breakfast':
        return t('fitness.nutrition.labels.breakfast');
        break;
      case 'lunch':
        return t('fitness.nutrition.labels.lunch');
        break;
      case 'dinner':
        return t('fitness.nutrition.labels.dinner');
        break;
      case 'snacks':
        return t('fitness.nutrition.labels.snack');
        break;
      default:
        return '';
    }
  };

  return useMemo(() => {
    const selectedMeal = findMeal(meal, mealLogRes);
    const cal = totalValue(selectedMeal, 'calories');

    if (!selectedMeal)
      return {
        title: capitalizeFirstLetter(mealName()),
        totalCal: 0,
        data: [],
        addMeal: {
          category: meal,
          logDate: params.start,
          mealExists: true,
        },
      };

    const data = selectedMeal.foods.map(it => ({
      entryID: '',
      id: it.food._id,
      quantity: it.quantity,
      mealID: selectedMeal._id,
      logDate: selectedMeal.logDate,
      category: selectedMeal.category,
      cal: Math.round((it.food.calories / 100) * it.quantity),
      title: typeof it.food.name === 'string' ? it.food.name : it.food.name[lang],
    }));

    const metrics = [
      {
        id: '1',
        max: Math.round(cal),
        eaten: Math.round(cal),
        measure: t('measurements.gr'),
        category: t('fitness.calories.titles.calories'),
      },
      {
        id: '2',
        measure: t('measurements.gr'),
        max: Math.round(cal * 0.5),
        category: t('fitness.calories.titles.carbs'),
        eaten: Math.round(totalValue(selectedMeal, 'carbs')),
      },
      {
        id: '3',
        max: Math.round(cal * 0.3),
        measure: t('measurements.gr'),
        category: t('fitness.calories.titles.protein'),
        eaten: Math.round(totalValue(selectedMeal, 'protein')),
      },
      {
        id: '4',
        measure: t('measurements.gr'),
        max: Math.round(cal * 0.2),
        category: t('fitness.calories.titles.fat'),
        eaten: totalValue(selectedMeal, 'fat'),
      },
    ];

    const patchArray = selectedMeal.foods.map((it: any) => ({
      id: it._id,
      entry: {
        food: it.food._id,
        quantity: it.quantity,
        portion: `100${t('measurements.gr')}`,
      },
    }));

    return {
      id: selectedMeal._id,
      title: capitalizeFirstLetter(mealName()),
      totalCal: cal,
      description: foodTitlesString(selectedMeal),
      data,
      metrics,
      patchArray,
    };
  }, [meal, mealLogRes, params]);
};

export default usePrepareMealList;
