import React, { FC, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import { ForgotPasswordPanelGlobal } from '@hdcorner/ui-library';
import { useForgotPasswordMutation } from '../../queries/forgotPasswordQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

export type ForgotPasswordPanelProps = {
  web: boolean;
};
const ForgotPasswordPanel: FC<ForgotPasswordPanelProps> = ({ web }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [forgotPassword] = useForgotPasswordMutation();

  const [email, setEmail] = useState<string>('');
  const [emailSentBtn, setEmailSentBtn] = useState<boolean>(false);

  const handleCancelButton = () => {
    router.push('/sign-in', 'back', 'pop');
  };

  const handleSendEmail = async () => {
    try {
      setEmailSentBtn(true);
      await forgotPassword({ email }).unwrap();
    } catch (e) {
      console.log(e);
      setEmailSentBtn(false);
      presentError(t('authentication.forgotReset.error.errorSendLink'));
    }
  };

  const handleGoBack = () => {
    router.push('/sign-in', 'back', 'pop');
  };

  return (
    <ForgotPasswordPanelGlobal
      web={web}
      email={email}
      setEmail={setEmail}
      emailSentBtn={emailSentBtn}
      handleGoBack={handleGoBack}
      handleSendEmail={handleSendEmail}
      handleCancelButton={handleCancelButton}
      logoContainer={
        <img
          src={LogoHeartPNG}
          alt={'company logo'}
          width={web ? '134px' : '44px'}
          height={web ? '136px' : '46px'}
        />
      }
    />
  );
};

export default ForgotPasswordPanel;
