import {
  ChatIcon,
  GearIcon,
  LogoutIcon,
  NavHomeIcon,
  NavProfileIcon,
  SideBar,
  theme,
} from '@hdcorner/ui-library';
import {
  IonBackdrop,
  IonContent,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from '@ionic/react';
import { Box, Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useMemo, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import DashboardSidebarIcon from '../assets/icons/DashboardSidebarIcon';
import HealthProfSidebarIcon from '../assets/icons/HealthProfSidebarIcon';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { hideNavigation } from '../utils/hideNavigation';
import NonProtectedRoutes from './NonProtectedRoutes';
import ProtectedRoutes from './ProtectedRoutes';
import logoBig from '../features/aboutUs/assets/HDCLogo.png';
import logoSmall from '../assets/illustrations/LogoHeartPNG.png';
import { logout } from '../features/authentication/authSlice';
import { useTranslation } from 'react-i18next';

const routesWithTabs = [
  '/dashboard/home',
  '/dashboard/chat',
  '/dashboard/users',
  '/dashboard/records',
  '/dashboard/profile',
];

const showTabs = (path: string) => {
  return routesWithTabs.includes(path);
};

const MenuToMap = [
  {
    label: 'general.labels.dashboard',
    icon: DashboardSidebarIcon,
    link: '/dashboard/home',
  },
  {
    label: 'general.labels.users',
    icon: HealthProfSidebarIcon,
    link: '/dashboard/users',
  },
];

const Routes = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pathname = router.routeInfo.pathname;

  const shrinkMenu = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const shrunkSideBar = useAppSelector(state => state.layout.shrunkSideBar);

  const tabBarRef = useRef<HTMLIonTabBarElement>(null);

  const {
    data: { user },
    meta: { isAuthenticated },
  } = useAppSelector(state => state.auth);

  const isVerified = useMemo(() => {
    if (!pathname.includes('home')) return true;
    return !!user?.verified;
  }, [pathname, user?.verified]);

  const isCurrentScreen = useMemo(
    () => (link: string) => {
      if (pathname === link) {
        return true;
      }
      const linkSplit = link.split('/');
      const pathnameSplit = pathname.split('/');
      if (link === '/dashboard/users') {
        if (linkSplit[2] === 'users' && pathnameSplit[2] === 'users') {
          return true;
        }
      }

      if (link === '/dashboard/users-records') {
        if (linkSplit[2] === 'users-records' && pathnameSplit[2] === 'users-records') {
          return true;
        }
      }

      return false;
    },
    [pathname],
  );

  const handleClickRoute = (routeLink: string) => {
    if (routeLink.includes('user-data')) {
      const userId = routeLink.split('/')?.[3];
      router.push(`/dashboard/users/${userId}/fitness`);
      return;
    }

    router.push(routeLink);
  };

  const logOutUser = () => {
    dispatch(logout());
  };

  const translateText = () => {
    return MenuToMap.map((it: any) => {
      return {
        label: t(it.label),
        icon: it.icon,
        link: it.link,
      };
    });
  };

  return (
    <>
      <IonSplitPane
        when={'sm'}
        contentId={'main'}
        className={'ion-split-pane'}
        disabled={smallScreen || hideNavigation(pathname)}
      >
        <IonMenu
          contentId={'main'}
          disabled={smallScreen}
          className={
            shrinkMenu || shrunkSideBar
              ? 'ion-split-pane-menu-shrink'
              : 'ion-split-pane-menu'
          }
        >
          <IonContent>
            <SideBar
              menuOptions={translateText()}
              isCurrentScreen={isCurrentScreen}
              handleClickRoute={handleClickRoute}
              shrunkSideBar={shrinkMenu || shrunkSideBar}
              logoBig={
                <img
                  src={logoBig}
                  width={'134px'}
                  height={'72px'}
                  alt={`HD Corner Logo`}
                />
              }
              logoSmall={
                <img
                  width={'40px'}
                  height={'40px'}
                  src={logoSmall}
                  alt={`HD Corner Logo`}
                  style={{ maxWidth: 'none' }}
                />
              }
            />
          </IonContent>
        </IonMenu>
        <div className="ion-page" id="main">
          <IonTabs>
            <IonRouterOutlet animated={smallScreen}>
              <NonProtectedRoutes />
              <Route path={'/dashboard'}>
                {isAuthenticated && user && user.authUser ? (
                  <ProtectedRoutes />
                ) : (
                  <Redirect to={'/sign-in'} />
                )}
              </Route>
              <Route exact path={'/'}>
                <Redirect to={'/dashboard/home'} />
              </Route>
            </IonRouterOutlet>
            <IonTabBar
              slot="bottom"
              ref={tabBarRef}
              hidden={!showTabs(pathname) || !smallScreen}
            >
              <IonTabButton tab="chat" href="/dashboard/chat">
                <ChatIcon
                  width={'20px'}
                  height={'20px'}
                  color={
                    pathname === '/dashboard/chat'
                      ? theme.palette.kmColorsRed.main
                      : theme.palette.primary.main
                  }
                />
              </IonTabButton>
              <IonTabButton tab="users" href="/dashboard/users">
                <NavProfileIcon
                  color={
                    pathname === '/dashboard/users'
                      ? theme.palette.kmColorsRed.main
                      : theme.palette.primary.main
                  }
                />
              </IonTabButton>
              <IonTabButton tab="home" href="/dashboard/home">
                <NavHomeIcon
                  color={
                    pathname === '/dashboard/home'
                      ? theme.palette.kmColorsRed.main
                      : theme.palette.primary.main
                  }
                />
              </IonTabButton>

              <IonTabButton tab="profile" href="/dashboard/profile">
                <GearIcon
                  color={
                    pathname === '/dashboard/profile'
                      ? theme.palette.kmColorsRed.main
                      : theme.palette.primary.main
                  }
                />
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </div>
      </IonSplitPane>
      {!isVerified && (
        <>
          <IonBackdrop
            style={{ background: theme.palette.kmColorsBg.main, opacity: 0.7 }}
          />
          <Box
            zIndex={2}
            top={'50%'}
            left={'50%'}
            width={'395px'}
            position={'absolute'}
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Box
              p={2}
              gap={2}
              display={'flex'}
              bgcolor={'#fff'}
              borderRadius={1}
              flexDirection={'column'}
              boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
            >
              <Box>
                <Typography variant={'h1'}>
                  {t('modals.hcpNotVerified.welcome')}
                </Typography>
                <Divider />
              </Box>
              <Box gap={1} display={'flex'} flexDirection={'column'}>
                <Typography variant={'subtitle1'}>
                  {t('modals.hcpNotVerified.accountCreated')}
                </Typography>
                <Typography variant={'subtitle2'}>
                  {t('modals.hcpNotVerified.waitForVerification')}
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={logOutUser}>
                  <LogoutIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Routes;
