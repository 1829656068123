import {
  Collapsible,
  CvdRiskChartWithRisk,
  DownloadIcon,
  GoalDetailsCard,
  usePrepareInDepthData,
} from '@hdcorner/ui-library';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import useTimeframe from '../../hooks/useTimeframe';
import { useAppSelector } from '../../redux/hooks';
import usePrepareGoalData from './hooks/usePrepareGoalData';
import {
  useDeleteCvdRiskLogMutation,
  useGetCvdCurrentMeasurementQuery,
  useGetCvdGraphDataQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdRiskLogsQuery,
  useGetCvdStartingMeasurementQuery,
} from './queries/cvdRiskQueries';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import DownloadButton from '../../components/DownloadButton';
import useIdealData from './hooks/useIdealData';
import usePrepareGraphData from './hooks/usePrepareGraphData';
import useAlert from '../../hooks/useAlert';
import { useNotifyUserMutation } from '../../redux/queries/queries';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

type Props = { userId: string };
const UserDataCvd: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [cvdRiskParams, setCvdRiskParams] = useState({
    skip: 0,
    limit: 10,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const [notify] = useNotifyUserMutation();
  const [deleteLogMutation] = useDeleteCvdRiskLogMutation();

  const { data: graphData, error: errorGraph } = useGetCvdGraphDataQuery({
    ...timeframe,
    userId,
  });
  const { data: currentMeasurement, error: errorCurrentMeasure } =
    useGetCvdCurrentMeasurementQuery({ userId });
  const { data: cvdRiskGoal, error: cvdRiskGoalError } = useGetCvdRiskGoalQuery({
    userId,
  });
  const {
    data: cvdRiskLogs,
    error: cvdRiskError,
    isFetching: cvdRiskFetching,
  } = useGetCvdRiskLogsQuery({
    userId,
    ...timeframe,
    ...cvdRiskParams,
  });
  const { data: cvdRiskFirstLog, error: cvdRiskFirstError } =
    useGetCvdStartingMeasurementQuery(
      {
        userId,
        startingDate: cvdRiskGoal?.[0]?.startDate,
      },
      { skip: Boolean(!cvdRiskGoal?.[0]) },
    );
  const { data: cvdRiskLastLog, error: cvdRiskLastError } =
    useGetCvdCurrentMeasurementQuery({ userId });

  const idealData = useIdealData(currentMeasurement);
  const prepareGraphData = usePrepareGraphData(graphData);
  const prepareInDepthData = usePrepareInDepthData(cvdRiskLogs);
  const prepareGoalData = usePrepareGoalData(
    cvdRiskGoal,
    cvdRiskFirstLog,
    cvdRiskLastLog,
  );

  useEffect(() => {
    if (
      cvdRiskError ||
      cvdRiskFirstError ||
      cvdRiskLastError ||
      cvdRiskGoalError ||
      errorGraph ||
      errorCurrentMeasure
    ) {
      presentError(t('errors.generic.error01'));
    }
  }, [
    cvdRiskError,
    cvdRiskFirstError,
    cvdRiskGoalError,
    cvdRiskLastError,
    errorGraph,
    errorCurrentMeasure,
  ]);

  const removeLog = (id: string) => {
    deleteLogMutation({ id, userId })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('cvd.text.logDeleted'),
            body: t('cvd.text.logDelBody'),
          },
        });
      })
      .catch(() => {
        presentError(t('errors.generic.error01'));
      });
  };

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
        <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
          <Box display={'flex'} flex={1} flexDirection={'column'}>
            <Title
              variant={'subtitle1'}
              textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            >
              {t('cvd.titles.riskCalc')}
            </Title>
            <CvdRiskChartWithRisk data={idealData} chartData={prepareGraphData} />
          </Box>
          <Box display={'flex'} flex={1} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Title
                variant={'subtitle1'}
                textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
              >
                {t('headingsTitles.goal')}
              </Title>
            </Box>
            <Box
              flex={1}
              display={'flex'}
              borderRadius={'8px'}
              flexDirection={'column'}
              padding={theme.spacing(1)}
              marginTop={theme.spacing(1)}
              bgcolor={theme.palette.secondary.light}
            >
              {prepareGoalData?.map((item, index) => (
                <Box key={`${item}${index}`} mt={theme.spacing(2)}>
                  <GoalDetailsCard goal={item} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
          <Box display={'flex'} flexDirection={'column'} flex={1} gap={theme.spacing(1)}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Title
                variant={'subtitle1'}
                textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
              >
                {t('headingsTitles.inDepth')}
              </Title>
              <DownloadButton
                web={true}
                onClick={() => setDownloadDataModalOpen(true)}
                startIcon={
                  <DownloadIcon
                    width={'14px'}
                    height={'15px'}
                    color={theme.palette.secondary.main}
                  />
                }
              >
                {t('buttons.dlData')}
              </DownloadButton>
            </Box>
            <InfiniteScrollList
              loading={cvdRiskFetching}
              skip={cvdRiskParams.skip}
              limit={cvdRiskParams.limit}
              totalCount={cvdRiskLogs?.count}
              count={cvdRiskLogs?.logs.length}
              onLoadMore={(skip, limit) => {
                setCvdRiskParams({ skip, limit });
              }}
            >
              {prepareInDepthData.map((item, index) => {
                const title = parseInt(item.values['Overall Risk'].value);
                return (
                  <Box key={index}>
                    <Collapsible
                      icon
                      titleSub={'%'}
                      data={item.values}
                      title={title.toString()}
                      progress={item.progress}
                      handleRemove={() => removeLog(item._id)}
                    />
                  </Box>
                );
              })}
            </InfiniteScrollList>
          </Box>
          <Box display={'flex'} flex={1} />
        </Box>
      </Box>
      <ModalInDepthDataDL
        module={'cvd'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </>
  );
};

export default UserDataCvd;
