import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Details returned from the API
interface addFoodDetails {
  _id: string;
  name: string;
  calories: number;
}

// Append items to POST to API
interface foodItem {
  _id: string;
  name: string;
  grams: number;
  calories: number;
  category: string;
}

// Collect existing meal items from API
interface existingFoodItems {
  food: string;
  portion: string;
  quantity: number;
}

const initialState: {
  mealID: string;
  logDate: string;
  foods: foodItem[];
  mealCategory: string;
  addFoodDetails: addFoodDetails;
  apiFoodArray: existingFoodItems[];
} = {
  foods: [],
  mealID: '',
  logDate: '',
  mealCategory: '',
  apiFoodArray: [],
  addFoodDetails: { _id: '', name: '', calories: 0 },
};

const mealFoodsSlice = createSlice({
  name: 'mealFoodsList',
  initialState,
  reducers: {
    addFoodItem: (state, action: PayloadAction<foodItem>) => {
      state.foods = [...state.foods, action.payload];
    },
    removeFoodItem: (state, action: PayloadAction<string>) => {
      const index = state.foods.findIndex(it => it._id === action.payload);
      if (index !== -1) {
        state.foods.splice(index, 1);
      }
    },
    deleteFoodArray: state => {
      state.foods = [];
    },
    updateCategory: (state, action: PayloadAction<string>) => {
      state.mealCategory = action.payload;
    },
    updateMealID: (state, action: PayloadAction<string>) => {
      state.mealID = action.payload;
    },
    updateMealLogDate: (state, action: PayloadAction<string>) => {
      state.logDate = action.payload;
    },
    deleteMealLogDate: state => {
      state.logDate = '';
    },
    updateAddFoodDetails: (state, action: PayloadAction<addFoodDetails>) => {
      state.addFoodDetails = action.payload;
    },
    createAPIFoodArray: (state, action: PayloadAction<existingFoodItems[]>) => {
      state.apiFoodArray = action.payload;
    },
    patchNewFoodArray: () => {},
  },
});

export const {
  addFoodItem,
  updateMealID,
  removeFoodItem,
  updateCategory,
  deleteFoodArray,
  deleteMealLogDate,
  updateMealLogDate,
  patchNewFoodArray,
  createAPIFoodArray,
  updateAddFoodDetails,
} = mealFoodsSlice.actions;

export default mealFoodsSlice.reducer;
