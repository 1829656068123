import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  ConduitFile,
  CustomButton,
  FilePreviewIcon,
  UserRecord,
} from '@hdcorner/ui-library';
import useAlert from '../../hooks/useAlert';
import { useGetFileUrlQuery } from './queries/recordsQueries';
import { downloadFile } from '../../utils/Filesystem';
import { bytesToSize, findCategoryLabel } from './utils';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedFile: UserRecord;
};
const ViewUserRecord: FC<Props> = ({ selectedFile }) => {
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();

  const { data: fileUrl } = useGetFileUrlQuery(
    { fileId: (selectedFile.file as ConduitFile)._id },
    {
      skip: !selectedFile.file,
    },
  );

  const handleDownloadFile = async () => {
    if (!fileUrl) return;
    const name = selectedFile.fileName;
    await downloadFile(fileUrl.result, name).catch(err => {
      console.log(err);
      presentError(t('errors.records.errorDL'));
    });
    presentSuccess(t('errors.records.successDL'));
  };

  return (
    <Box p={3} display={'flex'} flexDirection={'column'} gap={3} width={'auto'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Typography variant={'h6'}>{selectedFile.fileName}</Typography>
          <Typography variant={'subtitle2'}>
            {bytesToSize(selectedFile.fileSize)}
          </Typography>
        </Box>
        <Box>
          <Typography variant={'subtitle2'}>
            {t(findCategoryLabel(selectedFile.category))}
          </Typography>
        </Box>
      </Box>
      <Box maxHeight={'585px'} overflow={'hidden'} borderRadius={1}>
        {selectedFile.fileType === 'image' ? (
          <img
            src={fileUrl?.result}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={3}>
            <Box>
              <FilePreviewIcon />
            </Box>
            <Box>
              <CustomButton variant={'contained'} onClick={handleDownloadFile}>
                {t('buttons.dlFile')}
              </CustomButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ViewUserRecord;
