import { Box, Typography, useTheme } from '@mui/material';
import {
  Collapsible,
  DownloadIcon,
  FeelsEmpty,
  GoalDetailsCard,
  HBAC1Chart,
  InDepthDataCard,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import moment from 'moment';
import useTimeframe from '../../hooks/useTimeframe';
import {
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1LogsQuery,
  useGetHbac1OverallDataQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetHbacGoalQuery,
  useGetHcac1GraphDataQuery,
} from './queries/diabetesQueries';
import useGoalValue from './hooks/useGoalValue';
import useOverallData from './hooks/useOverallData';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useHbac1GoalData from './hooks/useHbac1GoalData';
import DownloadButton from '../../components/DownloadButton';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import usePrepareGraph from './hooks/usePrepareGraph';
import useAlert from '../../hooks/useAlert';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

type Props = { userId: string };
const Hbac1WebSection: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [startingDate, setStartingDate] = useState<string>('');
  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [hba1cParams, setHba1cParams] = useState({
    skip: 0,
    limit: 8,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: hbac1Goal, error: hbac1GoalError } = useGetHbacGoalQuery({ userId });
  const { data: overallData, error: errorOverall } = useGetHbac1OverallDataQuery({
    ...timeframe,
    userId,
  });
  const { data: hbacGraphData, error: errorHbacGraph } = useGetHcac1GraphDataQuery({
    ...timeframe,
    userId,
  });
  const { data: hbac1CurrentMeasurement, error: errorHbacCurrent } =
    useGetHbac1CurrentMeasurementQuery({
      userId,
    });
  const {
    data: hbac1Logs,
    error: errorHbacLog,
    isFetching: hbac1LogsFetching,
  } = useGetHbac1LogsQuery({
    params: { ...hba1cParams, ...timeframe, userId },
  });
  const { data: startingMeasurements, error: errorHbacStarting } =
    useGetHbac1StartingMeasurementQuery(
      { startDate: startingDate, userId },
      { skip: !startingDate },
    );

  const prepareGoalValue = useGoalValue(hbac1Goal);
  const prepareOverallData = useOverallData(overallData);
  const prepareGraphData = usePrepareGraph(hbacGraphData);
  const prepareCurrentMeasurement = useCurrentMeasurement(hbac1CurrentMeasurement);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);

  const prepareGoalDataValues = useHbac1GoalData(
    prepareGoalValue,
    hbac1Goal,
    prepareCurrentMeasurement,
    prepareStartingMeasurement,
  );

  useEffect(() => {
    if (hbac1Goal && hbac1Goal.length > 0) {
      const goal = hbac1Goal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [hbac1Goal]);

  useEffect(() => {
    if (
      hbac1GoalError ||
      errorOverall ||
      errorHbacLog ||
      hbac1GoalError ||
      errorHbacGraph ||
      errorHbacCurrent ||
      errorHbacStarting
    ) {
      presentError(t('errors.generic.error01'));
    }
  }, [
    errorOverall,
    errorHbacLog,
    hbac1GoalError,
    errorHbacGraph,
    errorHbacCurrent,
    errorHbacStarting,
  ]);

  const prepareData = useMemo(() => {
    if (!hbac1Logs || hbac1Logs.documents.length === 0) return [];

    return hbac1Logs.documents.map(item => {
      return {
        titleSub: t('measurements.mgdl'),
        title: item.measurement.toString(),
        data: {
          [t('dashboard.dashcards.diabetes.hba1c')]: {
            value: `${item.measurement.toString()}%`,
          },
          [t('inDepth.timeOfCalc')]: { value: moment(item.createdAt).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(item.createdAt).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [hbac1Logs]);

  const prepareOverallDataValues = useMemo(() => {
    if (!overallData)
      return [
        {
          name: t('general.labels.all'),
          min: t('general.labels.nA'),
          max: t('general.labels.nA'),
          avg: t('general.labels.nA'),
        },
      ];
    const data = overallData;
    return [
      {
        name: t('general.labels.all'),
        min: data.min ? data.min.toFixed() : t('general.labels.nA'),
        max: data.max ? data.max.toFixed() : t('general.labels.nA'),
        avg: data.avg ? data.avg.toFixed() : t('general.labels.nA'),
      },
    ];
  }, [overallData]);

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} gap={theme.spacing(3)}>
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
          }}
          variant={'subtitle1'}
        >
          {t('dashboard.dashcards.diabetes.hba1c')}
        </Typography>
        <HBAC1Chart
          chartData={prepareGraphData}
          overallData={prepareOverallData}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
      </Box>
      <Box display={'flex'} flex={1} flexDirection={'column'} gap={1}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography
            variant={'subtitle1'}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            sx={{
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {t('headingsTitles.inDepth')}
          </Typography>
          <DownloadButton
            web={true}
            onClick={() => setDownloadDataModalOpen(true)}
            startIcon={
              <DownloadIcon
                width={'14px'}
                height={'15px'}
                color={theme.palette.secondary.main}
              />
            }
          >
            {t('buttons.dlData')}
          </DownloadButton>
        </Box>
        <InDepthDataCard
          measurement={'%'}
          rows={prepareOverallDataValues}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
        />
        <InfiniteScrollList
          skip={hba1cParams.skip}
          limit={hba1cParams.limit}
          loading={hbac1LogsFetching}
          totalCount={hbac1Logs?.count}
          count={hbac1Logs?.documents.length}
          onLoadMore={(skip, limit) => {
            setHba1cParams({ skip, limit });
          }}
        >
          {prepareData.map((data, index) => (
            <Box sx={{ marginBottom: theme.spacing(1) }}>
              <Collapsible
                key={index}
                data={data.data}
                title={data.title}
                titleSub={data.titleSub}
                handleRemove={() => {
                  console.log('TODO: handle remove outliers');
                }}
              />
            </Box>
          ))}
        </InfiniteScrollList>
        {prepareData.length === 0 && <FeelsEmpty />}
        <Box
          flex={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginTop={theme.spacing(3)}
          marginBottom={theme.spacing(1)}
        >
          <Typography
            variant={'subtitle1'}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            sx={{
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {t('headingsTitles.goal')}
          </Typography>
        </Box>
        <Box
          borderRadius={'8px'}
          padding={theme.spacing(1)}
          bgcolor={theme.palette.secondary.light}
        >
          {prepareGoalDataValues?.map((item, index) => (
            <Box key={`${item}${index}`} mt={index === 1 ? 2 : 0}>
              <GoalDetailsCard goal={item} />
            </Box>
          ))}
        </Box>
      </Box>
      <ModalInDepthDataDL
        module={'lipid'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </Box>
  );
};

export default Hbac1WebSection;
