import { useEffect, useState } from 'react';
import { FilterOptions } from '../constants';
import { useTranslation } from 'react-i18next';

const useFilterLabel = (selectedFilter: string) => {
  const { t } = useTranslation();
  const [filterLabel, setFilterLabel] = useState<string>('');

  useEffect(() => {
    const filter = FilterOptions.find(option => option.value === selectedFilter);
    if (filter) {
      setFilterLabel(t(filter.label) as string);
    } else {
      setFilterLabel(t('constants.diabetes.timing.default') as string);
    }
  }, [selectedFilter]);

  return filterLabel;
};

export default useFilterLabel;
