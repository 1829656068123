import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import React, { useEffect } from 'react';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import AppStack from './AppStack';
import { ScreenOrientation } from '@capacitor/screen-orientation';

setupIonicReact({
  mode: 'ios',
  scrollPadding: false,
  scrollAssist: false,
});

const App: React.FC = () => {
  const capacitor = isPlatform('capacitor');

  useEffect(() => {
    if (capacitor) {
      ScreenOrientation.lock({ orientation: 'portrait' });
    }
  }, []);

  return (
    <IonApp>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IonReactRouter>
            <AppStack />
          </IonReactRouter>
        </ThemeProvider>
      </Provider>
    </IonApp>
  );
};

export default App;
