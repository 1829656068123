import {
  Collapsible,
  ComponentsLayout,
  DownloadIcon,
  FeelsEmpty,
  usePrepareInDepthData,
} from '@hdcorner/ui-library';
import { Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import PageLayout from '../../components/PageLayout';
import {
  useDeleteCvdRiskLogMutation,
  useGetCvdRiskLogsQuery,
} from './queries/cvdRiskQueries';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useNotifyUserMutation } from '../../redux/queries/queries';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const CvdRiskInDepthData: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const theme = useTheme();
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [cvdRiskParams, setCvdRiskParams] = useState({
    skip: 0,
    limit: 20,
  });

  const {
    data: cvdRiskLogs,
    error: cvdRiskError,
    isFetching: cvdRiskFetching,
  } = useGetCvdRiskLogsQuery({
    userId,
    ...cvdRiskParams,
  });

  const [notify] = useNotifyUserMutation();
  const [deleteLogMutation] = useDeleteCvdRiskLogMutation();

  useEffect(() => {
    if (cvdRiskError) {
      presentError(t('errors.generic.error01'));
    }
  }, [cvdRiskError]);

  const prepareData = usePrepareInDepthData(cvdRiskLogs);

  const removeLog = (id: string) => {
    deleteLogMutation({ id, userId })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('cvd.text.logDeleted'),
            body: t('cvd.text.logDelBody'),
          },
        });
      })
      .catch(() => {
        presentError(t('errors.generic.error01'));
      });
  };

  const EmptyData = useMemo(() => {
    return prepareData?.length ? (
      <Typography
        mt={2}
        variant={'body1'}
        fontWeight={theme.typography.fontWeightRegular}
      >
        {t('cvd.text.riskPercentage')}
      </Typography>
    ) : (
      <Box mt={2}>
        <FeelsEmpty />
      </Box>
    );
  }, [prepareData?.length, theme.typography.fontWeightRegular]);

  return (
    <PageLayout
      headerTitle={t('headingsTitles.inDepth')}
      defaultHref={`/dashboard/users/${userId}/cvd`}
      endButtons={[
        {
          icon: <DownloadIcon />,
          onClick: () => setDownloadDataModalOpen(true),
        },
      ]}
    >
      <ComponentsLayout>
        <Typography variant={'h1'} fontWeight={theme.typography.fontWeightMedium}>
          {t('headingsTitles.latestUpdates')}
        </Typography>
        {EmptyData}
        <InfiniteScrollList
          loading={cvdRiskFetching}
          skip={cvdRiskParams.skip}
          count={prepareData.length}
          limit={cvdRiskParams.limit}
          totalCount={cvdRiskLogs?.count}
          onLoadMore={(skip, limit) => setCvdRiskParams({ skip, limit })}
        >
          {prepareData.map((item: any, index: number) => {
            const title = parseInt(item.values['Overall Risk'].value);
            return (
              <Box key={index}>
                <Collapsible
                  icon
                  titleSub={'%'}
                  data={item.values}
                  title={title.toString()}
                  progress={item.progress}
                  handleRemove={() => removeLog(item._id)}
                />
              </Box>
            );
          })}
        </InfiniteScrollList>
      </ComponentsLayout>
      <ModalInDepthDataDL
        module={'cvd'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </PageLayout>
  );
};

export default CvdRiskInDepthData;
