import { CSSProperties, FC } from 'react';
import { BoxProps, styled, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';

const GraphDotContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'justify',
})<BoxProps & { justify: string }>(({ theme, justify }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  justifyContent: justify ? justify : 'unset',
}));

const GraphDot = styled('div', {
  shouldForwardProp: prop => prop !== 'customColor',
})<{ customColor?: CSSProperties['color'] }>(({ theme, customColor }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  boxSizing: 'border-box',
  backgroundColor: customColor ? customColor : theme.palette.primary.main,
}));

type GraphDotsProps = {
  justify: string;
  dots: { color: string; text: string }[];
};
const GraphDots: FC<GraphDotsProps> = ({ dots, justify = 'flex-start' }) => {
  const theme = useTheme();

  return (
    <GraphDotContainer justify={justify}>
      {dots.map(dot => (
        <>
          <GraphDot customColor={dot.color} />
          <Typography
            variant={'body1'}
            color={theme.palette.primary.main}
            fontWeight={theme.typography.subtitle1.fontWeight}
          >
            {dot.text}
          </Typography>
        </>
      ))}
    </GraphDotContainer>
  );
};

export default GraphDots;
