import PageLayout from '../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import FoodEquivalentsInner from './components/FoodEquivalentsInner';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const FoodEquivalents: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('fitness.calories.titles.foodEquiv')}
      defaultHref={`/dashboard/users/${userId}/fitness`}
    >
      <ComponentsLayout>
        <FoodEquivalentsInner userId={userId} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default FoodEquivalents;
