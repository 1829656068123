import InfiniteScrollList from '../../components/InfiniteScrollList';
import { Box } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  useDeleteHypertensionLogMutation,
  useGetHypertensionLogsQuery,
} from './queries/hypertensionQueries';
import moment from 'moment/moment';
import { Collapsible, FeelsEmpty } from '@hdcorner/ui-library';
import useAlert from '../../hooks/useAlert';
import { useNotifyUserMutation } from '../../redux/queries/queries';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
  endParam?: string;
  startParam?: string;
  selectedCategory?: string;
};
const LogsList: FC<Props> = ({
  userId,
  endParam,
  startParam,
  selectedCategory = 'all',
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [hypertensionParams, setHypertensionParams] = useState({
    skip: 0,
    limit: 20,
  });

  const [notify] = useNotifyUserMutation();
  const [deleteLogMutation] = useDeleteHypertensionLogMutation();

  const {
    error,
    data: hypertensionLogs,
    isFetching: hypertensionIsFetching,
  } = useGetHypertensionLogsQuery({
    userId,
    params: hypertensionParams,
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
    category: selectedCategory === 'all' ? undefined : selectedCategory,
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.errorFetchLog'));
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!hypertensionLogs) return [];

    return hypertensionLogs.documents.map(log => {
      return {
        _id: log._id,
        titleSub: t('measurements.kcal'),
        title: moment(log.logDate).format('MMMM Do, YYYY'),
        data: {
          [t('hypertension.labels.sysMmGH')]: { value: log.systolic.toString() },
          [t('hypertension.labels.diaMmGh')]: { value: log.diastolic.toString() },
          [t('dashboard.dashcards.hypertension.pulses')]: {
            value: log.pulses.toString(),
          },
          [t('goals.measurement')]: { value: log.category },
          [t('measurements.afib')]: { value: log.afib ? 'Yes' : '-' },
          [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(log.logDate).format('MMMM Do, YYYY'),
          },
        },
      };
    });
  }, [hypertensionLogs]);

  const removeLog = (id: string) => {
    deleteLogMutation({ id, userId })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('hypertension.notifications.logDel'),
            body: t('hypertension.notifications.logDelBody'),
          },
        });
      })
      .catch(() => {
        presentError(t('errors.generic.error01'));
      });
  };

  return (
    <Box>
      <InfiniteScrollList
        skip={hypertensionParams.skip}
        loading={hypertensionIsFetching}
        limit={hypertensionParams.limit}
        totalCount={hypertensionLogs?.count}
        count={hypertensionLogs?.documents.length}
        onLoadMore={(skip, limit) => setHypertensionParams({ skip, limit })}
      >
        {inDepthData.map((data, index) => (
          <Box>
            <Collapsible
              key={index}
              data={data.data}
              title={data.title}
              handleRemove={() => {
                removeLog(data._id);
              }}
            />
          </Box>
        ))}
      </InfiniteScrollList>
      {inDepthData.length === 0 && (
        <Box>
          <FeelsEmpty />
        </Box>
      )}
    </Box>
  );
};

export default LogsList;
