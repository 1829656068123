import api from '../../../redux/api';
import {
  ApiPagination,
  LipidGoal,
  LipidLog,
  LipidOverall,
  PaginationResult,
} from '@hdcorner/ui-library';

const lipidQueries = api
  .enhanceEndpoints({ addTagTypes: ['LipidLog', 'LipidGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      getLipidLogs: build.query<
        PaginationResult<LipidLog>,
        { params: ApiPagination; start?: string; end?: string; userId: string }
      >({
        query: ({ params, start, end, userId }) => ({
          method: 'GET',
          url: `database/function/getLipidLogs`,
          params: {
            ...params,
            endingDate: end,
            sort: '-logDate',
            startingDate: start,
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['LipidLog'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return (
            previousArg?.params.skip !== currentArg?.params.skip ||
            previousArg?.params.limit !== currentArg?.params.limit ||
            previousArg?.start !== currentArg?.start ||
            previousArg?.end !== currentArg?.end ||
            previousArg?.userId !== currentArg?.userId
          );
        },
      }),
      getLipidFirstLog: build.query<
        PaginationResult<LipidLog>,
        { startingDate: string; userId: string }
      >({
        query: ({ startingDate, userId }) => ({
          method: 'GET',
          url: `database/function/getLipidLogs`,
          params: {
            skip: 0,
            limit: 1,
            startingDate,
            sort: 'logDate',
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['LipidLog'],
      }),
      getLipidLastLog: build.query<PaginationResult<LipidLog>, { userId: string }>({
        query: ({ userId }) => ({
          url: `database/function/getLipidLogs`,
          method: 'GET',
          params: {
            skip: 0,
            limit: 1,
            sort: '-logDate',
            scope: `BasicHealth:${userId}`,
          },
        }),
        providesTags: ['LipidLog'],
      }),
      getLipidGoal: build.query<LipidGoal[], { goalType: string; userId: string }>({
        query: ({ goalType, userId }) => ({
          method: 'GET',
          url: `database/function/getUserGoal`,
          params: { goalType, scope: `Goal:${userId}` },
        }),
        providesTags: ['LipidGoal'],
      }),
      getOverallLipid: build.query<
        LipidOverall,
        { start?: string; end?: string; userId: string }
      >({
        query: ({ start, end, userId }) => ({
          method: 'GET',
          params: { start, end, userId },
          url: `hdCornerService/user/lipid/overall`,
        }),
        providesTags: ['LipidLog'],
      }),
      getLipidGraphData: build.query<
        { logs: LipidLog[] },
        {
          params: { metric: string | undefined; start?: string; end?: string };
          userId: string;
        }
      >({
        query: ({ params, userId }) => ({
          method: 'GET',
          params: { ...params, userId },
          url: `hdCornerService/user/lipid/graph`,
        }),
        providesTags: ['LipidLog'],
      }),
      // Used to make selected values of Lipid null to act like delete
      deleteLipidLog: build.mutation<
        string,
        { userId: string; _id: string; lipidType: string }
      >({
        query: ({ userId, _id, lipidType }) => ({
          method: 'DELETE',
          params: {
            userId,
            lipidType,
          },
          url: `/hdCornerService/hcp/lipid/${_id}`,
        }),
        invalidatesTags: ['LipidLog'],
      }),
    }),
  });

export const {
  useGetLipidLogsQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
  useGetOverallLipidQuery,
  useGetLipidFirstLogQuery,
  useDeleteLipidLogMutation,
  useGetLipidGraphDataQuery,
} = lipidQueries;

export default lipidQueries;
