import React, { FC, useEffect } from 'react';
import RecordsInner from './RecordsInner';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import PageLayout from '../../components/PageLayout';
import { useGetUserDetailsQuery } from '../Users/queries/usersQueries';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const Records: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const { error, data: userDetails } = useGetUserDetailsQuery({ userId });

  useEffect(() => {
    if (error) presentError(t('errors.generic.errorFetchUser'));
  }, [error]);

  return (
    <PageLayout
      headerTitle={
        userDetails?.data.authUser.firstName +
          ' ' +
          userDetails?.data.authUser.lastName ?? ''
      }
      defaultHref={'/dashboard/users-records'}
    >
      <RecordsInner userId={userId} />
    </PageLayout>
  );
};

export default Records;
