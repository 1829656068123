import {
  AdminTablePagination,
  AuthUser,
  ComponentsLayout,
  FeelsEmpty,
  HCPUser,
  HealthProfessionalCard,
  HealthProfTableHeading,
  HealthProfTableRows,
  theme,
} from '@hdcorner/ui-library';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Table, TableBody, useMediaQuery } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import { useGetConnectedUsersQuery } from './queries/usersQueries';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

type HeadCell = {
  _id: string;
  sort?: string;
  label: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
};

const skipAmount = 5;

const Users = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const { data: connectionsRes } = useGetConnectedUsersQuery({ skip: skip, limit: 12 });

  const connections = useMemo(() => {
    if (!connectionsRes) return [];
    return connectionsRes.documents;
  }, [connectionsRes]);

  const connectedUsers = useMemo(() => {
    return connections.map((connection: HCPUser) => connection.user);
  }, [connections]);

  const headCells: HeadCell[] = [
    { _id: 'name', label: t('personalDetails.name'), align: 'left', sort: '' },
    { _id: 'email', label: t('personalDetails.email'), align: 'left' },
    { _id: 'gender', label: t('personalDetails.gender'), align: 'center' },
    { _id: 'dateOfBirth', label: t('userDetails.dob'), align: 'center' },
    { _id: 'records', label: t('records.titles.records'), align: 'center' },
    {
      _id: 'latestRecord',
      label: t('dashboard.dashcards.health.latest'),
      align: 'center',
    },
    { _id: 'actions', label: t('personalDetails.actions'), align: 'center' },
  ];

  const handleNavigateToSingleUser = (id: string) => {
    router.push(`/dashboard/users/${id}/overview`);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createData = (user: AuthUser) => {
    return [
      user.firstName + ' ' + user.lastName,
      user.email || `${t('general.labels.nA')}`,
      user.gender || `${t('general.labels.nA')}`,
      moment(user.birthDate).format('DD/MM/YYYY'),
      '-',
      '-',
    ];
  };

  const handleClick = (id: string) => {
    router.push(`/dashboard/users/${id}/overview`);
  };

  const handleChat = (id: string) => {
    router.push(`/dashboard/chat/${id}`);
  };

  const convertUser = (user: AuthUser) => ({
    specialty: '',
    clinicalCoach: false,
    image: user.profilePicture?.url || '',
    name: user.firstName + ' ' + user.lastName,
  });

  const handleSkipClick = (amount: number) => {
    setCurrentPage(currentPage => currentPage + amount);
  };

  useEffect(() => {
    const apiSkip = (currentPage - 1) * 10;
    setSkip(apiSkip);
  }, [currentPage]);

  const numberOfPages = useMemo(() => {
    if (!connectionsRes) return 0;
    return Math.ceil(connectionsRes.count / 12);
  }, [connectionsRes]);

  return (
    <PageLayout headerTitle={'Users'} headerColor={theme.palette.primary.main}>
      <ComponentsLayout>
        {connectionsRes && connectionsRes.documents.length > 0 && (
          <>
            {desktop ? (
              <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
                <Table>
                  <HealthProfTableHeading
                    order={order}
                    orderBy={orderBy}
                    headCells={headCells}
                    onRequestSort={handleRequestSort}
                    totalRowsSelected={selected.length}
                    totalRowsCount={connectedUsers.length}
                    onCancelClick={() => setSelected([])}
                  />
                  <TableBody>
                    {connectedUsers.map((user: AuthUser) => (
                      <HealthProfTableRows
                        key={user._id}
                        rowCells={createData(user)}
                        selected={selected.length >= 1}
                        onClick={() => handleClick(user._id)}
                        avatar={user.profilePicture?.url || ''}
                        handleChatClick={() => handleChat(user._id)}
                      />
                    ))}
                  </TableBody>
                </Table>

                <AdminTablePagination
                  skipAmount={skipAmount}
                  currentPage={currentPage}
                  numberOfPages={numberOfPages}
                  onSkipClick={handleSkipClick}
                />
              </Box>
            ) : (
              <Box maxHeight={'calc(100vh - 80px)'} sx={{ overflowY: 'scroll' }}>
                {connectedUsers.map((user: AuthUser) => (
                  <HealthProfessionalCard
                    key={user._id}
                    data={convertUser(user)}
                    onClick={() => handleNavigateToSingleUser(user._id)}
                  />
                ))}
              </Box>
            )}
          </>
        )}

        {(!connectionsRes || connectionsRes.documents.length === 0) && <FeelsEmpty />}
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Users;
