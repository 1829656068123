import React, { FC } from 'react';
import { ComponentsLayout, theme } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import { Box, BoxProps, styled, useMediaQuery } from '@mui/material';
import DietCalculatorInitial from './DietCalculatorInitial';
import { useIonRouter } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps & { web: boolean }>(({ web }) => ({
  display: 'flex',
  margin: '0 auto',
  alignItems: 'center',
  justifyContent: 'center',
  height: web ? '100%' : 'auto',
  width: web ? '350px' : '100%',
}));

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const DietCalculatorInitialPage: FC<Props> = ({ match }) => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const web = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <PageLayout
      headerTitle={t('headingsTitles.dietPlan')}
      defaultHref={`/dashboard/users/${match.params.userId}/fitness`}
    >
      <ComponentsLayout height={'100%'} width={'100%'}>
        <BoxMain web={web}>
          <DietCalculatorInitial
            nextStepHandle={() => {
              router.push(
                `/dashboard/users/${match.params.userId}/fitness/nutrition/diet-plans/calculator`,
              );
            }}
          />
        </BoxMain>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DietCalculatorInitialPage;
