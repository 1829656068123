import React, { FC, useEffect, useState } from 'react';
import { AuthUser, CustomButton } from '@hdcorner/ui-library';
import { Box, BoxProps, ButtonProps, styled, Typography } from '@mui/material';
import {
  useCreateDietPlanMutation,
  useEditDietPlanMutation,
  useGetDietPlanQuery,
} from './queries/dietPlanQueries';
import { calculateCalories } from '../utils/caloriesFormula';
import DietCalculatorFirstStep from './DietCalculatorFirstStep';
import DietCalculatorSecondStep from './DietCalculatorSecondStep';
import { useGetUserDetailsQuery } from '../../Users/queries/usersQueries';
import moment from 'moment/moment';
import useAlert from '../../../hooks/useAlert';
import { useNotifyUserMutation } from '../../../redux/queries/queries';
import { useTranslation } from 'react-i18next';

const BoxContainer = styled(Box)<BoxProps & { inner: boolean }>(({ theme, inner }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  background: inner ? theme.palette.highlight.main : theme.palette.secondary.light,
}));

const Button = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 48,
  width: '240px',
  boxShadow: theme.shadows[0],
  color: theme.palette.secondary.main,
  background: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  padding: theme.spacing(1.5, 0),
}));

type Props = {
  userId: string;
  onClick?: () => void;
};

const DietCalculatorWeb: FC<Props> = ({ userId, onClick }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { data: dietPlan, error: errorDietPlan } = useGetDietPlanQuery({ userId });
  const { data: userDetails, error: errorUserDetails } = useGetUserDetailsQuery({
    userId,
  });

  const [notify] = useNotifyUserMutation();
  const [editCalculatedCalories] = useEditDietPlanMutation();
  const [setCalculatedCalories] = useCreateDietPlanMutation();

  useEffect(() => {
    if (errorDietPlan || errorUserDetails) presentError(t('errors.generic.error01'));
  }, [errorDietPlan, errorUserDetails]);

  const [userData, setUserData] = useState({
    sex: '',
    age: 0,
    weight: 0,
    height: 0,
  });

  const [changePerWeek, setChangePerWeek] = useState<number>(750);

  const [activityLevel, setActivityLevel] = useState<'light' | 'moderate' | 'heavy'>(
    'light',
  );
  const [goal, setGoal] = useState<'lose_weight' | 'muscle_gain' | 'maintain_weight'>(
    'maintain_weight',
  );
  const [dietType, setDietType] = useState<
    'standard' | 'low_carb' | 'low_fat' | 'high_protein'
  >('standard');

  useEffect(() => {
    if (userDetails && userDetails.data.authUser) {
      const user = userDetails.data;
      const authUser = userDetails.data.authUser as AuthUser;
      const age = moment().diff(moment(authUser.birthDate), 'years');
      setUserData({
        age: age,
        height: user.height as number,
        weight: user.weight as number,
        sex: authUser.gender as string,
      });
    }
  }, [userDetails]);

  const handleCalculate = () => {
    const data = {
      goal,
      dietType,
      changePerWeek,
      activityLevel,
      sex: userData.sex,
      age: userData.age,
      weight: userData.weight,
      height: userData.height,
    };

    const calories = calculateCalories(data);

    if (dietPlan && dietPlan.length > 0) {
      editCalculatedCalories({
        userId,
        dietType,
        calculatedCalories: calories,
      })
        .unwrap()
        .then(() => {
          notify({
            userId,
            payload: {
              title: t('fitness.dietPlan.notifications.dietEdited'),
              body: t('fitness.dietPlan.notifications.dietEditMessage'),
            },
          });
        })
        .catch(() => {
          presentError(t('errors.generic.error01'));
        });
    } else {
      setCalculatedCalories({
        userId,
        dietType,
        calculatedCalories: calories,
      })
        .unwrap()
        .then(() => {
          notify({
            userId,
            payload: {
              title: t('fitness.dietPlan.notifications.dietCreated'),
              body: t('fitness.dietPlan.notifications.dietCreatedMessage'),
            },
          });
        })
        .catch(() => {
          presentError(t('errors.generic.error01'));
        });
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <BoxContainer inner={false}>
      <Box
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          variant={'h1'}
          textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
        >
          {t('headingsTitles.personalDietCal')}
        </Typography>
        <Button
          fullWidth
          disableRipple
          disableElevation
          disableFocusRipple
          variant={'contained'}
          onClick={handleCalculate}
        >
          {t('buttons.calculate')}
        </Button>
      </Box>
      <BoxContainer inner>
        <DietCalculatorFirstStep user={userData} setUser={setUserData} />
      </BoxContainer>
      <BoxContainer inner>
        <DietCalculatorSecondStep
          goal={goal}
          setGoal={setGoal}
          dietType={dietType}
          setDietType={setDietType}
          activityLevel={activityLevel}
          changePerWeek={changePerWeek}
          setChangePerWeek={setChangePerWeek}
          setActivityLevel={setActivityLevel}
        />
      </BoxContainer>
    </BoxContainer>
  );
};

export default DietCalculatorWeb;
