import { useMemo } from 'react';
import moment from 'moment/moment';
import { useIonRouter } from '@ionic/react';
import { Box, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import PageLayout from '../../components/PageLayout';
import { MobileProfile, theme } from '@hdcorner/ui-library';
import WebAccountSettingsContainer from './components/WebAccountSettingsContainer';
import { logout } from '../authentication/authSlice';
import { useSignOutMutation } from '../authentication/queries/signInQueries';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();

  const web = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [logoutTrigger] = useSignOutMutation();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const getUser = useMemo(() => {
    if (user && user.authUser) {
      const authUser = user.authUser;
      return {
        verified: user.verified,
        image: authUser.profilePicture?.url || '',
        name: `${authUser.firstName} ${authUser.lastName}`,
        joined: moment(authUser.createdAt).format('DD/MM/YYYY'),
      };
    }

    return {
      name: '',
      image: '',
      joined: '',
      verified: false,
    };
  }, [user]);

  const getHcpCode = useMemo(() => {
    if (user && user.code) {
      return user.code;
    }

    return '';
  }, [user]);

  const handlePageRouting = (path: string) => {
    if (path === 'sign-in') {
      logoutTrigger()
        .unwrap()
        .finally(() => {
          dispatch(logout());
          router.push('/sign-in', 'root', 'replace');
        });
    } else {
      router.push(`/dashboard/${path}`);
    }
  };

  return (
    <PageLayout backgroundWhite={!web} headerTitle={t('profile.titles.myProf')}>
      <Box hidden={mobile} padding={3} boxSizing={'border-box'}>
        <WebAccountSettingsContainer />
      </Box>

      <Box hidden={web}>
        <MobileProfile
          healthProf
          data={getUser}
          id={getHcpCode}
          handlePageRouting={handlePageRouting}
        />
      </Box>
    </PageLayout>
  );
};

export default Profile;
