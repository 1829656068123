import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  ClickNavCardNutrition,
  ComponentsLayout,
  FeelsEmpty,
  HorizontalCalendar,
  theme,
} from '@hdcorner/ui-library';
import Box from '@mui/material/Box';
import { useIonRouter } from '@ionic/react';
import moment from 'moment/moment';
import PageLayout from '../../components/PageLayout';
import MedicationHeaderIcon from '../../assets/icons/MedicationHeaderIcon';
import NavCardMedication from './components/NavCardMedication';
import { useGetMedicationsQuery } from './queries/medicationQueries';
import extractMedicationState from './utils/extractMedicationState';
import { RouteComponentProps } from 'react-router';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}

const Medications: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());
  const [openMonthly, setOpenMonthly] = useState<boolean>(false);

  const { error, data: medicationRes } = useGetMedicationsQuery({
    userId,
    skip: 0,
    limit: 1000,
    endDate: moment(selectedDate).utc(true).endOf('day').toISOString(),
    startDate: moment(selectedDate).utc(true).startOf('day').toISOString(),
  });

  useEffect(() => {
    if (error) presentError(t('errors.medication.errorFetchMeds'));
  }, [error]);

  const medications = useMemo(() => {
    if (!medicationRes) return [];
    return medicationRes.documents;
  }, [medicationRes]);

  const handleShowAllMedicines = () => {
    router.push(`/dashboard/users/${userId}/medicines`);
  };

  const handleShowReminders = () => {
    router.push(`/dashboard/users/${userId}/reminders?medication=true`);
  };

  const handleAddMedication = () => {
    router.push(`/dashboard/users/${userId}/medicines?add=true`);
  };

  const handleShowMedicationSingle = (medicationId: string) => {
    router.push(`/dashboard/users/${userId}/medication/${medicationId}`);
  };

  const handleChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const renderMedications = useMemo(() => {
    if (medications.length === 0) return null;

    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={theme.spacing(1)}
        paddingBottom={theme.spacing(2)}
      >
        {medications.map((medication, index) => (
          <NavCardMedication
            medicineName={medication.medicine.medicineName}
            key={index}
            time={medication.time}
            checkLabel={medication.medicine.label as string}
            medicationState={extractMedicationState(medication)}
            onClickHandler={() => handleShowMedicationSingle(medication._id)}
            amount={`${medication.amount} (${medication.medicine.medicineType})`}
          />
        ))}
      </Box>
    );
  }, [medications, openMonthly, selectedDate]);

  return (
    <PageLayout
      headerTitle={'Medications'}
      fabClick={handleAddMedication}
      headerColor={theme.palette.medication.main}
      defaultHref={`/dashboard/users/${userId}/medication`}
      headerIcon={
        <MedicationHeaderIcon
          width={'20px'}
          height={'20px'}
          color={theme.palette.medication.main}
        />
      }
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Box
          display={'flex'}
          borderRadius={'8px'}
          flexDirection={'column'}
          sx={{ backgroundColor: theme.palette.secondary.light }}
        >
          <Box padding={theme.spacing(2, 2, 3, 2)}>
            <HorizontalCalendar
              openMonthly={openMonthly}
              selectedDate={selectedDate}
              setOpenMonthly={setOpenMonthly}
              handleChange={date => handleChange(date as Date)}
            />
          </Box>
          {renderMedications}
        </Box>
        {medications.length === 0 && <FeelsEmpty />}
        <Box marginTop={theme.spacing(1)}>
          <ClickNavCardNutrition
            onClick={handleShowAllMedicines}
            headings={t('buttons.navigation.showAllMed')}
          />
          <ClickNavCardNutrition
            onClick={handleShowReminders}
            headings={t('buttons.navigation.medReminders')}
          />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Medications;
