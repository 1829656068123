import api from '../../../../redux/api';
import { ApiPagination, Calories, PaginationResult } from '@hdcorner/ui-library';

const caloriesQueries = api
  .enhanceEndpoints({ addTagTypes: ['Calories'] })
  .injectEndpoints({
    endpoints: build => ({
      getCaloriesInDepth: build.query<
        PaginationResult<Calories>,
        { params: ApiPagination; start?: string; end?: string }
      >({
        query: ({ params, start, end }) => ({
          method: 'GET',
          params: { ...params, start, end },
          url: '/database/function/getCaloriesInDepth',
        }),
        providesTags: ['Calories'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
          currentCache.count = newItems?.count;
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return (
            currentArg?.end !== previousArg?.end ||
            currentArg?.start !== previousArg?.start ||
            currentArg?.params.sort !== previousArg?.params.sort ||
            currentArg?.params.skip !== previousArg?.params.skip ||
            currentArg?.params.limit !== previousArg?.params.limit
          );
        },
      }),
    }),
  });

export const { useGetCaloriesInDepthQuery } = caloriesQueries;

export default caloriesQueries;
