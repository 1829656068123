import React, { useEffect, useMemo, useState } from 'react';
import {
  CalendarModal,
  ComponentsLayout,
  dayWeekMonthYearCustom,
  DiabetesSQ,
  GoalsProgressSQ,
  GraphDownRed,
  GraphUpGreen,
  HealthProfDashboardCard,
  HypertensionSQ,
  LipidSQ,
  StableTrend,
  theme,
  ToggleButtons,
  UsersSQ,
} from '@hdcorner/ui-library';
import { Badge, BadgeProps, Box, styled, Typography, useMediaQuery } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import moment from 'moment';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import AveragesCard from './components/AveragesCard';
import {
  useGetDashboardDataQuery,
  useGetPatientsAnalyticsQuery,
} from './queries/dashboardQueries';
import useTimeframe from '../../hooks/useTimeframe';
import { useGetNotificationsQuery } from '../notifications/queries/notificationQueries';
import useAlert from '../../hooks/useAlert';
import FcmIntegration from '../pushNotifications/PushNotifications';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const BadgeMain = styled(Badge)<BadgeProps>(({ theme }) => ({
  '.MuiBadge-badge': {
    border: '1px solid white',
    margin: theme.spacing(1, 0.7, 0, 0),
  },
}));

const Dashboard = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [customEndDate, setCustomEndDate] = useState<string>('');
  const [activeFilter, setActiveFilter] = useState<string>('day');
  const [customStartDate, setCustomStartDate] = useState<string>('');
  const [calendarModal, setCalendarModal] = useState<boolean>(false);

  const timeframe = useTimeframe(activeFilter, customStartDate, customEndDate);

  const { data: patientsAnalyticsRes, error: errorAnalytics } =
    useGetPatientsAnalyticsQuery();
  const { data: averagesRes, error: errorAvg } = useGetDashboardDataQuery({
    ...timeframe,
  });

  const { data: notificationRes, error: errorNotifications } = useGetNotificationsQuery({
    skip: 0,
    limit: 100,
  });

  useEffect(() => {
    if (errorAnalytics || errorAvg || errorNotifications)
      presentError(t('errors.generic.error01'));
  }, [errorAnalytics, errorAvg, errorNotifications]);

  const notifications = useMemo(() => {
    if (!notificationRes) return 0;
    return notificationRes.count;
  }, [notificationRes]);

  const hypertensionAverages = useMemo(() => {
    const systolic = {
      details: '< 120',
      title: t('dashboard.dashcards.hypertension.systolic'),
      value: (averagesRes?.hypertension?.systolic?.toFixed() || '0') + '%',
    };
    const diastolic = {
      details: '< 80',
      title: t('dashboard.dashcards.hypertension.diastolic'),
      value: (averagesRes?.hypertension?.diastolic?.toFixed() || '0') + '%',
    };

    return [systolic, diastolic];
  }, [averagesRes?.hypertension.systolic, averagesRes?.hypertension.diastolic]);

  const lipidAverages = useMemo(() => {
    const chol = {
      title: t('dashboard.dashcards.lipid.chol'),
      details: `< 200 ${t('measurements.mgdl')}`,
      value: (averagesRes?.lipid?.chol?.toFixed() || '0') + '%',
    };

    const hdl = {
      title: t('dashboard.dashcards.lipid.hdl'),
      details: `< 150 ${t('measurements.mgdl')}`,
      value: (averagesRes?.lipid?.hdl?.toFixed() || '0') + '%',
    };

    const ldl = {
      title: t('dashboard.dashcards.lipid.ldl'),
      details: `< 35 ${t('measurements.mgdl')}`,
      value: (averagesRes?.lipid?.ldl?.toFixed() || '0') + '%',
    };

    const trg = {
      title: t('dashboard.dashcards.lipid.trg'),
      details: `< 130 ${t('measurements.mgdl')}`,
      value: (averagesRes?.lipid?.trg?.toFixed() || '0') + '%',
    };

    const lpa = {
      title: t('dashboard.dashcards.lipid.lpa'),
      details: `< 100 ${t('measurements.mgdl')}`,
      value: (averagesRes?.lipid?.lpa?.toFixed() || '0') + '%',
    };

    return [chol, hdl, ldl, trg, lpa];
  }, [
    averagesRes?.lipid?.chol,
    averagesRes?.lipid?.hdl,
    averagesRes?.lipid?.ldl,
    averagesRes?.lipid?.trg,
    averagesRes?.lipid?.lpa,
  ]);

  const diabetesAverages = useMemo(() => {
    const bloodGlucose = {
      details: `72 - 106 ${t('measurements.mgdl')}`,
      title: t('dashboard.dashcards.diabetes.glucose'),
      value: (averagesRes?.diabetes?.glucose?.toFixed() || '0') + '%',
    };

    const hba1c = {
      details: '< 5.9%',
      title: t('dashboard.dashcards.diabetes.hba1c'),
      value: (averagesRes?.diabetes?.hba1c?.toFixed() || '0') + '%',
    };
    return [bloodGlucose, hba1c];
  }, [averagesRes?.diabetes?.glucose, averagesRes?.diabetes?.hba1c]);

  const prepareTotalUsers = useMemo(() => {
    const values = patientsAnalyticsRes ? patientsAnalyticsRes[0] : undefined;
    return {
      count: values?.totalUsers?.measurement || 0,
      direction: values?.totalUsers?.trend || undefined,
      date: values?.updatedAt ? moment(values?.updatedAt).format('MMM YYYY') : '-',
    };
  }, [patientsAnalyticsRes]);

  const prepareGoalsInProgress = useMemo(() => {
    const values = patientsAnalyticsRes ? patientsAnalyticsRes[0] : undefined;
    return {
      count: values?.goalsInProgress?.measurement || 0,
      direction: values?.goalsInProgress?.trend || undefined,
      date: values?.updatedAt ? moment(values?.updatedAt).format('MMM YYYY') : '-',
    };
  }, [patientsAnalyticsRes]);

  const chooseIcon = (direction: 'ascending' | 'descending' | 'stable' | undefined) => {
    switch (direction) {
      case 'ascending':
        return <GraphUpGreen />;
      case 'descending':
        return <GraphDownRed />;
      case 'stable':
        return <StableTrend />;
      default:
        return null;
    }
  };

  const onCustomDatesChange = (dates: string[]) => {
    let start, end;
    end = dates[1];
    start = dates[0];
    setCustomEndDate(end);
    setCustomStartDate(start);
  };

  const onDateFilterChange = (newFilter: string) => {
    if (newFilter === 'custom') {
      setCalendarModal(true);
    }
    setActiveFilter(newFilter);
  };

  const handleClickNotifications = () => {
    router.push('/dashboard/notifications');
  };

  return (
    <PageLayout
      headerTitle={'Home'}
      endButtons={[
        {
          icon: (
            <BadgeMain
              color="error"
              variant="dot"
              invisible={notifications === 0}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <NotificationsNoneOutlinedIcon htmlColor={theme.palette.primary.main} />
            </BadgeMain>
          ),
          onClick: handleClickNotifications,
        },
      ]}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} flex={1} alignItems={'center'} flexWrap={'wrap'} gap={2}>
            <HealthProfDashboardCard
              expanded={web}
              icon={<UsersSQ />}
              date={prepareTotalUsers.date}
              num={prepareTotalUsers.count}
              heading={`${t('dashboard.labels.totalUsers')}`}
              iconTwo={chooseIcon(prepareTotalUsers.direction)}
            />
            <HealthProfDashboardCard
              expanded={web}
              icon={<GoalsProgressSQ />}
              date={prepareGoalsInProgress.date}
              num={prepareGoalsInProgress.count}
              heading={`${t('dashboard.labels.goalsProgress')}`}
              iconTwo={chooseIcon(prepareGoalsInProgress.direction)}
            />
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Box
              sx={{
                display: 'flex',
                borderRadius: '8px',
                alignItems: 'center',
                gap: theme.spacing(3),
                padding: theme.spacing(2),
                backgroundColor: theme.palette.secondary.light,
              }}
            >
              <ToggleButtons
                buttonBorder
                value={activeFilter}
                size={web ? 'large' : 'small'}
                handleChange={onDateFilterChange}
                headings={useConvertJSON(
                  dayWeekMonthYearCustom.filter((it: any) => it.value !== 'all'),
                )}
              />
            </Box>
            <Box>
              <Typography variant={'subtitle1'} fontWeight={'fontWeightMedium'}>
                {t('dashboard.labels.userAvg')}
              </Typography>
            </Box>
            <Box display={'flex'} flexWrap={'wrap'} gap={2}>
              <Box flexGrow={1}>
                <AveragesCard
                  icon={<DiabetesSQ />}
                  averages={diabetesAverages}
                  color={theme.palette.diabetes.main}
                  title={t('dashboard.dashcards.diabetes.title')}
                />
              </Box>
              <Box flexGrow={1}>
                <AveragesCard
                  icon={<HypertensionSQ />}
                  averages={hypertensionAverages}
                  color={theme.palette.hypertension.main}
                  title={t('dashboard.dashcards.hypertension.title')}
                />
              </Box>
              <Box flexGrow={1}>
                <AveragesCard
                  icon={<LipidSQ />}
                  averages={lipidAverages}
                  color={theme.palette.lipid.main}
                  title={t('dashboard.dashcards.lipid.title')}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ComponentsLayout>
      <CalendarModal
        open={calendarModal}
        setOpen={setCalendarModal}
        saveDate={onCustomDatesChange}
        selectedDate={[customStartDate, customEndDate]}
      />
      <FcmIntegration />
    </PageLayout>
  );
};

export default Dashboard;
