import { Avatar, Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import ArrowLeft from '../../assets/ArrowLeft';
import PlanBadge from '../../components/PlanBadge';
import ToggleButtonGroupCu from '../../components/layout/ToggleButtonGroupCu';

type UserDownloadProps = {
  name: string;
  avatar?: string;
  userFilter?: string;
  backArrow: () => void;
  isUserPremium: boolean;
  setUserFilter?: (filter: string) => void;
  headings?: string[] | { label: string; value: string }[];
};

const UserDownload: FC<UserDownloadProps> = ({
  name,
  avatar,
  headings,
  backArrow,
  userFilter,
  isUserPremium,
  setUserFilter,
}) => {
  const theme = useTheme();

  const handleGoBack = () => {
    backArrow();
  };

  return (
    <Box
      height={'80px'}
      display={'flex'}
      borderRadius={'8px'}
      alignItems={'center'}
      gap={theme.spacing(3)}
      marginBottom={theme.spacing(3)}
      padding={theme.spacing(2, 3)}
      sx={{ backgroundColor: theme.palette.secondary.light }}
    >
      <IconButton onClick={handleGoBack}>
        <ArrowLeft />
      </IconButton>
      {avatar && <Avatar src={avatar} />}
      <Typography
        variant={'subtitle2'}
        color={theme.palette.primary.main}
        fontWeight={theme.typography.fontWeightBold}
      >
        {name}
      </Typography>
      <PlanBadge premium={isUserPremium} />
      {setUserFilter && (
        <ToggleButtonGroupCu
          setValue={setUserFilter}
          value={userFilter ?? ''}
          headings={headings ? headings : ['data', 'user info', 'devices']}
        />
      )}
      {/*<Box marginLeft="auto" display={'flex'} alignItems={'center'}>*/}
      {/*  <IconButton onClick={handleDownload} disableRipple>*/}
      {/*    <DownloadIcon />*/}
      {/*    <Typography*/}
      {/*      variant={'subtitle2'}*/}
      {/*      color={theme.palette.primary.main}*/}
      {/*      fontWeight={theme.typography.fontWeightBold}*/}
      {/*      marginLeft={theme.spacing(1)}*/}
      {/*    >*/}
      {/*      Download user data*/}
      {/*    </Typography>*/}
      {/*  </IconButton>*/}
      {/*</Box>*/}
    </Box>
  );
};

export default UserDownload;
