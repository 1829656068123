import React from 'react';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import { SettingsPanelMobile } from '@hdcorner/ui-library';
import { useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

const AccountSettings = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const handleRouting = (route: string) => {
    router.push(`/dashboard/account-settings/${route}`);
  };

  return (
    <PageLayout headerTitle={t('headingsTitles.accSettings')} backgroundWhite>
      <SettingsPanelMobile
        handleRouting={handleRouting}
        phone={user?.phone || t('general.labels.nA')}
        email={user?.authUser.email || t('general.labels.nA')}
      />
    </PageLayout>
  );
};

export default AccountSettings;
