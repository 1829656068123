import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useIonRouter } from '@ionic/react';
import UserDetailsOne from './UserDetailsOne';
import UserDetailsTwo from './UserDetailsTwo';
import { Box, Typography } from '@mui/material';
import useAlert from '../../../../hooks/useAlert';
import useQuery from '../../../../hooks/useQuery';
import OnBoardingHeading from './OnBoardingHeading';
import { AuthUser, HealthProfessionalsData, theme } from '@hdcorner/ui-library';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import {
  useCalculateLocationDataMutation,
  useSetOnboardingOneMutation,
  useSetOnboardingTwoMutation,
} from '../../queries/onBoardingQueries';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const OnBoardingPanelWeb = () => {
  const router = useIonRouter();
  const searchParams = useQuery();
  const { t } = useTranslation();

  const { presentError } = useAlert();

  const [count, setCount] = useState<number>(1);

  const [onboardingPtOne] = useSetOnboardingOneMutation();
  const [onboardingPtTwo] = useSetOnboardingTwoMutation();
  const [calculateLocationData] = useCalculateLocationDataMutation();

  useEffect(() => {
    if (searchParams.get('incomplete')) {
      setCount(2);
    }
  }, [searchParams]);

  const handleNextPage = (data: any) => {
    if (count === 2) {
      sendUserDataTwo(data);
    } else {
      sendUserDataOne(data);
    }
  };

  const sendUserDataOne = async (data: Omit<AuthUser, '_id' | 'email' | 'createdAt'>) => {
    try {
      const body = {
        gender: data.gender,
        lastName: data.lastName,
        firstName: data.firstName,
      };
      await onboardingPtOne(body).unwrap();
      setCount(count + 1);
    } catch (e) {
      console.log(e);
      presentError(t('errors.authentication.errorCreateAcc'));
    }
  };

  const sendUserDataTwo = async (data: any) => {
    try {
      const code = uuidv4();
      const hcpData: Omit<HealthProfessionalsData, 'authUser' | '_id' | 'verified'> = {
        code,
        vat: data.vat,
        area: data.area,
        phone: data.phone,
        address: data.address,
        speciality: data.speciality,
        postalCode: data.postalCode,
        addressNum: data.addressNum,
        clinicalCoach: data.clinicalCoach,
      };
      await onboardingPtTwo(hcpData).unwrap();

      if (data.clinicalCoach) {
        calculateLocationData()
          .unwrap()
          .catch(e => {
            console.log(e);
            presentError(t('errors.authentication.errorCreateLocation'));
          });
      }

      router.push('/dashboard');
    } catch (e) {
      console.log(e);
      presentError(t('errors.authentication.errorCreateAcc'));
    }
  };

  return (
    <Box
      height={'100%'}
      flexWrap={'wrap'}
      maxWidth={'560px'}
      overflow={'scroll'}
      boxSizing={'border-box'}
      alignContent={'space-between'}
      margin={theme.spacing(0, 'auto')}
      padding={theme.spacing(10, 13, 3, 13)}
      sx={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box marginBottom={3}>
        <Box textAlign={'center'}>
          <img src={LogoHeartPNG} alt={'company logo'} width={'78px'} height={'80px'} />
        </Box>
        <OnBoardingHeading web />
        {count === 1 && <UserDetailsOne handleNextPage={handleNextPage} />}
        {count === 2 && <UserDetailsTwo handleNextPage={handleNextPage} />}
      </Box>
      <Box textAlign={'center'}>
        <Typography
          variant={'body1'}
          sx={{
            color: theme.palette.primary.light,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {t('karabinis.footer', { year: moment().year() })}
        </Typography>
      </Box>
    </Box>
  );
};

export default OnBoardingPanelWeb;
