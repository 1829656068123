import * as React from 'react';
import { SVGProps } from 'react';

const ArrowBackwardDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M8.95647 4.23827C9.31502 3.92399 9.36346 3.36349 9.06467 2.98636C8.76589 2.60923 8.23302 2.55827 7.87447 2.87255L2.80407 7.31698C2.6114 7.48586 2.5 7.73603 2.5 7.99984C2.5 8.26364 2.6114 8.51381 2.80407 8.6827L7.87447 13.1271C8.23302 13.4414 8.76589 13.3904 9.06467 13.0133C9.36346 12.6362 9.31501 12.0757 8.95647 11.7614L4.66511 7.99984L8.95647 4.23827Z"
        fill={props?.color ?? '#B2B8BD'}
      />
      <path
        d="M14.1959 4.23827C14.5545 3.92399 14.6029 3.36349 14.3041 2.98636C14.0053 2.60923 13.4725 2.55827 13.1139 2.87255L8.0435 7.31698C7.85084 7.48586 7.73944 7.73603 7.73944 7.99984C7.73944 8.26364 7.85084 8.51381 8.0435 8.6827L13.1139 13.1271C13.4725 13.4414 14.0053 13.3904 14.3041 13.0133C14.6029 12.6362 14.5545 12.0757 14.1959 11.7614L9.90454 7.99984L14.1959 4.23827Z"
        fill={props?.color ?? '#B2B8BD'}
      />
    </g>
  </svg>
);

export default ArrowBackwardDouble;
