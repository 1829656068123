import { ComponentsLayout, CustomTabs, theme } from '@hdcorner/ui-library';
import * as React from 'react';
import { FC, useState } from 'react';
import DiabetesTabHBAC1 from './DiabetesTabHBAC1';
import DiabetesHeaderIcon from '../../assets/icons/DiabetesHeaderIcon';
import PageLayout from '../../components/PageLayout';
import DiabetesBloodGlucose from './DiabetesBloodGlucose';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const Diabetes: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <PageLayout
      headerColor={theme.palette.diabetes.main}
      defaultHref={`/dashboard/users/${userId}/overview`}
      headerTitle={t('dashboard.dashcards.diabetes.title')}
      headerIcon={<DiabetesHeaderIcon color={theme.palette.diabetes.main} />}
    >
      <ComponentsLayout>
        <CustomTabs
          value={selectedTab}
          setValue={setSelectedTab}
          tabs={[
            {
              label: t('dashboard.dashcards.diabetes.glucose'),
              children: <DiabetesBloodGlucose userId={userId} />,
            },
            {
              label: t('dashboard.dashcards.diabetes.hba1c'),
              children: <DiabetesTabHBAC1 userId={userId} />,
            },
          ]}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Diabetes;
