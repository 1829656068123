import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { RootState } from './store';
import {
  logout,
  setAccessToken,
  setRefreshToken,
} from '../features/authentication/authSlice';

const BASE_URL = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const authState = (getState() as RootState).auth;
    const {
      data: { token, refreshToken },
    } = authState;

    const authToken = endpoint === '/authentication/renew' ? refreshToken : token;
    if (token) {
      headers.set('authorization', `Bearer ${authToken}`);
    }

    // Disables caching for all requests
    headers.set('cache-control', 'no-cache, no-store, must-revalidate');
    headers.set('pragma', 'no-cache');
    headers.set('expires', '0');
    return headers;
  },
  cache: 'no-cache',
});

const mutex = new Mutex();
const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  args = injectScopes(args as FetchArgs, api.getState() as RootState);
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            url: '/authentication/renew',
          },
          { ...api, endpoint: '/authentication/renew' },
          extraOptions,
        );
        const refreshRes = refreshResult as any;
        if (refreshRes.data) {
          // update the tokens in redux store
          api.dispatch(setAccessToken(refreshRes.data.accessToken));
          api.dispatch(setRefreshToken(refreshRes.data.refreshToken));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const injectScopes = (args: FetchArgs, state: RootState): FetchArgs => {
  if (!state.auth.data.user) return args;
  const userId = state.auth.data.user.authUser._id;

  switch (args.url) {
    case '/database/function/getUserRecordHcp':
      args.params = { ...args.params, scope: `HealthProfessional:${userId}` };
      break;
  }

  return args;
};

const api = createApi({
  reducerPath: 'api',
  endpoints: () => ({}),
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['AuthUser', 'HealthProfessionalData', 'Notification'],
});

export default api;
