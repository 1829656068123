const OverviewIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.588 2C10.5156 2.00318 7.57004 3.22507 5.39756 5.39756C3.22507 7.57004 2.00318 10.5156 2 13.588C2 13.8532 2.10536 14.1076 2.29289 14.2951C2.48043 14.4826 2.73478 14.588 3 14.588H5.241C5.42817 16.155 6.0525 17.6376 7.0426 18.8665C8.0327 20.0953 9.34861 21.0208 10.8399 21.537C12.3311 22.0532 13.9376 22.1394 15.4755 21.7857C17.0135 21.4319 18.4208 20.6526 19.5367 19.5367C20.6526 18.4208 21.4319 17.0135 21.7857 15.4755C22.1394 13.9376 22.0532 12.3311 21.537 10.8399C21.0208 9.34861 20.0953 8.0327 18.8665 7.0426C17.6376 6.0525 16.155 5.42817 14.588 5.241V3C14.588 2.73478 14.4826 2.48043 14.2951 2.29289C14.1076 2.10536 13.8532 2 13.588 2ZM4.052 12.588C4.28329 10.4032 5.25694 8.36392 6.81043 6.81043C8.36392 5.25694 10.4032 4.28329 12.588 4.052V12.588H4.052ZM20 13.588C20.0008 15.2012 19.3931 16.7554 18.2982 17.9403C17.2033 19.1251 15.7018 19.8533 14.0935 19.9796C12.4852 20.1058 10.8885 19.6208 9.62211 18.6214C8.35574 17.6219 7.51294 16.1816 7.262 14.588H13.588C13.8532 14.588 14.1076 14.4826 14.2951 14.2951C14.4826 14.1076 14.588 13.8532 14.588 13.588V7.262C16.0948 7.50094 17.4673 8.26895 18.4591 9.42826C19.4509 10.5876 19.9972 12.0623 20 13.588Z"
        fill="#222222"
      />
    </svg>
  );
};

export default OverviewIcon;
