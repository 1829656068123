import { Box, Grid } from '@mui/material';
import MetricsTable from './MetricsTable';
import { useMemo } from 'react';
import moment from 'moment';
import { LineChart, theme, User24HourHypertension } from '@hdcorner/ui-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const TwentyFourHoursReport = ({ report }: { report?: User24HourHypertension }) => {
  const { t } = useTranslation();

  const ambulatoryBloodPressureMeasurementReport = useMemo<{
    data: any[];
    headings: any[];
  }>(() => {
    if (!report)
      return {
        data: [
          ['Date', ''],
          ['Health professional', ''],
        ],
        headings: ['', ''],
      };
    const date = moment(report.logDate).format('DD/MM/YYYY');
    const hcpName = report.info.physician;
    const data = [
      ['Date', date],
      ['Health professional', hcpName],
    ];
    const headings = ['', ''];
    return { data, headings };
  }, [report]);

  const bpThreshold = useMemo<{
    data: any[];
    headings: any[];
  }>(() => {
    if (!report)
      return {
        data: [
          ['Day', '- mmHG'],
          ['Night', '- mmHG'],
        ],
        headings: ['', ''],
      };

    const day = report.info.daySys + '/' + report.info.dayDia + ' mmHG';
    const night = report.info.nightSys + '/' + report.info.nightDia + ' mmHG';
    return {
      data: [
        ['Day', day],
        ['Night', night],
      ],
      headings: ['', ''],
    };
  }, [report]);

  const dayNightReal = useMemo<{
    data: any[];
    headings: any[];
  }>(() => {
    if (!report)
      return {
        data: [
          ['Day', '-'],
          ['Night', '-'],
        ],
        headings: ['', 'Time'],
      };

    const day = report.info.trueAwakeTime + ' - ' + report.info.trueAsleepTime;
    const night = report.info.trueAsleepTime + ' - ' + report.info.trueAwakeTime;

    return {
      data: [
        ['Day', day],
        ['Night', night],
      ],
      headings: ['', 'Time'],
    };
  }, [report]);

  const dayNightAdjusted = useMemo<{
    data: any[];
    headings: any[];
  }>(() => {
    if (!report)
      return {
        data: [
          ['Day', '-', '- minutes'],
          ['Night', '-', '- minutes'],
        ],
        headings: ['', 'Time', 'Measurement every'],
      };
    const dayInterval = report.info.dayInterval;
    const nightInterval = report.info.nightInterval;
    const day = report.info.awakeTime + ' - ' + report.info.asleepTime;
    const night = report.info.asleepTime + ' - ' + report.info.awakeTime;

    return {
      data: [
        ['Day', day, dayInterval + ' minutes'],
        ['Night', night, nightInterval + ' minutes'],
      ],
      headings: ['', 'Time', 'Measurement every'],
    };
  }, [report]);

  const whiteCoatWindow = useMemo<{
    data: any[];
    headings: any[];
  }>(() => {
    if (!report)
      return {
        data: [
          ['Readings', '-', '-', '-'],
          ['First hour max', '-', '-', '-'],
        ],
        headings: ['', 'SYS', 'DIA', 'Pulses'],
      };
    const readings = report.results.whiteCoatWindow?.readings;
    const firstHourMax = report.results.whiteCoatWindow.firstHourMax;
    return {
      data: [
        ['Readings', readings.systolic, readings.diastolic, readings.bpm],
        [
          'First hour max',
          firstHourMax.systolic,
          firstHourMax.diastolic,
          firstHourMax.bpm,
        ],
      ],
      headings: ['', 'SYS', 'DIA', 'Pulses'],
    };
  }, [report]);

  const nightTimeDip = useMemo<{
    data: any[];
    headings: any[];
  }>(() => {
    if (!report)
      return {
        data: [['Dip%', '0%', '0%']],
        headings: ['', 'SYS', 'DIA'],
      };
    const nightDip = report.results.nightDip;
    return {
      data: [['Dip%', nightDip.systolic + '%', nightDip.diastolic + '%']],
      headings: ['', 'SYS', 'DIA'],
    };
  }, [report]);

  const readings = useMemo<{ data: any[]; headings: any[] }>(() => {
    if (!report)
      return {
        data: [
          ['Total readings', '-'],
          ['Succesfull', '-'],
          ['Afib', '-'],
          ['Day readings above threshold 85/135', '-%'],
          ['Night readings above threshold 70/120', '-%'],
        ],
        headings: ['', ''],
      };
    const totalReadings = report.results.readings.total;
    const successfulReadings = report.results.readings.successful;
    const afibReadings = report.results.readings.afib;
    const dayReadingsPercentage = report.results.readings.dayThresholdPercentage + '%';
    const nightReadingsPercentage =
      report.results.readings.nightThresholdPercentage + '%';

    return {
      data: [
        ['Total readings', totalReadings],
        ['Successful', successfulReadings],
        ['Afib', afibReadings],
        ['Day readings above threshold 85/135', dayReadingsPercentage],
        ['Night readings above threshold 70/120', nightReadingsPercentage],
      ],
      headings: ['', ''],
    };
  }, [report]);

  const averageBloodPressure = useMemo<{
    data: any[];
    headings: any[];
  }>(() => {
    if (!report)
      return {
        data: [
          ['24 hr', '-', '-', '-', '-', '-', '-%'],
          ['Day', '-', '-', '-', '-', '-', '-%'],
          ['Asleep', '-', '-', '-', '-', '-', '-%'],
        ],
        headings: ['', 'SYS', 'DIA', 'Pulse', 'MAP', 'PP', 'Afib'],
      };
    return {
      data: [
        [
          '24 hr',
          report.results.average.all.systolic,
          report.results.average.all.diastolic,
          report.results.average.all.bpm,
          report.results.average.all.map,
          report.results.average.all.pp,
          report.results.average.all.afibSum,
        ],
        [
          'Day',
          report.results.average.day.systolic,
          report.results.average.day.diastolic,
          report.results.average.day.bpm,
          report.results.average.day.map,
          report.results.average.day.pp,
          report.results.average.day.afibSum,
        ],
        [
          'Asleep',
          report.results.average.night.systolic,
          report.results.average.night.diastolic,
          report.results.average.night.bpm,
          report.results.average.night.map,
          report.results.average.night.pp,
          report.results.average.night.afibSum,
        ],
      ],
      headings: ['', 'SYS', 'DIA', 'Pulse', 'MAP', 'PP', 'Afib'],
    };
  }, [report]);

  const logs = useMemo<{ data: any[]; headings: any[] }>(() => {
    if (!report)
      return {
        data: [],
        headings: ['#', 'Date', 'Systolic', 'Diastolic', 'Pulses', 'MAP', 'Afib'],
      };

    return {
      data: report.logs.map((log, index) => [
        index + 1,
        moment(log.logDate).format('DD/MM/YYYY HH:mm'),
        log.systolic,
        log.diastolic,
        log.bpm,
        log.map,
        log.afib ? '1' : '0',
      ]),
      headings: ['#', 'Date', 'Systolic', 'Diastolic', 'Pulses', 'MAP', 'Afib'],
    };
  }, [report]);

  const pressureGraphData = useMemo(() => {
    if (!report) return [];
    const data: { name: string; data: { x: string; y: number }[] }[] = [
      { name: t('hypertension.labels.diastolic'), data: [] },
      { name: t('hypertension.labels.systolic'), data: [] },
    ];

    report.logs.forEach(log => {
      data[0].data.push({
        x: log.logDate,
        y: log.diastolic,
      });
      data[1].data.push({
        x: log.logDate,
        y: log.systolic,
      });
    });

    return data;
  }, [report, t]);

  const pulsesGraphData = useMemo(() => {
    if (!report) return [];
    const data: { name: string; data: { x: string; y: number }[] }[] = [
      { name: t('dashboard.dashcards.hypertension.pulses'), data: [] },
    ];

    report.logs.forEach(log => {
      data[0].data.push({
        x: log.logDate,
        y: log.bpm,
      });
    });

    return data;
  }, [report, t]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MetricsTable
          data={ambulatoryBloodPressureMeasurementReport.data}
          title={'Ambulatory Blood Pressure Measurement Report'}
          headings={ambulatoryBloodPressureMeasurementReport.headings}
        />
      </Grid>
      <Grid item xs={6}>
        <MetricsTable
          title={'BP Threshold'}
          data={bpThreshold.data}
          headings={bpThreshold.headings}
        />
      </Grid>
      <Grid item xs={6}>
        <MetricsTable
          data={dayNightReal.data}
          headings={dayNightReal.headings}
          title={'Day and night period - Real'}
        />
      </Grid>
      <Grid item xs={6}>
        <MetricsTable
          data={dayNightAdjusted.data}
          headings={dayNightAdjusted.headings}
          title={'Day and night period - Adjusted to device'}
        />
      </Grid>
      <Grid item xs={6}>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <MetricsTable
            title={'White coat window'}
            data={whiteCoatWindow.data}
            headings={whiteCoatWindow.headings}
          />
          <MetricsTable
            data={nightTimeDip.data}
            title={'Night time - Dip%'}
            headings={nightTimeDip.headings}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <MetricsTable
          title={'Readings'}
          data={readings.data}
          headings={readings.headings}
        />
      </Grid>
      <Grid item xs={12}>
        <MetricsTable
          data={averageBloodPressure.data}
          title={'Average blood pressure (SD)'}
          headings={averageBloodPressure.headings}
        />
      </Grid>
      <Grid item xs={12}>
        <Box width={'100%'} borderRadius={'8px'} bgcolor={'white'} px={3} py={2}>
          <LineChart
            showTime
            showLegend
            height={'300px'}
            chartData={pressureGraphData}
            colors={[theme.palette.error.main, theme.palette.warning.main]}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box width={'100%'} borderRadius={'8px'} bgcolor={'white'} px={3} py={2}>
          <LineChart
            showTime
            showLegend
            height={'300px'}
            chartData={pulsesGraphData}
            colors={[theme.palette.warning.main]}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MetricsTable data={logs.data} headings={logs.headings} />
      </Grid>
    </Grid>
  );
};

export default TwentyFourHoursReport;
