import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Collapse,
  CollapseProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import FoodItems from './FoodItems';
import { SmallMacros } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(() => ({
  flexGrow: 1,
  padding: 0,
  display: 'flex',
  boxShadow: 'none',
  borderRadius: '8px',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const CardHeaderMain = styled(CardHeader)<CardHeaderProps & { white: boolean }>(
  ({ theme, white }) => ({
    height: '35px',
    boxSizing: 'border-box',
    padding: theme.spacing(1, 2),
    backgroundColor: white ? theme.palette.secondary.light : '#FBFBFB',
  }),
);

const BoxHeader = styled(Box)<BoxProps>(() => ({
  flexGrow: 1,
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const CollapseStyle = styled(Collapse)<CollapseProps & { white: boolean }>(
  ({ theme, white }) => ({
    width: '100%',
    backgroundColor: '#FBFBFB',
    borderTop: white ? 'none' : `1px solid ${theme.palette.secondary.dark}`,
  }),
);

const BoxInner = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
}));

const TextTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  maxWidth: '95px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1),
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

const TextSecondary = styled(Typography)<TypographyProps>(({ theme }) => ({
  maxWidth: '90px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
}));

interface mealData {
  id?: string;
  cal: number;
  title: string;
  entryID?: string;
  mealID?: string;
  quantity: number;
  logDate?: string;
  category?: string;
  mealExists?: boolean;
}

interface metricsData {
  id: string;
  category: string;
  eaten: number;
  max: number;
  measure: string;
}

type MealCollapseCardProps = {
  title: string;
  totalCal: number;
  data: mealData[];
  description: string;
  white?: boolean | any;
  metrics?: metricsData[];
};

const MealCollapseCard: FC<MealCollapseCardProps> = ({
  data,
  white,
  title,
  metrics,
  totalCal,
  description,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);

  return (
    <CardMain>
      <CardActionArea onClick={() => setExpanded(!expanded)}>
        <CardHeaderMain
          white={white}
          title={
            <BoxHeader>
              <Box display={'flex'}>
                <TextTitle>{title}</TextTitle>
                <TextSecondary>{description}</TextSecondary>
              </Box>
              <Box display={'flex'}>
                <TextSecondary>{`${totalCal} ${t('measurements.kcal')}`}</TextSecondary>
              </Box>
            </BoxHeader>
          }
        ></CardHeaderMain>
      </CardActionArea>
      <CollapseStyle in={expanded} timeout="auto" white={white} unmountOnExit>
        <CardContent>
          <BoxInner>
            {data?.map(it => (
              <FoodItems
                key={it.id}
                cal={it.cal}
                title={it.title}
                quantity={it.quantity}
              />
            ))}
            <BoxSecondary>
              {metrics?.map((it: any) => (
                <Box display={'flex'} flex={1} key={it.id}>
                  <SmallMacros
                    max={it.max}
                    value={it.eaten}
                    title={it.category}
                    measure={`${it.eaten} / ${it.max} ${it.measure}`}
                  />
                </Box>
              ))}
            </BoxSecondary>
          </BoxInner>
        </CardContent>
      </CollapseStyle>
    </CardMain>
  );
};

export default MealCollapseCard;
