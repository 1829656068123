import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isPlatform } from '@ionic/react';
import { FCM } from '@capacitor-community/fcm';
import { useSetPushNotificationsTokenMutation } from './queries';
import { PushNotifications } from '@capacitor/push-notifications';
import { useAppDispatch } from '../../redux/hooks';
import { onMessageListener, requestWebFirebaseToken } from '../../firebase';
import api from '../../redux/api';

const FcmIntegration = () => {
  const dispatch = useAppDispatch();
  const isIOS = isPlatform('ios');
  const isAndroid = isPlatform('android');
  const isCapacitor = isPlatform('capacitor');

  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState<boolean>(false);

  const [setFcmToken] = useSetPushNotificationsTokenMutation();

  onMessageListener()?.then(payload => {
    dispatch(api.util.invalidateTags(['Notification']));
  });

  useEffect(() => {
    if ((!isIOS && !isAndroid) || !isCapacitor) {
      registerTokenWeb();
    }
  }, [isAndroid, isCapacitor, isIOS]);

  useEffect(() => {
    if ((!isIOS && !isAndroid) || !isCapacitor) return;

    PushNotifications.requestPermissions()
      .then(result => {
        if (result.receive === 'granted') {
          setHasPermission(true);
        }
      })
      .catch(err => console.log(err));

    PushNotifications.register()
      .then(() => {
        setIsRegistered(true);
      })
      .catch(err => console.log(err));

    PushNotifications.addListener('registrationError', error => {
      console.error('FCM Registration Error', error);
    });

    PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('FCM Push Notification Received', notification);
    });
  }, [isAndroid, isCapacitor, isIOS]);

  useEffect(() => {
    if (isRegistered && hasPermission) {
      registerToken();
    }
  }, [isRegistered, hasPermission]);

  const registerToken = async () => {
    try {
      const result = await FCM.getToken();
      const platform = isIOS ? 'IOS' : isAndroid ? 'ANDROID' : 'WEB';
      setFcmToken({ token: result.token, platform }).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const registerTokenWeb = async () => {
    try {
      const token = await requestWebFirebaseToken();
      if (!token) return;
      setFcmToken({ token, platform: 'WEB' }).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box hidden>
      {isRegistered && 'Registered'}
      {hasPermission && 'Has Permission'}
    </Box>
  );
};

export default FcmIntegration;
