import * as React from 'react';
import { SVGProps } from 'react';

const ArrowForward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.46655 11.7614C5.11299 12.0757 5.06522 12.6362 5.35986 13.0133C5.65449 13.3904 6.17996 13.4414 6.53352 13.1271L11.5335 8.6827C11.7235 8.51382 11.8334 8.26364 11.8334 7.99984C11.8334 7.73603 11.7235 7.48586 11.5335 7.31698L6.53352 2.87255C6.17996 2.55827 5.65449 2.60923 5.35986 2.98636C5.06522 3.36349 5.11299 3.92399 5.46655 4.23827L9.69832 7.99984L5.46655 11.7614Z"
      fill={props?.color ?? '#B2B8BD'}
    />
  </svg>
);

export default ArrowForward;
