import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import React, { useState } from 'react';
import NotificationSettings from './NotificationSettings';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const TypographyHeading = styled(Typography)<TypographyProps>(() => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const WebNotifications = () => {
  const { t } = useTranslation();

  const [genNotifications, setGenNotifications] = useState<boolean>(false);

  return (
    <>
      <TypographyHeading variant={'subtitle2'}>
        {t('notifications.titles.notifications')}
      </TypographyHeading>

      <Box
        gap={3}
        flexGrow={1}
        display={'flex'}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        padding={theme.spacing(3)}
        bgcolor={theme.palette.highlight.main}
      >
        <NotificationSettings
          checked={genNotifications}
          setChecked={setGenNotifications}
          text={t('notifications.text.genNots')}
          title={t('notifications.titles.genNots')}
        />
      </Box>
    </>
  );
};

export default WebNotifications;
