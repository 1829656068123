import PageLayout from '../../../components/PageLayout';
import {
  CalendarGoalsModal,
  CalendarIcon,
  ClickNavCardNutrition,
  ComponentsLayout,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import SectionNutritionCal from './components/SectionNutritionCal';
import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import moment from 'moment/moment';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

// For next release
const nextPage = [
  {
    title: 'fitness.calories.titles.foodEquiv',
    value: 'food-equivalents',
  },
  {
    title: 'headingsTitles.tipsTricks',
    value: 'tips-tricks',
  },
  // {
  //   title: ''headingsTitles.dietPlan'',
  //   value: 'diet-plans',
  // },
];

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const NutritionFitness: FC<Props> = ({ match }) => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const [openCalendarGoals, setOpenCalendarGoals] = useState<boolean>(false);

  const handleOpenGoalsCalendar = () => {
    setOpenCalendarGoals(true);
  };

  // For next release
  const handleNextPage = (value: string) => {
    router.push(`/dashboard/users/${match.params.userId}/fitness/nutrition/${value}`);
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.nutrition')}
      defaultHref={`/dashboard/users/${match.params.userId}/fitness`}
      endButtons={[
        {
          onClick: handleOpenGoalsCalendar,
          icon: <CalendarIcon width={'20px'} height={'20px'} />,
        },
      ]}
    >
      <ComponentsLayout>
        <SectionNutritionCal userId={match.params.userId} />
        <Box>
          {nextPage.map(it => (
            <ClickNavCardNutrition
              key={it.value}
              headings={t(it.title)}
              onClick={() => handleNextPage(it.value)}
            />
          ))}
        </Box>
      </ComponentsLayout>
      <CalendarGoalsModal
        open={openCalendarGoals}
        setOpen={setOpenCalendarGoals}
        goalDates={[
          //TODO :: remove dummy data
          { date: moment().toDate(), success: true },
          { date: moment().add(1, 'days').toDate(), success: false },
        ]}
        onMonthChange={(date: string) =>
          console.log('Request data with start of month: ' + date)
        }
      />
    </PageLayout>
  );
};

export default NutritionFitness;
