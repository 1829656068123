import {
  ComponentsLayout,
  CustomInput,
  DropdownMenu,
  FileRecordCard,
  ModalMobile,
  NoContentCloudIcon,
  theme,
  UserRecord,
} from '@hdcorner/ui-library';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import useAlert from '../../hooks/useAlert';
import { useGetUserRecordsQuery } from './queries/recordsQueries';
import { bytesToSize, findCategoryLabel } from './utils';
import { fileCategories } from './constants';
import SearchIcon from '../../assets/SearchIcon';
import ViewUserRecord from './ViewUserRecord';
import { useConvertJSON } from '../../utils/useConvertJSON';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
};
const Records: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [search, setSearch] = useState('');
  const [fileType, setFileType] = useState<string>('');
  const [fileCategory, setFileCategory] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<UserRecord | null>(null);

  const { data: recordsRes, error } = useGetUserRecordsQuery({
    userId,
    search,
    fileType,
    fileCategory,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.generic.error01'));
    }
  }, [error]);

  const images = useMemo(() => {
    if (!recordsRes) return [];

    return recordsRes.filter(record => record.fileType === 'image');
  }, [recordsRes]);

  const files = useMemo(() => {
    if (!recordsRes) return [];

    return recordsRes.filter(record => record.fileType === 'file');
  }, [recordsRes]);

  const handleFileTypeChange = (value: string) => {
    setFileType(value);
  };

  const handleFileCategoryChange = (value: string) => {
    setFileCategory(value);
  };

  const handleEditFile = (file: UserRecord) => {
    setSelectedFile(file);
  };

  const renderFileCard = (file: UserRecord, type: 'image' | 'file') => {
    return (
      <FileRecordCard
        type={type}
        key={file._id}
        name={file.fileName}
        fileSize={bytesToSize(file.fileSize)}
        handleEdit={() => handleEditFile(file)}
        category={t(findCategoryLabel(file.category))}
      />
    );
  };

  // Moved out of function to resolve build failure
  const dropdownCategories = (
    <DropdownMenu
      noBorder
      fullWidth={mobile}
      value={fileCategory}
      alternativeBackground
      setValue={handleFileCategoryChange}
      menuItems={useConvertJSON(fileCategories)}
      placeholder={t('records.labels.allCat') as string}
    />
  );

  const renderToolbar = () => {
    return (
      <Box>
        <Box
          borderRadius={1}
          display={'flex'}
          gap={mobile ? 1 : 6}
          alignItems={'center'}
          padding={mobile ? 0 : theme.spacing(2, 3)}
          sx={{
            backgroundColor: mobile ? 'none' : theme.palette.secondary.light,
          }}
        >
          <DropdownMenu
            noBorder
            value={fileType}
            fullWidth={mobile}
            alternativeBackground
            placeholder={`${t('records.labels.allRec')}`}
            setValue={handleFileTypeChange}
            menuItems={[
              { label: t('records.labels.files'), value: 'file' },
              { label: t('records.labels.imgs'), value: 'image' },
            ]}
          />

          {!mobile && (
            <Box
              sx={{
                width: '2px',
                height: '24px',
                background: theme.palette.secondary.dark,
              }}
            />
          )}

          {dropdownCategories}

          {!mobile && (
            <Box
              sx={{
                width: '2px',
                height: '24px',
                background: theme.palette.secondary.dark,
              }}
            />
          )}
          {!mobile && (
            <CustomInput
              value={search}
              handleChange={setSearch}
              placeholder={`${t('records.labels.search')}`}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon color={theme.palette.primary.light} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        </Box>
        {mobile && (
          <>
            <CustomInput
              fullWidth
              value={search}
              alternativeBackground
              handleChange={setSearch}
              placeholder={`${t('records.labels.search')}`}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon color={theme.palette.primary.light} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={3}>
        {renderToolbar()}
        <Box display={'flex'} gap={4} flexDirection={'column'}>
          {/*Images section*/}
          <Box display={'flex'} gap={2} flexDirection={'column'}>
            <Box display={'flex'} gap={1}>
              <Typography textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}>
                {t('records.labels.imgs')}
              </Typography>
              <Typography>(.jpg, .png)</Typography>
            </Box>
            {!mobile && (
              <Box display={'flex'} flexWrap={'wrap'} gap={theme.spacing(1)}>
                {images.map(file => renderFileCard(file, 'image'))}
              </Box>
            )}
            {mobile && (
              <Grid container spacing={1}>
                {images.map(file => (
                  <Grid key={file._id} item xs={6}>
                    {renderFileCard(file, 'image')}
                  </Grid>
                ))}
              </Grid>
            )}
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              {images.length === 0 && <NoContentCloudIcon />}
            </Box>
          </Box>
          {/*Files section*/}
          <Box display={'flex'} gap={2} flexDirection={'column'}>
            <Box display={'flex'} gap={1}>
              <Typography textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}>
                {t('records.labels.files')}
              </Typography>
              <Typography>(.pdf, .xls, .csv)</Typography>
            </Box>
            {!mobile && (
              <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                {files.map(file => renderFileCard(file, 'file'))}
              </Box>
            )}
            {mobile && (
              <Grid container spacing={1}>
                {files.map(file => (
                  <Grid key={file._id} item xs={6}>
                    {renderFileCard(file, 'file')}
                  </Grid>
                ))}
              </Grid>
            )}
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              {files.length === 0 && <NoContentCloudIcon />}
            </Box>
          </Box>
        </Box>
      </ComponentsLayout>
      <ModalMobile
        open={!!selectedFile}
        setOpen={() => {
          setSelectedFile(null);
        }}
      >
        {selectedFile && <ViewUserRecord selectedFile={selectedFile} />}
      </ModalMobile>
    </>
  );
};

export default Records;
