import { useMemo } from 'react';
import moment from 'moment';
import { DiabetesGoal } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const useHbac1GoalData = (
  prepareGoalValue: number | null,
  hbac1Goal: DiabetesGoal[] | undefined,
  prepareStartingMeasurement: number | undefined,
  prepareCurrentMeasurement: number | undefined,
) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const goalValue = hbac1Goal?.[0];
    const firstValue = prepareStartingMeasurement;
    const currentValue = prepareCurrentMeasurement;

    return [
      {
        label: t('headingsTitles.goal'),
        value: prepareGoalValue ? `${prepareGoalValue} %` : '-',
      },
      {
        label: t('goals.startingDate'),
        value: goalValue ? moment(goalValue.startDate).format('MMM Do, YYYY') : '-',
      },
      {
        label: t('goals.startingMeasurement'),
        value: firstValue ? `${firstValue} %` : '-',
      },
      {
        label: t('goals.latestMeasurement'),
        value: currentValue ? `${currentValue} %` : '-',
      },
      {
        label: t('goals.progress'),
        value:
          goalValue && firstValue && currentValue
            ? `${Math.round(
                ((prepareCurrentMeasurement - prepareStartingMeasurement) /
                  (goalValue.goal - prepareStartingMeasurement)) *
                  100,
              )} %`
            : '-',
      },
    ];
  }, [
    hbac1Goal,
    prepareGoalValue,
    prepareCurrentMeasurement,
    prepareStartingMeasurement,
  ]);
};

export default useHbac1GoalData;
