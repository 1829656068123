import DeviceApps from './components/DeviceApps';
import PageLayout from '../../components/PageLayout';
import FitnessDevicesIcon from '../../assets/icons/FitnessDevicesIcon';
import { ComponentsLayout, ExerciseIcon, theme } from '@hdcorner/ui-library';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const devices = [
  {
    id: '9182374',
    title: 'devices.labels.fitbit',
    icon: <ExerciseIcon />,
  },
];

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const DevicesFitness: FC<Props> = ({ match }) => {
  const { t } = useTranslation();
  const handleAddDevice = () => {
    console.log('add device');
  };

  const handleAppleHealth = () => {
    console.log('adding apple health');
  };

  const handleRemoveDevice = (id: string) => {
    console.log(`Removing ${id}`);
  };

  return (
    <PageLayout
      defaultHref={`/dashboard/users/${match.params.userId}/fitness`}
      headerTitle={t('headingsTitles.fitnessDevices')}
      headerIcon={<FitnessDevicesIcon />}
      headerColor={theme.palette.primary.main}
    >
      <ComponentsLayout>
        <DeviceApps
          devices={devices}
          handleAddDevice={handleAddDevice}
          handleAppleHealth={handleAppleHealth}
          handleRemoveDevice={handleRemoveDevice}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DevicesFitness;
