import {
  BarChart,
  CustomSlider,
  DayScatterChart,
  FeelsEmpty,
  LineChart,
  theme,
} from '@hdcorner/ui-library';
import { Button, styled, Typography, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { CSSProperties, FC, useMemo, useState } from 'react';
import useTimeframe from '../../../hooks/useTimeframe';
import {
  useGetBloodGlucoseGraphDataQuery,
  useGetHcac1GraphDataQuery,
} from '../queries/diabetesQueries';
import usePrepareGraph from '../hooks/usePrepareGraph';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const GraphTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'dotColor',
})<TypographyProps & { dotColor?: CSSProperties['color'] }>(({ dotColor, theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    backgroundColor: dotColor ? dotColor : '#222222',
  },
}));

type Props = {
  userId: string;
  customEndDate?: string;
  customStartDate?: string;
  selectedCategory: string;
  selectedGraphType: string;
  selectedTimeframe: string;
};
const DiabetesGraphData: FC<Props> = ({
  userId,
  customEndDate,
  customStartDate,
  selectedCategory,
  selectedTimeframe,
  selectedGraphType,
}) => {
  const { t } = useTranslation();

  const [limits, setLimits] = useState<number[]>([100, 300]);
  const timeframe = useTimeframe(selectedTimeframe, customStartDate, customEndDate);

  const { data: graphDataRes } = useGetBloodGlucoseGraphDataQuery({
    userId,
    ...timeframe,
    category: selectedCategory === 'default' ? undefined : selectedCategory,
  });

  const { data: hbacGraphData } = useGetHcac1GraphDataQuery({ userId, ...timeframe });

  const hba1cGraphData = usePrepareGraph(hbacGraphData);
  const bloodGlucoseGraphData = usePrepareGraph(graphDataRes);

  const dayScatterChartData = useMemo(() => {
    const data = bloodGlucoseGraphData.map(item => {
      const today = moment().utc(true);
      const hour = moment(item.x).format('HH');
      const minute = moment(item.x).format('mm');
      const currentDate = today
        .startOf('day')
        .add(hour, 'hours')
        .add(minute, 'minutes')
        .toDate()
        .getTime();
      const value = item.y;
      return { x: currentDate, y: value };
    });
    return [{ name: 'BloodGlucose', data }];
  }, [bloodGlucoseGraphData]);

  const barChartData = useMemo(() => {
    const lowerLimit = limits[0];
    const upperLimit = limits[1];

    // extract the percentages of below limit, within limit and above limit
    const belowLimit = bloodGlucoseGraphData.filter(item => item.y < lowerLimit).length;
    const withinLimit = bloodGlucoseGraphData.filter(
      item => item.y >= lowerLimit && item.y <= upperLimit,
    ).length;
    const aboveLimit = bloodGlucoseGraphData.filter(item => item.y > upperLimit).length;

    // calculate the percentages
    const total = belowLimit + withinLimit + aboveLimit;
    const belowLimitPercent = (belowLimit / total) * 100;
    const withinLimitPercent = (withinLimit / total) * 100;
    const aboveLimitPercent = (aboveLimit / total) * 100;

    return [
      {
        x: t('general.labels.belowLim'),
        fillColor: '#979FA580',
        y: Math.round(belowLimitPercent),
      },
      {
        x: t('general.labels.withinLim'),
        fillColor: '#4780EF80',
        y: Math.round(withinLimitPercent),
      },
      {
        x: t('general.labels.aboveLim'),
        fillColor: '#E1205A80',
        y: Math.round(aboveLimitPercent),
      },
    ];
  }, [bloodGlucoseGraphData, limits]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <GraphContainer>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={theme.spacing(3)}
            paddingLeft={theme.spacing(2)}
          >
            <GraphTypography dotColor={'#979FA580'}>
              {t('general.text.belowLim')}
            </GraphTypography>
            <GraphTypography dotColor={'#4780EF80'}>
              {t('general.text.withinLim')}
            </GraphTypography>
            <GraphTypography dotColor={'#E1205A80'}>
              {t('general.text.overLim')}
            </GraphTypography>
          </Box>
          {bloodGlucoseGraphData.length > 0 && selectedGraphType === 'lineChart' && (
            <LineChart
              height={'250px'}
              yAnnotations={[
                {
                  from: 0,
                  to: limits[0],
                  fillColor: '#979FA580',
                },
                {
                  to: limits[1],
                  from: limits[0],
                  fillColor: '#4780EF80',
                },
                {
                  to: 600,
                  from: limits[1],
                  fillColor: '#E1205A80',
                },
              ]}
              chartData={[
                {
                  name: t('dashboard.dashcards.diabetes.glucose'),
                  data: bloodGlucoseGraphData,
                },
              ]}
              colors={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {bloodGlucoseGraphData.length > 0 && selectedGraphType === 'barChart' && (
            <BarChart chartData={barChartData} />
          )}
          {bloodGlucoseGraphData.length > 0 && selectedGraphType === '24hours' && (
            <DayScatterChart
              height={'250px'}
              xAxisAnnotations={[
                {
                  borderWidth: 2,
                  strokeDashArray: 4,
                  borderColor: 'red',
                  x: moment().utc(true).startOf('day').add(8, 'hours').toDate().getTime(),
                },
                {
                  borderWidth: 2,
                  strokeDashArray: 4,
                  borderColor: 'red',
                  x: moment()
                    .utc(true)
                    .startOf('day')
                    .add(16, 'hours')
                    .toDate()
                    .getTime(),
                },
              ]}
              yAxisAnnotations={[
                {
                  y: 0,
                  y2: limits[0],
                  fillColor: '#979FA580',
                },
                {
                  y: limits[0],
                  y2: limits[1],
                  fillColor: '#4780EF80',
                },
                {
                  y2: 600,
                  y: limits[1],
                  fillColor: '#E1205A80',
                },
              ]}
              chartData={dayScatterChartData}
            />
          )}
          {bloodGlucoseGraphData.length === 0 && <FeelsEmpty />}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={theme.spacing(3)}
            paddingLeft={theme.spacing(2)}
          >
            <GraphTypography dotColor={theme.palette.warning.main}>
              {t('dashboard.dashcards.diabetes.glucose')}
            </GraphTypography>
          </Box>
        </GraphContainer>
      </Box>
      <Box>
        <GraphContainer>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography
              fontSize={20}
              variant={'subtitle1'}
              marginBottom={theme.spacing(1)}
              color={theme.palette.primary.main}
              fontWeight={theme.typography.fontWeightMedium}
            >
              {t('diabetes.graph.text.change')}
            </Typography>
            <Button
              onClick={() => setLimits([100, 300])}
              sx={{
                display: 'flex',
                paddingY: '10px',
                paddingX: '40px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.medication.main,
              }}
            >
              <Typography
                fontSize={14}
                variant={'subtitle1'}
                color={theme.palette.secondary.main}
                fontWeight={theme.typography.fontWeightMedium}
              >
                {t('buttons.resetLimits')}
              </Typography>
            </Button>
          </Box>
          <CustomSlider
            min={80}
            max={600}
            customThumbIcon
            selected={limits}
            valueLabelDisplay={'on'}
            sx={{ color: '#4780EF80' }}
            setSliderValue={value => setLimits(value as number[])}
          />
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography
              fontSize={14}
              variant={'subtitle1'}
              color={theme.palette.primary.main}
              marginBottom={theme.spacing(1)}
              fontWeight={theme.typography.fontWeightMedium}
            >
              80{t('measurements.mgdl')}
            </Typography>
            <Typography
              fontSize={14}
              variant={'subtitle1'}
              color={theme.palette.primary.main}
              marginBottom={theme.spacing(1)}
              fontWeight={theme.typography.fontWeightMedium}
            >
              600{t('measurements.mgdl')}
            </Typography>
          </Box>
        </GraphContainer>
      </Box>
      <Box>
        {hba1cGraphData.length > 0 && selectedGraphType === 'lineChart' && (
          <GraphContainer>
            <LineChart
              chartData={[
                {
                  name: t('dashboard.dashcards.diabetes.hba1c'),
                  data: hba1cGraphData,
                },
              ]}
              colors={[theme.palette.error.main, theme.palette.warning.main]}
            />
            {hba1cGraphData.length === 0 && <FeelsEmpty />}
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={theme.spacing(3)}
              paddingLeft={theme.spacing(2)}
            >
              <GraphTypography dotColor={theme.palette.warning.main}>
                {t('dashboard.dashcards.diabetes.hba1c')}
              </GraphTypography>
            </Box>
          </GraphContainer>
        )}
      </Box>
    </Box>
  );
};

export default DiabetesGraphData;
