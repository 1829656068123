import api from '../../../redux/api';
import {
  ApiPagination,
  Medication,
  MedicationRequest,
  Medicine,
  MedicineStock,
  PaginationResult,
} from '@hdcorner/ui-library';

const medicationQueries = api
  .enhanceEndpoints({ addTagTypes: ['Medication', 'MedicineSearch', 'MedicineStock'] })
  .injectEndpoints({
    endpoints: build => ({
      getSearchMedicines: build.query<
        PaginationResult<Medicine>,
        ApiPagination & { searchText: string }
      >({
        query: ({ skip, limit, sort, searchText }) => ({
          method: 'GET',
          url: `database/function/searchMedicines`,
          params: { skip, limit, sort, searchText },
        }),
        providesTags: ['MedicineSearch'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
      }),

      getMedicineById: build.query<Medicine, string>({
        query: id => `database/Medicine/${id}`,
      }),

      getMedications: build.query<
        PaginationResult<Medication>,
        ApiPagination & { startDate: string; endDate: string; userId: string }
      >({
        query: ({ skip, limit, startDate, endDate, userId }) => ({
          method: 'GET',
          url: `database/function/getMedications`,
          params: {
            skip,
            limit,
            endDate,
            startDate,
            sort: 'time',
            populate: 'medicine',
            scope: `PremiumHealth:${userId}`,
          },
        }),
        providesTags: ['Medication'],
      }),

      createNewMedication: build.mutation<
        Medication,
        MedicationRequest & { userId: string }
      >({
        query: ({ userId, ...medication }) => ({
          method: 'POST',
          body: medication,
          params: { userId },
          url: `hdCornerService/user/medications`,
        }),
        invalidatesTags: ['Medication'],
      }),

      getMedicationById: build.query<
        Medication[],
        { medicationId: string; userId: string }
      >({
        query: ({ userId, medicationId }) => ({
          method: 'GET',
          url: `database/function/getMedicationById/${medicationId}`,
          params: { populate: 'medicine', scope: `PremiumHealth:${userId}` },
        }),
      }),

      getMedicineStock: build.query<
        MedicineStock[],
        { medicineId: string; userId: string }
      >({
        query: ({ userId, medicineId }) => ({
          method: 'GET',
          params: { medicineId, authUser: userId },
          url: `database/function/hcpGetMedicineStock`,
        }),
        providesTags: ['MedicineStock'],
      }),

      createMedicineStock: build.mutation<
        MedicineStock,
        Pick<MedicineStock, 'medicine' | 'instructions' | 'instructionsCategory'> & {
          userId: string;
        }
      >({
        query: ({ userId, medicine, instructions, instructionsCategory }) => ({
          method: 'POST',
          url: `database/function/hcpCreateMedicineStock`,
          body: {
            medicine,
            instructions,
            authUser: userId,
            instructionsCategory,
          },
        }),
        invalidatesTags: ['MedicineStock'],
      }),

      editMedicineStock: build.mutation<
        MedicineStock,
        Pick<MedicineStock, '_id' | 'instructions' | 'instructionsCategory'>
      >({
        query: ({ _id, instructions, instructionsCategory }) => ({
          method: 'PATCH',
          url: `/database/MedicineStock/${_id}`,
          body: {
            instructions,
            instructionsCategory,
          },
        }),
        invalidatesTags: ['MedicineStock'],
      }),

      // Deletes all instances of a medication except doses that have already been taken
      deleteAllUnTakenMedication: build.mutation<void, { id: string; userId: string }>({
        query: ({ id, userId }) => ({
          method: 'DELETE',
          params: { id, userId },
          url: '/hdCornerService/user/medication',
        }),
        invalidatesTags: ['Medication'],
      }),

      deleteSingleMedication: build.mutation<
        number,
        { medicationId: string; userId: string }
      >({
        query: ({ medicationId, userId }) => ({
          method: 'DELETE',
          url: `database/function/deleteSingleMedication`,
          params: { medicationId, scope: `PremiumHealth:${userId}` },
        }),
        invalidatesTags: ['Medication'],
      }),
    }),
  });

export const {
  useDeleteAllUnTakenMedicationMutation,
  useDeleteSingleMedicationMutation,
  useCreateMedicineStockMutation,
  useCreateNewMedicationMutation,
  useEditMedicineStockMutation,
  useGetSearchMedicinesQuery,
  useGetMedicationByIdQuery,
  useGetMedicineStockQuery,
  useGetMedicineByIdQuery,
  useGetMedicationsQuery,
} = medicationQueries;

export default medicationQueries;
