import moment from 'moment';
import api from '../../../../redux/api';
import {
  Activity,
  ActivityGoal,
  ActivityLog,
  ApiPagination,
  PaginationResult,
} from '@hdcorner/ui-library';

const activitiesQueries = api
  .enhanceEndpoints({ addTagTypes: ['Activity', 'ActivityLog', 'ActivityGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      getActivityList: build.query<
        PaginationResult<Activity>,
        ApiPagination & { searchText: string }
      >({
        query: ({ skip, limit, sort, searchText }) => ({
          method: 'GET',
          url: '/database/function/searchActivity',
          params: { skip, limit, sort, searchText },
        }),
        providesTags: ['Activity'],
      }),

      getActivityLogs: build.query<
        PaginationResult<ActivityLog>,
        {
          end?: string;
          skip: number;
          limit: number;
          start?: string;
          sort: string[];
          userId: string;
        }
      >({
        query: ({ skip, limit, start, end, sort, userId }) => ({
          method: 'GET',
          url: '/database/function/getUserActivities',
          params: {
            skip,
            limit,
            start,
            end,
            sort,
            populate: 'activity',
            scope: `Exercise:${userId}`,
          },
        }),
        providesTags: ['ActivityLog'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
          currentCache.count = newItems?.count;
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return (
            previousArg?.end !== currentArg?.end ||
            previousArg?.sort !== currentArg?.sort ||
            previousArg?.skip !== currentArg?.skip ||
            previousArg?.start !== currentArg?.start ||
            previousArg?.limit !== currentArg?.limit ||
            previousArg?.userId !== currentArg?.userId
          );
        },
      }),

      getCalendarActivityLogs: build.query<
        PaginationResult<ActivityLog>,
        {
          end?: string;
          skip: number;
          limit: number;
          start?: string;
          sort: string[];
          userId: string;
        }
      >({
        query: ({ skip, limit, start, end, sort, userId }) => ({
          method: 'GET',
          url: '/database/function/getUserActivities',
          params: {
            skip,
            limit,
            start,
            end,
            sort,
            userId,
            populate: 'activity',
          },
        }),
        providesTags: ['ActivityLog'],
      }),

      getTodaysActivityLogs: build.query<
        PaginationResult<ActivityLog>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          method: 'GET',
          url: '/database/function/getUserActivities',
          params: {
            skip: 0,
            limit: 100,
            populate: 'activity',
            scope: `Exercise:${userId}`,
            end: moment().endOf('day').toISOString(),
            start: moment().startOf('day').toISOString(),
          },
        }),
        providesTags: ['ActivityLog'],
      }),

      getUserGoalActivity: build.query<ActivityGoal[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          scope: `Exercise:${userId}`,
          params: { goalType: 'exercise' },
          url: '/database/function/getUserGoal',
        }),
        providesTags: ['ActivityGoal'],
      }),
    }),
  });

export const {
  useGetActivityListQuery,
  useGetActivityLogsQuery,
  useGetUserGoalActivityQuery,
  useGetTodaysActivityLogsQuery,
  useGetCalendarActivityLogsQuery,
} = activitiesQueries;

export default activitiesQueries;
