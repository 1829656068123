import api from '../../../redux/api';
import { UsersAverages } from '../types/UsersAverages';
import { PatientsAnalytics } from '../types/PatientsAnalytics';

const dashboardQueries = api.injectEndpoints({
  endpoints: build => ({
    getDashboardData: build.query<UsersAverages, { start?: string; end?: string }>({
      query: ({ start, end }) => ({
        method: 'GET',
        params: { start, end },
        url: 'hdCornerService/hcp/dashboard/user-averages',
      }),
    }),
    getPatientsAnalytics: build.query<PatientsAnalytics[], void>({
      query: () => ({
        method: 'GET',
        url: 'database/function/getPatientsAnalytics',
      }),
    }),
  }),
});

export const { useGetDashboardDataQuery, useGetPatientsAnalyticsQuery } =
  dashboardQueries;

export default dashboardQueries;
