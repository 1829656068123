import { Box, Typography } from '@mui/material';

const TermsAndConditionsEN = () => {
  return (
    <Box>
      <Box>
        <Typography variant={'h1'}>TERMS OF USE</Typography>
        <Typography variant={'body1'} paragraph>
          These terms of use govern the relationship between health professionals of any
          specialty or profession related to health and the company " KARABINIS MEDICAL
          SA-Manufacture –Sale of Technological Products and Providing Services ", based
          in Peania Attica, on Lavriou Avenue, no. 151, with A.F.M.: 099052507 – D.O.Y.:
          FAE Athens- NO. GEMI 87183102000, tel. 2106645751. ("we", "us", "our" or
          "Company") regarding your use of the services of the HD Corner Application -web
          or mobile app (the "Application" or "Service" or " HD Corner "), and all
          information, text, graphics, software and services available for use of the
          Application (the " Content").
        </Typography>
        <Typography variant={'body1'} paragraph>
          These Terms constitute the legally binding agreement between you and the Company
          for the use of the Application. For this reason, PLEASE READ THE TERMS CAREFULLY
          BEFORE USING THE APPLICATION SERVICES.
        </Typography>
        <Typography variant={'body1'} paragraph>
          The Company reserves the right to change or modify the applicable terms and
          conditions for the use of the Application at its discretion and at any time,
          always taking into account the conditions set forth by current legislation
          accordingly.
        </Typography>
        <Typography variant={'subtitle1'} paragraph>
          DISCLAIMER
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE SERVICES AND THE CONTENT ARE PROVIDED "AS IS", WITHOUT WARRANTIES OF ANY
          KIND.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE COMPANY DOES NOT PROVIDE MEDICAL ADVICE OF ANY KIND, REGARDING THE
          PREVENTION, DIAGNOSIS OR TREATMENT OF ANY DISEASE OR HEALTH CONDITION.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE CONTENT PROVIDED THROUGH THE SERVICE, WHETHER PROVIDED BY US OR THIRD
          PARTIES (EVEN IF THEY ARE HEALTHCARE PROFESSIONALS) IS NOT INTENDED AND SHOULD
          NOT BE USED AS A SUBSTITUTE FOR VISIT, CALL OR CONSULT A PHYSICIAN OR OTHERWISE
          HEALTH PROFESSIONALS.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE COMPANY ASSUMES NO RESPONSIBILITY FOR THE SUITABILITY OF THE SERVICE TO EACH
          USER AND THE LEVEL OF SUCCESS AND RESULTS MAY VARY PER INDIVIDUAL.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE SERVICE MAY NOT BE SUITABLE FOR ALL INDIVIDUALS AND IS NOT A SUBSTITUTE FOR
          PROFESSIONAL HEALTH CARE. THE SERVICE IS INTENDED ONLY AS A TOOL, WHICH MAY BE
          USEFUL IN ACHIEVING USERS' ADJUSTMENT GOALS OF THE APPLICATION.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE COMPANY ASSUMES NO RESPONSIBILITY FOR INACCURACIES REGARDING FOOD RECIPES,
          EXERCISES OR OTHER CONTENT ON THE SERVICE. YOU SHOULD CAREFULLY READ ALL
          INFORMATION PROVIDED BY FOOD PRODUCT MANUFACTURERS, WHETHER ON THE INTERNET OR
          ON THE ACTUAL PRODUCT PACKAGING AND LABELS, INCLUDING NUTRIENT CONTENT
          INGREDIENTS BEFORE USING OR CONSUMING A PRODUCT. FOR ADDITIONAL INFORMATION
          ABOUT A FOOD PRODUCT, CONTACT THE MANUFACTURER DIRECTLY.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE COMPANY ASSUMES NO RESPONSIBILITY AS TO THE SUITABILITY OF THE SERVICE FOR
          YOU AND THE LEVEL OF SUCCESS AND RESULTS MAY VARY PER INDIVIDUAL. In particular,
          the Company does not provide and expressly disclaims any warranty that: (i) the
          Service will meet your requirements, (ii) the Service will be uninterrupted,
          fast, secure or error-free, (iii) the results that may arise from your use of
          the Service, including data, will be accurate or reliable, (iv) the quality of
          any data or services available on the Service will meet your expectations, and
          (v) any errors in the Service will be corrected. Any material obtained through
          use of the Service is at your own discretion and responsibility, and you shall
          be solely responsible for any damage to your computer system or mobile device or
          loss of data resulting from your use of any such material. We cannot guarantee
          and do not promise specific results from the use of the Application and/or
          Service. You also agree to bear the risks of interruption of the Service for any
          technical reasons.
        </Typography>
        <Typography variant={'body1'} paragraph>
          TESTIMONIALS AND EXAMPLES THAT MAY BE PROVIDED ON THE SERVICE MAY NOT APPLY TO
          ALL INDIVIDUALS AND RESULTS ARE NOT GUARANTEED. THERE IS NO ASSURANCE THAT
          EXAMPLES OF PAST EXERCISE AND NUTRITION RESULTS CAN HAVE FUTURE RESULTS. WE
          CANNOT GUARANTEE FUTURE RESULTS AND/OR SUCCESS.
        </Typography>
        <Typography variant={'body1'} paragraph>
          EACH PERSON'S HEALTH, EXERCISE AND NUTRITION RESULTS DEPEND ON THEIR HISTORY,
          DEDICATION, DESIRE AND MOTIVATION. AS WITH ANY HEALTH-RELATED PROGRAM OR
          SERVICE, RESULTS MAY VARY AND WILL BE BASED ON MANY VARIABLES, INCLUDING BUT NOT
          LIMITED TO INDIVIDUAL ABILITY, AND LEVEL OF CONSISTENCE. USE OF THE SERVICE
          SHOULD BE BASED ON DUE DILIGENCE OF THE USER AND YOU AGREE THAT THE COMPANY IS
          NOT RESPONSIBLE FOR ANY SUCCESS OR FAILURE OF THE HEALTH AND PHYSICAL CONDITION
          OF USERS.
        </Typography>
        <Typography variant={'body1'} paragraph>
          IN ADDITION TO ALL OTHER LIMITATIONS AND DISCLAIMER IN THESE TERMS, THE COMPANY
          DISCLAIMS ANY LIABILITY OR DAMAGES IN CONNECTION WITH THE CONTENT PROVIDED ON
          THE SERVICE.
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>Create an Account</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            In order to fully use the Services, you are required to create an account,
            providing us with the following information: name, medical specialty, VAT
            number, address, Number, postal code, region, contact phone number and email
            address. In addition, you will be asked to answer if you will be an HD Corner
            Clinical coach and create a password, where together with your email you will
            use them from now on to connect to the service. You can change the password in
            the "Profile" Tab.
          </Typography>
          <Typography variant={'body1'} paragraph>
            It is your sole responsibility to control the disclosure and use of your
            password and to permit, control and manage access to and use of your account
            and password on the HD Corner Service.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Upon your registration for the HD Corner service and systemic approval by the
            Company, your account will be activated and you will obtain a unique code with
            which you will be able to "connect" with users of the service.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Use of the Services</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            By registering with the Service as a healthcare professional, users of the
            Application will be able to give you access to their data in order to provide
            them with advice through the Application. App users will enter your unique
            code into their profile in order to be granted access.
          </Typography>
          <Typography variant={'body1'} paragraph>
            In order to use the services of the application, in addition to your own
            personal data for the creation of your account, you may provide various Data,
            information, medical measurements, comments, questions, medical reports,
            photos, etc., which will concern the users of the application, who will select
            you for direct communication with you (collectively referred to as "Data")
          </Typography>
          <Typography variant={'body1'} paragraph>
            By using the Services, you acknowledge that you may not (1) upload any content
            that contains software viruses or that is designed to interrupt, damage or
            limit the functionality of any equipment or service, or that contains other
            files or content that is harmful, destructive, disruptive or disruptive, (2)
            use or attempt to use another user's account without their authorization, or
            impersonate any other person or entity, (3) collect, solicit or combine other
            users' information for any reason, including; without limitation, sending
            spam, (4) publicize, advertise or promote commercial products or services, or
            upload any harmful content, (5) use the Services in any manner that, in our
            sole discretion, is objectionable or restricts or inhibits any other person
            from using or enjoying the Services, or that may expose us or our users to any
            harm or liability of any kind (6) Develop, support or use the software,
            devices, texts, robots or any other means or process (including trackers,
            plug-ins or browser supplements or any other technology) to damage the
            Services or copy the profiles and other data of the Services,(7) Override any
            other security feature, avoid or circumvent any access control or restrict use
            of the Service, (8) copy, use, disclose or distribute any information obtained
            from the Services, either directly or through third parties, without the
            consent of HD Corner (9) Disclose information for which you have not obtained
            permission to disclose (such as the confidential information of others),(10)
            infringe HD Corner's intellectual property and other rights, including,
            without limitation, (i) copying or distributing the diet or workout, tips and
            wellness articles, videos and other material or (ii) copying or distributing
            the technology, unless it is free under open source licenses; (iii) the use of
            the word " HD Corner " or our logos in any trade name, e-mail or URL; (11)
            Reproduce, decode or attempt to derive the original code for the Services or
            any related technology that is not open source, (12) rent, lend, trade,
            sell/resell or otherwise monetize the Services or related data or access to
            them, without HD Corner's consent.(12) to connect to our Services for any
            purpose other than profile promotion or to a Group on our Services, without HD
            Corner's consent.(14) monitor the availability, performance or functionality
            of the Services for any competitive purpose (15) engage in "framing",
            "mirroring" or otherwise impersonating the appearance or operation of the
            Services (16) overlay or otherwise modify the Services or their appearance
            (for example, by inserting elements into the Services by removing or hiding an
            advertisement included in the Services), (17) violate any additional terms
            related to the particular Service provided to you when you register or start
            using the particular Service.
          </Typography>
          <Typography variant={'body1'} paragraph>
            The Company reserves the right to suspend or terminate your Profile or your
            access to the Service, with or without notice to you, if you violate these
            Terms. You are responsible for maintaining the confidentiality of your Profile
            login information and are fully responsible for all activities that occur
            under your Profile. You agree to notify the Company immediately in case of any
            unauthorized use or suspected unauthorized use of your Profile or any other
            breach of security. The Company cannot and will not be liable for any loss or
            damage arising from your failure to comply with the above requirements.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Change of Information and Services
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            We may change all information provided by the Service at our sole discretion
            without notice. We may at any time modify or discontinue, temporarily or
            permanently, the Service (or any part thereof) in our sole discretion with or
            without notice. You agree that we will not be liable to you or any third party
            for any modification, suspension or discontinuance of the Service
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Unsubscribe from the Service</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            The user can unsubscribe from the service by selecting the corresponding
            option on the profile tab. By choosing to unsubscribe, you agree that your
            profile will be deleted and all data you have entered will be destroyed in
            accordance with personal data law.
          </Typography>
          <Typography variant={'body1'} paragraph>
            The Company reserves the right to terminate the service at its discretion, in
            the event that the user engages in actions that are illegal or prohibited or
            behaviors that undermine the operation of the Website/Application and violate
            these Terms of Use
          </Typography>
          <Typography variant={'body1'} paragraph>
            In cases where there are suspicions/prohibited actions are detected, the
            Company reserves the right to take any action it deems necessary at its
            discretion, such as indicatively investigating and removing content that
            contravenes these terms.
          </Typography>
          <li>
            <Typography variant={'body1'} paragraph>
              Use of Content
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            HD Corner Content includes photographs, images, graphics, video, audio, data,
            text, music, fitness, comments, software, author work of any kind and other
            information, content and other material published, produced, made available on
            or through Services (collectively "Content"). The Content, Services are
            protected by copyrights, trademarks and patents. We reserve all rights not
            expressly granted in these Terms. You agree not to delete, alter or obscure
            any copyright, trademark, service mark or other proprietary notices
            incorporated or accompanying the Services. Our logos and any other HD Corner
            trademarks that may appear on the Services, and App Content, such as the
            general appearance of the Services, including page headings, graphics, images
            and text, may not be copied, imitated or used in any way, in whole or in part,
            without prior written authorization. Other trademarks, product and service
            names, and company names or logos mentioned on the Services are property of
            their respective owners and may not be copied, imitated or used, in whole or
            in part, without the permission of the trademark owner that is respectively
            protected.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Please note that the use of the application and its content is solely at your
            own risk. The Company bears no responsibility for any delays, failures,
            interruptions, or damage to any data or information related to the
            application. Furthermore, under no circumstances does the Company guarantee
            the absence of "viruses" or other harmful code, whether it is the application,
            or any other site or server through which you receive its content
          </Typography>
          <Typography variant={'body1'} paragraph>
            Subject to these Terms, Company grants you a non-transferable, non-exclusive
            license (with no right to sublicense) to (i) use the Service solely for your
            personal, non-commercial purposes, and (b) install and use the Application,
            solely in your portable handheld device (eg iPhone, Android, etc. as
            applicable) or personal computer and solely for personal, noncommercial
            purposes.
          </Typography>
          <Typography variant={'body1'} paragraph>
            You agree, state and warrant that your use of the Service, or any part
            thereof, will be in accordance with the foregoing license, covenants and
            restrictions and will not infringe or violate the rights of any other party or
            violate any contract or legal obligation in any other parts. In addition, you
            agree that you will comply with all applicable laws, and regulations relating
            to the Service or its use and will be solely responsible for your own
            individual violations of any such laws.
          </Typography>
          <Typography variant={'body1'} paragraph>
            You are solely responsible for obtaining the equipment and telecommunications
            services necessary to access the Service, and for all charges associated
            therewith (such as computing devices and Internet Service Provider and airtime
            charges).
          </Typography>
          <Typography variant={'body1'} paragraph>
            Your access to and use of the Service is at your own risk. The Company shall
            have no liability for any damage to your computer system, loss of data or
            other harm to you or any third party.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Limitation of Liability</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            THE COMPANY AND THE ENTITIES INVOLVED IN CREATING, PRODUCING OR PROVIDING THE
            SERVICES SHALL NOT BE LIABLE FOR ANY INCIDENTAL, SPECIAL OR CONSEQUENTIAL
            DAMAGES, INCLUDING RPFOIT LOSS, LOSS OF DATA, SERVICE INTERRUPTION, DAMAGE TO
            ELECTRONIC EQUIPMENT, OR SYSTEM FAILURE OR COSTS OF REPLACEMENT OF THE
            SERVICES ARISING OUT OF OR RELATED TO THE TERMS OR THE USE OR INABILITY TO USE
            THE SERVICES, BASED ON ANY WARRANTY, CONTRACT, TORT (INCLUDING THAT
            NEGLIGENCE), PRODUCT LIABILITY PROVISIONS OR THIRD PARTY ADVERTISING, OR ANY
            OTHER LEGAL PROVISION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Newsletters</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            By accepting the terms of use of the Application and registering to the
            HDCORNER Services, newsletters can be sent to the user’s e-mail address they
            have indicated during their registration. In the event that the user does not
            wish the sending of newsletters - news of HD Corner in the future, they can
            immediately proceed to delete their e-mail address from the list of addresses
            receiving such messages, using the special deletion link (unsubscribe) that
            exists in each such newsletter (Law 3741/2006 no. 11 par. 3 and 4).
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Read the Privacy Policy</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            HD Corner respects your privacy. To obtain information about our data
            protection policy, please consult the Privacy Policy mentioned below. By
            accessing or using the Services, you agree that HD Corner may collect and use
            the information you provide to us in accordance with the Privacy Policy
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Jurisdiction</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            These Terms of Use are governed by Greek law. The user expressly accepts that
            the Courts of Athens have the exclusive jurisdiction and competence for any
            dispute arising from the use of the Application. If any of the above
            conditions prove to be invalid, this does not affect the validity and force of
            the remaining conditions which remain in full force.
          </Typography>
          <Typography variant={'body1'} paragraph>
            IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, THEN DO NOT DOWNLOAD THE
            APPLICATION OR USE THE SERVICE
          </Typography>
          <Typography variant={'subtitle1'}>Contact</Typography>
          <Typography variant={'body1'} paragraph>
            If you wish to contact us regarding these Terms of Use or have any other
            question regarding the provision of the Services you may send an email to
            info@hdcorner.com.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Last update 08/01/2024
          </Typography>
        </ol>
      </Box>
      <Box>
        <Typography variant={'h1'} paragraph>
          PRIVACY POLICY
        </Typography>
        <Typography variant={'body1'} paragraph>
          This Privacy Policy concerns the processing of your personal data when using the
          HDCORNER Application (hereinafter the "Application")
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>Controller – Contact details</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            " KARABINIS MEDICAL SA-Manufacture –Sale of Technological Products and
            Providing Services ", based in Peania Attica, on Lavriou Avenue, no. 151, with
            A.F.M.: 099052507 – D.O.Y.: FAE Athens- LOT NO. 87183102000, tel. 2106645751
            (hereinafter the "Company")
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              What personal data of mine is collected and how?
            </Typography>
          </li>
          <Typography variant={'body1'}>
            When you register in the Application (sign up) as a user, you provide the
            following personal data:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>Name</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Date of birth</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Gender</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Contact info</Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            When you register in the Application (sign up) as a health professional, you
            provide the following personal data:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>first and last name,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>specialty,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>VAT NUMBER,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>address, number, zip code, area,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                contact phone number and email address
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            When using the services of the Application as a user you have the possibility
            to enter the following data:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Health data (e.g. diabetes readings, blood pressure, diagnostic tests,
                etc.) that you enter into the platform if you wish
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Information regarding sports activities, eating habits
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                The details of your credit card (number, name of the holder, security
                code) that you provide to us when paying for the service are not collected
                by the Application, but are provided directly to the authorized provider
                to which you are redirected during the payment process.
              </Typography>
            </li>
          </ul>
          <li>
            <Typography variant={'subtitle1'}>
              For what purposes is the processing carried out and what is its legal basis?
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Your data is processed to provide you with access to HDCORNER services. The
            legal basis for this processing is the performance of a contract (a. 6 par. 2
            b' GDPR).
          </Typography>
          <Typography variant={'body1'}>
            For the health data you provide us, the legal basis is the performance of a
            contract and a. 9 par.1 para. h'
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Who are the recipients of my personal data?
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Access to your personal data is given to the platform provider acting as data
            processor as well as to the doctor or pharmacist or other healthcare
            professional to whom you choose to give access to your data. The healthcare
            professional will be able to see the data you enter on the platform, only if
            you yourself give him access.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>How long is my data kept?</Typography>
          </li>
          <Typography variant={'body1'}>
            Your data will be strictly maintained for as long as you are registered with
            the service. If you are deleted from the service, your data will be deleted
            immediately. They will also be deleted if you submit a deletion request to us.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Cookies</Typography>
          </li>
          <Typography variant={'body1'}>
            The application does not use cookies when the user enters it, either through
            the mobile phone or through the web platform.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>How is my data protected?</Typography>
          </li>
          <Typography variant={'body1'}>
            Your data is kept on an external server with all the necessary security
            measures. Especially for the health data you enter on the platform, these are
            subject to encryption and pseudonymization, therefore no recipient can
            associate them with your person, except for the health professional to whom
            you give access.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>What are my rights?</Typography>
          </li>
          <li>
            <Typography variant={'body1'}>
              As a data subject you have the following rights:
            </Typography>
          </li>
          <table>
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to information</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to be informed about the collection, processing and
                    storage of your personal data.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right of access</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to receive confirmation as to whether or not your
                    data is being processed and, if this is the case, you have the right
                    to access your data in a short, understandable, transparent and easily
                    accessible form.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to rectification</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You may request the rectification of inaccurate or incomplete personal
                    data concerning you.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to erasure</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to request the erasure of the data concerning you,
                    if you consider that it is being held in an unlawful manner and the
                    Company is obliged to proceed with the erasure unless there is a legal
                    reason for its retention
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to data portability</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to receive the personal data concerning you and
                    which you have provided to the Company in a commonly used and
                    machine-readable format, as well as the right to request the
                    transmission of said data to another data controller, under the
                    conditions set by law
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
          <Typography variant={'body1'} paragraph>
            We will respond to your request without delay and in any case within one month
            of receiving it. This deadline is extended for another two months, if
            necessary, taking into account the complexity of the request and the number of
            requests. We will inform you of said extension within one month of receiving
            the request, as well as the reasons for the delay.
          </Typography>
          <Typography variant={'body1'} paragraph>
            The company has the right in any case to refuse to comply with your request to
            limit the processing or delete your personal data, if this processing is
            necessary for the establishment, exercise or support of its legal rights or
            the fulfillment of its obligations.
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              How you can exercise your rights:
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            In order to exercise your above rights, you can contact the Data Protection
            Officer at the email address dataprotection@karabinismedical.gr or by phone at
            210-6645751 or by post at the company address KARABINIS MEDICAL SA, Lavriou
            Ave. 151, 19005 Peania – Att: Data Protection Officer .
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              Personal Data Protection Authority
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            You have the right to file a complaint with the Personal Data Protection
            Authority (www.dpa.gr), which is the competent supervisory authority for the
            protection of the fundamental rights and freedoms of natural persons against
            the processing of their personal data, if you consider that they are being
            violated your rights in any way.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Last update 08/01/2024
          </Typography>
        </ol>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsEN;
