import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import { CalendarModal, Collapsible, FeelsEmpty, theme } from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import { useGetActivityLogsQuery } from '../queries/activitiesQueries';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import InDepthHeading from '../../components/InDepthHeading';
import InfiniteScrollList from '../../../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
};
const SectionActivitiesData: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [open, setOpen] = useState<boolean>(false);
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');
  const [params, setParams] = useState({
    skip: 0,
    limit: 1000,
  });

  const {
    error,
    isFetching,
    data: activityLogsRes,
  } = useGetActivityLogsQuery({
    userId,
    skip: params.skip,
    sort: ['-logDate'],
    limit: params.limit,
    end: endParam || undefined,
    start: startParam || undefined,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.generic.error01'));
    }
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!activityLogsRes || activityLogsRes.documents.length === 0) return [];

    return activityLogsRes.documents.map(it => {
      let activityName = '',
        customActivityName = '';
      if (it.custom || !it.activity) {
        customActivityName = it.name || 'custom activity';
        customActivityName = capitalizeFirstLetter(customActivityName);
      } else {
        activityName =
          typeof it.activity === 'string'
            ? it.activity
            : typeof it.activity.name === 'string'
            ? it.activity.name
            : it.activity.name[lang];
        activityName = capitalizeFirstLetter(activityName);
      }

      return {
        _id: it._id,
        title: activityName || customActivityName,
        subtitle: t('fitness.exercise.labels.activity'),
        data: {
          [t('fitness.calories.titles.kcalBurned')]: {
            value: Math.round(it.calories || 0).toString(),
          },
          [t('fitness.exercise.labels.duration')]: { value: it.duration.toString() },
          [t('inDepth.timeOfCalc')]: { value: moment(it.logDate).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(it.logDate).format('MMMM Do, YYYY'),
          },
        },
      };
    });
  }, [activityLogsRes]);

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <Box>
      <InDepthHeading
        module={'activities'}
        color={theme.palette.exercise.main}
        handleDate={() => setOpen(true)}
      />
      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        <InfiniteScrollList
          skip={params.skip}
          limit={params.limit}
          loading={isFetching}
          count={inDepthData.length}
          totalCount={activityLogsRes?.count}
          onLoadMore={(skip, limit) => setParams({ skip, limit })}
        >
          {inDepthData.map(data => (
            <Box key={data._id}>
              <Collapsible data={data.data} title={data.title} titleSub={data.subtitle} />
            </Box>
          ))}
        </InfiniteScrollList>
        {inDepthData.length === 0 && (
          <Box mb={1} width={'100%'}>
            <FeelsEmpty />
          </Box>
        )}
      </Box>
      <CalendarModal
        open={open}
        setOpen={setOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default SectionActivitiesData;
