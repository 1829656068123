import moment from 'moment';
import api from '../../../../redux/api';
import { ApiPagination, PaginationResult, SleepLog } from '@hdcorner/ui-library';

const sleepQueries = api.enhanceEndpoints({ addTagTypes: ['SleepLog'] }).injectEndpoints({
  endpoints: build => ({
    getSleepLogs: build.query<
      PaginationResult<SleepLog>,
      { params: ApiPagination; start?: string; end?: string; userId: string }
    >({
      query: ({ params, start, end, userId }) => ({
        method: 'GET',
        url: '/database/function/getSleepLogs',
        params: { ...params, start, end, scope: `Sleep:${userId}` },
      }),
      providesTags: ['SleepLog'],
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.params.skip === 0) {
          currentCache.documents = newItems?.documents;
        } else {
          currentCache?.documents.push(...newItems?.documents);
        }
        currentCache.count = newItems?.count;
      },
      forceRefetch: ({ previousArg, currentArg }) => {
        return (
          previousArg?.end !== currentArg?.end ||
          previousArg?.start !== currentArg?.start ||
          previousArg?.params.sort !== currentArg?.params.sort ||
          previousArg?.params.skip !== currentArg?.params.skip ||
          previousArg?.params.limit !== currentArg?.params.limit ||
          previousArg?.userId !== currentArg?.userId
        );
      },
    }),

    getTodaysLastSleepLog: build.query<PaginationResult<SleepLog>, { userId: string }>({
      query: ({ userId }) => ({
        method: 'GET',
        url: '/database/function/getSleepLogs',
        params: {
          skip: 0,
          limit: 1,
          sort: '-createdAt',
          scope: `Sleep:${userId}`,
          end: moment().endOf('day').toISOString(),
          start: moment().startOf('day').toISOString(),
        },
      }),
      providesTags: ['SleepLog'],
    }),

    getSleepGraphData: build.query<
      { graphData: SleepLog[]; total?: number; avg?: number },
      {
        end?: string;
        userId: string;
        start?: string;
        timezone: string;
        timeframe?: string;
      }
    >({
      query: ({ start, end, timeframe, timezone, userId }) => ({
        method: 'GET',
        url: '/hdCornerService/user/sleep/graph',
        params: { start, end, timeframe, timezone, userId },
      }),
    }),
  }),
});

export const {
  useGetSleepLogsQuery,
  useGetSleepGraphDataQuery,
  useGetTodaysLastSleepLogQuery,
} = sleepQueries;

export default sleepQueries;
