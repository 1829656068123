import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { ComponentsLayout, Goal, theme } from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import {
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
} from './queries/diabetesQueries';
import useGoalValue from './hooks/useGoalValue';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useBloodGlucoseGoalData from './hooks/useBloodGlucoseGoalData';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const DiabetesGoal: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [startingDate, setStartingDate] = useState<string>('');

  const { data: startingMeasurements, error: errorStartingGlucose } =
    useGetBloodGlucoseStartingMeasurementQuery(
      { startDate: startingDate, userId },
      { skip: !startingDate },
    );
  const { data: currentMeasurements, error: errorCurrentGlucose } =
    useGetBloodGlucoseCurrentMeasurementQuery({
      userId,
    });
  const { data: bloodGlucoseGoal, error: bloodGlucoseGoalError } =
    useGetBloodGlucoseGoalQuery({ userId });

  useEffect(() => {
    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      const goal = bloodGlucoseGoal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [bloodGlucoseGoal]);

  const prepareGoalValue = useGoalValue(bloodGlucoseGoal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);

  const prepareData = useBloodGlucoseGoalData(
    prepareGoalValue,
    bloodGlucoseGoal,
    prepareStartingMeasurement,
    prepareCurrentMeasurement,
  );

  useEffect(() => {
    if (bloodGlucoseGoalError || errorStartingGlucose || errorCurrentGlucose) {
      presentError(t('errors.generic.error01'));
    }
  }, [bloodGlucoseGoalError, errorStartingGlucose, errorCurrentGlucose]);

  return (
    <PageLayout
      headerTitle={'Blood Glucose Goal'}
      headerColor={theme.palette.primary.main}
      defaultHref={`/dashboard/users/${userId}/diabetes`}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Goal data={prepareData} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DiabetesGoal;
