import React, { useState } from 'react';
import { Box } from '@mui/material';
import NotificationSettings from './components/NotificationSettings';
import PageLayout from '../../components/PageLayout';
import { useTranslation } from 'react-i18next';

const AccountNotifications = () => {
  const { t } = useTranslation();

  const [genNotifications, setGenNotifications] = useState<boolean>(false);

  return (
    <PageLayout headerTitle={t('headingsTitles.accSettings')} backgroundWhite>
      <Box
        gap={3}
        padding={3}
        display={'flex'}
        flexDirection={'column'}
        boxSizing={'border-box'}
      >
        <NotificationSettings
          checked={genNotifications}
          setChecked={setGenNotifications}
          text={t('notifications.text.genNots')}
          title={t('notifications.titles.genNots')}
        />
      </Box>
    </PageLayout>
  );
};

export default AccountNotifications;
