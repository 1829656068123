import { useMemo } from 'react';
import moment from 'moment';
import { DiabetesGoal } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const useBloodGlucoseGoalData = (
  prepareGoalValue: number | null,
  bloodGlucoseGoal: DiabetesGoal[] | undefined,
  prepareStartingMeasurement: number | undefined,
  prepareCurrentMeasurement: number | undefined,
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const goalValue = bloodGlucoseGoal?.[0];
    const firstValue = prepareStartingMeasurement;
    const currentValue = prepareCurrentMeasurement;

    return [
      {
        label: t('headingsTitles.goal'),
        value: prepareGoalValue ? `${prepareGoalValue} ${t('measurements.mgdl')}` : '-',
      },
      {
        label: t('goals.startingDate'),
        value: goalValue ? moment(goalValue.startDate).format('MMM Do, YYYY') : '-',
      },
      {
        label: t('goals.startingMeasurement'),
        value: firstValue ? `${firstValue.toFixed(2)} ${t('measurements.mgdl')}` : '-',
      },
      {
        label: t('goals.latestMeasurement'),
        value: currentValue
          ? `${currentValue.toFixed(2)} ${t('measurements.mgdl')}`
          : '-',
      },
      {
        label: t('goals.progress'),
        value:
          goalValue && firstValue && currentValue
            ? `${Math.round(
                ((prepareStartingMeasurement - prepareCurrentMeasurement) /
                  (prepareStartingMeasurement - goalValue.goal)) *
                  100,
              )} %`
            : '-',
      },
    ];
  }, [
    bloodGlucoseGoal,
    prepareGoalValue,
    prepareCurrentMeasurement,
    prepareStartingMeasurement,
  ]);
};

export default useBloodGlucoseGoalData;
