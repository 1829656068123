import { Box, BoxProps, styled, useMediaQuery } from '@mui/material';
import SectionGraph from './calories/components/SectionGraph';
import SectionDietPlan from './diet-plan/SectionDietPlan';
import SectionWellness from './wellness/components/SectionWellness';
import SectionExercise from './exercise/components/SectionExercise';
import SectionNutrition from './nutrition/components/SectionNutrition';
import React, { FC, useState } from 'react';
import DietCalculatorWeb from './diet-plan/DietCalculatorWeb';
import DietCalculatorInitial from './diet-plan/DietCalculatorInitial';
import { theme } from '@hdcorner/ui-library';

const BoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

const BoxDiet = styled(Box)<BoxProps>(() => ({
  width: '350px',
  margin: '0 auto',
  alignItems: 'center',
  justifyContent: 'center',
}));

type Props = {
  userId: string;
};
const WebFitnessOverview: FC<Props> = ({ userId }) => {
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const [display1, setDisplay1] = useState<string>('flex');
  const [display2, setDisplay2] = useState<string>('none');
  const [display3, setDisplay3] = useState<string>('none');

  const handlePanelSwitch = (value: number) => {
    if (value === 1) {
      setDisplay1('none');
      setDisplay2('flex');
    } else if (value === 2) {
      setDisplay2('none');
      setDisplay3('flex');
    } else {
      setDisplay3('none');
      setDisplay1('flex');
    }
  };

  return (
    <>
      <BoxContainer display={display1}>
        <Box gap={3} display={'flex'} flexWrap={lg ? 'nowrap' : 'wrap'}>
          <Box flexGrow={1}>
            <SectionGraph heading toggle={false} userId={userId} />
          </Box>
          <Box>
            <SectionNutrition userId={userId} />
          </Box>
        </Box>
        <Box
          gap={3}
          display={'flex'}
          justifyContent={'space-between'}
          flexWrap={xl ? 'nowrap' : 'wrap'}
        >
          <Box width={xl ? '50%' : '100%'}>
            <SectionDietPlan
              title
              userId={userId}
              handleCalculateClick={() => handlePanelSwitch(1)}
            />
          </Box>
          <Box
            gap={3}
            display={'flex'}
            flexDirection={'column'}
            width={xl ? '50%' : '100%'}
          >
            <Box gap={2} display={'flex'} flexGrow={1}>
              <SectionWellness userId={userId} />
              <SectionExercise userId={userId} />
            </Box>
          </Box>
        </Box>
      </BoxContainer>
      <BoxDiet display={display2}>
        <DietCalculatorInitial nextStepHandle={() => handlePanelSwitch(2)} />
      </BoxDiet>
      <Box display={display3}>
        <DietCalculatorWeb userId={userId} onClick={() => handlePanelSwitch(3)} />
      </Box>
    </>
  );
};

export default WebFitnessOverview;
