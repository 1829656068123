import React, { FC, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import {
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseGraphDataQuery,
  useGetBloodGlucoseOverallQuery,
} from './queries/diabetesQueries';
import {
  AllMeasurements,
  CalendarModal,
  ClickNavCardNutrition,
  CustomButton,
  DiabetesBloodGlucoseChart,
  ModalFilters,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { FilterOptions } from './constants';
import useOverallData from './hooks/useOverallData';
import useFilterLabel from './hooks/useFilterLabel';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useGoalValue from './hooks/useGoalValue';
import useTimeframe from '../../hooks/useTimeframe';
import usePrepareGraph from './hooks/usePrepareGraph';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

interface Props {
  userId: string;
}
const DiabetesBloodGlucose: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentError, presentInfo } = useAlert();

  const [filter, setFilter] = useState<string>('default');
  const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const filterLabel = useFilterLabel(filter);

  const { data: overallData, error: errorOverall } = useGetBloodGlucoseOverallQuery({
    userId,
    ...timeframe,
    category: filter === 'default' ? undefined : filter,
  });
  const { data: bloodGlucoseGoal, error: bloodGlucoseGoalError } =
    useGetBloodGlucoseGoalQuery({ userId });
  const { data: currentMeasurements, error: errorCurrent } =
    useGetBloodGlucoseCurrentMeasurementQuery({
      userId,
    });
  const { data: graphDataRes, error: errorGraph } = useGetBloodGlucoseGraphDataQuery({
    userId,
    ...timeframe,
    category: filter === 'default' ? undefined : filter,
  });

  const graphData = usePrepareGraph(graphDataRes);
  const prepareOverallData = useOverallData(overallData);
  const prepareGoalValue = useGoalValue(bloodGlucoseGoal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);

  useEffect(() => {
    if (bloodGlucoseGoalError || errorOverall || errorGraph || errorCurrent) {
      presentError(t('errors.generic.error01'));
    }
  }, [bloodGlucoseGoalError, errorOverall, errorGraph, errorCurrent]);

  const handleClickGoal = () => {
    router.push(`/dashboard/users/${userId}/diabetes-blood-glucose-goal`);
  };

  const handleShowGraphData = () => {
    router.push(`/dashboard/users/${userId}/diabetes-graph-data/blood-glucose`);
  };

  const handleShowInDepthData = () => {
    router.push(`/dashboard/users/${userId}/diabetes/blood-glucose-in-depth-data`);
  };

  const onClickConnectedDevice = () => {
    presentInfo('Coming soon');
  };

  const onOpenCalendar = () => {
    if (selectedTimeframe === 'custom') {
      setCalendarModalOpen(true);
    }
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={theme.spacing(1)}
        marginTop={theme.spacing(3)}
      >
        <AllMeasurements text={filterLabel} openModal={() => setFiltersModalOpen(true)} />
        <DiabetesBloodGlucoseChart
          chartData={graphData}
          overallData={prepareOverallData}
          handleOpenCalendar={onOpenCalendar}
          selectedTimeframe={selectedTimeframe}
          selectTimeframe={setSelectedTimeframe}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
        <Box marginTop={theme.spacing(2)}>
          <ClickNavCardNutrition
            onClick={handleShowInDepthData}
            headings={t('buttons.navigation.inDepth')}
          />

          <ClickNavCardNutrition
            headings={t('buttons.navigation.graphData')}
            onClick={handleShowGraphData}
          />

          {bloodGlucoseGoal && bloodGlucoseGoal.length > 0 && (
            <ClickNavCardNutrition
              onClick={handleClickGoal}
              headings={t('headingsTitles.goal')}
              body={
                prepareGoalValue
                  ? `${prepareGoalValue} ${t('measurements.mgdl')}`
                  : `${t('buttons.addGoal')}`
              }
            />
          )}
        </Box>
        <CustomButton
          fullWidth
          disableElevation
          color={'warning'}
          variant={'contained'}
          onClick={onClickConnectedDevice}
          sx={{
            justifyContent: 'flex-start',
            marginTop: theme.spacing(2),
          }}
        >
          {t('buttons.connectDevice')}
        </CustomButton>
      </Box>
      <ModalFilters
        selected={filter}
        open={filtersModalOpen}
        dismiss={newValue => {
          setFilter(newValue);
          setFiltersModalOpen(false);
        }}
        options={useConvertJSON(FilterOptions)}
      />
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </>
  );
};

export default DiabetesBloodGlucose;
