import { Box, Typography } from '@mui/material';

const TermsAndConditionsGR = () => {
  return (
    <Box>
      <Box>
        <Typography variant={'h1'}>ΟΡΟΙ ΧΡΗΣΗΣ</Typography>
        <Typography variant={'body1'} paragraph>
          Οι παρόντες όροι χρήσης διέπουν τη σχέση μεταξύ των επαγγελματιών υγείας
          οποιαδήποτε ειδικότητας ή επαγγέλματος που έχει σχέση με την υγεία και της
          εταιρίας «ΚΑΡΑΜΠΙΝΗΣ MEDICAL Α.Ε. ΠΑΡΑΓΩΓΗΣ ΕΜΠΟΡΙΑΣ ΙΑΤΡΟΤΕΧΝΟΛΟΓΙΚΩΝ ΠΡΟΪΟΝΤΩΝ
          ΚΑΙ ΠΑΡΟΧΗΣ ΥΠΗΡΕΣΙΩΝ», που εδρεύει στην Παιανία Αττικής, επί της Λεωφ. Λαυρίου,
          αριθ. 151, με Α.Φ.Μ.: 099052507 – Δ.Ο.Υ.: ΦΑΕ Αθηνών- ΑΡ. ΓΕΜΗ 87183102000 ,
          τηλ. 2106645751. («εμείς», « εμάς», «μας» ή «Εταιρεία») σχετικά με τη χρήση από
          μέρους σας των υπηρεσιών της Εφαρμογής HDCORNER -web ή mobile app (η «Εφαρμογή»
          ή «Υπηρεσία» ή «HDCORNER»), και όλων των διαθέσιμων πληροφοριών, κειμένου,
          γραφικών, λογισμικού και υπηρεσιών για τη χρήση εφαρμογής (το «Περιεχόμενο»).
        </Typography>
        <Typography variant={'body1'} paragraph>
          Αυτοί οι Όροι αποτελούν την νομικά δεσμευτική σύμβαση μεταξύ εσάς και της
          Εταιρείας για την χρήση της Εφαρμογής. Για το λόγο αυτό, ΠΑΡΑΚΑΛΟΥΜΕ ΔΙΑΒΑΣΤΕ
          ΠΡΟΣΕΚΤΙΚΑ ΤΟΥΣ ΟΡΟΥΣ ΠΡΙΝ ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΙΣ ΥΠΗΡΕΣΙΕΣ ΤΗΣ ΕΦΑΡΜΟΓΗΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η Εταιρεία διατηρεί το δικαίωμα να μεταβάλει ή να τροποποιεί τους εφαρμοστέους
          όρους και προϋποθέσεις για τη χρήση Εφαρμογής κατά την ελεύθερη κρίση της και σε
          οποιαδήποτε χρονική στιγμή, λαμβάνοντας πάντοτε υπόψη τις προϋποθέσεις που
          τάσσονται σχετικά από την κείμενη νομοθεσία
        </Typography>
        <Typography variant={'subtitle1'} paragraph>
          ΑΠΟΠΟΙΗΣΗ ΕΥΘΥΝΩΝ
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΟΙ ΥΠΗΡΕΣΙΕΣ ΚΑΙ ΤΑ ΠΕΡΙΕΧΟΜΕΝΑ ΠΑΡΕΧΟΝΤΑΙ "ΩΣ ΕΧΕΙ", ΧΩΡΙΣ ΚΑΝΕΝΟΣ ΕΙΔΟΥΣ
          ΕΓΓΥΗΣΗΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΠΑΡΕΧΕΙ ΚΑΝΕΝΟΣ ΕΙΔΟΥΣ ΙΑΤΡΙΚΗ ΣΥΜΒΟΥΛΗ, , ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ,
          ΠΡΟΛΗΨΗ, ΔΙΑΓΝΩΣΗ Ή ΘΕΡΑΠΕΙΑ ΑΣΘΕΝΕΙΑΣ Η ΚΑΤΑΣΤΑΣΗΣ ΥΓΕΙΑΣ .
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΤΟ ΠΕΡΙΕΧΟΜΕΝΟ ΠΟΥ ΠΑΡΕΧΕΤΑΙ ΜΕΣΩ ΤΗΣ ΥΠΗΡΕΣΙΑΣ, ΕΙΤΕ ΠΑΡΕΧΕΤΑΙ ΑΠΟ ΕΜΑΣ ΕΙΤΕ
          ΤΡΙΤΑ ΜΕΡΗ (ΑΚΟΜΑ ΚΑΙ ΑΝ ΕΙΝΑΙ ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΥΓΕΙΑΣ) ΔΕΝ ΠΡΟΟΡΙΖΕΤΑΙ ΚΑΙ ΔΕΝ
          ΠΡΕΠΕΙ ΝΑ ΧΡΗΣΙΜΟΠΟΙΕΙΤΑΙ ΑΝΤΙ ΓΙΑ ΤΗΝ ΕΠΙΣΚΕΨΗ, ΚΛΗΣΗ Ή ΔΙΑΒΟΥΛΕΥΣΗ ΜΕ ΤΟΝ
          ΓΙΑΤΡΟ Ή ΑΛΛΟΥΣ ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΤΗΣ ΥΓΕΙΑΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΑΝΑΛΑΜΒΑΝΕΙ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΤΗΝ ΚΑΤΑΛΛΗΛΟΤΗΤΑ ΤΗΣ ΥΠΗΡΕΣΙΑΣ ΣΕ
          ΚΑΘΕΝΑ ΧΡΗΣΤΗ ΚΑΙ ΤΟ ΕΠΙΠΕΔΟ ΤΗΣ ΕΠΙΤΥΧΙΑΣ ΚΑΙ ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΜΠΟΡΕΙ ΝΑ
          ΔΙΑΦΕΡΟΥΝ ΑΝΑ ΑΤΟΜΟ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΥΠΗΡΕΣΙΑ ΕΝΔΕΧΕΤΑΙ ΝΑ ΜΗΝ ΕΙΝΑΙ ΚΑΤΑΛΛΗΛΗ ΓΙΑ ΟΛΑ ΤΑ ΠΡΟΣΩΠΑ ΚΑΙ ΔΕΝ ΕΙΝΑΙ
          ΑΝΤΙΚΑΤΑΣΤΑΣΗ ΤΩΝ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΥΠΗΡΕΣΙΩΝ ΥΓΕΙΑΣ. Η ΥΠΗΡΕΣΙΑ ΠΡΟΟΡΙΖΕΤΑΙ ΜΟΝΟ
          ΩΣ ΕΡΓΑΛΕΙΟ, ΠΟΥ ΜΠΟΡΕΙ ΝΑ ΕΙΝΑΙ ΧΡΗΣΙΜΟ ΓΙΑ ΤΗΝ ΕΠΙΤΕΥΞΗ ΤΩΝ ΣΤΟΧΩΝ ΡΥΘΜΙΣΗΣ
          ΤΩΝ ΧΡΗΣΤΩΝ ΤΗΣ ΕΦΑΡΜΟΓΗΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΑΝΑΛΑΜΒΑΝΕΙ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΑΝΑΚΡΙΒΕΙΑ ΣΧΕΤΙΚΑ ΜΕ ΣΥΝΤΑΓΕΣ
          ΤΡΟΦΙΜΩΝ, ΑΣΚΗΣΕΙΣ Ή ΑΛΛΟ ΠΕΡΙΕΧΟΜΕΝΟ ΤΗΣ ΥΠΗΡΕΣΙΑΣ. ΠΡΕΠΕΙ ΝΑ ΔΙΑΒΑΣΕΤΕ
          ΠΡΟΣΕΚΤΙΚΑ ΟΛΕΣ ΤΙΣ ΠΛΗΡΟΦΟΡΙΕΣ ΠΟΥ ΠΑΡΕΧΟΝΤΑΙ ΑΠΟ ΤΟΥΣ ΚΑΤΑΣΚΕΥΑΣΤΕΣ ΤΩΝ
          ΔΙΑΤΡΟΦΙΚΩΝ ΠΡΟΪΟΝΤΩΝ, ΕΙΤΕ ΣΤΟ ΔΙΑΔΙΚΤΥΟ ΕΙΤΕ ΣΤΗΝ ΠΡΑΓΜΑΤΙΚΗ ΣΥΣΚΕΥΑΣΙΑ ΚΑΙ
          ΤΙΣ ΕΤΙΚΕΤΕΣ ΤΟΥ ΠΡΟΪΟΝΤΟΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΟΥ ΤΟΥ ΠΕΡΙΕΧΟΜΕΝΟΥ ΘΡΕΠΤΙΚΩΝ
          ΣΥΣΤΑΤΙΚΩΝ , ΠΡΙΝ ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ Ή ΚΑΤΑΝΑΛΩΣΕΤΕ ΕΝΑ ΠΡΟΪΟΝ. ΓΙΑ ΠΡΟΣΘΕΤΕΣ
          ΠΛΗΡΟΦΟΡΙΕΣ ΣΧΕΤΙΚΑ ΜΕ ΕΝΑ ΔΙΑΤΡΟΦΙΚΟ ΠΡΟΪΟΝ, ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΑΠΕΥΘΕΙΑΣ ΜΕ ΤΟΝ
          ΠΑΡΑΓΩΓΟ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΑΝΑΛΑΜΒΑΝΕΙ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΤΗΝ ΚΑΤΑΛΛΗΛΟΤΗΤΑ ΤΗΣ ΥΠΗΡΕΣΙΑΣ ΣΕ
          ΣΑΣ ΚΑΙ ΤΟ ΕΠΙΠΕΔΟ ΤΗΣ ΕΠΙΤΥΧΙΑΣ ΚΑΙ ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΜΠΟΡΕΙ ΝΑ ΔΙΑΦΕΡΟΥΝ ΑΝΑ
          ΑΤΟΜΟ. Ειδικότερα, η Εταιρία δεν παρέχει και αρνείται ρητά οποιαδήποτε εγγύηση
          ότι: (i) η Υπηρεσία θα ικανοποιήσει τις απαιτήσεις σας, (ii) η Υπηρεσία θα είναι
          αδιάκοπη, έγκαιρη, ασφαλής ή χωρίς σφάλματα, (iii) τα αποτελέσματα που ενδέχεται
          να προκύψουν από τη χρήση της Υπηρεσίας, συμπεριλαμβανομένων των δεδομένων, θα
          είναι ακριβή ή αξιόπιστα, (iv) η ποιότητα οποιωνδήποτε δεδομένων ή υπηρεσιών που
          διατίθενται στην Υπηρεσία θα ανταποκρίνεται στις προσδοκίες σας και (v) τυχόν
          σφάλματα στην υπηρεσία θα διορθωθούν. Οποιοδήποτε υλικό αποκτάται μέσω της
          χρήσης της Υπηρεσίας γίνεται με δική σας κρίση και ευθύνη και θα είστε
          αποκλειστικά υπεύθυνοι για οποιαδήποτε ζημία, σύστημα υπολογιστή ή φορητή
          συσκευή ή απώλεια δεδομένων που προκύπτει από τη χρήση οποιουδήποτε τέτοιου
          υλικού. Δεν μπορούμε να εγγυηθούμε και δεν υποσχόμαστε συγκεκριμένα αποτελέσματα
          από τη χρήση της Εφαρμογής ή/και της Υπηρεσίας. Συμφωνείτε επίσης να αναλάβετε
          τους κινδύνους διακοπής της Υπηρεσίας για οποιουσδήποτε τεχνικούς λόγους.
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΟΙ ΜΑΡΤΥΡΙΕΣ ΚΑΙ ΤΑ ΠΑΡΑΔΕΙΓΜΑΤΑ ΠΟΥ ΜΠΟΡΕΙ ΝΑ ΠΑΡΕΧΟΝΤΑΙ ΣΤΗΝ ΥΠΗΡΕΣΙΑ ΜΠΟΡΕΙ
          ΝΑ ΜΗΝ ΙΣΧΥΟΥΝ ΓΙΑ ΟΛΑ ΤΑ ΑΤΟΜΑ ΚΑΙ ΔΕΝ ΕΓΓΥΩΝΤΑΙ ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ. ΔΕΝ ΥΠΑΡΧΕΙ
          ΔΙΑΒΕΒΑΙΩΣΗ ΟΤΙ ΠΑΡΑΔΕΙΓΜΑΤΑ ΠΡΟΗΓΟΥΜΕΝΩΝ ΑΠΟΤΕΛΕΣΜΑΤΩΝ ΑΣΚΗΣΕΩΝ ΚΑΙ ΔΙΑΤΡΟΦΗΣ
          ΜΠΟΡΟΥΝ ΝΑ ΕΧΟΥΝ ΑΠΟΤΕΛΕΣΜΑΤΑ ΣΤΟ ΜΕΛΛΟΝ. ΔΕΝ ΜΠΟΡΟΥΜΕ ΝΑ ΕΓΓΥΗΘΟΥΜΕ ΤΑ
          ΜΕΛΛΟΝΤΙΚΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΚΑΙ/Η ΤΗΝ ΕΠΙΤΥΧΙΑ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ΥΓΕΙΑ ΤΗΝ ΑΣΚΗΣΗ ΚΑΙ ΤΗΝ ΔΙΑΤΡΟΦΗ ΚΑΘΕ ΑΤΟΜΟΥ
          ΕΞΑΡΤΑΤΑΙ ΑΠΟ ΤΟ ΙΣΤΟΡΙΚΟ ΤΟΥ, ΤΗΝ ΑΦΟΣΙΩΣΗ, ΤΗΝ ΕΠΙΘΥΜΙΑ ΚΑΙ ΤΑ ΚΙΝΗΤΡΑ ΤΟΥ.
          ΟΠΩΣ ΜΕ ΟΠΟΙΟΔΗΠΟΤΕ ΠΡΟΓΡΑΜΜΑ Ή ΥΠΗΡΕΣΙΑ ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ΥΓΕΙΑ, ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ
          ΕΝΔΕΧΕΤΑΙ ΝΑ ΔΙΑΦΕΡΟΥΝ ΚΑΙ ΘΑ ΒΑΣΙΣΘΟΥΝ ΣΕ ΠΟΛΛΕΣ ΜΕΤΑΒΛΗΤΕΣ,
          ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΑΛΛΑ ΟΧΙ ΠΕΡΙΟΡΙΣΤΙΚΑ ΤΗΣ ΑΤΟΜΙΚΗΣ ΙΚΑΝΟΤΗΤΑΣ, ΚΑΙ ΤΟ
          ΕΠΙΠΕΔΟ ΤΗΣ ΣΥΝΕΠΕΙΑΣ. Η ΧΡΗΣΗ ΤΗΣ ΥΠΗΡΕΣΙΑΣ ΠΡΕΠΕΙ ΝΑ ΒΑΣΙΖΕΤΑΙ ΣΤΗ ΔΕΟΥΣΑ
          ΕΠΙΜΕΛΕΙΑ ΤΟΥ ΧΡΗΣΤΗ ΚΑΙ ΣΥΜΦΩΝΕΙΤΕ ΟΤΙ Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΦΕΡΕΙ ΕΥΘΥΝΗ ΓΙΑ
          ΟΠΟΙΑΔΗΠΟΤΕ ΕΠΙΤΥΧΙΑ Ή ΑΠΟΤΥΧΙΑ ΤΗΣ ΥΓΕΙΑΣ ΚΑΙ ΤΗΣ ΦΥΣΙΚΗΣ ΚΑΤΑΣΤΑΣΗΣ ΤΩΝ
          ΧΡΗΣΤΩΝ .
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΕΠΙΠΛΕΟΝ ΟΛΩΝ ΤΩΝ ΑΛΛΩΝ ΠΕΡΙΟΡΙΣΜΩΝ ΚΑΙ ΑΠΟΠΟΙΗΣΕΩΝ ΣΕ ΑΥΤΟΥΣ ΟΡΟΥΣ, Η ΕΤΑΙΡΕΙΑ
          ΑΠΟΠΟΙΕΙΤΑΙ ΟΠΟΙΑΔΗΠΟΤΕ ΕΥΘΥΝΗ Ή ΖΗΜΙΑ ΣΕ ΣΥΝΔΕΣΗ ΜΕ ΤΟ ΠΕΡΙΕΧΟΜΕΝΟ ΠΟΥ
          ΠΑΡΕΧΕΤΑΙ ΣΤΗΝ ΥΠΗΡΕΣΙΑ.
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>Δημιουργία Λογαριασμού </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Για την πλήρη χρήση των Υπηρεσιών απαιτείται να δημιουργήσετε έναν λογαριασμό,
            παρέχοντάς μας τις κάτωθι πληροφορίες: ονοματεπώνυμο, ειδικότητα, ΑΦΜ,
            διεύθυνση, Αριθμός, ΤΚ, περιοχή, τηλέφωνο επικοινωνίας και ηλεκτρονική
            διεύθυνση. Επιπλέον θα κληθείτε να απαντήσετε εάν θα είστε HD Corner Clinical
            coach και θα φτιάξετε ένα κωδικό πρόσβασης (password), όπου μαζί με το email
            σας θα τα χρησιμοποιείτε εφεξής για την σύνδεσή σας στην υπηρεσία. Έχετε τη
            δυνατότητα να αλλάξετε το password στην Καρτέλα «Προφίλ».
          </Typography>
          <Typography variant={'body1'} paragraph>
            Αποτελεί αποκλειστικά δική σας ευθύνη να ελέγχετε τη γνωστοποίηση και την
            χρήση του κωδικού πρόσβασης και να επιτρέπετε, να ελέγχετε και να
            διαχειρίζεστε την πρόσβαση και χρήση του λογαριασμού και του κωδικού σας στην
            υπηρεσία HD Corner.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Με την εγγραφή σας στην υπηρεσία HD Corner και την συστημική έγκριση από την
            Εταιρεία, ο λογαριασμός σας θα ενεργοποιηθεί και θα αποκτήσετε έναν μοναδικό
            κωδικό με τον οποίο θα μπορείτε να «συνδέεστε» με τους χρήστες της υπηρεσίας.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Χρήση των Υπηρεσιών</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Με την εγγραφή σας στην Υπηρεσίας ως επαγγελματίας υγείας, οι χρήστες της
            εφαρμογής θα μπορούν να σας δώσουν πρόσβαση σε δεδομένα τους, προκειμένου να
            τους παράσχετε συμβουλές μέσω της Εφαρμογής. Οι χρήστες της εφαρμογής θα
            εισάγουν τον μοναδικό κωδικό σας στο προφίλ τους προκειμένου να σας δοθεί
            πρόσβαση.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Για την χρήση των υπηρεσιών της εφαρμογής ενδεχομένως θα παράσχετε εκτός από
            τα δικά σας προσωπικά δεδομένα για την δημιουργία του λογαριασμού σας και
            διάφορα Δεδομένα, πληροφορίες, ιατρικές μετρήσεις, σχόλια, ερωτήσεις ,
            ιατρικές αναφορές , φωτογραφίες κλπ. , τα οποία θα αφορούν τους χρήστες της
            εφαρμογής οι οποίοι θα σας επιλέξουν για την απευθείας επικοινωνία μαζί σας (
            συλλογικά αναφέρονται ως «Δεδομένα»)
          </Typography>
          <Typography variant={'body1'} paragraph>
            Με την χρήση των Υπηρεσιών αναγνωρίζετε ότι απαγορεύεται (1) να φορτώσετε
            οποιοδήποτε περιεχόμενο που περιέχει ιούς software ή που έχει σχεδιαστεί για
            να διακόψει, καταστρέψει ή περιορίσει τη λειτουργικότητα οποιουδήποτε
            εξοπλισμού ή υπηρεσίας, ή που περιέχει άλλα αρχεία ή περιεχόμενα που είναι
            επιβλαβή, καταστροφικά, ενοχλητικά ή καταστρεπτικά, (2) να χρησιμοποιήσετε ή
            να προσπαθήσετε να χρησιμοποιήσετε τον λογαριασμό άλλου χρήστη χωρίς την
            έγκρισή του, ή να μιμηθείτε οποιοδήποτε άλλο άτομο ή οντότητα, (3) να
            συλλέξετε, ζητήσετε ή ενώσετε πληροφορίες άλλων χρηστών για οποιοδήποτε λόγο,
            συμπεριλαμβανομένης, χωρίς περιορισμών, της αποστολής ανεπιθύμητης
            επικοινωνίας, (4) να δημοσιοποιήσετε, διαφημίσετε ή προωθήσετε εμπορικά
            προϊόντα ή υπηρεσίες, ή να φορτώσετε οποιοδήποτε επιβλαβές περιεχόμενο, (5) να
            χρησιμοποιείτε τις Υπηρεσίες με οποιοδήποτε τρόπο, που κατά τη δική μας
            διακριτική ευχέρεια, είναι απαράδεκτο ή περιορίζει ή εμποδίζει οποιοδήποτε
            άλλο άτομο να χρησιμοποιήσει ή να απολαύσει τις Υπηρεσίες, ή που μπορεί να
            εκθέσει εμάς ή τους χρήστες μας σε οποιαδήποτε βλάβη ή ευθύνη πάσης φύσεως (6)
            Να Αναπτύσσετε, υποστηρίζετε ή χρησιμοποιείτε το λογισμικό, συσκευές, κείμενα,
            ρομπότ ή οποιοδήποτε άλλο μέσο ή διαδικασία (συμπεριλαμβάνοντας ιχνηλάτες,
            πρόσθετα ή συμπληρώματα περιήγησης ή οποιαδήποτε άλλη τεχνολογία) για να
            βλάψετε τις Υπηρεσίες ή να αντιγράψετε τα προφίλ και άλλα δεδομένα των
            Υπηρεσιών,(7) να Ακυρώνετε οποιοδήποτε άλλο χαρακτηριστικό ασφαλείας,
            αποφεύγετε ή παρακάμπτετε οποιοδήποτε έλεγχο πρόσβασης ή περιορισμού χρήσης
            της Υπηρεσίας, (8) να αντιγράφετε, χρησιμοποιείτε, αποκαλύπτετε ή διανείμετε
            οποιαδήποτε πληροφορία που έχει αποκτηθεί από τις Υπηρεσίες, είτε απευθείας ή
            μέσω τρίτων, χωρίς τη συγκατάθεση του HD Corner (9) να Αποκαλύπτετε
            πληροφορίες που δεν έχετε λάβει την άδεια να αποκαλύψετε (όπως οι
            εμπιστευτικές πληροφορίες άλλων),(10) να παραβιάζετε την πνευματική ιδιοκτησία
            και άλλα δικαιώματα του HD Corner , συμπεριλαμβάνοντας, χωρίς περιορισμό, (i)
            της αντιγραφής ή διανομής των διαιτολογίων ή της προπόνησης, συμβουλών και
            άρθρων ευεξίας, βίντεο και άλλου υλικού ή (ii) της αντιγραφής ή διανομής της
            τεχνολογίας, εκτός και αν είναι ελεύθερη με βάση τις άδειες του ανοιχτού
            κώδικα; (iii) της χρήσης της λέξης " HD Corner " ή των λογότυπων μας σε
            οποιαδήποτε εμπορική ονομασία, e-mail ή URL, (11) Να αναπαράγετε,
            αποκωδικοποιήσετε ή να επιχειρήσετε να αποκομίσετε τον αρχικό κωδικό για τις
            Υπηρεσίες ή για οποιαδήποτε σχετική τεχνολογία που δεν είναι ανοιχτού κώδικα,
            (12) να νοικιάσετε, δανειστείτε, εμπορευτείτε, πουλήσετε/μεταπουλήσετε ή να
            αποκτήσετε έσοδα με οποιοδήποτε άλλο τρόπο από τις Υπηρεσίες ή τα σχετικά
            δεδομένα ή την πρόσβαση σε αυτά, χωρίς την συγκατάθεση του HD Corner.(12) να
            συνδέεστε στις Υπηρεσίες μας για οποιοδήποτε σκοπό εκτός από την προώθηση του
            προφίλ ή σε μια Ομάδα στις Υπηρεσίες μας, χωρίς την συγκατάθεση του HD
            Corner.(14) να παρακολουθείτε τη διαθεσιμότητα, την απόδοση ή τη
            λειτουργικότητα των Υπηρεσιών για οποιοδήποτε ανταγωνιστικό σκοπό (15) να
            συμμετέχετε "πλαισιώνοντας", "αντανακλώντας" ή προσποιούμενοι με άλλο τρόπο
            την εμφάνιση ή τη λειτουργία των Υπηρεσιών (16) να επικαλύπτετε ή τροποποιείτε
            με άλλο τις Υπηρεσίες ή την εμφάνισή τους (για παράδειγμα, εισάγοντας στοιχεία
            στις Υπηρεσίες αφαιρώντας ή κρύβοντας μια διαφήμιση που περιλαμβάνεται στις
            Υπηρεσίες), (17) να παραβιάζεται οποιοδήποτε επιπλέον όρο σχετικά με την
            συγκεκριμένη Υπηρεσία που σας παρέχεται όταν εγγράφεστε ή ξεκινάτε να
            χρησιμοποιείτε την συγκεκριμένη Υπηρεσία.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Η Εταιρεία διατηρεί το δικαίωμα να αναστείλει ή να τερματίσει το Προφίλ σας ή
            την πρόσβασή σας στην Υπηρεσία, με ή χωρίς ειδοποίηση προς εσάς, σε περίπτωση
            που παραβιάσετε αυτούς τους Όρους. Είστε υπεύθυνοι για τη διατήρηση της
            εμπιστευτικότητας των πληροφοριών σύνδεσης του Προφίλ σας και είστε πλήρως
            υπεύθυνοι για όλες τις δραστηριότητες που πραγματοποιούνται στο πλαίσιο του
            Προφίλ σας. Συμφωνείτε να ειδοποιήσετε αμέσως την Εταιρεία για οποιαδήποτε μη
            εξουσιοδοτημένη χρήση ή ύποπτη μη εξουσιοδοτημένη χρήση του Προφίλ σας ή
            οποιαδήποτε άλλη παραβίαση ασφάλειας. Η Εταιρεία δεν μπορεί και δεν θα φέρει
            ευθύνη για οποιαδήποτε απώλεια ή ζημία προκύψει από τη μη συμμόρφωσή σας με
            τις παραπάνω απαιτήσεις.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Αλλαγή Πληροφοριών και Υπηρεσιών Ιστοσελίδας
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Μπορούμε να αλλάξουμε όλες τις πληροφορίες που παρέχονται από την Υπηρεσία
            κατά την απόλυτη διακριτική μας ευχέρεια χωρίς προειδοποίηση. Μπορούμε ανά
            πάσα στιγμή να τροποποιήσουμε ή να διακόψουμε, προσωρινά ή μόνιμα, την
            Υπηρεσία (ή οποιοδήποτε μέρος αυτής) κατά τη διακριτική μας ευχέρεια με ή
            χωρίς προειδοποίηση. Συμφωνείτε ότι δεν θα φέρουμε ευθύνη έναντι εσάς ή
            οποιουδήποτε τρίτου για οποιαδήποτε τροποποίηση, αναστολή ή διακοπή της
            Υπηρεσίας
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Διαγραφή από την Υπηρεσία</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Ο χρήστης έχει τη δυνατότητα να διαγραφεί από την υπηρεσία (unsubscribe)
            επιλέγοντας την αντίστοιχη επιλογή στην καρτέλα προφίλ. Επιλέγοντας να
            διαγραφείτε συμφωνείτε ότι το προφίλ σας θα διαγραφεί και όλα τα δεδομένα που
            έχετε καταχωρήσει θα καταστραφούν σύμφωνα με τη νομοθεσία περί προσωπικών
            δεδομένων.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Η Εταιρεία διατηρεί το δικαίωμα διακόψει την υπηρεσία κατά την ελεύθερη κρίση
            της, σε περίπτωση που ο χρήστης προβαίνει σε ενέργειες που είναι παράνομες ή
            απαγορευμένες ή συμπεριφορές οι οποίες υπονομεύουν τη λειτουργία του
            Διαδικτυακού της Τόπου/ Εφαρμογής και παραβιάζουν τους παρόντες Όρους χρήσης
          </Typography>
          <Typography variant={'body1'} paragraph>
            Σε περιπτώσεις που υπάρχουν υπόνοιες/ διαπιστώνονται απαγορευμένες ενέργειες,
            η Εταιρεία διατηρεί το δικαίωμα να προβεί σε κάθε ενέργεια την οποία θεωρεί
            αναγκαία κατά την ελεύθερη κρίση της, όπως ενδεικτικά να διερευνάει και να
            αφαιρεί το περιεχόμενο που αντιβαίνει στους παρόντες όρους.
          </Typography>
          <li>
            <Typography variant={'body1'} paragraph>
              Χρήση Περιεχομένου
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Το Περιεχόμενο του HD Corner συμπεριλαμβάνει φωτογραφίες, εικόνες, γραφικά,
            βίντεο, ήχο, δεδομένα, κείμενα, μουσική, φυσική κατάσταση, σχόλια, λογισμικά,
            εργασίες συγγραφέων οποιουδήποτε είδους και άλλες πληροφορίες, περιεχόμενο και
            άλλο υλικό που δημοσιεύεται, παράγεται, διατίθεται ή μέσω των Υπηρεσιών
            (συλλογικά αναφέρονται ως "Περιεχόμενο"). Το Περιεχόμενο, οι Υπηρεσίες
            προστατεύονται από πνευματικά δικαιώματα, εμπορικά σήματα και διπλώματα
            ευρεσιτεχνίας, . Διατηρούμε όλα τα δικαιώματα που δεν αναφέρονται ρητά στους
            παρόντες Όρους. Συμφωνείτε να μη διαγράψετε, αλλάξετε ή αποκρύψετε τυχόν
            ειδοποίηση πνευματικών δικαιωμάτων, εμπορικών σημάτων, σημάτων υπηρεσιών ή
            άλλων δικαιωμάτων ιδιοκτησίας που ενσωματώνονται ή συνοδεύουν τις Υπηρεσίες.
            Τα λογότυπά μας και οποιοδήποτε άλλο εμπορικό σήμα του HD Corner που μπορεί να
            εμφανιστεί στις Υπηρεσίες, και το Περιεχόμενο της εφαρμογής , όπως π.χ. η
            γενική εμφάνιση των Υπηρεσιών, συμπεριλαμβανομένων των επικεφαλίδων των
            σελίδων, των γραφικών, των εικόνων και των κειμένων, δεν επιτρέπεται να
            αντιγράφονται , να μιμούνται ή να χρησιμοποιούνται με οποιονδήποτε τρόπο, στο
            σύνολό ή μέρος αυτών, χωρίς προηγούμενη γραπτή εξουσιοδότηση. Άλλα εμπορικά
            σήματα, ονόματα προϊόντων και υπηρεσιών και ονόματα εταιρειών ή λογότυπα που
            έχουν αναφερθεί στις Υπηρεσίες αποτελούν ιδιοκτησία των αντίστοιχων ιδιοκτητών
            και δεν μπορούν να αντιγράφονται, να μιμούνται ή να χρησιμοποιούνται, στο
            σύνολο ή μέρος αυτών, χωρίς την άδεια του κατόχου του εμπορικού σήματος που
            είναι αντίστοιχα κατοχυρωμένη.
          </Typography>

          <Typography variant={'body1'} paragraph>
            Επισημαίνεται ότι η χρήση της εφαρμογής και του περιεχομένου της , γίνεται
            αποκλειστικά με δική σας ευθύνη. Η Εταιρία δεν φέρει καμία ευθύνη για τυχόν
            καθυστερήσεις, αποτυχίες, διακοπές, ή φθορές οποιονδήποτε δεδομένων ή
            πληροφοριών σχετίζονται με την εφαρμογή. Η Εταιρία καμία περίπτωση δεν
            εγγυάται ακόμη, την έλλειψη «ιών» ή άλλου επιζήμιου κώδικα είτε πρόκειται για
            την εφαρμογή, είτε για κάποιο άλλο site ή server μέσω των οποίων λαμβάνετε το
            περιεχόμενο της.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Με την επιφύλαξη των παρόντων Όρων, η Εταιρεία σας χορηγεί μια μη
            μεταβιβάσιμη, μη αποκλειστική άδεια (χωρίς δικαίωμα υποάδειας) για (i) χρήση
            της Υπηρεσίας αποκλειστικά για προσωπικούς, μη εμπορικούς σκοπούς και (β)
            εγκατάσταση και χρήση της Εφαρμογής, αποκλειστικά στη δική σας φορητή συσκευή
            χειρός (π.χ. iPhone, Android κ.λπ. κατά περίπτωση) ή προσωπικό υπολογιστή και
            αποκλειστικά για προσωπικούς, μη εμπορικούς σκοπούς.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Συμφωνείτε, δηλώνετε και εγγυάστε, ότι η χρήση της Υπηρεσίας ή οποιουδήποτε
            τμήματος αυτής, θα είναι σύμφωνη με την προαναφερόμενη άδεια, τις συμβάσεις
            και τους περιορισμούς και δεν θα παραβιάζει ούτε θα παραβιάζει τα δικαιώματα
            οποιουδήποτε άλλου μέρους ούτε θα παραβιάζει οποιαδήποτε σύμβαση ή νομική
            υποχρέωση σε οποιαδήποτε άλλα μέρη. Επιπλέον, συμφωνείτε ότι θα συμμορφώνεστε
            με όλους τους ισχύοντες νόμους, και κανονισμούς που σχετίζονται με την
            Υπηρεσία ή τη χρήση της και θα είστε αποκλειστικά υπεύθυνοι για τις δικές σας
            ατομικές παραβιάσεις οποιωνδήποτε τέτοιων νόμων.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Είστε αποκλειστικά υπεύθυνοι για την απόκτηση του εξοπλισμού και των
            τηλεπικοινωνιακών υπηρεσιών που είναι απαραίτητες για την πρόσβαση στην
            Υπηρεσία, καθώς και για όλες τις χρεώσεις που σχετίζονται με αυτές (όπως
            υπολογιστικές συσκευές και Πάροχος υπηρεσιών Διαδικτύου και χρεώσεις χρόνου
            ομιλίας).
          </Typography>
          <Typography variant={'body1'} paragraph>
            Η πρόσβασή σας και η χρήση της Υπηρεσίας γίνεται με δική σας ευθύνη. Η
            Εταιρεία δεν θα έχει καμία ευθύνη για οποιαδήποτε βλάβη στο υπολογιστικό σας
            σύστημα, απώλεια δεδομένων ή άλλη βλάβη σε εσάς ή σε οποιοδήποτε τρίτο μέρος.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Περιορισμός ευθύνης</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Η ΕΤΑΙΡΙΑ ΚΑΙ ΟΙ ΟΝΤΟΤΗΤΕΣ ΠΟΥ ΕΜΠΛΕΚΟΝΤΑΙ ΜΕ ΤΗ ΔΗΜΙΟΥΡΓΙΑ, ΤΗΝ ΠΑΡΑΓΩΓΗ Η
            ΤΗΝ ΠΑΡΟΧΗ ΥΠΗΡΕΣΙΩΝ ΔΕΝ ΕΥΘΥΝΟΝΤΑΙ ΓΙΑ ΤΥΧΟΝ ΑΚΟΥΣΙΕΣ, ΕΙΔΙΚΕΣ Η ΕΠΑΚΟΛΟΥΘΕΣ
            ΖΗΜΙΕΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΗΣ ΤΗΣ ΑΠΩΛΕΙΑΣ ΚΕΡΔΩΝ, ΑΠΩΛΕΙΑΣ ΔΕΔΟΜΕΝΩΝ , ΔΙΑΚΟΠΗΣ
            ΤΗΣ ΥΠΗΡΕΣΙΑΣ, ΖΗΜΙΑΣ ΣΤΟ ΗΛΕΚΤΡΟΝΙΚΟ ΥΛΙΚΟ, Ή ΑΔΥΝΑΜΙΑΣ ΤΟΥ ΣΥΣΤΗΜΑΤΟΣ Ή
            ΔΑΠΑΝΕΣ ΓΙΑ ΤΗΝ ΑΝΤΙΚΑΤΑΣΤΑΣΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΠΟΥ ΠΡΟΚΥΠΤΟΥΝ ΑΠΟ Ή ΣΕ ΣΧΕΣΗ ΜΕ
            ΤΟΥΣ ΌΡΟΥΣ Η ΑΠΟ ΤΗ ΧΡΗΣΗ Η ΤΗΝ ΑΔΥΝΑΜΙΑ ΧΡΗΣΗΣ ΤΩΝ ΥΠΗΡΕΣΙΩΝ, ΠΟΥ ΒΑΣΙΖΟΝΤΑΙ
            ΣΕ ΟΠΟΙΑΔΉΠΟΤΕ ΕΓΓΥΗΣΗ, ΣΥΜΒΑΣΗ, ΑΔΙΚΟΠΡΑΞΙΑ (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΗΣ ΤΗΣ
            ΑΜΕΛΕΙΑΣ), ΔΙΑΤΑΞΕΙΣ ΕΥΘΥΝΗΣ ΠΡΟΪΟΝΤΟΣ Ή ΔΙΑΦΗΜΙΣΕΩΝ ΤΡΙΤΩΝ ΜΕΡΩΝ, Ή
            ΟΠΟΙΑΔΗΠΟΤΕ ΆΛΛΗ ΝΟΜΙΚΗ ΔΙΑΤΑΞΗ, ΑΚΟΜΑ ΚΑΙ ΑΝ ΈΧΟΥΝ ΕΝΗΜΕΡΩΘΕΙ ΓΙΑ ΤΗΝ
            ΠΙΘΑΝΟΤΗΤΑ ΑΥΤΩΝ ΤΩΝ ΖΗΜΙΩΝ.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Ενημερωτικά δελτία (newsletters)
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Με την αποδοχή των όρων χρήσης της Εφαρμογής και την εγγραφή του χρήστη στις
            Υπηρεσίες του HDCORNER, θα μπορούν να αποστέλλονται σε αυτόν ενημερωτικά
            δελτία (newsletters) στην διεύθυνση ηλεκτρονικού ταχυδρομείου που έχει δηλώσει
            κατά την εγγραφή του .Σε περίπτωση που ο χρήστης δεν επιθυμεί στο μέλλον την
            αποστολή ενημερωτικών δελτίων - ειδήσεων του HD CORNER , μπορεί να προβεί
            άμεσα στην διαγραφή της ηλεκτρονικής διεύθυνσης ταχυδρομείου του από την λίστα
            διευθύνσεων αποδεκτών τέτοιων μηνυμάτων, χρησιμοποιώντας τον ειδικό σύνδεσμο
            διαγραφής (unsubscribe) που υπάρχει σε κάθε τέτοιo ενημερωτικό δελτίο ( ν.
            3741/2006 αρ. 11 παρ. 3 και 4).
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Διαβάστε την Πολιτική Απορρήτου</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Το HD Corner σέβεται την ιδιωτικότητά σας. Για να λάβετε πληροφορίες για την
            πολιτική προστασίας των δεδομένων μας, συμβουλευτείτε την Πολιτική Απορρήτου
            που αναφέρεται κατωτέρω. Με την πρόσβαση ή χρήση των Υπηρεσιών, αποδέχεστε πως
            το HD Corner μπορεί να συλλέξει και να χρησιμοποιήσει τις πληροφορίες που μας
            παρέχετε σύμφωνα με την Πολιτική Απορρήτου.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Δικαιοδοσία</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Οι παρόντες Όροι Χρήσης διέπονται από το Ελληνικό δίκαιο. Ο χρήστης αποδέχεται
            ρητά ότι την αποκλειστική δικαιοδοσία και αρμοδιότητα για οποιαδήποτε διαφορά
            προκύψει με την χρήση της Εφαρμογής την έχουν τα Δικαστήρια της Αθήνας. Αν
            οποιοσδήποτε από τους ανωτέρω όρους αποδειχθεί άκυρος, αυτό δεν επηρεάζει την
            εγκυρότητα και ισχύ των υπολοίπων όρων οι οποίοι παραμένουν σε πλήρη ισχύ.
          </Typography>
          <Typography variant={'body1'} paragraph>
            ΕΑΝ ΔΕΝ ΣΥΜΦΩΝΕΙΤΕ ΜΕ ΟΠΟΙΟΔΗΠΟΤΕ ΜΕΡΟΣ ΑΥΤΩΝ ΤΩΝ ΟΡΩΝ, ΤΟΤΕ ΜΗΝ ΚΑΤΕΒΑΣΕΤΕ
            ΤΗΝ ΕΦΑΡΜΟΓΗ Ή ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΗΝ ΥΠΗΡΕΣΙΑ
          </Typography>
          <Typography variant={'subtitle1'}>Επικοινωνία</Typography>
          <Typography variant={'body1'} paragraph>
            Εάν θέλετε να επικοινωνήσετε μαζί μας σχετικά τους παρόντες Όρους χρήσης ή
            έχετε οποιαδήποτε άλλη απορία σχετικά με την παροχή των Υπηρεσιών μπορείτε να
            στείλετε μήνυμα στο info@hdcorner.com.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Τελευταία ημερομηνία ενημέρωσης 08/01/2024
          </Typography>
        </ol>
      </Box>
      <Box>
        <Typography variant={'h1'} paragraph>
          ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η παρούσα Πολιτική Προστασίας προσωπικών Δεδομένων αφορά την επεξεργασία των
          προσωπικών δεδομένων σας κατά την χρήση της Εφαρμογής HDCORNER ( εφεξής η
          «Εφαρμογή»)
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>
              Υπεύθυνος Επεξεργασίας – Στοιχεία επικοινωνίας
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            «ΚΑΡΑΜΠΙΝΗΣ MEDICAL Α.Ε. ΠΑΡΑΓΩΓΗΣ ΕΜΠΟΡΙΑΣ ΙΑΤΡΟΤΕΧΝΟΛΟΓΙΚΩΝ ΠΡΟΪΟΝΤΩΝ ΚΑΙ
            ΠΑΡΟΧΗΣ ΥΠΗΡΕΣΙΩΝ», που εδρεύει στην Παιανία Αττικής, επί της Λεωφ. Λαυρίου,
            αριθ. 151, με Α.Φ.Μ.: 099052507 – Δ.Ο.Υ.: ΦΑΕ Αθηνών- ΑΡ. ΓΕΜΗ 87183102000 ,
            τηλ. 2106645751 ( κατωτέρω η «Εταιρία»)
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Ποια προσωπικά μου δεδομένα συλλέγονται και πώς;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Κατά την εγγραφή σας στην Εφαρμογή (sign up) ως χρήστης παρέχετε τα κάτωθι
            προσωπικά σας δεδομένα:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>Ονοματεπώνυμο</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Ημερομηνία γέννησης</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Φύλο</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Στοιχεία επικοινωνίας</Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            Κατά την εγγραφή σας στην Εφαρμογή (sign up) ως επαγγελματίας υγείας παρέχετε
            τα κάτωθι προσωπικά σας δεδομένα:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>ονοματεπώνυμο,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>ειδικότητα,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>ΑΦΜ,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>διεύθυνση, Αριθμός, ΤΚ, περιοχή,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                τηλέφωνο επικοινωνίας και ηλεκτρονική διεύθυνση
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            Κατά τη χρήση των υπηρεσιών της Εφαρμογής ως χρήστης έχετε την δυνατότητα να
            εισάγετε τα κάτωθι δεδομένα:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Δεδομένα υγείας (π.χ. μετρήσεις διαβήτη, αρτηριακή πίεση, διαγνωστικές
                εξετάσεις κλπ.) που εισάγετε στην πλατφόρμα αν το επιθυμείτε
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Στοιχεία που αφορούν αθλητικές δραστηριότητες, διατροφικές συνήθειες
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Τα στοιχεία της πιστωτική σας κάρτας ( αριθμός, όνομα κατόχου, κωδικός
                ασφαλείας) που μας παρέχετε κατά την πληρωμή της υπηρεσίας δεν συλλέγονται
                από την Εφαρμογή, αλλά παρέχονται απευθείας στην εξουσιοδοτημένη Τράπεζα
                προς την οποία γίνεται ανακατεύθυνση κατά την διαδικασία πληρωμής .
              </Typography>
            </li>
          </ul>
          <li>
            <Typography variant={'subtitle1'}>
              Για ποιους σκοπούς γίνεται η επεξεργασία και ποια η νομική της βάση;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Η επεξεργασία των δεδομένων σας γίνεται για να σας παρέχουμε την πρόσβαση στις
            υπηρεσίες του HDCORNER. Η νομική βάση για αυτήν την επεξεργασία είναι η
            εκτέλεση σύμβασης (α. 6 παρ. 2 β’ ΓΚΠΔ).
          </Typography>
          <Typography variant={'body1'}>
            Για τα δεδομένα υγείας που μας παρέχετε η νομική βάση είναι η εκτέλεση
            σύμβασης και η α. 9 παρ.1 περ. η’
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Ποιοι είναι οι αποδέκτες των προσωπικών μου δεδομένων;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Πρόσβαση στα προσωπικά σας δεδομένα έχει ο πάροχος της πλατφόρμας που ενεργεί
            ως εκτελών την επεξεργασία καθώς και ο γιατρός ή φαρμακοποιός ή άλλος
            επαγγελματίας υγείας που εσείς θα επιλέξετε να δώσετε πρόσβαση στα δεδομένα
            σας . O επαγγελματίας υγείας θα μπορεί να βλέπει τα δεδομένα που καταχωρείτε
            στην πλατφόρμα, μόνο εφόσον εσείς οι ίδιοι του δώσετε πρόσβαση.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Πόσο χρόνο διατηρούνται τα δεδομένα μου;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Τα δεδομένα σας θα τηρηθούν αυστηρώς για όσο χρονικό διάστημα είστε
            εγγεγραμμένοι στην υπηρεσία. Σε περίπτωση διαγραφής σας από την υπηρεσία θα
            διαγράφονται αμέσως. Θα διαγράφονται επίσης σε περίπτωση που μας υποβάλλετε
            αίτημα διαγραφής.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Cookies</Typography>
          </li>
          <Typography variant={'body1'}>
            Η εφαρμογή δεν χρησιμοποιεί cookies όταν ο χρήστης εισέρχεται σε αυτήν, είτε
            μέσω του κινητού τηλεφώνου είτε μέσω της web πλατφόρμας.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Πως προστατεύονται τα δεδομένα μου;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Τα δεδομένα σας τηρούνται σε εξωτερικό server με όλα τα απαραίτητα μέτρα
            ασφαλείας. Το ίδιο ισχύει για τα δεδομένα χρηστών που ενδεχομένως καταχωρείτε
            μέσω του chat και τα οποία υπόκεινται σε κρυπτογράφηση και ψευδωνυμοποίηση και
            ως εκ τούτου δεν μπορούν να συσχετισθούν με συγκεκριμένα πρόσωπα.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Ποια είναι τα δικαιώματά μου;</Typography>
          </li>
          <li>
            <Typography variant={'body1'}>
              Ως υποκείμενο των δεδομένων έχετε τα ακόλουθα δικαιώματα:
            </Typography>
          </li>
          <table>
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα ενημέρωσης</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να ενημερώνεστε για τη συλλογή, τον τρόπο
                    επεξεργασίας και φύλαξης των προσωπικών σας δεδομένων.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα πρόσβασης</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να λαμβάνετε την επιβεβαίωση για το κατά πόσον ή όχι
                    τα δεδομένα σας υφίστανται επεξεργασία και, εάν συμβαίνει τούτο, έχετε
                    το δικαίωμα πρόσβασης στα δεδομένα σας σε σύντομη, κατανοητή, διαφανή
                    και εύκολα προσβάσιμη μορφή .
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα διόρθωσης</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Μπορείτε να ζητήσετε τη διόρθωση ανακριβών ή ελλιπών προσωπικών
                    δεδομένων που σας αφορούν.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα διαγραφής</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να ζητήσετε τη διαγραφή των δεδομένων που σας
                    αφορούν, αν κρίνετε ότι αυτά τηρούνται με μη σύννομο τρόπο και η
                    Εταιρεία υποχρεούται να προβεί στη διαγραφή εκτός αν υπάρχει νόμιμος
                    λόγος διατήρησής τους.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Δικαίωμα στη φορητότητα των δεδομένων
                  </Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να λαμβάνετε τα δεδομένα προσωπικού χαρακτήρα που
                    σας αφορούν και τα οποία έχει παράσχει στην Εταιρεία σε κοινώς
                    χρησιμοποιούμενο και αναγνώσιμο από μηχανήματα μορφότυπο, καθώς και το
                    δικαίωμα να ζητάτε τη διαβίβαση των λόγω δεδομένων σε άλλον υπεύθυνο
                    επεξεργασίας, υπό τις προϋποθέσεις που ορίζει ο νόμος.
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
          <Typography variant={'body1'} paragraph>
            Θα απαντήσουμε στο αίτημά σας χωρίς καθυστέρηση και σε κάθε περίπτωση εντός
            μηνός από την παραλαβή του. Η εν λόγω προθεσμία παρατείνεται για δύο ακόμα
            μήνες, εφόσον είναι απαραίτητο, λαμβάνοντας υπόψη την πολυπλοκότητα του
            αιτήματος και το πλήθος των αιτημάτων. Θα σας ενημερώσουμε για την εν λόγω
            παράταση εντός μηνός από την παραλαβή του αιτήματος, καθώς και για τους λόγους
            της καθυστέρησης.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Η εταιρεία έχει σε κάθε περίπτωση δικαίωμα να αρνηθεί την ικανοποίηση του
            αιτήματός σας για περιορισμό της επεξεργασίας ή διαγραφή των προσωπικών σας
            δεδομένων, εάν η επεξεργασία αυτή είναι απαραίτητη για τη θεμελίωση, άσκηση ή
            υποστήριξη νόμιμων δικαιωμάτων της ή την εκπλήρωση υποχρεώσεών της.
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              Πως μπορείτε να ασκήσετε τα δικαιώματά σας:
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Για να ασκήσετε τα ανωτέρω δικαιώματά σας μπορείτε να επικοινωνείτε με τον
            Υπεύθυνο Προστασίας Δεδομένων στην ηλεκτρονική διεύθυνση
            dataprotection@karabinismedical.gr είτε τηλεφωνικώς στο 210-6645751 είτε
            ταχυδρομικώς στη διεύθυνση εταιρεία ΚΑΡΑΜΠΙΝΗΣ MEDICAL ΑΕ, Λεωφ. Λαυρίου 151,
            19005 Παιανία -Υπόψη Υπευθύνου Προστασίας Δεδομένων.
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Έχετε δικαίωμα να υποβάλλετε καταγγελία στην Αρχή Προστασίας Δεδομένων
            Προσωπικού Χαρακτήρα (www.dpa.gr), η οποία είναι η αρμόδια εποπτική αρχή για
            την προστασία των θεμελιωδών δικαιωμάτων και ελευθεριών των φυσικών προσώπων
            έναντι της επεξεργασίας των προσωπικών τους δεδομένων του, εφόσον θεωρείτε ότι
            προσβάλλονται τα δικαιώματά σας με οποιοδήποτε τρόπο.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Τελευταία ημερομηνία ενημέρωσης 08/01/2024
          </Typography>
        </ol>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsGR;
