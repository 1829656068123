import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AuthUser, ComponentsLayout, CustomButton } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import { useIonRouter } from '@ionic/react';
import DietCalculatorFirstStep from './DietCalculatorFirstStep';
import CalcOptInner from './CalcOptInner';
import { calculateCalories } from '../utils/caloriesFormula';
import {
  useCreateDietPlanMutation,
  useEditDietPlanMutation,
  useGetDietPlanQuery,
} from './queries/dietPlanQueries';
import useAlert from '../../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useGetUserDetailsQuery } from '../../Users/queries/usersQueries';
import moment from 'moment';
import { useNotifyUserMutation } from '../../../redux/queries/queries';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const DietCalculatorMobile: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentError, presentSuccess } = useAlert();

  const { error, data: userDetails } = useGetUserDetailsQuery({ userId });

  useEffect(() => {
    if (error) presentError(t('errors.generic.error01'));
  }, [error]);

  const [userData, setUserData] = useState({
    age: 0,
    sex: '-',
    weight: 0,
    height: 0,
  });

  const [step, setStep] = useState<number>(0);

  const [changePerWeek, setChangePerWeek] = useState<number>(750);

  const [activityLevel, setActivityLevel] = useState<'light' | 'moderate' | 'heavy'>(
    'light',
  );
  const [goal, setGoal] = useState<'lose_weight' | 'muscle_gain' | 'maintain_weight'>(
    'maintain_weight',
  );
  const [dietType, setDietType] = useState<
    'standard' | 'low_carb' | 'low_fat' | 'high_protein'
  >('standard');

  const { data: dietPlan } = useGetDietPlanQuery({ userId });

  const [notify] = useNotifyUserMutation();
  const [editCalculatedCalories] = useEditDietPlanMutation();
  const [setCalculatedCalories] = useCreateDietPlanMutation();

  useEffect(() => {
    if (userDetails && userDetails.data.authUser) {
      const user = userDetails.data;
      const authUser = userDetails.data.authUser as AuthUser;
      const age = moment().diff(moment(authUser.birthDate), 'years');
      setUserData({
        age: age,
        height: user.height as number,
        weight: user.weight as number,
        sex: authUser.gender as string,
      });
    }
  }, [userDetails]);

  const handleCalculate = () => {
    if (step === 0) {
      setStep(1);
      return;
    }
    const data = {
      goal,
      dietType,
      changePerWeek,
      activityLevel,
      sex: userData.sex,
      age: userData.age,
      weight: userData.weight,
      height: userData.height,
    };

    const calories = calculateCalories(data);

    if (dietPlan && dietPlan.length > 0) {
      editCalculatedCalories({
        userId,
        dietType,
        calculatedCalories: calories,
      })
        .unwrap()
        .then(() => {
          notify({
            userId,
            payload: {
              title: t('fitness.dietPlan.notifications.dietEdited'),
              body: t('fitness.dietPlan.notifications.dietEdited.dietEditMessage'),
            },
          });
          setStep(0);
          router.push(`/dashboard/users/${userId}/fitness`);
        })
        .catch(() => {
          presentError(t('errors.generic.error01'));
        });
    } else {
      setCalculatedCalories({
        userId,
        dietType,
        calculatedCalories: calories,
      })
        .unwrap()
        .then(() => {
          notify({
            userId,
            payload: {
              title: t('fitness.dietPlan.notifications.dietCreated'),
              body: t('fitness.dietPlan.notifications.dietCreatedMessage'),
            },
          });
          setStep(0);
          router.push(`/dashboard/users/${userId}/fitness`);
        })
        .catch(() => {
          presentError(t('errors.generic.error01'));
        });
    }
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.personalDietCal')}
      defaultHref={`/dashboard/users/${userId}/fitness`}
    >
      <ComponentsLayout>
        {step === 0 && (
          <Box mt={3}>
            <DietCalculatorFirstStep user={userData} setUser={setUserData} />
          </Box>
        )}
        {step === 1 && (
          <CalcOptInner
            goal={goal}
            setGoal={setGoal}
            dietType={dietType}
            setDietType={setDietType}
            changePerWeek={changePerWeek}
            activityLevel={activityLevel}
            setChangePerWeek={setChangePerWeek}
            setActivityLevel={setActivityLevel}
          />
        )}
        <Box mt={3}>
          <CustomButton
            fullWidth
            disableRipple
            disableElevation
            disableFocusRipple
            variant={'contained'}
            onClick={handleCalculate}
          >
            {t('buttons.calculate')}
          </CustomButton>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DietCalculatorMobile;
