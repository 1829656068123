import {
  Collapsible,
  DownloadIcon,
  FeelsEmpty,
  GoalDetailsCard,
  InDepthDataCard,
  LipidChart,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import React, { FC, useCallback, useMemo, useState } from 'react';
import DownloadButton from '../../components/DownloadButton';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import { useNotifyUserMutation } from '../../redux/queries/queries';
import useAlert from '../../hooks/useAlert';
import { useDeleteLipidLogMutation } from './queries/lipidQueries';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  lineHeight: '24px',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  minWidth: '400px',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const BoxSection = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

type Props = {
  userId: string;
  lipidType: string;
  goalData: {
    goal?: number;
    progress: string;
    startingDate?: string;
    latestMeasurement?: number;
    startingMeasurement?: number;
  };
  listParams: { skip: number; limit: number };
  count?: number;
  chartData: any;
  inDepthData: any;
  overallData: any;
  totalCount?: number;
  sectionName: string;
  isFetching: boolean;
  setListParams: (arg0: { skip: number; limit: number }) => void;
};
const LipidGroupDetails: FC<Props> = ({
  userId,
  lipidType,
  count,
  goalData,
  chartData,
  isFetching,
  listParams,
  totalCount,
  overallData,
  inDepthData,
  sectionName,
  setListParams,
}) => {
  const theme = useTheme();
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);

  const [notify] = useNotifyUserMutation();
  const [deleteLogMutation] = useDeleteLipidLogMutation();

  const getChartData = useMemo((): any[] => {
    if (!chartData) return [];
    return chartData.map((item: { date: string; value: any }) => {
      return {
        x: item.date,
        y: item.value,
      };
    });
  }, [chartData]);

  const getChartGoalData = useMemo<
    | {
        goal?: number;
        latestMeasurement?: number;
        startingMeasurement?: number;
      }
    | undefined
  >(() => {
    if (!goalData) return undefined;

    return {
      goal: goalData.goal,
      latestMeasurement: goalData.latestMeasurement,
      startingMeasurement: goalData.startingMeasurement,
    };
  }, [goalData]);

  const getChartOverallData = useMemo(() => {
    return {
      min: overallData ? overallData.min : t('general.labels.nA'),
      max: overallData ? overallData.max : t('general.labels.nA'),
      avg: overallData ? overallData.avg : t('general.labels.nA'),
      latestMeasurement: goalData.latestMeasurement,
    };
  }, [overallData, goalData]);

  const getGoalData = useCallback(() => {
    if (!goalData) return [];

    return [
      {
        label: t('headingsTitles.goal'),
        value:
          goalData && goalData.goal
            ? goalData.goal + ' ' + t('measurements.mgdl')
            : t('general.labels.nA'),
      },
      {
        label: t('goals.startingDate'),
        value:
          goalData && goalData.startingDate
            ? goalData.startingDate
            : t('general.labels.nA'),
      },
      {
        label: t('goals.startingMeasurement'),
        value:
          goalData && goalData.startingMeasurement
            ? goalData.startingMeasurement + ' ' + t('measurements.mgdl')
            : t('general.labels.nA'),
      },
      {
        label: t('goals.latestMeasurement'),
        value:
          goalData && goalData.latestMeasurement
            ? goalData.latestMeasurement + ' ' + t('measurements.mgdl')
            : t('general.labels.nA'),
      },
      {
        label: t('goals.progress'),
        value: goalData && goalData.progress ? goalData.progress : t('general.labels.nA'),
      },
    ];
  }, [goalData]);

  const getOverallData = useCallback(() => {
    return [
      {
        name: overallData ? overallData.title.toUpperCase() : t('general.labels.nA'),
        min: overallData ? overallData.min : t('general.labels.nA'),
        max: overallData ? overallData.max : t('general.labels.nA'),
        avg: overallData ? overallData.avg : t('general.labels.nA'),
      },
    ];
  }, [overallData]);

  const removeLog = (id: string) => {
    let valueKey: string = lipidType;
    if (lipidType === 'chol') {
      valueKey = 'totalChol';
    } else if (lipidType === 'trg') {
      valueKey = 'triglycerides';
    }
    deleteLogMutation({ userId, _id: id, lipidType: valueKey })
      .unwrap()
      .then(() => {
        notify({
          userId,
          payload: {
            title: t('lipid.notifications.logDel'),
            body: t('lipid.notifications.logDelBody'),
          },
        });
      })
      .catch(() => {
        presentError(t('errors.generic.error01'));
      });
  };

  return (
    <Box gap={3} flex={1} display={'flex'} flexWrap={'wrap'}>
      <BoxColumn>
        {/*CHART*/}
        <BoxSection>
          <Title
            variant={'subtitle1'}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          >
            {sectionName}
          </Title>
          {/*Chart area*/}
          <LipidChart
            chartData={getChartData}
            goalData={getChartGoalData}
            inDepthData={getChartOverallData}
            headings={useConvertJSON(weekMonthYearCustom)}
          />
        </BoxSection>

        {/*GOAL*/}
        <BoxSection>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Title variant={'subtitle1'}>{t('headingsTitles.goal')}</Title>
          </Box>
          {/*Goal details box area*/}
          <Box
            borderRadius={'8px'}
            padding={theme.spacing(1)}
            marginTop={theme.spacing(1)}
            bgcolor={theme.palette.secondary.light}
          >
            {getGoalData().map(
              (item: { label: string; value: string }, index: number) => (
                <Box key={`${item}${index}`} mt={index === 1 ? 3 : 0}>
                  <GoalDetailsCard goal={item} />
                </Box>
              ),
            )}
          </Box>
        </BoxSection>
      </BoxColumn>

      <BoxColumn>
        {/*IN-DEPTH DATA*/}
        <BoxSection>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Title
              variant={'subtitle1'}
              textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            >
              {t('headingsTitles.inDepth')}
            </Title>
            <DownloadButton
              web={true}
              onClick={() => setDownloadDataModalOpen(true)}
              startIcon={
                <DownloadIcon
                  width={'14px'}
                  height={'15px'}
                  color={theme.palette.secondary.main}
                />
              }
            >
              {t('buttons.dlData')}
            </DownloadButton>
          </Box>
          {/*In Depth Card area*/}
          <InDepthDataCard
            rows={getOverallData()}
            headings={[
              `${t('measurements.min')}`,
              `${t('measurements.max')}`,
              `${t('measurements.avg')}`,
            ]}
            measurement={`${t('measurements.mgdl')}`}
          />
          {/*In depth list area*/}
          <Box overflow={'hidden'} borderRadius={'8px'}>
            <InfiniteScrollList
              count={count}
              loading={isFetching}
              skip={listParams.skip}
              limit={listParams.limit}
              totalCount={totalCount}
              onLoadMore={(skip, limit) => {
                setListParams({ skip: skip, limit: limit });
              }}
            >
              {inDepthData.map((item: any, index: number) => (
                <Box key={`${index}`}>
                  <Collapsible
                    data={item.data}
                    title={item.title}
                    titleSub={item.titleSub}
                    progress={item?.progress}
                    handleRemove={() => {
                      removeLog(item._id);
                    }}
                  />
                </Box>
              ))}
            </InfiniteScrollList>
            {inDepthData.length === 0 && <FeelsEmpty />}
          </Box>
        </BoxSection>
      </BoxColumn>
      <ModalInDepthDataDL
        module={'lipid'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </Box>
  );
};

export default LipidGroupDetails;
