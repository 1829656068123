import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import LipidGroupDetails from './LipidGroupDetails';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetLipidLogsQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import moment from 'moment/moment';
import { useAppSelector } from '../../redux/hooks';
import useTimeframe from '../../hooks/useTimeframe';
import { LipidGoal, minMaxAvg, minMaxObj } from '@hdcorner/ui-library';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  userId: string;
};
const TotalCholesterolSection: FC<Props> = ({ userId }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [params, setParams] = useState({
    skip: 0,
    limit: 8,
    sort: ['-logDate'],
  });

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: lastLog, error: errorLpdLastLog } = useGetLipidLastLogQuery({ userId });
  const { data: cholGoalRes, error: errorCholGoal } = useGetLipidGoalQuery({
    userId,
    goalType: 'chol',
  });
  const { data: lipidOverallData, error: errorLpdOverall } = useGetOverallLipidQuery({
    ...timeframe,
    userId,
  });
  const {
    isFetching,
    data: lipidLogs,
    error: errorLpdLog,
  } = useGetLipidLogsQuery({
    userId,
    params,
    ...timeframe,
  });
  const { data: chartDataRes, error: errorLpdChart } = useGetLipidGraphDataQuery({
    userId,
    params: { metric: 'totalChol', ...timeframe },
  });

  const { data: cholFirstLog, error: errorCholFirstLog } = useGetLipidFirstLogQuery(
    {
      startingDate:
        cholGoalRes && cholGoalRes.length > 0
          ? (cholGoalRes as LipidGoal[])[0].startDate
          : '',
      userId,
    },
    { skip: cholGoalRes ? cholGoalRes.length === 0 : true },
  );

  useEffect(() => {
    if (errorLpdLastLog || errorLpdLog || errorCholFirstLog)
      presentError(t('errors.generic.errorFetchLog'));
    if (errorCholGoal) presentError(t('errors.generic.errorFetchGoal'));
    if (errorLpdOverall) presentError(t('errors.generic.error01'));
    if (errorLpdChart) presentError(t('errors.generic.errorFetchGraph'));
  }, [errorLpdLastLog, errorCholGoal, errorLpdOverall, errorLpdChart, errorCholFirstLog]);

  const getOverallData = useCallback(() => {
    return minMaxAvg(lipidOverallData, 'totalChol');
  }, [lipidOverallData]);

  const goalData = useMemo(() => {
    const cholGoal = cholGoalRes ? cholGoalRes[0] : null;
    const lastLogItem = lastLog ? lastLog.documents[0] : null;
    const cholFirst = cholFirstLog ? cholFirstLog.documents[0] : null;

    let progress;

    if (cholGoal && cholFirst && lastLogItem) {
      const currentMeasurement = lastLogItem.totalChol || 0;
      const startingMeasurement = cholFirst.totalChol || 0;
      const goalMeasurement = parseFloat(cholGoal.goal.measurement) || 0;
      progress = Math.round(
        ((currentMeasurement - startingMeasurement) /
          (goalMeasurement - startingMeasurement)) *
          100,
      );
      if (progress > 100) progress = 100;
    }

    return {
      progress: progress ? progress + '%' : t('general.labels.nA'),
      startingMeasurement: cholFirst ? cholFirst.totalChol : undefined,
      goal: cholGoal ? parseFloat(cholGoal.goal.measurement) : undefined,
      latestMeasurement: lastLogItem ? lastLogItem.totalChol : undefined,
      startingDate: cholGoal
        ? moment(cholGoal.startDate).format('MMMM Do, YYYY')
        : undefined,
    };
  }, [cholGoalRes, cholFirstLog, lastLog]);

  const chartData = useMemo(() => {
    if (chartDataRes) {
      const logs = chartDataRes.logs;
      if (!logs) return [];

      return logs.map(log => {
        return {
          date: log.logDate,
          value: log.totalChol,
        };
      });
    }
    return [];
  }, [chartDataRes]);

  const overallData = useMemo(() => {
    return minMaxObj('Total Cholesterol', getOverallData);
  }, [getOverallData]);

  const inDepthData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      return lipidLogs.documents
        .filter(log => log.totalChol)
        .map(log => {
          return {
            _id: log._id,
            title: log.totalChol,
            titleSub: t('measurements.mgdl'),
            data: {
              [t('lipid.labels.totalChol')]: {
                value: `${log.totalChol} ${t('measurements.mgdl')}`,
              },
              [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
              [t('inDepth.dateOfCalc')]: {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs]);

  return (
    <LipidGroupDetails
      userId={userId}
      lipidType={'chol'}
      goalData={goalData}
      listParams={params}
      chartData={chartData}
      isFetching={isFetching}
      inDepthData={inDepthData}
      overallData={overallData}
      totalCount={lipidLogs?.count}
      count={lipidLogs?.documents.length}
      sectionName={t('lipid.labels.totalChol')}
      setListParams={arg0 => setParams({ ...params, ...arg0 })}
    />
  );
};

export default TotalCholesterolSection;
