import { CustomButton } from '@hdcorner/ui-library';
import { ButtonProps, styled } from '@mui/material';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const ModifiedButton = styled(CustomButton, {
  shouldForwardProp: prop => prop !== 'web' && prop !== 'textColor',
})<ButtonProps & { web?: boolean; textColor?: string }>(({ web, textColor, theme }) => ({
  height: web ? '32px' : 'none',
  width: web ? 'auto' : 'none',
  fontWeight: theme.typography.fontWeightBold,
  color: web ? theme.palette.secondary.main : textColor,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
  backgroundColor: web ? theme.palette.primary.main : 'none',
  fontSize: web ? theme.typography.body1.fontSize : theme.typography.caption.fontSize,
}));

export default ModifiedButton;
