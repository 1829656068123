import moment from 'moment';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { CvdRiskGoal, CvdRiskLog, PaginationResult } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const usePrepareGoalData = (
  goal?: CvdRiskGoal[],
  currentLog?: PaginationResult<CvdRiskLog>,
  cvdStartingLog?: PaginationResult<CvdRiskLog>,
) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const goalValue = goal?.[0];

    // TODO change logs to documents
    const currentValue = currentLog?.logs?.[0];
    const firstValue = cvdStartingLog?.logs?.[0];

    return [
      {
        label: t('headingsTitles.goal'),
        value: goalValue ? `${goalValue.goal} % Overall risk` : '-',
      },
      {
        label: t('goals.startingDate'),
        value: goalValue ? moment(goalValue.createdAt).format('MMM Do, YYYY') : '-',
        marginBottom: true,
      },
      {
        label: t('goals.startingMeasurement'),
        value: firstValue && !isNil(firstValue.score) ? `${firstValue.score} %` : '-',
      },
      {
        label: t('goals.latestMeasurement'),
        value:
          currentValue && !isNil(currentValue.score) ? `${currentValue.score} %` : '-',
      },
      {
        label: t('goals.progress'),
        value:
          goalValue && firstValue && currentValue
            ? `${Math.round(
                ((currentValue.score - firstValue.score) /
                  (goalValue.goal - firstValue.score)) *
                  100,
              )} %`
            : '-',
      },
    ];
  }, [currentLog, cvdStartingLog, goal]);
};

export default usePrepareGoalData;
