import * as React from 'react';
import { SVGProps } from 'react';

const GraphUpGreen = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 22C0.734784 22 0.48043 21.8946 0.292893 21.7071C0.105357 21.5196 0 21.2652 0 21V18C0 17.7348 0.105357 17.4804 0.292893 17.2929C0.48043 17.1054 0.734784 17 1 17C1.26522 17 1.51957 17.1054 1.70711 17.2929C1.89464 17.4804 2 17.7348 2 18V21C2 21.2652 1.89464 21.5196 1.70711 21.7071C1.51957 21.8946 1.26522 22 1 22ZM10 21V14C10 13.7348 9.89464 13.4804 9.70711 13.2929C9.51957 13.1054 9.26522 13 9 13C8.73478 13 8.48043 13.1054 8.29289 13.2929C8.10536 13.4804 8 13.7348 8 14V21C8 21.2652 8.10536 21.5196 8.29289 21.7071C8.48043 21.8946 8.73478 22 9 22C9.26522 22 9.51957 21.8946 9.70711 21.7071C9.89464 21.5196 10 21.2652 10 21ZM17 10C16.7348 10 16.4804 10.1054 16.2929 10.2929C16.1054 10.4804 16 10.7348 16 11V21C16 21.2652 16.1054 21.5196 16.2929 21.7071C16.4804 21.8946 16.7348 22 17 22C17.2652 22 17.5196 21.8946 17.7071 21.7071C17.8946 21.5196 18 21.2652 18 21V11C18 10.7348 17.8946 10.4804 17.7071 10.2929C17.5196 10.1054 17.2652 10 17 10ZM17.382 0.077C17.261 0.0263044 17.1312 0.000131833 17 0H13C12.7348 0 12.4804 0.105357 12.2929 0.292893C12.1054 0.48043 12 0.734784 12 1C12 1.26522 12.1054 1.51957 12.2929 1.70711C12.4804 1.89464 12.7348 2 13 2H14.586L9 7.586L5.707 4.293C5.51947 4.10553 5.26516 4.00021 5 4.00021C4.73484 4.00021 4.48053 4.10553 4.293 4.293L0.293 8.293C0.110842 8.4816 0.0100476 8.7342 0.012326 8.9964C0.0146044 9.2586 0.119773 9.50941 0.305182 9.69482C0.49059 9.88023 0.741402 9.9854 1.0036 9.98767C1.2658 9.98995 1.5184 9.88916 1.707 9.707L5 6.414L8.293 9.707C8.48053 9.89447 8.73484 9.99979 9 9.99979C9.26516 9.99979 9.51947 9.89447 9.707 9.707L16 3.414V5C16 5.26522 16.1054 5.51957 16.2929 5.70711C16.4804 5.89464 16.7348 6 17 6C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5V1C17.9998 0.802291 17.9409 0.609086 17.8309 0.444801C17.7209 0.280517 17.5647 0.152525 17.382 0.077Z"
        fill="#5FD071"
      />
    </svg>
  );
};

export default GraphUpGreen;
