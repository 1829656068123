import SectionGraph from './SectionGraph';
import { theme } from '@hdcorner/ui-library';
import { Box, useMediaQuery } from '@mui/material';
import CalorieDataInner from '../../components/CalorieDataInner';
import React from 'react';

interface Props {
  userId: string;
}
const WebCalories: React.FC<Props> = ({ userId }) => {
  const mid = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box>
      <Box
        gap={3}
        flex={1}
        display={'flex'}
        flexDirection={mid ? 'column' : 'row'}
        alignItems={mid ? 'center' : 'baseline'}
      >
        <Box width={mid ? 'auto' : '50%'}>
          <SectionGraph toggle heading userId={userId} />
        </Box>
        <Box width={mid ? '517px' : '50%'}>
          <CalorieDataInner />
        </Box>
      </Box>
    </Box>
  );
};

export default WebCalories;
