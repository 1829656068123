import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { ComponentsLayout, CustomTabs } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import ChartWeight from './components/ChartWeight';
import ChartSleep from './components/ChartSleep';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const Wellness: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <PageLayout
      headerTitle={t('headingsTitles.wellness')}
      defaultHref={`/dashboard/users/${userId}/fitness`}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <CustomTabs
            value={selectedTab}
            setValue={setSelectedTab}
            tabs={[
              {
                label: t('userDetails.weight'),
                children: <ChartWeight userId={userId} />,
              },
              {
                label: t('fitness.wellness.labels.sleepTim'),
                children: <ChartSleep userId={userId} />,
              },
            ]}
            sx={{ display: 'flex', flex: 1, width: '100%' }}
          />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};
export default Wellness;
