import {
  ComponentsLayout,
  ProfileDetailsCard,
  theme,
  ToggleButtonGroupCu,
  userHCPHeadings,
} from '@hdcorner/ui-library';
import React, { FC, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import { RouteComponentProps } from 'react-router';
import { Box } from '@mui/material';
import DashDataCards from './components/DashDataCards';
import { useGetUserDetailsQuery } from './queries/usersQueries';
import moment from 'moment';
import { useConvertJSON } from '../../utils/useConvertJSON';
import UserInfo from './UserInfo';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const User: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const router = useIonRouter();

  const [view, setView] = useState<'data' | 'user info'>('data');

  const { data: userDetails } = useGetUserDetailsQuery({ userId });

  const handleClickCard = (value: string) => {
    if (value === 'programs') {
      // HCP cannot access programs
      return;
    }

    router.push(`/dashboard/users/${userId}/${value}`);
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/users'}
      headerColor={theme.palette.primary.main}
      headerTitle={
        userDetails?.data.authUser.firstName + ' ' + userDetails?.data.authUser.lastName
      }
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box>
            <ProfileDetailsCard
              plan={userDetails?.data.premiumUser ? 'Premium' : 'Free'}
              image={userDetails?.data.authUser.profilePicture?.url || ''}
              joined={moment(userDetails?.data.authUser.createdAt).format('DD/MM/YYYY')}
              name={
                userDetails?.data.authUser.firstName +
                ' ' +
                userDetails?.data.authUser.lastName
              }
            />
          </Box>
          <Box>
            <ToggleButtonGroupCu
              value={view}
              setValue={(value: any) => setView(value)}
              headings={useConvertJSON([userHCPHeadings[0], userHCPHeadings[1]])}
            />
          </Box>
          {view === 'data' && (
            <Box>
              <DashDataCards userId={userId} handleClickCard={handleClickCard} />
            </Box>
          )}
          {view === 'user info' && (
            <Box>
              <UserInfo userId={userId} />
            </Box>
          )}
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default User;
