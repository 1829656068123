import { ComponentsLayout, Goal } from '@hdcorner/ui-library';
import { FC, useEffect } from 'react';
import PageLayout from '../../components/PageLayout';
import { useAppDispatch } from '../../redux/hooks';
import usePrepareGoalData from './hooks/usePrepareGoalData';
import {
  useGetCvdCurrentMeasurementQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdStartingMeasurementQuery,
} from './queries/cvdRiskQueries';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const CvdRiskGoalScreen: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { presentError } = useAlert();

  const { data: cvdRiskGoal, error: cvdRiskGoalError } = useGetCvdRiskGoalQuery({
    userId,
  });
  const { data: cvdRiskFirstLog, error: cvdRiskFirstError } =
    useGetCvdStartingMeasurementQuery(
      {
        userId,
        startingDate: cvdRiskGoal?.[0]?.startDate,
      },
      { skip: !cvdRiskGoal?.[0]?.startDate },
    );
  const { data: cvdRiskLastLog, error: cvdRiskLastError } =
    useGetCvdCurrentMeasurementQuery({ userId });

  const prepareGoalData = usePrepareGoalData(
    cvdRiskGoal,
    cvdRiskFirstLog,
    cvdRiskLastLog,
  );

  useEffect(() => {
    if (cvdRiskFirstError || cvdRiskLastError || cvdRiskGoalError) {
      presentError(t('errors.generic.error01'));
    }
  }, [cvdRiskFirstError, cvdRiskGoalError, cvdRiskLastError, dispatch]);

  return (
    <PageLayout
      headerTitle={t('headingsTitles.goal')}
      defaultHref={`/dashboard/users/${userId}/cvd`}
    >
      <ComponentsLayout>
        <Goal data={prepareGoalData} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default CvdRiskGoalScreen;
