import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { BoxProps, useMediaQuery } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ArrowDown, ArrowUp, theme } from '@hdcorner/ui-library';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';

const TableCellHeadings = styled(TableCell, {
  shouldForwardProp: propName => propName !== 'web',
})<TableCellProps & { web: boolean }>(({ web, theme }) => ({
  width: '20%',
  flexGrow: '1',
  height: '32px',
  boxSizing: 'border-box',
  color: theme.palette.primary.light,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.secondary.light,
  borderBottomColor: theme.palette.background.default,
  padding: web ? theme.spacing(0, 2) : theme.spacing(0, 0.5),
  '.Mui-active': {
    color: theme.palette.primary.light,
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    padding: web ? theme.spacing(0, 2) : theme.spacing(0, 0.5, 0, 1),
  },
  '&:last-of-type': {
    borderTopRightRadius: '8px',
    padding: web ? theme.spacing(0, 2) : theme.spacing(0, 1, 0, 0.5),
  },
}));

const MarginBox = styled(Box)<BoxProps>(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0.75),
}));

const SortOrderDesc = () => (
  <MarginBox>
    <ArrowDown />
  </MarginBox>
);

const SortOrderAsc = () => (
  <MarginBox>
    <ArrowUp />
  </MarginBox>
);

type Order = 'asc' | 'desc';

type HealthProfTableHeadingProps = {
  order: Order;
  orderBy: string;
  headCells: {
    _id: string;
    label: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  }[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
};

const SortTableHeading: FC<HealthProfTableHeadingProps> = ({
  order,
  orderBy,
  headCells,
  onRequestSort,
}) => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCellHeadings
            web={web}
            key={headCell._id}
            align={headCell.align}
            sortDirection={orderBy === headCell._id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell._id}
              direction={orderBy === headCell._id ? order : 'asc'}
              onClick={createSortHandler(headCell._id)}
              IconComponent={
                orderBy === headCell._id
                  ? order === 'asc'
                    ? SortOrderAsc
                    : SortOrderDesc
                  : SortOrderDesc
              }
            >
              {t(headCell.label)}
              {orderBy === headCell._id && (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              )}
            </TableSortLabel>
          </TableCellHeadings>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SortTableHeading;
