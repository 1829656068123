import React, { FC, useState } from 'react';
import { ToggleButtonGroupCu } from '@hdcorner/ui-library';
import { Box, styled } from '@mui/material';
import HdlCholesterolSection from './HdlCholesterolSection';
import TriglyceridesSection from './TriglyceridesSection';
import LdlCholesterolSection from './LdlCholesterolSection';
import LpaSection from './LpaSection';
import TotalCholesterolSection from './TotalCholesterolSection';
import AllMeasurementsSection from './components/AllMeasurementsSection';

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  padding: theme.spacing(3, 2),
}));

type Props = {
  userId: string;
};
const UserDataLipid: FC<Props> = ({ userId }) => {
  const headings = ['all', 'lpa', 'chol', 'trg', 'hdl', 'ldl'];

  const [value, setValue] = useState<string>('all');

  return (
    <>
      <Box height={'74px'} position={'relative'}>
        <CustomBox>
          <ToggleButtonGroupCu value={value} headings={headings} setValue={setValue} />
        </CustomBox>
      </Box>
      {value === 'lpa' && <LpaSection userId={userId} />}
      {value === 'trg' && <TriglyceridesSection userId={userId} />}
      {value === 'hdl' && <HdlCholesterolSection userId={userId} />}
      {value === 'ldl' && <LdlCholesterolSection userId={userId} />}
      {value === 'all' && <AllMeasurementsSection userId={userId} />}
      {value === 'chol' && <TotalCholesterolSection userId={userId} />}
    </>
  );
};

export default UserDataLipid;
