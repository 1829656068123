import api from '../../../redux/api';
import {
  ApiPagination,
  GetPressureGoal,
  HypertensionLog,
  HypertensionOverall,
  PaginationResult,
} from '@hdcorner/ui-library';

const hypertensionQueries = api
  .enhanceEndpoints({ addTagTypes: ['HypertensionLog', 'PressureGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      getFirstLogOfGoal: build.query<
        PaginationResult<HypertensionLog>,
        { start?: string; userId: string }
      >({
        query: ({ start, userId }) => ({
          method: 'GET',
          url: `database/function/getHypertensionLogs`,
          params: {
            skip: 0,
            limit: 1,
            start,
            sort: 'logDate',
            scope: `BasicHealth:${userId}`,
          },
        }),
      }),
      getLatestLog: build.query<PaginationResult<HypertensionLog>, { userId: string }>({
        query: ({ userId }) => ({
          params: {
            skip: 0,
            limit: 1,
            sort: '-logDate',
            scope: `BasicHealth:${userId}`,
          },
          method: 'GET',
          url: `database/function/getHypertensionLogs`,
        }),
      }),
      getHypertensionLogs: build.query<
        PaginationResult<HypertensionLog>,
        {
          end?: string;
          start?: string;
          userId: string;
          category?: string;
          params: ApiPagination;
        }
      >({
        query: ({ params, category, start, end, userId }) => ({
          method: 'GET',
          url: `database/function/getHypertensionLogs`,
          params: { ...params, category, start, end, scope: `BasicHealth:${userId}` },
        }),
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return (
            previousArg?.params.skip !== currentArg?.params.skip ||
            previousArg?.params.limit !== currentArg?.params.limit ||
            previousArg?.category !== currentArg?.category ||
            previousArg?.start !== currentArg?.start ||
            previousArg?.end !== currentArg?.end ||
            previousArg?.userId !== currentArg?.userId
          );
        },
      }),
      getPressureGoal: build.query<GetPressureGoal[], { userId: string }>({
        query: ({ userId }) => ({
          method: 'GET',
          url: `database/function/getUserGoal`,
          params: { goalType: 'pressure', scope: `Goal:${userId}` },
        }),
        providesTags: ['PressureGoal'],
      }),
      getHypertensionOverall: build.query<
        HypertensionOverall,
        { start?: string; end?: string; category?: string; userId: string }
      >({
        query: ({ category, start, end, userId }) => ({
          method: 'GET',
          params: { category, start, end, userId },
          url: `hdCornerService/user/hypertension/overall`,
        }),
      }),
      getPressureGraphData: build.query<
        { logs: HypertensionLog[] },
        { start?: string; end?: string; userId: string; category?: string }
      >({
        query: ({ start, end, userId, category }) => ({
          method: 'GET',
          params: { start, end, userId, category },
          url: `hdCornerService/user/blood-pressure/graph`,
        }),
      }),
      getPulsesGraphData: build.query<
        { logs: HypertensionLog[] },
        { start?: string; end?: string; userId: string; category?: string }
      >({
        query: ({ start, end, userId, category }) => ({
          method: 'GET',
          params: { start, end, userId, category },
          url: `hdCornerService/user/pulses/graph`,
        }),
      }),
      deleteHypertensionLog: build.mutation<string, { id: string; userId: string }>({
        query: ({ id, userId }) => ({
          method: 'DELETE',
          url: `database/UserHypertensionLog/${id}`,
          params: { scope: `BasicHealth:${userId}` },
        }),
        invalidatesTags: ['HypertensionLog'],
      }),
    }),
  });

export const {
  useDeleteHypertensionLogMutation,
  useGetHypertensionOverallQuery,
  useGetPressureGraphDataQuery,
  useGetHypertensionLogsQuery,
  useGetPulsesGraphDataQuery,
  useGetFirstLogOfGoalQuery,
  useGetPressureGoalQuery,
  useGetLatestLogQuery,
} = hypertensionQueries;

export default hypertensionQueries;
