import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import SectionHeading from '../../components/SectionHeading';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  BMIIndicator,
  calculateAxisTotal,
  calculateBMI,
  CalendarModal,
  categoryBMI,
  ChartCard,
  ClickNavCardNutrition,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  LineChart,
  prepareGraphData,
} from '@hdcorner/ui-library';
import {
  useGetUserGoalWeightQuery,
  useGetWeightGraphDataQuery,
} from '../queries/weightQueries';
import ModalSliderWeight from './ModalSliderWeight';
import useTimeframe from '../../../../hooks/useTimeframe';
import useAlert from '../../../../hooks/useAlert';
import { useGetUserDetailsQuery } from '../../../Users/queries/usersQueries';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../../utils/useConvertJSON';

interface Props {
  userId: string;
}
const ChartWeight: FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const router = useIonRouter();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('day');
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, endParam, startParam);

  const { data: userData, error: errorUser } = useGetUserDetailsQuery({ userId });
  const { data: weightGoalRes, error: errorWeight } = useGetUserGoalWeightQuery({
    userId,
  });
  const { data: graphData, error: errorGraph } = useGetWeightGraphDataQuery({
    userId,
    ...timeframe,
  });

  useEffect(() => {
    if (errorUser) presentError(t('errors.generic.errorFetchUser'));
    if (errorWeight) presentError(t('errors.fitness.wellness.errorWeightFetch'));
    if (errorGraph)
      presentError(t('errors.generic.errorFetchGraph.errorWeightGraphFetch'));
  }, [errorUser, errorWeight, errorGraph]);

  const weightData = prepareGraphData(graphData, 'weight');

  const goalWeight = useMemo(() => {
    if (!weightGoalRes || weightGoalRes.length === 0) return `- ${t('measurements.kg')}`;

    return `${weightGoalRes[0].goal.measurement} ${t('measurements.kg')}`;
  }, [weightGoalRes]);

  const userHeight = useMemo(() => {
    if (!userData) return 0;
    return userData.data.height || 0;
  }, [userData]);

  const weight = calculateAxisTotal(weightData) / weightData.length;
  const BMI = calculateBMI(userHeight, weight);

  const handleClickGoal = () => {
    if (!weightGoalRes || weightGoalRes.length === 0) {
      setOpen(true);
    } else {
      router.push(`/dashboard/users/${userId}/fitness/wellness/weight/goal`);
    }
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'} mt={theme.spacing(2)}>
      <Box mb={web ? 1 : 0} display={web ? 'block' : 'none'}>
        <SectionHeading hasGoal={false} heading={'weight'} />
      </Box>
      <Box>
        <ChartCard
          setOpen={setCalendarOpen}
          value={selectedTimeframe}
          unit={t('measurements.kg')}
          setValue={setSelectedTimeframe}
          headings={useConvertJSON(dayWeekMonthYearCustom).slice(1)}
          amount={weight ? weight.toFixed(1).toString() : '-'}
          BMIIcon={
            BMI ? (
              <BMIIndicator bmi={BMI.toString()} weight={categoryBMI(Number(BMI))} />
            ) : null
          }
        >
          {!weightData.length || !weightData ? (
            <FeelsEmpty />
          ) : (
            <LineChart chartData={weightData} colors={['#C41168']} />
          )}
        </ChartCard>
      </Box>
      <Box mt={2} display={web ? 'none' : 'block'}>
        <ClickNavCardNutrition
          headings={t('buttons.navigation.inDepth')}
          onClick={() =>
            router.push(`/dashboard/users/${userId}/fitness/wellness/in-depth/weight`)
          }
        />
        <ClickNavCardNutrition
          body={goalWeight}
          onClick={handleClickGoal}
          headings={t('headingsTitles.goal')}
        />
      </Box>

      <ModalSliderWeight userId={userId} log={false} open={open} setOpen={setOpen} />

      <CalendarModal
        open={calendarOpen}
        setOpen={setCalendarOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default ChartWeight;
