import React, { FC, useEffect, useMemo } from 'react';
import { ClickNavCardNutrition, ComponentsLayout, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import moment from 'moment';
import ActionButtonsSmall from './components/ActionButtonsSmall';
import BannerReschedule from './components/BannerReschedule';
import { useGetMedicationByIdQuery } from './queries/medicationQueries';
import MedicationHeaderIcon from '../../assets/icons/MedicationHeaderIcon';
import { RouteComponentProps } from 'react-router';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
    medicationId: string;
  }> {}
const MedicationDrug: FC<Props> = ({
  match: {
    params: { userId, medicationId },
  },
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { data: medicationRes, error: errorMedication } = useGetMedicationByIdQuery(
    { medicationId, userId },
    {
      skip: !medicationId,
    },
  );

  const medication = useMemo(() => {
    if (!medicationRes || medicationRes.length === 0) return null;
    return medicationRes[0];
  }, [medicationRes]);

  useEffect(() => {
    if (errorMedication) presentError(t('errors.medication.errorFetchMeds'));
  }, [errorMedication]);

  const handleShowMedicationInstructions = () => {
    router.push(`/dashboard/users/${userId}/medication-instructions/${medicationId}`);
  };

  const rescheduleBanner = useMemo(() => {
    if (!medication) return null;

    if (medication.rescheduledTo) {
      return (
        <BannerReschedule
          time={t('general.labels.to')}
          date={moment(medication.rescheduledTo).format('DD/MM/YYYY')}
        />
      );
    }

    if (medication.rescheduledFrom) {
      return (
        <BannerReschedule
          time={t('general.labels.from')}
          date={moment(medication.rescheduledFrom).format('DD/MM/YYYY')}
        />
      );
    }
  }, [medication]);

  const renderActionButtons = useMemo(() => {
    if (!medication) return null;
    return (
      <ActionButtonsSmall
        taken={medication.taken}
        rescheduledTo={!!medication.rescheduledTo}
        rescheduledFrom={!!medication.rescheduledFrom}
      />
    );
  }, [medication]);

  return (
    <PageLayout
      headerColor={theme.palette.primary.main}
      defaultHref={`/dashboard/users/${userId}/medication`}
      headerTitle={medication ? medication.medicine.medicineName : '-'}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
          {rescheduleBanner}
          {renderActionButtons}
          {medication?.medicine.substanceUrl && (
            <Box
              px={2}
              py={1}
              borderRadius={1}
              sx={{
                backgroundColor: 'white',
                paddingInline: theme.spacing(2),
              }}
            >
              <MedicationHeaderIcon
                width={'16px'}
                height={'16px'}
                color={theme.palette.medication.main}
              />
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                variant={'subtitle2'}
                color={theme.palette.medication.main}
                fontWeight={theme.typography.fontWeightRegular}
              >
                {medication.medicine.substanceUrl}
              </Typography>
            </Box>
          )}

          <Box>
            <Typography
              variant={'subtitle2'}
              color={theme.palette.primary.main}
              marginBottom={theme.spacing(1)}
              fontWeight={theme.typography.fontWeightRegular}
            >
              {t('general.labels.info')}
            </Typography>
            <ClickNavCardNutrition
              headings={t('general.labels.content')}
              body={
                medication ? medication.medicine.content : `${t('general.labels.nA')}`
              }
            />
            <ClickNavCardNutrition
              headings={t('general.labels.type')}
              body={
                medication
                  ? medication.medicine.medicineType
                  : `${t('general.labels.nA')}`
              }
            />
            <ClickNavCardNutrition
              headings={t('buttons.navigation.package')}
              body={
                medication ? medication.medicine.package : `${t('general.labels.nA')}`
              }
            />
            <ClickNavCardNutrition
              onClick={handleShowMedicationInstructions}
              headings={t('buttons.navigation.medInstructions')}
            />
          </Box>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default MedicationDrug;
