import React, { FC } from 'react';
import { Box } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import SectionGraph from './calories/components/SectionGraph';
import SectionDietPlan from './diet-plan/SectionDietPlan';
import SectionWellness from './wellness/components/SectionWellness';
import SectionExercise from './exercise/components/SectionExercise';
import SectionNutrition from './nutrition/components/SectionNutrition';
import { ComponentsLayout, FitnessHeartIcon, theme } from '@hdcorner/ui-library';
import FitnessDevicesIcon from '../../assets/icons/FitnessDevicesIcon';
import { useIonRouter } from '@ionic/react';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const Fitness: FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const { t } = useTranslation();
  const router = useIonRouter();

  const { presentInfo } = useAlert();

  const handleFitnessDevices = () => {
    presentInfo('Coming soon!');
  };

  return (
    <PageLayout
      headerColor={theme.palette.exercise.main}
      defaultHref={`/dashboard/users/${userId}`}
      headerTitle={t('dashboard.dashcards.fitness.title')}
      headerIcon={<FitnessHeartIcon fill={theme.palette.exercise.main} />}
      endButtons={[
        {
          icon: <FitnessDevicesIcon />,
          onClick: handleFitnessDevices,
        },
      ]}
    >
      <ComponentsLayout>
        <Box gap={3} flex={1} display={'flex'} flexDirection={'column'}>
          <SectionGraph toggle={false} heading userId={userId} />
          <SectionDietPlan
            title
            userId={userId}
            handleCalculateClick={() => {
              router.push(`/dashboard/users/${userId}/fitness/nutrition/diet-plans`);
            }}
          />
          <SectionNutrition userId={userId} />
          <SectionWellness userId={userId} />
          <SectionExercise userId={userId} />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Fitness;
