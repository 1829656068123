import React, { FC, useEffect, useState } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import {
  ChartTypeButtons,
  ColumnsIcon,
  theme,
  ToggleButtonGroupCu,
} from '@hdcorner/ui-library';
import ToggleHalfRectangleIcon from '../../../assets/icons/ToggleHalfRectangleIcon';
import { useAppSelector } from '../../../redux/hooks';
import LinearGraphIcon from '../../../assets/icons/LinearGraphIcon';
import DiabetesGraphData from '../../diabetes/components/DiabetesGraphData';
import HypertensionGraphData from '../../hypertension/components/HypertensionGraphData';
import LipidGraphData from '../../lipid/components/LipidGraphData';
import UpdateIcon from '@mui/icons-material/Update';
import { useTranslation } from 'react-i18next';

const Line = styled(Box)<BoxProps>(({ theme }) => ({
  width: 2,
  height: 32,
  borderRadius: '1px',
  background: theme.palette.secondary.dark,
  margin: theme.spacing(0, 3),
}));

const icons = [
  { name: 'lineChart', icon: LinearGraphIcon },
  { name: 'scatterChart', icon: ToggleHalfRectangleIcon },
  { name: 'barChart', icon: ColumnsIcon },
  { name: '24hours', icon: UpdateIcon },
];

const headings = ['lpa', 'chol', 'trg', 'hdl', 'ldl'];

type Props = {
  userId: string;
  selectedType: string;
};
const GraphView: FC<Props> = ({ userId, selectedType }) => {
  const { t } = useTranslation();
  const { filter } = useAppSelector(state => state.diabetes);
  const { measurement } = useAppSelector(state => state.hypertension);
  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [lipidMetric, setLipidMetric] = useState<string>('lpa');
  const [availableIcons, setAvailableIcons] = useState<any[]>([]);
  const [selectedChartType, setSelectedChartType] = useState('lineChart');

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  useEffect(() => {
    switch (selectedType) {
      case 'lipid':
        setAvailableIcons([icons[0], icons[2]]);
        break;
      case 'hypertension':
        setAvailableIcons([icons[0], icons[1]]);
        break;
      case 'diabetes':
        setAvailableIcons([icons[0], icons[2], icons[3]]);
        break;
      default:
        break;
    }
  }, [selectedType]);

  const handleChart = () => {
    switch (selectedType) {
      case 'diabetes':
        return (
          <DiabetesGraphData
            userId={userId}
            selectedCategory={filter}
            selectedTimeframe={dateFilter}
            selectedGraphType={selectedChartType}
            customEndDate={customDateFilter?.end}
            customStartDate={customDateFilter?.start}
          />
        );
      case 'hypertension':
        return (
          <HypertensionGraphData
            userId={userId}
            selectedCategory={measurement}
            selectedTimeframe={dateFilter}
            selectedGraphType={selectedChartType}
            customEndDate={customDateFilter?.end}
            customStartDate={customDateFilter?.start}
          />
        );
      case 'lipid':
        return (
          <LipidGraphData
            userId={userId}
            metric={lipidMetric}
            selectedTimeframe={dateFilter}
            selectedGraphType={selectedChartType}
            customEndDate={customDateFilter?.end}
            customStartDate={customDateFilter?.start}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
      <Box
        display="flex"
        alignItems="center"
        borderRadius={'8px'}
        padding={theme.spacing(2)}
        bgcolor={theme.palette.secondary.light}
      >
        {selectedType === 'lipid' && (
          <>
            <Box>
              <ToggleButtonGroupCu
                value={lipidMetric}
                headings={headings}
                setValue={setLipidMetric}
              />
            </Box>
            <Box>
              <Line />
            </Box>
          </>
        )}
        <Box>
          <ChartTypeButtons
            size={'large'}
            icons={availableIcons}
            iconValue={selectedChartType}
            setIconValue={setSelectedChartType}
          />
        </Box>
      </Box>
      <Box>
        <Typography
          variant={'subtitle1'}
          color={theme.palette.primary.main}
          marginBottom={theme.spacing(1)}
          fontWeight={theme.typography.fontWeightMedium}
          textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
        >
          {t('buttons.navigation.graphData')}
        </Typography>
        {handleChart()}
      </Box>
    </Box>
  );
};

export default GraphView;
