import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { TableCell, TableRow, useMediaQuery } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import { theme } from '@hdcorner/ui-library';

const TableCellData = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'alignData',
})<TableCellProps & { web: boolean; alignData?: boolean }>(
  ({ web, alignData, theme }) => ({
    width: '20%',
    flexGrow: '1',
    height: '32px',
    boxSizing: 'border-box',
    color: theme.palette.primary.main,
    textAlign: alignData ? 'center' : 'left',
    fontWeight: web
      ? theme.typography.fontWeightRegular
      : theme.typography.body2.fontWeight,
    padding: web ? theme.spacing(0, 2) : theme.spacing(0, 0.5),
    backgroundColor: theme.palette.secondary.light,
    borderBottomColor: theme.palette.background.default,
    fontSize: web ? theme.typography.body1.fontSize : theme.typography.body2.fontSize,
    '.Mui-active': {
      color: theme.palette.primary.light,
    },
    '&:first-of-type': {
      textAlign: 'left',
      padding: web ? theme.spacing(0, 2) : theme.spacing(0, 0.5, 0, 1),
    },
    '&:last-of-type': {
      padding: web ? theme.spacing(0, 2) : theme.spacing(0, 1, 0, 0.5),
    },
  }),
);

export type HealthProfTableRowsProps = {
  key: string;
  rowCells: string[];
  alignData?: boolean;
};

const SortTableRows: FC<HealthProfTableRowsProps> = ({ key, rowCells, alignData }) => {
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <TableRow
      hover
      key={key}
      tabIndex={-1}
      sx={{
        '&:last-child th, &:last-child td': {
          borderBottom: 0,
          '&:first-of-type': {
            borderBottomLeftRadius: '8px',
          },
          '&:last-of-type': {
            borderBottomRightRadius: '8px',
          },
        },
      }}
    >
      {rowCells.map((cell, index) => (
        <TableCellData web={web} align={'center'} key={index} alignData={alignData}>
          {cell}
        </TableCellData>
      ))}
    </TableRow>
  );
};

export default SortTableRows;
