import React, { FC } from 'react';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { ComponentsLayout } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import { RouteComponentProps } from 'react-router';
import TrafficLightTabsList from './components/TrafficLightTabsList';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
}));

interface Props
  extends RouteComponentProps<{
    userId: string;
  }> {}
const TipsTricks: FC<Props> = ({ match }) => {
  const { t } = useTranslation();

  return (
    <PageLayout headerTitle={t('headingsTitles.tipsTricks')}>
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box>
            <Title variant={'subtitle2'}>{t('headingsTitles.trafficLights')}</Title>
            <TrafficLightTabsList userId={match.params.userId} />
          </Box>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default TipsTricks;
