const hcpSpecialties = [
  { label: 'constants.hcp.specialties.genDoc', value: 'general_doctor' },
  { label: 'constants.hcp.specialties.genPract', value: 'general_practitioner' },
  { label: 'constants.hcp.specialties.diabetologist', value: 'diabetologist' },
  { label: 'constants.hcp.specialties.doc', value: 'doctor' },
  { label: 'constants.hcp.specialties.pharmacist', value: 'pharmacist' },
  { label: 'constants.hcp.specialties.dietician', value: 'dietician' },
  { label: 'constants.hcp.specialties.nurse', value: 'nurse' },
  { label: 'constants.hcp.specialties.psycho', value: 'psychologist' },
  { label: 'constants.hcp.specialties.personalTrainer', value: 'personal_trainer' },
  {
    label: 'constants.hcp.specialties.cCSupport',
    value: 'hd_corner_supportive_clinical_coach',
  },
];

export { hcpSpecialties };
