import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../../redux/hooks';
import { AuthUser, CustomButton, CustomInput, DropdownMenu } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

type UserDetailsOneProps = {
  handleNextPage: (
    data: Omit<AuthUser, '_id' | 'email' | 'createdAt' | 'birthDate'>,
  ) => void;
};
const UserDetailsOne: FC<UserDetailsOneProps> = ({ handleNextPage }) => {
  const { t } = useTranslation();

  const [gender, setGender] = useState<string>('male');
  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (user && user.authUser) {
      const authUser = user.authUser as AuthUser;
      setLastName(authUser.lastName);
      setFirstName(authUser.firstName);
      setGender(authUser.gender as string);
    }
  }, [user]);

  const handleSaveData = () => {
    const data = { firstName, lastName, gender };
    handleNextPage(data);
  };

  return (
    <Box gap={6} display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <CustomInput
          fullWidth
          value={firstName}
          handleChange={setFirstName}
          label={`${t('userDetails.fName')}`}
        />
        <CustomInput
          fullWidth
          value={lastName}
          handleChange={setLastName}
          label={`${t('userDetails.lName')}`}
        />
        <DropdownMenu
          value={gender}
          label={`${t('userDetails.sex')}`}
          menuItems={[
            { label: t('userDetails.male'), value: 'male' },
            { label: t('userDetails.female'), value: 'female' },
          ]}
          setValue={value => setGender(value)}
        />
      </Box>
      <CustomButton
        fullWidth
        variant={'contained'}
        onClick={handleSaveData}
        disabled={!firstName || !lastName || !gender}
      >
        {t('buttons.continue')}
      </CustomButton>
    </Box>
  );
};

export default UserDetailsOne;
