import { useIonRouter } from '@ionic/react';
import { CheckBoxes, CustomButton, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import { useSetTermsAndConditionsMutation } from '../../queries/onBoardingQueries';
import LoadingPage from '../../components/LoadingPage';
import TermsAndConditionsGR from './TermsAndConditionsGR';
import { useAppDispatch } from '../../../../redux/hooks';
import { logout } from '../../authSlice';
import { useTranslation } from 'react-i18next';
import TermsAndConditionsEN from './TermsAndConditionsEN';
import moment from 'moment';

type TandCPanelProps = {
  web: boolean;
};
const TandCPanel: FC<TandCPanelProps> = ({ web }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [tandc] = useSetTermsAndConditionsMutation();

  const [agreeTC, setAgreeTC] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [agreePrivacy, setAgreePrivacy] = useState<boolean>(false);

  const handleSignUp = async () => {
    try {
      setHasLoaded(true);
      await tandc({
        termsAndConditionsAccepted: agreeTC,
        privacyPolicyAccepted: agreePrivacy,
      }).unwrap();

      setTimeout(() => {
        setHasLoaded(false);
        router.push('/dashboard/onboarding');
      }, 1000);
    } catch (e) {
      console.log(e);
      setHasLoaded(false);
    }
  };

  const handleDecline = () => {
    dispatch(logout());
    router.push('/', 'root', 'replace');
  };

  return (
    <>
      {hasLoaded ? (
        <LoadingPage web={web} />
      ) : (
        <Box
          height={'100%'}
          display={'flex'}
          flexWrap={'wrap'}
          margin={'0 auto'}
          maxWidth={'350px'}
          textAlign={'center'}
          alignContent={'space-between'}
        >
          <Box gap={3} display={'flex'} flexDirection={'column'}>
            <Box textAlign={'center'}>
              <img
                src={LogoHeartPNG}
                alt={'company logo'}
                width={web ? '78px' : '54px'}
                height={web ? '80px' : '56px'}
              />
            </Box>

            <Box
              height={'420px'}
              textAlign={'left'}
              overflow={'auto'}
              borderRadius={'8px'}
              boxSizing={'border-box'}
              padding={theme.spacing(2)}
              bgcolor={theme.palette.secondary.main}
            >
              {lang === 'en' && <TermsAndConditionsEN />}
              {lang === 'el' && <TermsAndConditionsGR />}
            </Box>
            <Box gap={3} display={'flex'} flexDirection={'column'} px={2}>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <CheckBoxes
                  size={'mobile'}
                  checked={agreeTC}
                  setChecked={setAgreeTC}
                  label={`${t('authentication.signUp.t&c.agreeTC')}`}
                />
                <CheckBoxes
                  size={'mobile'}
                  checked={agreePrivacy}
                  setChecked={setAgreePrivacy}
                  label={`${t('authentication.signUp.t&c.agreePrivacy')}`}
                />
              </Box>
              <Box>
                <Typography variant={'body2'}>
                  To read the Privacy Policy click{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/privacy_policy.html"
                  >
                    here
                  </a>
                </Typography>
              </Box>
              <CustomButton
                fullWidth
                variant={'contained'}
                onClick={handleSignUp}
                disabled={!agreePrivacy || !agreeTC}
              >
                {t('buttons.accept')}
              </CustomButton>
              <CustomButton variant={'text'} onClick={handleDecline}>
                {t('buttons.decline')}
              </CustomButton>
            </Box>
          </Box>
          <Box margin={'0 auto'}>
            <Typography
              variant={'body1'}
              sx={{
                display: web ? 'inherit' : 'none',
                color: theme.palette.primary.light,
                fontWeight: theme.typography.fontWeightRegular,
              }}
            >
              {t('karabinis.footer', { year: moment().year() })}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TandCPanel;
