import React, { FC } from 'react';
import DashDataCards from './DashDataCards';
import { useIonRouter } from '@ionic/react';

type Props = { userId: string };
const UserOverview: FC<Props> = ({ userId }) => {
  const router = useIonRouter();

  const handleClickCard = (value: string) => {
    router.push(`/dashboard/${value}`);
  };

  return <DashDataCards userId={userId} handleClickCard={handleClickCard} />;
};

export default UserOverview;
